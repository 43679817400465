import { useEffect } from 'react';
import { Form, Row, Typography } from 'antd';
import Wrapper from '../Wrapper';
import Experiences from '../../../../Common/Experiences';
import General from '../../../../Common/Applicant/General';
import AHPRAnumber from '../../../../Common/Applicant/AHPRAnumber';
import Indemnity from '../../../../Common/Applicant/Indemnity';
import Contacts from '../../../../Common/Applicant/Contacts/index';
import { useSettingsContext } from '../../Context';

export default function Details() {
  const { applicant } = useSettingsContext();
  const [form] = Form.useForm();
  const experiencesId = applicant?.experiences.map((exp) => exp.id);

  useEffect(() => {
    form.setFieldsValue({
      experiences: experiencesId,
      title: applicant?.title,
      address: applicant?.user?.address,
      firstName: applicant?.user?.firstName,
      lastName: applicant?.user?.lastName,
      phone: applicant?.user.phone,
      email: applicant?.user.email,
      ahpra: applicant?.ahpra,
      indemnityProvider: applicant?.indemnityProvider?.title,
      indemnityNumber: applicant?.indemnityNumber,
    });
  }, [applicant, form]);

  return (
    <Form form={form} className="applicant-single-wrapper" layout="vertical">
      <Wrapper>
        <Typography.Title level={5} style={{ margin: 0 }}>
          General details
        </Typography.Title>
        <Experiences disabled />
        <Row gutter={[16, 4]} style={{ marginTop: '12px' }}>
          <General disabled />
          <Contacts />
        </Row>
      </Wrapper>
      <AHPRAnumber disabled />
      <Indemnity disabled />
    </Form>
  );
}
