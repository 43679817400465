import {
  Typography, Form, Input, Select,
} from 'antd';
import { useEffect } from 'react';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { InfoCircleTwoTone } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { validationUserAdd, createRulesForAntd } from '../../../utils/validations';
import { useLocationsActiveGet } from '../../../hooks/location';
import { useAuthState } from '../../../store/auth';
import { UserRoles } from '../../../enums/user';
import { COLOR_PRIMARY } from '../../../AntdConfigProvider';
import PhoneNumberInput from '../PhoneNumberInput';
import { useMessageError } from '../../../hooks/common';

const validationRules = createRulesForAntd(validationUserAdd);

interface UserFieldsProps {
  disableRole?: boolean;
  showLocation?: boolean;
}

function UserFields({ disableRole, showLocation }: UserFieldsProps) {
  const { user } = useAuthState();
  const clientId = user?.locationAdmin?.client.id || user?.manager?.client.id;
  const locations = useLocationsActiveGet();
  const { t } = useTranslation();

  useEffect(() => {
    locations.fetch({ clients: [clientId] || ' ' });
  }, []);

  useMessageError([locations]);

  return (
    <>
      <Typography.Title style={{ margin: '0 0 15px 0' }} level={5}>
        Personal info
      </Typography.Title>

      <Form.Item
        label="First Name"
        name="firstName"
        rules={[
          { required: true, message: 'First name should be at least one character long!' },
          { min: 2, message: 'Must be at least 2 characters' },
        ]}
      >
        <Input placeholder="First Name" />
      </Form.Item>

      <Form.Item
        label="Last Name"
        name="lastName"
        rules={[
          { required: true, message: ' Last name should be at least one character long!' },
          { min: 2, message: 'Must be at least 2 characters' },
        ]}
      >
        <Input placeholder="Last Name" />
      </Form.Item>

      <Form.Item label="Phone" name="phone" rules={[validationRules]}>
        <PhoneNumberInput placeholder="Phone" />
      </Form.Item>

      <Form.Item label="Contact Email" name="email" rules={[validationRules]}>
        <Input placeholder="Email" />
      </Form.Item>

      <Form.Item
        label="Role"
        name="role"
        tooltip={{
          title: t('userRole'),
          icon: <InfoCircleTwoTone style={{ cursor: 'help' }} twoToneColor={COLOR_PRIMARY} />,
        }}
      >
        <Select
          disabled={disableRole}
          placeholder="Please select role"
          options={[
            {
              label: 'Client Admin',
              value: UserRoles.CLIENT_ADMIN,
            },
            {
              label: 'Manager',
              value: UserRoles.MANAGER,
            },
          ]}
        />
      </Form.Item>

      {showLocation && (
        <Form.Item
          name="location"
          label="Select location"
          rules={[{ required: true, message: 'Please select location!' }]}
        >
          <Select<string, DefaultOptionType>
            mode="multiple"
            options={locations?.data?.map((item) => ({ value: item.id, label: item.name })) || []}
            placeholder="Please select"
          />
        </Form.Item>
      )}
    </>
  );
}

UserFields.defaultProps = {
  disableRole: false,
  showLocation: false,
};

export default UserFields;
