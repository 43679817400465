import React, { CSSProperties } from 'react';
import { AnyObject } from '@triare/auth-redux';

/* eslint-disable max-len */

interface Svg {
  children: React.ReactNode;
  className?: string;
  viewBox?: string;
  style?: CSSProperties;
}

export function Svg({
  children, className, viewBox, ...props
}: Svg): React.ReactNode {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      height="1em"
      width="1em"
      viewBox={viewBox}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {children}
    </svg>
  );
}

Svg.defaultProps = {
  className: '',
  viewBox: '0 0 24 24',
  style: {},
};

function TextLogo(): React.ReactNode {
  return (
    <path
      d="M145.807 20.052h14.158v51.883h25.12V84.45h-39.278V20.052Zm49.159 8.312c6.272-6.272 14.097-9.408 23.475-9.408 9.378 0 17.173 3.136 23.384 9.408 6.273 6.212 9.409 14.19 9.409 23.932 0 9.744-3.136 17.752-9.409 24.024-6.211 6.211-14.006 9.317-23.384 9.317s-17.203-3.106-23.475-9.317c-6.273-6.272-9.409-14.28-9.409-24.024 0-9.743 3.136-17.72 9.409-23.932Zm36.994 10.14c-3.593-3.593-8.099-5.39-13.519-5.39s-9.926 1.797-13.519 5.39c-3.593 3.592-5.389 8.19-5.389 13.792 0 5.603 1.796 10.2 5.389 13.793s8.099 5.39 13.519 5.39 9.926-1.797 13.519-5.39 5.389-8.19 5.389-13.793c0-5.602-1.796-10.2-5.389-13.792Zm24.726-18.452h14.159v27.677l25.941-27.677h16.351L287.469 47.09l26.125 37.36h-15.985l-19.365-27.586-7.399 7.947v19.638h-14.159V20.052Zm60.926 64.398V20.052h44.576v12.514H331.77v13.336h27.404v12.606H331.77v13.427h30.418V84.45h-44.576Zm51.292-64.398h15.894l20.461 33.889 20.644-33.889h15.893V84.45h-14.158V43.07l-22.379 36.356-22.197-36.355V84.45h-14.158V20.052Z"
      fill="#73BF44"
    />
  );
}

function LogoRect(): React.ReactNode {
  return (
    <>
      <path
        d="M21.75 86.79H3.075a2.887 2.887 0 0 1-2.886-2.886V65.227a2.887 2.887 0 0 1 2.886-2.885H21.75a2.886 2.886 0 0 1 2.886 2.885v18.677a2.894 2.894 0 0 1-2.886 2.885Z"
        fill="#74C044"
      />
      <path
        d="M44.067 63.785H25.39a2.887 2.887 0 0 1-2.885-2.885V42.223a2.887 2.887 0 0 1 2.885-2.886h18.677a2.887 2.887 0 0 1 2.885 2.886V60.9a2.894 2.894 0 0 1-2.885 2.885ZM92.673 86.434h18.677a2.886 2.886 0 0 0 2.885-2.885V64.872a2.886 2.886 0 0 0-2.885-2.885H92.673a2.887 2.887 0 0 0-2.885 2.885V83.55a2.894 2.894 0 0 0 2.885 2.885Z"
        fill="#74C044"
      />
      <path
        d="M70.101 63.785h18.677a2.887 2.887 0 0 0 2.885-2.885V42.223a2.887 2.887 0 0 0-2.885-2.886H70.1a2.887 2.887 0 0 0-2.885 2.886V60.9a2.88 2.88 0 0 0 2.885 2.885Z"
        fill="#74C044"
      />
      <path
        d="M66.228 40.858H47.552a2.887 2.887 0 0 1-2.886-2.886V19.296a2.887 2.887 0 0 1 2.886-2.885h18.676a2.887 2.887 0 0 1 2.886 2.885v18.676a2.887 2.887 0 0 1-2.886 2.886ZM66.55 64.85c.21 0 .377.166.377.377v18.677c0 .21-.166.377-.377.377H47.873a.374.374 0 0 1-.377-.377V65.227c0-.21.166-.377.377-.377H66.55Zm0-2.497H47.873a2.887 2.887 0 0 0-2.885 2.886v18.676a2.887 2.887 0 0 0 2.885 2.885H66.55a2.887 2.887 0 0 0 2.885-2.885V65.238a2.887 2.887 0 0 0-2.885-2.885Z"
        fill="#74C044"
      />
    </>
  );
}

function TextGreyLogo(): React.ReactNode {
  return (
    <path
      d="M145.807 20.052h14.158v51.883h25.12V84.45h-39.278V20.052Zm49.159 8.312c6.272-6.272 14.097-9.408 23.475-9.408 9.378 0 17.173 3.136 23.384 9.408 6.273 6.212 9.409 14.19 9.409 23.932 0 9.744-3.136 17.752-9.409 24.024-6.211 6.211-14.006 9.317-23.384 9.317s-17.203-3.106-23.475-9.317c-6.273-6.272-9.409-14.28-9.409-24.024 0-9.743 3.136-17.72 9.409-23.932Zm36.994 10.14c-3.593-3.593-8.099-5.39-13.519-5.39s-9.926 1.797-13.519 5.39c-3.593 3.592-5.389 8.19-5.389 13.792 0 5.603 1.796 10.2 5.389 13.793s8.099 5.39 13.519 5.39 9.926-1.797 13.519-5.39 5.389-8.19 5.389-13.793c0-5.602-1.796-10.2-5.389-13.792Zm24.726-18.452h14.159v27.677l25.941-27.677h16.351L287.469 47.09l26.125 37.36h-15.985l-19.365-27.586-7.399 7.947v19.638h-14.159V20.052Zm60.926 64.398V20.052h44.576v12.514H331.77v13.336h27.404v12.606H331.77v13.427h30.418V84.45h-44.576Zm51.292-64.398h15.894l20.461 33.889 20.644-33.889h15.893V84.45h-14.158V43.07l-22.379 36.356-22.197-36.355V84.45h-14.158V20.052Z"
      fill="#BFBFBF"
    />
  );
}

function LogoGreyRect(): React.ReactNode {
  return (
    <>
      <path
        d="M21.75 86.79H3.075a2.887 2.887 0 0 1-2.886-2.886V65.227a2.887 2.887 0 0 1 2.886-2.885H21.75a2.886 2.886 0 0 1 2.886 2.885v18.677a2.894 2.894 0 0 1-2.886 2.885Z"
        fill="#BFBFBF"
      />
      <path
        d="M44.067 63.785H25.39a2.887 2.887 0 0 1-2.885-2.885V42.223a2.887 2.887 0 0 1 2.885-2.886h18.677a2.887 2.887 0 0 1 2.885 2.886V60.9a2.894 2.894 0 0 1-2.885 2.885ZM92.673 86.434h18.677a2.886 2.886 0 0 0 2.885-2.885V64.872a2.886 2.886 0 0 0-2.885-2.885H92.673a2.887 2.887 0 0 0-2.885 2.885V83.55a2.894 2.894 0 0 0 2.885 2.885Z"
        fill="#BFBFBF"
      />
      <path
        d="M70.101 63.785h18.677a2.887 2.887 0 0 0 2.885-2.885V42.223a2.887 2.887 0 0 0-2.885-2.886H70.1a2.887 2.887 0 0 0-2.885 2.886V60.9a2.88 2.88 0 0 0 2.885 2.885Z"
        fill="#BFBFBF"
      />
      <path
        d="M66.228 40.858H47.552a2.887 2.887 0 0 1-2.886-2.886V19.296a2.887 2.887 0 0 1 2.886-2.885h18.676a2.887 2.887 0 0 1 2.886 2.885v18.676a2.887 2.887 0 0 1-2.886 2.886ZM66.55 64.85c.21 0 .377.166.377.377v18.677c0 .21-.166.377-.377.377H47.873a.374.374 0 0 1-.377-.377V65.227c0-.21.166-.377.377-.377H66.55Zm0-2.497H47.873a2.887 2.887 0 0 0-2.885 2.886v18.676a2.887 2.887 0 0 0 2.885 2.885H66.55a2.887 2.887 0 0 0 2.885-2.885V65.238a2.887 2.887 0 0 0-2.885-2.885Z"
        fill="#BFBFBF"
      />
    </>
  );
}

function LogoWrapper({ children, width, ...props }: AnyObject): React.ReactNode {
  return (
    <svg
      width={width}
      height="104"
      viewBox={`0 0 ${width} 104`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)">{children}</g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 16h445v71.022H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function Logo(props: AnyObject): React.ReactNode {
  return (
    process.env.REACT_APP_LOGO && process.env.REACT_APP_LOGO_WIDTH && process.env.REACT_APP_LOGO_HEIGHT ? (
      <svg
        height={104}
        viewBox={`0 0 ${process.env.REACT_APP_LOGO_WIDTH} ${process.env.REACT_APP_LOGO_HEIGHT}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <image
          href={process.env.REACT_APP_LOGO}
          width={process.env.REACT_APP_LOGO_WIDTH}
          height={process.env.REACT_APP_LOGO_HEIGHT}
        />
      </svg>
    ) : (
      <LogoWrapper width={445} {...props}>
        <TextLogo />
        <LogoRect />
      </LogoWrapper>
    )
  );
}

export function LogoGrey(props: AnyObject): React.ReactNode {
  return (
    <LogoWrapper width={445} {...props}>
      <TextGreyLogo />
      <LogoGreyRect />
    </LogoWrapper>
  );
}

export function LogoMini(props: AnyObject): React.ReactNode {
  return (
    <LogoWrapper width={114} {...props}>
      <LogoRect />
    </LogoWrapper>
  );
}

export function SuccessIcon(props: AnyObject) {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_1289_165848)">
        <path
          d="M38.5547 80C58.7433 80 75.1094 63.6339 75.1094 43.4453C75.1094 23.2567 58.7433 6.89056 38.5547 6.89056C18.3661 6.89056 2 23.2567 2 43.4453C2 63.6339 18.3661 80 38.5547 80Z"
          fill="#EFF6EB"
        />
        <path
          d="M38.4205 47.788C38.0833 47.7884 37.7557 47.676 37.4897 47.4688C37.2237 47.2615 37.0347 46.9713 36.9527 46.6443C36.8706 46.3172 36.9003 45.9721 37.0369 45.6639C37.1735 45.3556 37.4093 45.1019 37.7067 44.9431L65.0853 30.3112C66.4816 29.4186 67.5442 28.0901 68.1082 26.5319C68.6721 24.9737 68.706 23.2728 68.2044 21.6934C67.7953 20.4037 67.046 19.2483 66.0355 18.3487C65.0249 17.449 63.7905 16.8386 62.4622 16.5815C61.1338 16.3244 59.7608 16.4303 58.4876 16.888C57.2144 17.3456 56.0881 18.1382 55.2274 19.1821L53.9005 20.7923C53.7778 20.9552 53.6233 21.0916 53.4465 21.1933C53.2697 21.2949 53.0741 21.3597 52.8716 21.3838C52.6691 21.4079 52.4638 21.3907 52.268 21.3334C52.0723 21.276 51.8902 21.1796 51.7327 21.05C51.5752 20.9204 51.4456 20.7603 51.3516 20.5793C51.2577 20.3982 51.2013 20.2001 51.1859 19.9967C51.1706 19.7933 51.1965 19.5889 51.2622 19.3958C51.328 19.2028 51.4321 19.025 51.5683 18.8732L52.8952 17.263C54.1017 15.799 55.6806 14.6876 57.4657 14.0457C59.2509 13.4038 61.1761 13.2553 63.0385 13.6157C64.9009 13.9762 66.6317 14.8323 68.0484 16.0939C69.4652 17.3554 70.5154 18.9757 71.0885 20.7841C71.7934 23.0086 71.7408 25.404 70.9389 27.5954C70.1371 29.7868 68.6313 31.6504 66.6572 32.8947C66.6261 32.9139 66.595 32.9321 66.5658 32.9486L39.1315 47.6089C38.913 47.7266 38.6687 47.7882 38.4205 47.788Z"
          fill="white"
          stroke="#61A23A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M38.4071 47.788C38.7443 47.7884 39.0719 47.676 39.3379 47.4688C39.6038 47.2615 39.7929 46.9713 39.8749 46.6443C39.957 46.3172 39.9273 45.9721 39.7907 45.6639C39.654 45.3556 39.4183 45.1019 39.1208 44.9431L11.7423 30.3112C10.3473 29.4185 9.28563 28.0908 8.72175 26.5336C8.15786 24.9765 8.12324 23.2768 8.62324 21.698C9.03233 20.4083 9.7816 19.2529 10.7921 18.3532C11.8027 17.4536 13.0371 16.8431 14.3654 16.5861C15.6938 16.329 17.0668 16.4349 18.34 16.8926C19.6132 17.3502 20.7395 18.1427 21.6002 19.1867L22.9317 20.7923C23.0552 20.9529 23.2097 21.087 23.3861 21.1867C23.5625 21.2864 23.7571 21.3495 23.9584 21.3724C24.1597 21.3953 24.3636 21.3775 24.5579 21.32C24.7521 21.2625 24.9328 21.1665 25.0893 21.0377C25.2457 20.909 25.3746 20.7501 25.4683 20.5705C25.5621 20.3909 25.6188 20.1942 25.635 19.9923C25.6512 19.7903 25.6266 19.5872 25.5627 19.3949C25.4988 19.2027 25.3968 19.0253 25.263 18.8732L23.9369 17.2593C22.7306 15.7949 21.1515 14.683 19.3662 14.0408C17.5808 13.3986 15.6553 13.25 13.7925 13.6105C11.9298 13.9709 10.1987 14.8272 8.78187 16.0891C7.36499 17.351 6.31475 18.9717 5.74182 20.7804C5.03687 23.005 5.08953 25.4005 5.89156 27.592C6.69359 29.7834 8.1997 31.647 10.1741 32.891C10.2042 32.9102 10.2353 32.9285 10.2655 32.9449L37.6961 47.6089C37.9147 47.7264 38.159 47.788 38.4071 47.788Z"
          fill="white"
          stroke="#61A23A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M38.4139 44.9687C28.1786 44.9687 19.8779 28.6854 19.8779 18.4492V17.2931C19.8779 17.1413 19.9078 16.991 19.9659 16.8507C20.024 16.7105 20.1092 16.583 20.2165 16.4757C20.3239 16.3683 20.4513 16.2832 20.5916 16.2251C20.7318 16.167 20.8822 16.1371 21.034 16.1371H55.7939C55.9456 16.1371 56.0959 16.167 56.2361 16.2251C56.3763 16.2832 56.5037 16.3684 56.611 16.4757C56.7182 16.5831 56.8033 16.7105 56.8613 16.8508C56.9193 16.9911 56.9491 17.1414 56.949 17.2931V18.4492C56.949 28.6854 48.6501 44.9687 38.4139 44.9687Z"
          fill="white"
        />
        <path
          d="M38.3503 74.2993C45.2472 74.2993 50.8383 73.3554 50.8383 72.191C50.8383 71.0266 45.2472 70.0827 38.3503 70.0827C31.4534 70.0827 25.8623 71.0266 25.8623 72.191C25.8623 73.3554 31.4534 74.2993 38.3503 74.2993Z"
          fill="white"
        />
        <path
          d="M38.4159 47.4919C35.3408 48.0009 30.6426 57.4238 30.6426 60.7146H46.1893C46.1893 60.7146 41.4911 46.9865 38.4159 47.4919Z"
          fill="white"
        />
        <path
          d="M49.6962 71.6372H27.1355C26.8932 71.6372 26.6607 71.5409 26.4893 71.3695C26.318 71.1981 26.2217 70.9657 26.2217 70.7233V67.1208H50.6128V70.7233C50.6128 70.8436 50.5891 70.9626 50.543 71.0737C50.4969 71.1847 50.4293 71.2856 50.3442 71.3705C50.259 71.4554 50.158 71.5226 50.0468 71.5684C49.9356 71.6142 49.8164 71.6375 49.6962 71.6372Z"
          fill="white"
          stroke="#61A23A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M50.6101 67.1209H26.2217L27.799 62.6137C27.9932 62.06 28.3545 61.5803 28.833 61.2408C29.3115 60.9013 29.8836 60.7187 30.4703 60.7183H46.3615C46.9482 60.7187 47.5203 60.9013 47.9988 61.2408C48.4773 61.5803 48.8386 62.06 49.0327 62.6137L50.6101 67.1209Z"
          fill="white"
          stroke="#61A23A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M38.4139 44.9687C28.1786 44.9687 19.8779 28.6854 19.8779 18.4492V17.2931C19.8779 17.1413 19.9078 16.991 19.9659 16.8507C20.024 16.7105 20.1092 16.583 20.2165 16.4757C20.3239 16.3683 20.4513 16.2832 20.5916 16.2251C20.7318 16.167 20.8822 16.1371 21.034 16.1371H55.7939C55.9456 16.1371 56.0959 16.167 56.2361 16.2251C56.3763 16.2832 56.5037 16.3684 56.611 16.4757C56.7182 16.5831 56.8033 16.7105 56.8613 16.8508C56.9193 16.9911 56.9491 17.1414 56.949 17.2931V18.4492C56.949 28.6854 48.6501 44.9687 38.4139 44.9687Z"
          stroke="#61A23A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M46.0904 60.7183C46.0904 60.7183 49.5796 49.6139 38.4139 43.1062C27.2483 36.5986 19.8779 27.7523 19.8779 18.4492C19.8779 35.3466 23.3954 40.8389 32.2563 45.4604C41.1171 50.0818 42.6232 55.5559 46.0904 60.7183Z"
          fill="white"
        />
        <path
          d="M46.0904 60.7183C46.0904 60.7183 49.5796 49.6139 38.4139 43.1062C27.2483 36.5986 19.8779 27.7523 19.8779 18.4492C19.8779 35.3466 23.3954 40.8389 32.2563 45.4604C41.1171 50.0818 42.6232 55.5559 46.0904 60.7183Z"
          stroke="#61A23A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.7456 60.7183C30.7456 60.7183 27.2565 49.6139 38.4221 43.1053C49.5877 36.5967 56.9581 27.7523 56.9581 18.4492C56.9581 35.3466 53.4406 40.8389 44.5798 45.4604C35.7189 50.0818 34.2128 55.5559 30.7456 60.7183Z"
          fill="white"
        />
        <path
          d="M30.7456 60.7183C30.7456 60.7183 27.2565 49.6139 38.4221 43.1053C49.5877 36.5967 56.9581 27.7523 56.9581 18.4492C56.9581 35.3466 53.4406 40.8389 44.5798 45.4604C35.7189 50.0818 34.2128 55.5559 30.7456 60.7183Z"
          stroke="#61A23A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M50.0041 5.41193C49.4411 6.29946 48.6684 7.03481 47.7541 7.5531C46.8397 8.07139 45.8119 8.35669 44.7612 8.38383L42.9033 5.10761C43.9541 5.08029 44.9819 4.79482 45.8962 4.27638C46.8106 3.75793 47.5833 3.02245 48.1462 2.1348L50.0041 5.41193Z"
          fill="white"
          stroke="#61A23A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.1099 0.456934C30.4872 1.43793 30.6173 2.49674 30.4885 3.53988C30.3597 4.58302 29.9761 5.57842 29.3715 6.4382L25.6338 5.98127C26.2382 5.12141 26.6217 4.12597 26.7503 3.08283C26.8789 2.03969 26.7487 0.980925 26.3713 0L30.1099 0.456934Z"
          fill="white"
          stroke="#61A23A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.27 20.5136C13.5916 19.7107 13.1214 18.7531 12.9006 17.7255C12.6799 16.6978 12.7155 15.6316 13.0043 14.621L16.6862 13.8296C16.3978 14.8403 16.3625 15.9065 16.5834 16.9341C16.8043 17.9617 17.2746 18.9192 17.9529 19.7222L14.27 20.5136Z"
          fill="white"
          stroke="#61A23A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M68.8924 65.1122C68.2142 64.3094 67.7439 63.3521 67.523 62.3246C67.3021 61.2971 67.3374 60.2311 67.6258 59.2205L71.3087 58.4291C71.02 59.4397 70.9846 60.5058 71.2055 61.5333C71.4264 62.5608 71.8968 63.5181 72.5753 64.3208L68.8924 65.1122Z"
          fill="white"
          stroke="#61A23A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.5797 60.7366C27.532 61.1817 28.3601 61.8544 28.991 62.6952C29.6218 63.5361 30.0359 64.5193 30.1968 65.5581L27.1811 67.8181C27.0209 66.7802 26.6079 65.7976 25.9784 64.9569C25.3488 64.1162 24.5222 63.4433 23.5713 62.9975L26.5797 60.7366Z"
          fill="white"
          stroke="#61A23A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M58.6982 33.0117C57.746 33.4568 56.918 34.1294 56.2873 34.9703C55.6566 35.8112 55.2427 36.7945 55.082 37.8332L58.0978 40.0932C58.2581 39.0545 58.672 38.0712 59.3027 37.2304C59.9334 36.3896 60.7616 35.7172 61.714 35.2726L58.6982 33.0117Z"
          fill="white"
          stroke="#61A23A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M55.4034 49.4265C56.3659 49.849 57.2095 50.5019 57.8597 51.3277C58.51 52.1535 58.947 53.1266 59.132 54.1613L56.1729 56.4917C55.9876 55.4571 55.5505 54.4841 54.9003 53.6584C54.25 52.8327 53.4066 52.1796 52.4443 51.7569L55.4034 49.4265Z"
          fill="white"
          stroke="#61A23A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.91797 57.0345C3.04262 58.0781 3.4223 59.0749 4.02349 59.937C4.62467 60.7991 5.42887 61.4999 6.36508 61.9776L9.45487 59.8227C8.51859 59.345 7.71429 58.6442 7.11296 57.7821C6.51162 56.9201 6.13174 55.9232 6.00684 54.8796L2.91797 57.0345Z"
          fill="white"
          stroke="#61A23A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.51545 32.2897C7.63814 32.8685 6.63189 33.2225 5.58545 33.3205C4.539 33.4185 3.48451 33.2575 2.51499 32.8517L2.16406 29.1048C3.13343 29.5107 4.18777 29.6716 5.23407 29.5736C6.28038 29.4756 7.28647 29.1216 8.16361 28.5428L8.51545 32.2897Z"
          fill="white"
          stroke="#61A23A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M77.6766 42.9966C76.7996 43.576 75.7933 43.9304 74.7467 44.0285C73.7001 44.1265 72.6455 43.9651 71.6761 43.5586L71.3252 39.8117C72.2947 40.2175 73.3492 40.3785 74.3957 40.2805C75.4421 40.1825 76.4484 39.8285 77.3257 39.2497L77.6766 42.9966Z"
          fill="white"
          stroke="#61A23A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M57.3286 28.4661C56.2921 28.6352 55.23 28.5469 54.2357 28.2089C53.2415 27.8708 52.3456 27.2935 51.627 26.5277L52.8378 22.9637C53.5568 23.7303 54.4534 24.3081 55.4486 24.6461C56.4438 24.9842 57.5069 25.072 58.544 24.902L57.3286 28.4661Z"
          fill="white"
          stroke="#61A23A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.9707 46.9362C12.1958 45.9095 12.6701 44.9539 13.3517 44.1537C14.0334 43.3534 14.9014 42.7332 15.8793 42.3477L18.7461 44.7905C17.7684 45.1758 16.9004 45.7959 16.219 46.596C15.5375 47.396 15.0634 48.3515 14.8384 49.3781L11.9707 46.9362Z"
          fill="white"
          stroke="#61A23A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1289_165848">
          <rect width="80" height="80" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function MultiDayIcon(props: AnyObject) {
  return (
    <div style={{ width: '16px', height: '16px' }}>
      <svg
        style={{ width: '100%', height: '100%' }}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fill="#8C8C8C"
          d="M16.9 11c-.46-2.28-2.48-4-4.9-4s-4.44 1.72-4.9 4H2v2h5.1c.46 2.28 2.48 4 4.9 4s4.44-1.72 4.9-4H22v-2h-5.1zM12 15c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3z"
        />
      </svg>
    </div>
  );
}

export function ResizeIcon(props: AnyObject) {
  return (
    <Svg {...props} viewBox="0 0 24 36">
      <rect x="-0.5" y="0.5" width="2" height="35" rx="1" transform="matrix(-1 0 0 1 13.0078 0)" />
      <rect x="-0.5" y="0.5" width="2" height="35" rx="1" transform="matrix(-1 0 0 1 13.0078 0)" />
      <path d="M0 18L5 20.8868V15.1132L0 18ZM24 18L19 15.1132V20.8868L24 18ZM3 18.5H5V17.5H3V18.5ZM7 18.5H9V17.5H7V18.5ZM11 18.5H13V17.5H11V18.5ZM15 18.5H17V17.5H15V18.5ZM19 18.5H21V17.5H19V18.5Z" />
    </Svg>
  );
}

export function CopyIcon(props: AnyObject) {
  return (
    <Svg {...props} viewBox="0 0 24 24">
      <path
        d="M3.5 19V17H5.5V19H3.5ZM3.5 15.5V13.5H5.5V15.5H3.5ZM3.5 12V10H5.5V12H3.5ZM7 22.5V20.5H9V22.5H7ZM9.5 18.5C8.95 18.5 8.47917 18.3042 8.0875 17.9125C7.69583 17.5208 7.5 17.05 7.5 16.5V4.5C7.5 3.95 7.69583 3.47917 8.0875 3.0875C8.47917 2.69583 8.95 2.5 9.5 2.5H18.5C19.05 2.5 19.5208 2.69583 19.9125 3.0875C20.3042 3.47917 20.5 3.95 20.5 4.5V16.5C20.5 17.05 20.3042 17.5208 19.9125 17.9125C19.5208 18.3042 19.05 18.5 18.5 18.5H9.5ZM9.5 16.5H18.5V4.5H9.5V16.5ZM10.5 22.5V20.5H12.5V22.5H10.5ZM5.5 22.5C4.95 22.5 4.47917 22.3042 4.0875 21.9125C3.69583 21.5208 3.5 21.05 3.5 20.5H5.5V22.5ZM14 22.5V20.5H16C16 21.05 15.8042 21.5208 15.4125 21.9125C15.0208 22.3042 14.55 22.5 14 22.5ZM3.5 8.5C3.5 7.95 3.69583 7.47917 4.0875 7.0875C4.47917 6.69583 4.95 6.5 5.5 6.5V8.5H3.5Z"
        fill="#BFBFBF"
      />
    </Svg>
  );
}
export function MoveIcon(props: AnyObject) {
  return (
    <Svg {...props} viewBox="0 0 24 24">
      <path
        d="M1.5 12.5V10.5H3.5V12.5H1.5ZM21.5 12.5V10.5H23.5V12.5H21.5ZM1.5 8.5V6.5H3.5V8.5H1.5ZM21.5 8.5V6.5H23.5V8.5H21.5ZM1.5 4.5V2.5H3.5V4.5H1.5ZM5.5 12.5V10.5H7.5V12.5H5.5ZM17.5 12.5V10.5H19.5V12.5H17.5ZM21.5 4.5V2.5H23.5V4.5H21.5ZM5.5 4.5V2.5H7.5V4.5H5.5ZM9.5 4.5V2.5H11.5V4.5H9.5ZM13.5 4.5V2.5H15.5V4.5H13.5ZM17.5 4.5V2.5H19.5V4.5H17.5ZM12.325 23.5C11.925 23.5 11.5417 23.425 11.175 23.275C10.8083 23.125 10.4833 22.9083 10.2 22.625L5.1 17.5L5.925 16.65C6.15833 16.4167 6.44167 16.2583 6.775 16.175C7.10833 16.0917 7.44167 16.0917 7.775 16.175L9.5 16.675V8.5C9.5 8.21667 9.59583 7.97917 9.7875 7.7875C9.97917 7.59583 10.2167 7.5 10.5 7.5C10.7833 7.5 11.0208 7.59583 11.2125 7.7875C11.4042 7.97917 11.5 8.21667 11.5 8.5V19.325L9.05 18.625L11.625 21.2C11.725 21.3 11.8333 21.375 11.95 21.425C12.0667 21.475 12.1917 21.5 12.325 21.5H16.5C17.05 21.5 17.5208 21.3042 17.9125 20.9125C18.3042 20.5208 18.5 20.05 18.5 19.5V15.5C18.5 15.2167 18.5958 14.9792 18.7875 14.7875C18.9792 14.5958 19.2167 14.5 19.5 14.5C19.7833 14.5 20.0208 14.5958 20.2125 14.7875C20.4042 14.9792 20.5 15.2167 20.5 15.5V19.5C20.5 20.6 20.1083 21.5417 19.325 22.325C18.5417 23.1083 17.6 23.5 16.5 23.5H12.325ZM12.5 16.5V12.5C12.5 12.2167 12.5958 11.9792 12.7875 11.7875C12.9792 11.5958 13.2167 11.5 13.5 11.5C13.7833 11.5 14.0208 11.5958 14.2125 11.7875C14.4042 11.9792 14.5 12.2167 14.5 12.5V16.5H12.5ZM15.5 16.5V13.5C15.5 13.2167 15.5958 12.9792 15.7875 12.7875C15.9792 12.5958 16.2167 12.5 16.5 12.5C16.7833 12.5 17.0208 12.5958 17.2125 12.7875C17.4042 12.9792 17.5 13.2167 17.5 13.5V16.5H15.5Z"
        fill="#BFBFBF"
      />
    </Svg>
  );
}

export default Svg;
