import { useEffect, useState, useRef } from 'react';
import {
  Space, Transfer, Typography, App, Tooltip,
} from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';
import { useInductionsGet } from '../../../../../hooks/inductions';
import { UserRoles } from '../../../../../enums/user';
import { useAuthState } from '../../../../../store/auth';
import { useLocationContext } from '../../Context';
import { useLocationUpdate } from '../../../../../hooks/location';
import { useMessageError } from '../../../../../hooks/common';

import styles from './index.module.scss';
import { COLOR_PRIMARY } from '../../../../../AntdConfigProvider';

export interface RecordType {
  key: string;
  title: string;
  description?: string;
  chosen?: boolean;
}

export default function Inductions() {
  const { user } = useAuthState();
  const inductions = useInductionsGet();
  const [targetKeys, setTargetKeys] = useState<string[]>([]);
  const { message } = App.useApp();
  const {
    setEdit, isEdit, setOnSave, setChanging, fetch, setLoading, location,
  } = useLocationContext();
  const updateLocation = useLocationUpdate();
  const targets = useRef<string[]>([]);

  useEffect(() => {
    if (updateLocation.data) {
      message.info('Induction info has been successfully updated');
      setChanging(() => false);
      fetch();
      setLoading(false);
    }
  }, [updateLocation.data]);

  useEffect(() => {
    setEdit(() => true);
    setChanging(() => false);
    setOnSave(() => onSave);
  }, []);

  useEffect(() => {
    if (location?.inductions) {
      setTargetKeys(location.inductions.map((item) => item.id));
    }
    setEdit(() => true);
  }, [isEdit]);

  const onSave = () => {
    setLoading(true);
    updateLocation.fetch({ inductions: targets.current }, location?.id);
  };

  useEffect(() => {
    inductions.fetch();
  }, []);

  const onChange = (nextSourceKeys: string[]) => {
    setTargetKeys(nextSourceKeys);
    targets.current = nextSourceKeys;
    setChanging(() => true);
  };

  useMessageError([inductions, updateLocation]);

  return (
    <Space direction="vertical">
      <div className={styles.titles}>
        <Typography.Title level={5} className={styles.title}>
          Your Selected Inductions
          {[UserRoles.CLIENT_ADMIN, UserRoles.MANAGER].includes(user?.role as UserRoles) ? (
            <Tooltip
              className={styles.marginTooltip}
              title="Inductions listed here will be available to assign to roles."
            >
              <InfoCircleTwoTone style={{ cursor: 'help' }} twoToneColor={COLOR_PRIMARY} />
            </Tooltip>
          ) : null}
        </Typography.Title>

        <Typography.Title level={5} className={styles.title}>
          Inductions list
          {[UserRoles.CLIENT_ADMIN, UserRoles.MANAGER].includes(user?.role as UserRoles) ? (
            <Tooltip
              className={styles.marginTooltip}
              title={'This list displays all available inductions. '
                + 'If you need one that is missing, please contact us.'}
            >
              <InfoCircleTwoTone style={{ cursor: 'help' }} twoToneColor={COLOR_PRIMARY} />
            </Tooltip>
          ) : null}
        </Typography.Title>

      </div>
      <Transfer<RecordType>
        disabled={!location?.isActive}
        className={styles.transfer}
        dataSource={inductions.data?.map((item) => ({ title: item.title, key: item.id })) || []}
        targetKeys={targetKeys}
        // @ts-ignore string[] as Key[]
        onChange={onChange}
        selectAllLabels={['All', 'All']}
        render={(item) => item.title}
      />
    </Space>
  );
}
