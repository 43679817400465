import { Modal } from 'antd';
import React from 'react';
import { ModalFuncProps } from 'antd/es/modal/interface';
import { ModalStaticFunctions } from 'antd/es/modal/confirm';

import styles from './index.module.scss';

export interface UseSimpleModalProps extends ModalFuncProps {
  contentH2?: string
  contentSpan?: string
}

interface UseSimpleModal {
  open: (props?: UseSimpleModalProps) => void
  modal: Omit<ModalStaticFunctions, 'warn'>
  contextHolder: React.ReactElement
}

export function useSimpleModal(initProps?: UseSimpleModalProps): UseSimpleModal {
  const [modal, contextHolder] = Modal.useModal();

  return {
    open: (props?: UseSimpleModalProps) => modal.confirm({
      className: styles.modal,
      title: 'Confirm',
      ...initProps,
      ...props,
    }),
    modal,
    contextHolder,
  };
}

interface SimpleModalProps extends UseSimpleModalProps {
  open: boolean;
}

export default function SimpleModal(initProps: SimpleModalProps) {
  const { contextHolder } = useSimpleModal(initProps);

  return contextHolder;
}
