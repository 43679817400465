import { Row } from 'antd';
import ViewItem from '../ViewItem';
import { getDaysBetween, getHoursBetween } from '../../../../utils';
import styles from './index.module.scss';
import { isRoleEnough, UserRoles } from '../../../../enums/user';
import { useAuthState } from '../../../../store/auth';

interface DateTimeDetailProps {
  timeStart: string;
  timeEnd: string;
  onCallTimeStart: string;
  onCallTimeEnd: string;
  duration: string | undefined;
}
export default function DateTimeDetail({
  timeStart,
  timeEnd,
  onCallTimeStart,
  onCallTimeEnd,
  duration,
}: DateTimeDetailProps) {
  const { user } = useAuthState();
  const isAdmin = isRoleEnough(user?.role, UserRoles.LOKEM_ADMIN);

  return (
    <div className={styles.wrapper}>
      <Row gutter={[16, 0]}>
        <ViewItem text="Day(s)*" value={getDaysBetween(timeStart || '', timeEnd || '')} />
        <ViewItem text="Hours*" value={getHoursBetween(timeStart || '', timeEnd || '')} />
        {isAdmin && duration && <ViewItem text="Shift duration" value={duration} />}
      </Row>
      <Row gutter={[16, 0]}>
        <ViewItem text="On-call required?" value={onCallTimeStart ? 'Yes' : 'No'} />
      </Row>
      {onCallTimeStart && (
        <Row gutter={[16, 0]}>
          <ViewItem text="Day(s)*" value={getDaysBetween(onCallTimeStart || '', onCallTimeEnd || '')} />
          <ViewItem text="Hours*" value={getHoursBetween(onCallTimeStart || '', onCallTimeEnd || '')} />
        </Row>
      )}
    </div>
  );
}
