import { Layout, Space } from 'antd';
import { useEffect } from 'react';
import { useNotificationsGet, useNotificationsSaw } from '../../../hooks/notification';
import NotificationsContent from '../NotificationsModal/NotificationModalContent';
import {
  DefaultFetchError, FetchSuccess, FetchUpdate, useSendAllFetch,
} from '../../../hooks/fetch';
import styles from './index.module.scss';

function Notifications() {
  const notifications = useNotificationsGet();
  const sawNotifications = useNotificationsSaw();
  const sendAllFetch = useSendAllFetch<FetchUpdate<FetchSuccess, DefaultFetchError, null>>(sawNotifications);

  useEffect(() => {
    notifications.fetch({
      orderBy: 'DESC',
      page: 1,
      pageSize: 3,
      removed: false,
    });
  }, []);

  useEffect(() => {
    if (notifications?.data?.data) {
      const unreadNotifications = notifications?.data?.data?.filter((notification) => !notification.status.saw);

      if (!unreadNotifications) return;

      sendAllFetch.fetch(unreadNotifications.map((notification) => [null, `${notification.id}/saw`]));
    }
  }, [notifications.data]);

  return (
    <Layout className={styles.layout}>
      <Space direction="vertical" style={{ width: '100%' }}>
        <NotificationsContent notifications={notifications.data?.data} loading={sendAllFetch.loading} fullWidth />
      </Space>
    </Layout>
  );
}
export default Notifications;
