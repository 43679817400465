import { useState, useEffect } from 'react';
import type { PaginationProps as AntdPaginationProps } from 'antd';
import { Pagination as AntdPagination } from 'antd';
import {
  pageDefault, pageSizeDefault, pageSizePossible, pagingParamSearchList,
} from '../../../../../contstant';
import { useSearchParams } from '../../../../../hooks/useSearchParams';
import { getValidSearchParams } from '../../../../../utils';
import { PagingDataResponse } from '../../../../../hooks/fetch';
import styles from './index.module.scss';
import { useSettingsContext } from '../../Context';

interface Item {
  title: string;
  id: string;
}
interface PaginationProps {
  items?: PagingDataResponse<Item>;
}

function Pagination({ items }: PaginationProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { isChanging } = useSettingsContext();
  const [pagination, setPagination] = useState({
    current: pageDefault,
    pageSize: pageSizeDefault,
    pageSizeOptions: pageSizePossible,
    total: 10,
  });

  useEffect(() => {
    if (items?.data?.length) {
      setPagination({
        ...pagination,
        total: items?.meta.itemCount,
        current: items?.meta.page,
      });
    }
  }, [items?.data]);

  const handlePageChange: AntdPaginationProps['onShowSizeChange'] = (page, pageSize) => {
    setPagination({
      ...pagination,
      current: page,
      pageSize,
    });

    setSearchParams({
      ...getValidSearchParams(pagingParamSearchList, searchParams),
      page: page?.toString() || '',
      pageSize: pageSize?.toString() || '',
    });
  };

  return items?.meta && items.meta?.itemCount > pageSizeDefault ? (
    <AntdPagination
      disabled={isChanging}
      current={pagination.current}
      onChange={handlePageChange}
      pageSize={pagination.pageSize}
      className={styles.pagination}
      total={pagination.total}
      showSizeChanger
      showQuickJumper
    />
  ) : null;
}

Pagination.defaultProps = {
  items: undefined,
};

export default Pagination;
