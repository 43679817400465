import React, { useEffect } from 'react';
import {
  Alert, Button, Col, Form, Input, Row,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  signUp as signUpAction,
  SignUpAction,
  signUpClear as signUpClearAction,
} from '@triare/auth-redux/dist/saga/auth/signUp';
import { AnyObject } from '@triare/auth-redux';
import { SignUpPageDefaultProp } from '../../types';
import { useContextSignUp } from '../../Context';
import PhoneNumberInput from '../../../../Common/PhoneNumberInput';
import { createRulesForAntd, validationSignUp } from '../../../../../utils/validations';
import { moduleName } from '../../../../../store/auth';
import { RootState } from '../../../../../store';
import InputAddress from '../../../../Common/Input/Address';

const validationRules = createRulesForAntd(validationSignUp);

interface FormValues {
  firstName: string;
  surname: string;
  email: string;
  password: string;
  confirmPassword: string;
  phone: string;
  address: string;
}

interface PersonalSignUpAction extends SignUpAction {
  firstName: string;
  lastName: string;
  phone: string;
  address?: string;
}

interface PersonalProps extends SignUpPageDefaultProp {
  signUp: (payload: PersonalSignUpAction) => void;
  signUpClear: () => void;
  response: null | AnyObject;
  loading: boolean;
  error: null | AnyObject;
}

function Personal({
  name,
  className,
  signUp,
  loading,
  error,
  response,
  signUpClear,
}: PersonalProps): React.ReactNode | null {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { setForm, onFinish } = useContextSignUp();

  useEffect(() => {
    setForm(name, form);
  }, [name, form]);

  useEffect(() => {
    if (response) {
      onFinish(name, response);
    }
  }, [response]);

  const onFinishHandler = (values: FormValues) => {
    signUp({
      email: values.email,
      firstName: values.firstName,
      lastName: values.surname,
      password: values.password,
      phone: values.phone,
      address: values.address,
    });
  };

  return (
    <Form<FormValues>
      form={form}
      className={className}
      name={name}
      autoComplete="off"
      layout="vertical"
      onFinish={onFinishHandler}
    >
      <h2>Thank you for your interest in Lokem!</h2>
      <span>Please complete the questions below</span>
      <Row gutter={24} style={{ paddingBottom: 16 }}>
        <Col span={12}>
          <span>{`${t('firstName')} *`}</span>
          <Form.Item name="firstName" rules={[validationRules]}>
            <Input
              placeholder="First Name"
              size="large"
              readOnly
              onFocus={(e) => e.currentTarget.removeAttribute('readonly')}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <span>{`${t('surname')} *`}</span>
          <Form.Item name="surname" rules={[validationRules]}>
            <Input
              placeholder="Surname"
              size="large"
              readOnly
              onFocus={(e) => e.currentTarget.removeAttribute('readonly')}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <span>{`${t('email')} *`}</span>
          <Form.Item name="email" rules={[validationRules]}>
            <Input
              placeholder="Email"
              size="large"
              readOnly
              onFocus={(e) => e.currentTarget.removeAttribute('readonly')}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <span>{`${t('password')} *`}</span>
          <Form.Item name="password" rules={[validationRules]}>
            <Input.Password
              placeholder="Password"
              size="large"
              onChange={() => {
                if (form.getFieldValue('confirmPassword')) {
                  form.validateFields(['confirmPassword']);
                }
              }}
              readOnly
              onFocus={(e) => e.currentTarget.removeAttribute('readonly')}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <span>{`${t('repeatPassword')}  *`}</span>
          <Form.Item
            name="confirmPassword"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(new Error('Passwords that you entered do not match!'));
                },
              }),
            ]}
          >
            <Input.Password
              placeholder="Repeat password"
              size="large"
              readOnly
              onFocus={(e) => e.currentTarget.removeAttribute('readonly')}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <span>
            {t('Phone Number')}
            {' '}
            *
          </span>
          <Form.Item name="phone" rules={[validationRules]}>
            <PhoneNumberInput placeholder="Phone Number" size="large" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <span>{`${t('Address')}`}</span>

          <InputAddress size="large" name="address" placeholder="Address" />
        </Col>
      </Row>

      {error ? (
        <Alert
          type="error"
          message={error?.data?.error || error?.message}
          closable
          onClose={signUpClear}
          style={{ marginBottom: 16, marginTop: -16 }}
        />
      ) : null}

      <Form.Item shouldUpdate className="submit">
        {() => (
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            loading={loading}
            disabled={
              ['firstName', 'surname', 'email', 'password', 'confirmPassword', 'phone'].filter(
                (valueName) => (form.getFieldValue(valueName) || '').trim() === '',
              ).length > 0 || form.getFieldsError().filter(({ errors }) => errors.length).length > 0
            }
          >
            Save and Continue
          </Button>
        )}
      </Form.Item>
    </Form>
  );
}

export default connect(
  (state: RootState) => ({
    response: state[moduleName].signUp.response,
    error: state[moduleName].signUp.error,
    loading: state[moduleName].signUp.loading,
  }),
  {
    signUp: signUpAction,
    signUpClear: signUpClearAction,
  },
)(Personal);
