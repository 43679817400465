import { useLocation, useSearchParams } from 'react-router-dom';
import { AnyObject } from '@triare/auth-redux';
import { pagingParamList } from '../../../../contstant';
import { getValidSearchParams } from '../../../../utils';
import { LogoGrey } from '../../Icon';
import { useAuthState } from '../../../../store/auth';
import { getRouteParams } from '../../../../routes';

import styles from './index.module.scss';

interface ClearFiltersProps {
  setKey: (key: string) => void;
}

export default function ClearFilter({ setKey }: ClearFiltersProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();
  const { user } = useAuthState();
  const path = pathname.trim().split('/');

  return (
    <div className={styles.clearFilter}>
      <button
        className={styles.btn}
        type="button"
        onClick={(e) => {
          e.preventDefault();
          setKey(Date.now().toString());
          const params: AnyObject = {};

          if (pathname === '/') {
            Object.assign(params, {
              ...getRouteParams('/', user),
            });
          }

          if (path.includes('shifts')) {
            Object.assign(params, {
              ...getRouteParams('shifts', user),
            });
          }

          if (path.includes('applicants')) {
            Object.assign(params, {
              ...getRouteParams('applicants', user),
            });
          }

          setSearchParams({
            ...getValidSearchParams(pagingParamList, searchParams),
            ...params,
          });
        }}
      >
        Reset all filters
      </button>
      <div className={styles.logo}>
        <LogoGrey className={styles.logoSvg} />
      </div>
    </div>
  );
}
