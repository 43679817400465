import { Badge } from 'antd';
import { useEffect, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import type { ProColumns } from '@ant-design/pro-components';
import { useLocationsGet, Location } from '../../../../../hooks/location';
import { useSearchParams } from '../../../../../hooks/useSearchParams';
import { useSimpleModal } from '../../../../Common/Modal/Simple';
import { useMessageError } from '../../../../../hooks/common';
import CustomTable from '../../../../Common/Table/index';
import {
  DefaultFetchError, FetchSuccess, FetchUpdate, PagingDataResponse,
} from '../../../../../hooks/fetch';
import { COLOR_PRIMARY } from '../../../../../AntdConfigProvider';
import Action from '../Action';

const columns: ProColumns<Location>[] = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (text, entity) => (
      <NavLink to={entity.id} style={{ color: COLOR_PRIMARY }}>
        {text}
      </NavLink>
    ),
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'Status',
    dataIndex: 'isActive',
    key: 'isActive',
    render: (isActive) => <Badge color={isActive ? '#52C41A' : '#BFBFBF'} text={isActive ? 'Active' : 'Inactive'} />,
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
    render: (text, entity) => <NavLink to={`tel:${entity?.phone}`}>{entity.phone}</NavLink>,
  },
  {
    title: 'Email',
    key: 'email',
    dataIndex: 'email',
    render: (text, entity) => <NavLink to={`mailto:${entity?.email}`}>{entity.email}</NavLink>,
  },
];

type TableProps = {
  id: string;
  updateLocation: boolean;
  setUpdateLocation: (value: boolean) => void;
  clientId: string;
  hookAction?: FetchUpdate<FetchSuccess, DefaultFetchError>;
};

function Table({
  id, updateLocation, setUpdateLocation, clientId,
}: TableProps) {
  const { open, contextHolder } = useSimpleModal();
  const locations = useLocationsGet();
  const [searchParams, , paramsWithoutTableProps] = useSearchParams();

  useEffect(() => {
    if (updateLocation) {
      getLocations();
      setUpdateLocation(false);
    }
  }, [updateLocation]);

  const getLocations = () => {
    locations.fetch({
      client: clientId,
      domain: id,
      ...paramsWithoutTableProps,
    });
  };

  useEffect(getLocations, [searchParams]);

  const locationsData = useMemo<PagingDataResponse<Location>>(() => {
    if (locations.data && locations.data.data && locations.data.data.length) {
      return {
        ...locations.data,
        data: locations.data.data.map((items) => ({
          ...items,
        })),
      } as PagingDataResponse<Location>;
    }

    return locations.data as PagingDataResponse<Location>;
  }, [locations.data?.data]);

  useMessageError([locations]);

  return (
    <>
      <CustomTable
        rowKey="id"
        search={false}
        actions={Action}
        actionsProps={{ open, getLocations }}
        options={{ reload: false, density: false, setting: false }}
        columns={columns}
        loading={locations.loading}
        pagingData={locationsData}
      />

      {contextHolder}
    </>
  );
}

Table.defaultProps = {
  hookAction: undefined,
};

export default Table;
