import {
  App, Button, Form, Input, Select,
} from 'antd';
import { useParams } from 'react-router-dom';
import { CheckCircleOutlined, InfoCircleTwoTone, StopOutlined } from '@ant-design/icons';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { createRulesForAntd, validationUserInfo } from '../../../../../../utils/validations';
import { useClientUserContext } from '../../Context';
import { useSimpleModal } from '../../../../../Common/Modal/Simple';
import { UserRoles, UserStatus } from '../../../../../../enums/user';
import { translateStatusAction } from '../../../../../../utils';
import { usePasswordForgot } from '../../../../../../hooks/password';
import { COLOR_PRIMARY } from '../../../../../../AntdConfigProvider';
import { FetchGet } from '../../../../../../hooks/fetch';
import PhoneNumberInput from '../../../../../Common/PhoneNumberInput';
import { Locations } from '../../../../../../types/location';

const validationRules = createRulesForAntd(validationUserInfo);

interface FieldsProps {
  showLocation: boolean;
  locationsGet: FetchGet<Locations[]>;
}

export default function Fields({ locationsGet, showLocation }: FieldsProps) {
  const { userId } = useParams();
  const {
    resource, action, mode, form,
  } = useClientUserContext();
  const { open, contextHolder } = useSimpleModal();
  const isActive = resource?.data?.status === UserStatus.ACTIVE;
  const actionName = translateStatusAction(!isActive);
  const userResendLink = usePasswordForgot();
  const { t } = useTranslation();
  const { message } = App.useApp();

  useEffect(() => {
    if (userResendLink.data && !userResendLink.error && !userResendLink.loading) {
      message.success('Password reset link was sent successfully');
    }
  }, [userResendLink.response]);

  return (
    <>
      <Form.Item
        label="First name"
        name="firstName"
        rules={[{ required: true, ...validationRules, message: 'Please input Name!' }]}
      >
        <Input placeholder="First name" />
      </Form.Item>

      <Form.Item
        label="Last name"
        name="lastName"
        rules={[{ required: true, ...validationRules, message: 'Please input Name!' }]}
      >
        <Input placeholder="Last name" />
      </Form.Item>

      <Form.Item
        label="Phone"
        name="phone"
        rules={[{ required: true, ...validationRules, message: 'Phone must be a valid phone number' }]}
      >
        <PhoneNumberInput placeholder="General Phone Number" />
      </Form.Item>

      <Form.Item
        label="Contact Email"
        name="email"
        rules={[{ required: true, ...validationRules, message: 'Please input Email!' }]}
      >
        <Input placeholder="General Contact Email" />
      </Form.Item>

      <Form.Item
        label="Role"
        name="role"
        rules={[{ required: true, message: 'Please select Role!' }]}
        tooltip={{
          title: t('userRole'),
          icon: <InfoCircleTwoTone style={{ cursor: 'help' }} twoToneColor={COLOR_PRIMARY} />,
        }}
      >
        <Select
          options={[
            {
              label: 'Client Admin',
              value: UserRoles.CLIENT_ADMIN,
            },
            {
              label: 'Manager',
              value: UserRoles.MANAGER,
            },
          ]}
          placeholder="Please select role"
        />
      </Form.Item>
      {showLocation ? (
        <Form.Item
          label="Select location"
          name="location"
          rules={[{ required: true, message: 'Please select location!' }]}
        >
          <Select
            mode="multiple"
            loading={locationsGet.loading}
            options={locationsGet.data?.map((item: any) => ({ label: item.name, value: item.id }))}
            placeholder="Please select location"
          />
        </Form.Item>
      ) : null}

      {mode === 'edit' ? (
        <Form.Item label="Send password reset link">
          <Button
            ghost
            type="primary"
            onClick={(e) => {
              e.preventDefault();
              userResendLink.fetch({ email: form?.getFieldValue('email') });
            }}
          >
            Send link
          </Button>
        </Form.Item>
      ) : null}

      {userId && action ? (
        <Form.Item label="Status">
          <Button
            danger={isActive}
            ghost={!isActive}
            type={!isActive ? 'primary' : undefined}
            onClick={(e) => {
              e.preventDefault();
              open({
                icon: isActive ? (
                  <StopOutlined style={{ color: '#FF4D4F' }} />
                ) : (
                  <CheckCircleOutlined style={{ color: COLOR_PRIMARY }} />
                ),
                title: `${actionName} user`,
                content: `Are you sure you want to ${actionName?.toLowerCase()} this user?`,
                cancelText: 'Cancel',
                okText: actionName,
                onOk: () => action.fetch({
                  status: isActive ? UserStatus.DEACTIVATED : UserStatus.ACTIVE,
                }),
              });
            }}
          >
            {actionName}
          </Button>
        </Form.Item>
      ) : null}

      {contextHolder}
    </>
  );
}
