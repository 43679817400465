// version: 3.5.0 // 08.01.2024

import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import axios, {
  AxiosError, AxiosProgressEvent, AxiosRequestConfig, AxiosResponse,
} from 'axios';
import { useSelector } from 'react-redux';
import { Unsubscribe } from 'redux';
import {
  AnyObject, refreshToken, State, config as authConfig,
} from '@triare/auth-redux';
import { capitalizeFirstLetter } from '../utils';

export const FILE_FORMAT: { [key: string]: string } = {
  aac: 'audio/aac',
  abw: 'application/x-abiword',
  arc: 'application/x-freearc',
  avif: 'image/avif',
  avi: 'video/x-msvideo',
  azw: 'application/vnd.amazon.ebook',
  bin: 'application/octet-stream',
  bmp: 'image/bmp',
  bz: 'application/x-bzip',
  bz2: 'application/x-bzip2',
  cda: 'application/x-cdf',
  csh: 'application/x-csh',
  css: 'text/css',
  csv: 'text/csv',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  eot: 'application/vnd.ms-fontobject',
  epub: 'application/epub+zip',
  gz: 'application/gzip',
  gif: 'image/gif',
  htm: 'text/html',
  html: 'text/html',
  ico: 'image/vnd.microsoft.icon',
  ics: 'text/calendar',
  jar: 'application/java-archive',
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg',
  js: 'text/javascript',
  json: 'application/json',
  jsonld: 'application/ld+json',
  mid: 'audio/midi',
  midi: 'audio/x-midi',
  mjs: 'text/javascript',
  mp3: 'audio/mpeg',
  mp4: 'video/mp4',
  mpeg: 'video/mpeg',
  mpkg: 'application/vnd.apple.installer+xml',
  odp: 'application/vnd.oasis.opendocument.presentation',
  ods: 'application/vnd.oasis.opendocument.spreadsheet',
  odt: 'application/vnd.oasis.opendocument.text',
  oga: 'audio/ogg',
  ogv: 'video/ogg',
  ogx: 'application/ogg',
  opus: 'audio/opus',
  otf: 'font/otf',
  png: 'image/png',
  pdf: 'application/pdf',
  php: 'application/x-httpd-php',
  ppt: 'application/vnd.ms-powerpoint',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  rar: 'application/vnd.rar',
  rtf: 'application/rtf',
  sh: 'application/x-sh',
  svg: 'image/svg+xml',
  tar: 'application/x-tar',
  tif: 'image/tiff',
  tiff: 'image/tiff',
  ts: 'video/mp2t',
  ttf: 'font/ttf',
  txt: 'text/plain',
  vsd: 'application/vnd.visio',
  wav: 'audio/wav',
  weba: 'audio/webm',
  webm: 'video/webm',
  webp: 'image/webp',
  woff: 'font/woff',
  woff2: 'font/woff2',
  xhtml: 'application/xhtml+xml',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  xml: 'application/xml',
  xul: 'application/vnd.mozilla.xul+xml',
  zip: 'application/zip',
  '3gp': 'video/3gpp; audio/3gpp',
  '3g2': 'video/3gpp2; audio/3gpp2',
  '7z': 'application/x-7z-compressed',
};

export interface RequestResult {
  loading: boolean;
  error: Error | null;
}

export interface FetchSuccess {
  success: boolean;
}

export interface PagingParams extends AnyObject {
  page?: number;
  pageSize?: number;
  orderBy?: 'ASC' | 'DESC';
  orderByColumn?: string;
}

export interface PagingDataResponse<I> {
  data: I[];
  meta: {
    page: number;
    take: number;
    itemCount: number;
    pageCount: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  };
}
async function awaitAccessToken<T = unknown, E = Error | null>(
  callback: (token: string) => T,
  callbackError: (value: E) => E | void,
): Promise<T> {
  const history: {
    currentValue: string;
    unsubscribe?: Unsubscribe;
  } = {
    currentValue: authConfig.store ? authConfig.store.getState()[authConfig.auth.name]?.access?.token || '' : '',
    unsubscribe: () => {
      // unsubscribe store
    },
  };

  const result = await new Promise((resolve, reject) => {
    if (authConfig.store) {
      const { store } = authConfig;

      history.unsubscribe = store.subscribe(() => {
        const state = store.getState()[authConfig.auth.name];
        const token = state?.access?.token;
        const previousValue = history.currentValue;

        history.currentValue = token;

        if (history.currentValue && previousValue !== history.currentValue) {
          resolve(callback(history.currentValue));
        }

        if (state.error) {
          reject(callbackError(state.error));
        }
      });
    } else {
      reject();
    }
  });

  if (history.unsubscribe) {
    history.unsubscribe();
  }

  return result as T;
}

export interface FetchProps<Data, Props = undefined, DecorateData = Data, Error = DefaultFetchError> {
  fetchCreator: (
    setController: (controller: AbortController) => void,
    token?: string,
    props?: Props,
    ...args: unknown[]
  ) => Promise<AxiosResponse<Data>>;

  /**
   * Automatically cancels any previous fetch if it's still running.
   */
  latest?: boolean;
  decorateData?: (data: Data) => DecorateData;
  startStateLoading?: boolean;
  multiple?: string;
  cacheLifetime?: number;
  authorization?: boolean;
  beforeError?: (err: AxiosError<Error>) => boolean | void;
}

export type DefaultFetchError = {
  message?: string | string[]
  error?: string
};

export interface DefaultFetch<Data = undefined, Error = DefaultFetchError, Props = undefined, DecorateData = Data>
  extends RequestResult {
  abort: () => void;
  controller?: AbortController;
  history?: Props;
  fetch: (props?: Props) => Promise<DecorateData | null>;
  finish: (data?: DecorateData) => void;
  error: AxiosError<Error> | null;
  response: AxiosResponse<Data> | undefined;
  clearError: () => void;
  clearResponse: () => void;
  name?: string;
}

export interface FetchHooks<Data, Error = DefaultFetchError, Props = undefined, DecorateData = Data>
  extends DefaultFetch<Data, Error, Props, DecorateData> {
  data?: DecorateData;
}

// eslint-disable-next-line
const requestQueue: { [key: string]: Promise<any>; } = {};

interface Cache<Data> {
  cacheLifetime: number; // milliseconds
  response: AxiosResponse<Data> | undefined;
}

// eslint-disable-next-line
const cache: { [key: string]: Cache<any>; } = {};

export function useFetch<Data, Error = DefaultFetchError, Props = undefined, DecorateData = Data>({
  fetchCreator,
  latest = false,
  decorateData,
  authorization,
  startStateLoading = false,
  multiple, // string key
  cacheLifetime = 200, // milliseconds
  beforeError,
}: FetchProps<Data, Props, DecorateData, Error>): FetchHooks<Data, Error, Props, DecorateData> {
  const history = useRef<Props>();
  const controller = useRef<AbortController | undefined>();
  const live = useRef<boolean>(true);
  const { access } = useSelector((state: { [key: string]: State }) => state[authConfig.auth.name]);
  const { token: accessToken } = access || {};

  const [loading, setLoading] = useState(startStateLoading);
  const [error, setError] = useState<AxiosError<Error> | null>(null);
  const [data, setData] = useState<DecorateData>();
  const [response, setResponse] = useState<AxiosResponse<Data> | undefined>();

  useEffect(() => {
    if (response && cacheLifetime && multiple) {
      cache[multiple] = {
        cacheLifetime: Date.now() + cacheLifetime,
        response,
      };

      setTimeout(() => {
        if (typeof cache[multiple] !== 'undefined') {
          delete cache[multiple];
        }
      }, cacheLifetime);
    }
  }, [response]);

  const fetch = useCallback(async (params?: Props, ...args: unknown[]): Promise<DecorateData | null> => {
    const cacheResponse = multiple && cache[multiple] ? cache[multiple].response : undefined;

    setError(() => null);
    setLoading(() => true);

    if (latest && controller.current) {
      controller.current.abort();
    }

    if (authConfig.fetchDelay) {
      // eslint-disable-next-line no-promise-executor-return
      await new Promise((resolve) => setTimeout(resolve, authConfig.fetchDelay || 0));
    }

    const checkResponse = async (useReLogin = false, token = accessToken): Promise<DecorateData | null> => {
      let promise = useReLogin && !latest && multiple ? requestQueue[multiple] : undefined;

      const prepareData = (res: AxiosResponse<Data>): DecorateData | null => {
        const result = decorateData ? decorateData(res.data) : res.data;

        setData(() => result as DecorateData);
        setResponse(() => res);
        setLoading(() => false);

        return result as DecorateData;
      };

      if (!promise) {
        if (!latest && cacheResponse) {
          return prepareData(cacheResponse);
        }

        if (latest && controller.current) {
          controller.current.abort();
        }

        let validToken: string | undefined = token;

        if (!token && authorization) {
          // eslint-disable-next-line
          validToken = await awaitAccessToken<string, AxiosError<Error, any>>(
            (newToken) => newToken,
            (err) => {
              if (!beforeError || !beforeError(err)) {
                setError(() => err);
              }
              setLoading(() => false);
            },
          );
        }

        promise = fetchCreator(
          (abortController) => {
            controller.current = abortController;
          },
          validToken || '',
          params,
          ...args,
          cacheResponse,
        );

        history.current = params;

        if (!latest && multiple) {
          requestQueue[multiple] = promise;
        }
      }

      return await promise.then((res) => {
        if (!live.current) {
          return null as DecorateData;
        }

        return prepareData(res);
      }).catch(async (e) => {
        if (!axios.isCancel(e)) {
          if (!live.current) {
            return null;
          }

          if (access && useReLogin && e.response?.status === 401) {
            authConfig?.store?.dispatch(refreshToken());

            // eslint-disable-next-line
            return await awaitAccessToken<Promise<DecorateData | null>, AxiosError<Error, any>>(
              (newToken) => checkResponse(false, newToken),
              (err) => {
                if (!beforeError || !beforeError(err)) {
                  setError(() => err);
                }
                setLoading(() => false);
              },
            );
          }

          if (!beforeError || !beforeError(e)) {
            setError(() => e);
          }
          setLoading(() => false);
        }

        return e;
      }).finally(() => {
        if (!latest && multiple) {
          delete requestQueue[multiple];
        }
      });
    };

    return checkResponse(true);
  }, [accessToken]);

  useEffect(() => () => {
    live.current = false;
  }, []);

  return {
    abort: () => {
      if (controller.current) {
        controller.current.abort();
      }
    },
    controller: controller.current,
    history: history.current,
    loading,
    error,
    data,
    fetch,
    response,
    finish: (result) => {
      setData(() => result);
      setLoading(() => false);
      setError(() => null);
    },
    clearError: () => setError(() => null),
    clearResponse: () => {
      setData(() => undefined);
      setResponse(() => undefined);
    },
  };
}

// eslint-disable-next-line
export interface FetchGet<Data = any, Props = any, Error = DefaultFetchError, DecorateData = Data>
  extends DefaultFetch<Data, Error, Props, DecorateData> {
  data?: DecorateData;
}

export interface FetchOptions<Data, Props, DecorateData = Data, Error = DefaultFetchError>
  extends Omit<FetchProps<Data, Props, DecorateData, Error>, 'fetchCreator'> {
  name?: string; // name fetch function
  url?: string;
  authorization?: boolean;
  decorateData?: (data: Data) => DecorateData;
  config?: AxiosRequestConfig;
  params?: Props;
  autoStart?: boolean;
  multiple?: string;
  cacheLifetime?: number;
  startStateLoading?: boolean;
}

export type FetchGetOptions<Data, Props, DecorateData = Data, Error = DefaultFetchError>
  = FetchOptions<Data, Props, DecorateData, Error>;

export function useFetchGet<Data, Error = DefaultFetchError, Props = undefined, DecorateData = Data>(
  path: string,
  options: FetchGetOptions<Data, Props, DecorateData, Error> = {},
): FetchGet<Data, Props, Error, DecorateData> {
  const {
    name,
    url,
    decorateData,
    config = {
      headers: undefined,
      params: undefined,
    },
    params = {},
    autoStart = true,
    authorization = true,
    startStateLoading = true,
    ...props
  } = options || {};

  const { fetch, ...args } = useFetch<Data, Error, Props, DecorateData>({
    fetchCreator: (setController, token, paramsCreator?: Props) => {
      const controller = new AbortController();

      setController(controller);

      return axios.get<Data>(
        url || `${authConfig.api.url}${path}`,
        {
          signal: controller.signal,
          ...config,
          headers: {
            Authorization: authorization ? `Bearer ${token}` : undefined,
            ...config?.headers,
          },
          params: {
            ...config?.params,
            ...params,
            ...paramsCreator,
          },
        },
      );
    },
    authorization,
    decorateData,
    startStateLoading,
    ...props,
  });

  useEffect(() => {
    if (autoStart) {
      fetch();
    }
  }, []);

  return {
    ...args,
    name,
    fetch,
  };
}

export interface FetchGetId<Data = AnyObject, Error = DefaultFetchError, Props = undefined, DecorateData = Data>
  extends DefaultFetch<Data, Error, Props, DecorateData> {
  data?: DecorateData;
  fetch: (params?: Props, id?: string | number) => Promise<DecorateData | null>;
}

export type FetchGetIdOptions<Data, Props, DecorateData = Data, Error
  = DefaultFetchError> = FetchOptions<Data, Props, DecorateData, Error>;

export function useFetchGetId<Data, Error = DefaultFetchError, Props = undefined, DecorateData = Data>(
  path: string,
  initialId = '',
  options: FetchGetIdOptions<Data, Props, DecorateData, Error> = {},
  responseType: 'arraybuffer' | 'json' | 'blob' | 'text' | 'stream' | 'document' = 'json',
  axiosOnDownloadProgress: (progressEvent: AxiosProgressEvent) => void = () => undefined,
): FetchGetId<Data, Error, Props, DecorateData> {
  const {
    url,
    decorateData,
    config = {
      headers: undefined,
      params: undefined,
    },
    params = {},
    autoStart = true,
    authorization = true,
    startStateLoading = false,
    ...props
  } = options || {};

  const { fetch, ...args } = useFetch<Data, Error, Props, DecorateData>({
    fetchCreator: (setController, token, paramsCreator?: Props, id = initialId) => {
      const controller = new AbortController();

      setController(controller);

      return axios.get<Data>(
        url || `${authConfig.api.url}${path}${id ? `/${id}` : ''}`,
        {
          signal: controller.signal,
          ...config,
          headers: {
            Authorization: authorization ? `Bearer ${token}` : undefined,
            ...config?.headers,
          },
          params: {
            ...config?.params,
            ...params,
            ...paramsCreator,
          },
          responseType,
          onDownloadProgress: axiosOnDownloadProgress
            ? (progressEvent) => axiosOnDownloadProgress(progressEvent) : undefined,
        },
      );
    },
    authorization,
    decorateData,
    startStateLoading,
    ...props,
  });

  useEffect(() => {
    if (autoStart) {
      fetch();
    }
  }, []);

  return {
    ...args,
    fetch,
  };
}

// eslint-disable-next-line
export interface FetchCreate<Data = FetchSuccess, Error = DefaultFetchError, Props = any>
  extends DefaultFetch<Data, Error, Props> {
  data?: Data;
  fetch: (formData?: Props, id?: string) => Promise<Data | null>;
}

export type FetchCreateOptions<Data, Props, DecorateData = Data, Error = DefaultFetchError>
  = FetchOptions<Data, Props, DecorateData, Error>;

export function useFetchCreate<Data, Error, Props>(
  path: string,
  options: FetchCreateOptions<Data, Props, Data, Error> = {},
  axiosOnUploadProgress: (progressEvent: AxiosProgressEvent) => void = () => undefined,
): FetchCreate<Data, Error, Props> {
  const {
    url,
    decorateData,
    config = {
      headers: undefined,
      params: undefined,
    },
    params = {},
    authorization = true,
    startStateLoading = false,
    ...props
  } = options || {};

  return useFetch<Data, Error, Props>({
    fetchCreator: (setController, token, formData?: Props, partUrl = '') => {
      const controller = new AbortController();

      setController(controller);

      return axios.post<Data>(
        url || `${authConfig.api.url}${path}${partUrl ? `/${partUrl}` : ''}`,
        formData,
        {
          signal: controller.signal,
          ...config,
          headers: {
            Authorization: authorization ? `Bearer ${token}` : undefined,
            ...config?.headers,
          },
          params: {
            ...config?.params,
            ...params,
          },
          onUploadProgress: axiosOnUploadProgress
            ? (progressEvent) => axiosOnUploadProgress(progressEvent) : undefined,
        },
      );
    },
    authorization,
    decorateData,
    startStateLoading,
    ...props,
  });
}

// eslint-disable-next-line
export interface FetchUpdate<Data = FetchSuccess, Error = DefaultFetchError, Props = any>
  extends DefaultFetch<Data, Error, Props> {
  data?: Data;
  fetch: (params?: Props, id?: string | number) => Promise<Data | null>;
}

export type FetchUpdateOptions<Data, Props> = FetchOptions<Data, Props>;

export function useFetchUpdate<Data, Error, Props>(
  path: string,
  initialId = '',
  options: FetchCreateOptions<Data, Props, Data, Error> = {},
  axiosOnUploadProgress: (progressEvent: AxiosProgressEvent) => void = () => undefined,
): FetchUpdate<Data, Error, Props> {
  const {
    url,
    decorateData,
    config = {
      headers: undefined,
      params: undefined,
    },
    params = {},
    authorization = true,
    startStateLoading = false,
    ...props
  } = options || {};

  return useFetch<Data, Error, Props>({
    fetchCreator: (setController, token, formData?: Props, id = initialId) => {
      const controller = new AbortController();

      setController(controller);

      return axios.patch<Data>(
        url || `${authConfig.api.url}${path}${id ? `/${id}` : ''}`,
        formData,
        {
          signal: controller.signal,
          ...config,
          headers: {
            Authorization: authorization ? `Bearer ${token}` : undefined,
            ...config?.headers,
          },
          params: {
            ...config?.params,
            ...params,
          },
          onUploadProgress: axiosOnUploadProgress
            ? (progressEvent) => axiosOnUploadProgress(progressEvent) : undefined,
        },
      );
    },
    authorization,
    decorateData,
    startStateLoading,
    ...props,
  });
}

// eslint-disable-next-line
export interface FetchDelete<Data = any, Error = DefaultFetchError, Props = string>
  extends DefaultFetch<Data, Error, Props> {
  data?: Data;
  fetch: (id?: Props) => Promise<Data | null>;
}

export function useFetchDelete<Data, Error, Props = string>(
  path: string,
  initialId = '',
  options: FetchCreateOptions<Data, Props, Data, Error> = {},
): FetchDelete<Data, Error, Props> {
  const {
    url,
    decorateData,
    config = {
      headers: undefined,
      params: undefined,
    },
    params = {},
    authorization = true,
    startStateLoading = false,
    ...props
  } = options || {};

  return useFetch<Data, Error, Props>({
    fetchCreator: (setController, token, id) => {
      const controller = new AbortController();

      setController(controller);

      return axios.delete<Data>(
        url || `${authConfig.api.url}${path}${id || initialId ? `/${id || initialId}` : ''}`,
        {
          signal: controller.signal,
          ...config,
          headers: {
            Authorization: authorization ? `Bearer ${token}` : undefined,
            ...config?.headers,
          },
          params: {
            ...config?.params,
            ...params,
          },
        },
      );
    },
    authorization,
    decorateData,
    startStateLoading,
    ...props,
  });
}

// eslint-disable-next-line
export function getMessageInError(err: any): string {
  if (!err) {
    return 'Unknown error';
  }

  const message = err.response?.data?.detail || err.response?.data?.error || err.response?.data?.message || err.message;

  if (message) {
    return capitalizeFirstLetter(Array.isArray(message) ? message[0] : message);
  }

  return 'Something went wrong!';
}

export interface SendAllFetch<Props> {
  loading: boolean
  error: AxiosError<DefaultFetchError> | null
  list: Props[]
  fetch: (list: Props[]) => void
  stop: () => void
}

export function useSendAllFetch<
  Action = DefaultFetch | FetchCreate | FetchGet | FetchUpdate | FetchDelete,
  Props = AnyObject
>(
  action: Action & (DefaultFetch | FetchCreate | FetchGet | FetchUpdate | FetchDelete),
  initialList?: Props[],
): SendAllFetch<Props> {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<AxiosError<DefaultFetchError> | null>(null);
  const copyList = useRef<Props[]>(initialList === undefined ? [] : [...initialList].reverse());

  useEffect(() => {
    if (action.response && !action.error) {
      if (copyList.current.length) {
        // eslint-disable-next-line
        action.fetch.apply(null, copyList.current.pop() as any);
      } else {
        setLoading(false);
      }
    }
  }, [action.response]);

  useEffect(() => {
    if (action.error) {
      setLoading(false);
      setError(action.error);
    }
  }, [action.error]);

  return {
    loading,
    error,
    list: copyList.current,
    fetch: (list?: Props[]) => {
      if (typeof list !== 'undefined') {
        copyList.current = [...list].reverse();
      }
      if (copyList.current.length) {
        setLoading(true);
        setError(null);
        // eslint-disable-next-line
        action.fetch.apply(null, copyList.current.pop() as any);
      }
    },
    stop: () => {
      copyList.current = [];
      setLoading(false);
    },
  };
}
