import React, { useState, useEffect } from 'react';
import { Select, Form } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import type { SelectProps } from 'antd';
import { useAddressGet } from '../../../../hooks/user';

import styles from './index.module.scss';

interface InputAddressProps {
  name: string;
  placeholder?: string;
  label?: string;
  size?: SizeType;
  disabled?: boolean;
  required?: boolean;
}

function InputAddress({
  name, size, placeholder, disabled, label, required,
}: InputAddressProps) {
  const [data, setData] = useState<SelectProps['options']>([]);
  const getAddress = useAddressGet();

  const handleSearch = (value: string) => {
    if (value) {
      getAddress.fetch({ address: value });
    }
  };

  useEffect(() => {
    if (getAddress.data) {
      setData(getAddress.data.map((address) => ({ value: address, label: address })));
    }
  }, [getAddress.data]);

  return (
    <Form.Item name={name} label={label} rules={required ? [{ required: true, message: 'Please input Address!' }] : []}>
      <Select
        disabled={disabled}
        className={styles.select}
        size={size}
        showSearch
        placeholder={placeholder}
        defaultActiveFirstOption={false}
        suffixIcon={<SearchOutlined style={{ fontSize: '14px', color: '#8C8C8C' }} />}
        filterOption={false}
        onSearch={handleSearch}
        notFoundContent={null}
        options={(data || []).map(({ value, label: optionLabel }) => ({ value, label: optionLabel }))}
      />
    </Form.Item>
  );
}

InputAddress.defaultProps = {
  placeholder: 'File name',
  size: undefined,
  label: undefined,
  disabled: undefined,
  required: undefined,
};

export default InputAddress;
