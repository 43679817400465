import React from 'react';
import { Typography, Select, Button } from 'antd';
import type { Dayjs } from 'dayjs';
import { CalendarMode } from 'antd/es/calendar/generateCalendar';
import { NavLink } from 'react-router-dom';
import { UserRoles } from '../../../../enums/user';
import { useAuthState } from '../../../../store/auth';

import styles from './index.module.scss';

export interface HeaderPropsExport {
  value: Dayjs;
  type: CalendarMode;
  onChange: (date: Dayjs) => void;
  onTypeChange: (type: CalendarMode) => void;
}

interface HeaderProps extends HeaderPropsExport {
  name?: string;
  setDate: (date: Dayjs) => void;
}

function Header({
  name, value, onChange, setDate,
}: HeaderProps): React.ReactNode {
  const { user } = useAuthState();

  const start = 0;
  const end = 12;
  const monthOptions = [];

  let current = value.clone();
  const localeData = value.localeData();
  const months = [];

  for (let i = 0; i < 12; i++) {
    current = current.month(i);
    months.push(localeData.monthsShort(current));
  }

  for (let i = start; i < end; i++) {
    monthOptions.push(
      <Select.Option key={i} value={i} className="month-item">
        {months[i]}
      </Select.Option>,
    );
  }

  const year = value.year();
  const month = value.month();
  const options = [];

  for (let i = year - 10; i < year + 10; i += 1) {
    options.push(
      <Select.Option key={i} value={i} className="year-item">
        {i}
      </Select.Option>,
    );
  }

  return (
    <div className={styles.header}>
      <div>{typeof name === 'string' ? <Typography.Title level={4}>{name}</Typography.Title> : name}</div>
      <div className={styles.control}>
        {user?.role === UserRoles.APPLICANT ? null : (
          <NavLink to="/shifts/create">
            <Button className={styles.btn} type="primary">
              Create shift
            </Button>
          </NavLink>
        )}

        <Select
          popupMatchSelectWidth={false}
          className="my-year-select"
          value={year as number}
          onChange={(newYear) => {
            const now = value.clone().year(newYear);

            onChange(now);
            setDate(now);
          }}
        >
          {options}
        </Select>
        <Select
          popupMatchSelectWidth={false}
          value={month as number}
          onChange={(newMonth) => {
            const now = value.clone().month(newMonth);

            onChange(now);
            setDate(now);
          }}
        >
          {monthOptions}
        </Select>
      </div>
    </div>
  );
}

Header.defaultProps = {
  name: undefined,
};

export default Header;
