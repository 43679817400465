import { App, Empty, Space } from 'antd';
import { useEffect } from 'react';
import type { CollapseProps } from 'antd';
import Table from '../Table';
import { useDomainsSelectLocationsGet } from '../../../../../hooks/domain';
import { getMessageInError } from '../../../../../hooks/fetch';
import Loading from '../../../../Common/Loading';
import Collapse from '../../../../Common/Collapse';
import { useSearchParams } from '../../../../../hooks/useSearchParams';
import Counter from '../../../../Common/Counter';

import styles from './index.module.scss';

type NestedDomainsProps = {
  clientId: string;
  updateLocation: boolean;
  updateDomains?: boolean;
  setUpdateLocation: (value: boolean) => void;
  setUpdateDomains: (value: boolean) => void;
};

function NestedDomains({
  clientId,
  updateLocation,
  updateDomains,
  setUpdateLocation,
  setUpdateDomains,
}: NestedDomainsProps) {
  const domainsSelectLocationsGet = useDomainsSelectLocationsGet();
  const { message } = App.useApp();
  const [,, paramsWithoutTableProps] = useSearchParams();

  useEffect(() => {
    domainsSelectLocationsGet.fetch({
      clients: clientId || '',
      ...paramsWithoutTableProps,
    });

    if (updateDomains) {
      setUpdateDomains(false);
    }
  }, [paramsWithoutTableProps, updateDomains]);

  useEffect(() => {
    if (domainsSelectLocationsGet.error) {
      message.error(getMessageInError(domainsSelectLocationsGet.error));
      domainsSelectLocationsGet.clearError();
    }
  }, [domainsSelectLocationsGet.error]);

  const items: CollapseProps['items'] = (domainsSelectLocationsGet.data || []).map((item) => ({
    key: item.id,
    label: (
      <Space>
        {item.title}
        <Counter count={item.locationsCount} />
      </Space>
    ),
    children: (
      <Table id={item.id} clientId={clientId} updateLocation={updateLocation} setUpdateLocation={setUpdateLocation} />
    ),
  }));

  return domainsSelectLocationsGet.loading ? (
    <div className={styles.loading}>
      <Loading />
    </div>
  ) : (
    <div className={styles.nested}>
      <Collapse accordion items={items} />
      {(!domainsSelectLocationsGet.data || !domainsSelectLocationsGet.data.length) && (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </div>
  );
}

NestedDomains.defaultProps = {
  updateDomains: false,
};

export default NestedDomains;
