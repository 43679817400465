import {
  Typography, Modal, Form, Button,
} from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react';
import { PhoneNumberInput } from '../PhoneNumberInput';
import { createRulesForAntd, validationSignUpPhoneOnly } from '../../../utils/validations';
import { useUserChangePhone } from '../../../hooks/user';
import { useMessageError } from '../../../hooks/common';
import CodeConfirmation from './CodeConfirmation';
import styles from './index.module.scss';

const validationRules = createRulesForAntd(validationSignUpPhoneOnly);

type ChangePhoneProps = {
  isModalOpen: boolean;
  setIsModalOpen: (state: boolean) => void;
};

export default function ChangePhone({ isModalOpen, setIsModalOpen }: ChangePhoneProps) {
  const changePhone = useUserChangePhone();
  const [showOTP, setShowOTP] = useState(false);
  const [secretKey, setSecretKey] = useState('');
  const [form] = Form.useForm();

  useEffect(() => {
    if (changePhone.data) {
      setSecretKey(changePhone.data.secretKey);
      setShowOTP(true);
    }
  }, [changePhone.data]);

  useMessageError([changePhone]);

  return (
    <Modal
      className={styles.modal}
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      destroyOnClose
    >
      {!showOTP ? (
        <Form
          form={form}
          layout="vertical"
          onFinish={() => {
            changePhone.fetch({ phone: form.getFieldValue('phone') });
          }}
        >
          <Typography.Title className={styles.title} level={5}>
            Change phone
          </Typography.Title>
          <Typography.Text className={styles.text}>
            An SMS with a confirmation code will be sent to this number
          </Typography.Text>
          <Form.Item name="phone" label="Phone Number *" className={styles.phone} rules={[validationRules]}>
            <PhoneNumberInput placeholder="Phone Number" size="large" />
          </Form.Item>

          <Form.Item shouldUpdate>
            {({ getFieldsValue, getFieldsError }) => {
              const { phone: phoneValue } = getFieldsValue();
              const fieldsError = getFieldsError();

              return (
                <Button
                  disabled={!phoneValue || fieldsError.some((item) => item.errors.length)}
                  size="large"
                  type="primary"
                  htmlType="submit"
                  block
                  loading={changePhone.loading}
                  className={styles.btn}
                >
                  Confirm
                </Button>
              );
            }}
          </Form.Item>
        </Form>
      ) : (
        <div>
          <CodeConfirmation
            setShowOTP={setShowOTP}
            setIsModalOpen={setIsModalOpen}
            phone={form.getFieldValue('phone')}
            secretKey={secretKey}
          />
          <Button
            className={styles.back}
            type="text"
            block
            onClick={() => setShowOTP(false)}
            size="large"
            style={{ color: '#8C8C8C' }}
            icon={<LeftOutlined />}
          >
            Back
          </Button>
        </div>
      )}
    </Modal>
  );
}
