import React, { useEffect, useMemo } from 'react';
import { Alert, App, Form } from 'antd';
import { isEqual } from 'lodash';
import { FieldData } from 'rc-field-form/lib/interface';
import { useSettingsContext } from '../Context';
import Loading from '../../../Common/Loading';
import { getMessageInError } from '../../../../hooks/fetch';
import {
  useDomainDelete, useDomainsAllUpdate, useDomainsCreate, useDomainsGet,
} from '../../../../hooks/domain';
import { tabList } from '../index';
import FormList from '../Common/List';
import CreateModal from '../Common/CreateModal';

import styles from '../index.module.scss';

export default function Domains() {
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const {
    setForm, setLoading, setChanging, setValid, isChanging, activeTab,
  } = useSettingsContext();
  const domainsGet = useDomainsGet();
  const domainsAllUpdate = useDomainsAllUpdate();
  const domainDelete = useDomainDelete();
  const domainsCreate = useDomainsCreate();
  const domainData = useMemo(() => {
    if (domainsGet.data) {
      return {
        domains: domainsGet.data,
      };
    }

    return {
      domains: [],
    };
  }, [domainsGet.data]);

  const fetch = () => {
    domainsGet.fetch();
  };

  useEffect(() => {
    if (!isChanging) {
      form.setFieldsValue(domainData);
    }
  }, [domainData, isChanging]);

  useEffect(() => {
    fetch();
    setForm((state) => ({
      ...state,
      [tabList[3].key]: {
        form,
        update: domainsAllUpdate,
      },
    }));
  }, []);

  useEffect(() => {
    if (domainsAllUpdate.data) {
      message.success('Changes saved successfully!');
      setLoading(false);
      setChanging(false);
      fetch();
    }
  }, [domainsAllUpdate.data]);

  useEffect(() => {
    if (domainsAllUpdate.error) {
      setLoading(false);
    }
  }, [domainsAllUpdate.error]);

  const onFieldsChange = (_: FieldData[], allFields: FieldData[]) => {
    if (allFields.every((field) => field?.errors?.length === 0)) {
      setValid(true);
    } else {
      setValid(false);
    }

    const values = allFields.find((item) => item.name.length === 1 && item.name[0] === 'domains')?.value;

    if (!isEqual(values, domainData?.domains)) {
      setChanging(true);
    } else {
      setChanging(false);
    }
  };

  return (
    <>
      {domainsAllUpdate.error ? (
        <Alert
          type="error"
          message={getMessageInError(domainsAllUpdate.error)}
          closable
          onClose={domainsAllUpdate.clearError}
        />
      ) : null}

      {domainsGet.error ? (
        <Alert type="error" message={getMessageInError(domainsGet.error)} closable onClose={domainsGet.clearError} />
      ) : null}

      {domainsGet.loading || !domainsGet.data ? (
        <div className={styles.loading}>
          <Loading />
        </div>
      ) : (
        <Form
          form={form}
          name="dynamic_form_domain"
          autoComplete="off"
          initialValues={domainData}
          onFieldsChange={onFieldsChange}
        >
          <FormList
            deleteAction={domainDelete}
            removeTitle="Disable domain"
            removeButtonText="Disable anyway"
            nameButton="Add domain"
            name="domains"
            valueName="title"
            placeholder="Some domain"
            fetch={fetch}
            items={{ data: domainsGet.data }}
          />
          {activeTab === 'domains' && (
            <CreateModal
              createAction={domainsCreate}
              fetch={fetch}
              nameButton="Add domain"
              valueName="title"
              nameLabel="Domain name"
            />
          )}
        </Form>
      )}
    </>
  );
}
