import { useState, useEffect } from 'react';
import { App, Form, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useUserCreate } from '../../../../hooks/user';
import { getMessageInError } from '../../../../hooks/fetch';
import { UserRoles } from '../../../../enums/user';
import TopPanel from '../../../Common/TopPanel';
import AdminFields from '../../../Common/AdminFields';
import { useSimpleModal } from '../../../Common/Modal/Simple';

import styles from './index.module.scss';

export default function AdminCreate() {
  const { message } = App.useApp();
  const navigate = useNavigate();
  const createUser = useUserCreate();
  const [wasChanging, setWasChanging] = useState(false);
  const [form] = Form.useForm();
  const { open, contextHolder } = useSimpleModal();

  useEffect(() => {
    if (createUser.data) {
      message.info("Admin has been successfully created. The email has been sent to the admin's email address");
      navigate('/admins');
    }
  }, [createUser.data]);

  useEffect(() => {
    if (createUser.error) {
      message.error(getMessageInError(createUser.error));
      createUser.clearError();
    }
  }, [createUser.error]);

  const onFieldsChange = (_: any, allFields: any[]) => {
    const isFieldsValid = allFields.every((field) => field.errors.length === 0 && field.value);

    if (isFieldsValid) {
      setWasChanging(true);
    } else {
      setWasChanging(false);
    }
  };

  return (
    <div className={styles.wrapper}>
      <TopPanel
        padding
        title="Add admin"
        arrowBack="/admins"
        rightContent={(
          <Button
            disabled={!wasChanging}
            onClick={() => {
              open({
                icon: null,
                title: 'Add admin',
                content: 'Are you sure you want to add a new admin?',
                cancelText: 'Cancel',
                okText: 'Add',
                centered: true,
                onOk: () => createUser.fetch({
                  ...form.getFieldsValue(),
                  role: UserRoles.LOKEM_ADMIN,
                  status: 'active',
                }),
              });
            }}
            type="primary"
            loading={createUser.loading}
          >
            Create
          </Button>
        )}
      />
      <div className={styles.inner}>
        <Form
          form={form}
          requiredMark={false}
          onFieldsChange={onFieldsChange}
          layout="vertical"
          className={styles.form}
        >
          <AdminFields />
        </Form>
        {contextHolder}
      </div>
    </div>
  );
}
