import React from 'react';
import { MessageTwoTone } from '@ant-design/icons';
import { Typography } from 'antd';
import { NavLink } from 'react-router-dom';
import { COLOR_PRIMARY } from '../../../AntdConfigProvider';
import styles from './index.module.scss';

export default function ContactUs(): React.ReactNode {
  return (
    <div className={styles.wrapper}>
      <Typography.Title level={4}> Contact Us</Typography.Title>
      <div className={styles.inner}>
        <MessageTwoTone twoToneColor={COLOR_PRIMARY} />
        <Typography.Text>
          If you need assistance, please email us at
          <NavLink to="mailto:support@lokem.com.au"> support@lokem.com.au </NavLink>
          and we will get back to you as soon as possible
        </Typography.Text>
      </div>
    </div>
  );
}
