/* eslint-disable @typescript-eslint/no-unused-vars, no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { RootState } from '../index';

export const moduleName = 'filter';

export interface State {
  isFiltering: false;
  clients: string[];
  locations: string[];
}

export function useFilterData() {
  return useSelector((state: RootState) => state[moduleName]);
}

export const auth = createSlice({
  name: moduleName,
  initialState: () => {
    const params = new URLSearchParams(document.location.search);

    return {
      clients: params.getAll('clients'),
      locations: params.getAll('locations'),
      isFiltering: false,
    };
  },
  reducers: {
    setFilterData: (state, { payload }) => {
      state.clients = payload.clients;
      state.locations = payload.locations;
    },
    changeFiltering: (state, { payload }) => {
      state.isFiltering = payload;
    },
  },
});

export const { reducer, actions } = auth;
