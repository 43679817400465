import {
  DefaultFetchError,
  FetchCreate,
  FetchGet,
  FetchGetId,
  FetchUpdate,
  useFetchCreate, useFetchDelete,
  useFetchGet,
  useFetchGetId,
  useFetchUpdate,
  FetchSuccess,
  PagingDataResponse,
  PagingParams,
} from './fetch';

export interface LocationsRoles {
  id: string
  rate: number;
  rateRangeFrom: number;
  rateRangeTo: number;
  rateType: string;
  name: string;
  notes: string;
  location: Item;
  level: string;
  speciality: string;
  orientation: Item;
  experiences: Item[];
  documents: Item[];
  inductions: Item[];
  lunchBreak: number
}

interface Item {
  title: string
  id: string
}

export interface LocationsRolesCreateParams {
  rate?: number;
  rateRangeFrom?: number;
  rateRangeTo?: number;
  rateType: string;
  name?: string;
  notes: string;
  location?: string;
  level?: string;
  speciality?: string;
  orientation?: string;
  experiences: string[];
  documents: string[];
  inductions: string[];
  lunchBreak: number
}

export interface LocationsRolesUpdateParams {
  rate?: number;
  rateType?: string;
  name?: string;
  notes?: string;
  location?: string;
  level?: string;
  speciality?: string;
  orientation?: string;
  experiences?: string[];
  documents?: string[];
  inductions?: string[];
  lunchBreak?: number
}

export const useLocationsRolesCreate = (): FetchCreate<FetchSuccess, DefaultFetchError, LocationsRolesCreateParams> => (
  useFetchCreate('locations-roles')
);

export const useLocationsRolesUpdate = (): FetchUpdate<FetchSuccess, DefaultFetchError, LocationsRolesUpdateParams> => (
  useFetchUpdate('locations-roles')
);

export interface LocationsRolessGetParams extends PagingParams {
  location?: string;
}

export function useLocationsRolesGet<D = PagingDataResponse<LocationsRoles>,
  DD = D>(decorateData?: (data: D) => DD): FetchGet<D, LocationsRolessGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, LocationsRolessGetParams, DD>(
    'locations-roles',
    {
      decorateData,
      autoStart: false,
      startStateLoading: false,
    },
  );
}

interface LocationsRolesSelect extends LocationsRoles {
  ids?: string[]
}

export function useLocationsRolesSelectGet<D = LocationsRolesSelect[],
  DD = D>(decorateData?: (data: D) => DD): FetchGet<D, LocationsRolessGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, LocationsRolessGetParams, DD>(
    'locations-roles/select',
    {
      decorateData,
      autoStart: false,
      startStateLoading: false,

      beforeError(err) {
        if (err && err.response?.status === 422) {
          return true;
        }

        return false;
      },
    },
  );
}

export const useLocationsRolesId = (): FetchGetId<LocationsRoles> => (
  useFetchGetId('locations-roles', '', { autoStart: false })
);

export const useLocationsRolesDelete = () => useFetchDelete<FetchSuccess, DefaultFetchError, string>('locations-roles');
