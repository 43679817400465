import {
  DefaultFetchError, FetchGet, useFetchGet,
} from './fetch';

export interface CheckAHPRANumberParams {
  ahpra: string
}

export interface AHPRANumberResponse {
  unique: boolean
}

export const useCheckAHPRANumber = (): FetchGet<
  AHPRANumberResponse,
  CheckAHPRANumberParams,
  DefaultFetchError,
  AHPRANumberResponse
> => useFetchGet('auth/sign-up/ahpra', {
  autoStart: false,
  startStateLoading: false,
});

export interface CheckIndemnityNumberParams {
  indemnityNumber: string
}

export interface IndemnityNumberResponse {
  unique: boolean
}

export const useCheckIndemnityNumber = (): FetchGet<
  IndemnityNumberResponse,
  CheckIndemnityNumberParams,
  DefaultFetchError,
  IndemnityNumberResponse
> => useFetchGet('auth/sign-up/indemnity', {
  autoStart: false,
  startStateLoading: false,
});
