import {
  DefaultFetchError, FetchCreate, FetchUpdate, useFetchCreate, useFetchUpdate, useFetchDelete, FetchSuccess,
} from './fetch';

export interface ApplicantStatusesParams {
  client: string;
  applicant: string;
  status: string;
}

export const useApplicantStatusesCreate = (): FetchCreate<FetchSuccess, DefaultFetchError, ApplicantStatusesParams> => (
  useFetchCreate('applicant-statuses')
);

export const useApplicantStatusesDelete = () => (
  useFetchDelete<FetchSuccess, DefaultFetchError, string>('applicant-statuses')
);

export const useApplicantStatusesUpdate = (): FetchUpdate<FetchSuccess, DefaultFetchError, ApplicantStatusesParams> => (
  useFetchUpdate('applicant-statuses')
);
