import { Domain } from '../types/location';
import {
  DefaultFetchError,
  FetchCreate,
  FetchGetId, FetchSuccess,
  useFetchCreate,
  useFetchGetId,
  FetchUpdate,
  useFetchUpdate,
} from './fetch';

export interface ManagersCreateParams {
  client: string;
  user: string;
  locations: string[]
}
export interface ManagersUpdateParams {
  locations: string[]
}

export const useManagersCreate = (): FetchCreate<FetchSuccess, DefaultFetchError, ManagersCreateParams> => (
  useFetchCreate('managers')
);

export const useManagerUpdate = (): FetchUpdate<FetchSuccess, DefaultFetchError, ManagersUpdateParams> => (
  useFetchUpdate('managers')
);

export const useManagerId = (): FetchGetId<Domain> => useFetchGetId('managers', '', { autoStart: false });
