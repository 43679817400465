import React from 'react';
import Layout from '../../../components/Layout';
import AddUserForm from '../../../components/Pages/User/Add';

export default function AddUser(): React.ReactNode {
  document.title = `${process.env.REACT_APP_TITLE_NAME}: Add User`;

  return (
    <Layout disableLeftMenu>
      <AddUserForm />
    </Layout>
  );
}
