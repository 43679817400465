import { useMemo } from 'react';
import { Button } from 'antd';
import SettingsProvider, { useSettingsContext } from './Context';
import CommonTabs, { TabPaneItem } from '../../../Common/Tabs';
import General from './General';
import Layout from './Layout';
import TopPanel from '../../../Common/TopPanel';
import { useAuthState } from '../../../../store/auth';
import { UserRoles, isRoleEnough } from '../../../../enums/user';

import styles from './index.module.scss';

export const tabList: TabPaneItem[] = [
  {
    label: 'Layout settings',
    key: 'layout',
    children: (
      <>
        <Layout
          type="rectangle"
          title="Upload company logo"
          tooltipText="Upload your main company logo here. For best results, follow the guidelines listed below."
          text="Minimum pixels required: 160 for width, 32 for height"
        />
        <Layout
          type="square"
          title="Upload secondary company logo"
          tooltipText="This secondary logo is shown when the side navigation bar is closed."
          text="Minimum pixels required: 32 for width and height (suggested proportion 1:1)"
        />
      </>
    ),
  },
  {
    label: 'General info',
    key: 'general',
    children: <General />,
  },
];

function Tabs() {
  const { user } = useAuthState();
  const { isEdit, activeTab } = useSettingsContext();
  const items = useMemo(
    () => tabList
      .filter(({ key }) => !(key === 'layout' && user?.role === UserRoles.MANAGER))
      .map((item) => ({
        ...item,
        disabled: isEdit && item.key !== activeTab,
      })),
    [isEdit, activeTab],
  );

  return <CommonTabs items={items} />;
}
function TopBar() {
  const { user } = useAuthState();
  const isAdmin = isRoleEnough(user?.role, UserRoles.CLIENT_ADMIN);
  const {
    isEdit, setEdit, onSave, isChanging, activeTab,
  } = useSettingsContext();

  return (
    <TopPanel
      padding
      title="Settings"
      rightContent={
        activeTab === 'general' && (
          <>
            {isAdmin && !isEdit && <Button onClick={() => setEdit(() => true)}>Edit</Button>}
            {isEdit && (
              <div>
                <Button
                  type="text"
                  onClick={() => setEdit(() => false)}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  onClick={() => (onSave ? onSave() : null)}
                  disabled={!isChanging}
                >
                  Save
                </Button>
              </div>
            )}
          </>
        )
      }
    />
  );
}

export default function SettingList(): React.ReactNode | null {
  return (
    <div className={styles.wrapper}>
      <SettingsProvider>
        <TopBar />
        <Tabs />
      </SettingsProvider>
    </div>
  );
}
