import { Checkbox } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useSettingsContext } from '../../../Pages/Profile/Applicant/Context';
import styles from './index.module.scss';

interface ReviewedProps {
  name: string;
}

function Reviewed({ name }: ReviewedProps) {
  const {
    handleReviewed, isAdmin, isVerified, applicant,
  } = useSettingsContext();
  const onChange = (e: CheckboxChangeEvent) => {
    handleReviewed(name, e.target.checked);
  };

  return (
    applicant
    && isAdmin
    && !isVerified
    && applicant.user.status !== 'rejected' && (
      <Checkbox name={name} onChange={onChange} className={styles.checkbox}>
        Information reviewed
      </Checkbox>
    )
  );
}

export default Reviewed;
