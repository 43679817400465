import React, { useEffect, useRef } from 'react';
import { Badge, Space, Typography } from 'antd';
import clsx from 'clsx';
import type { Dayjs } from 'dayjs';
import { HighlightOutlined } from '@ant-design/icons';
import { useContextCalendar } from '../Context';
import { DefaultFetchError, FetchGet } from '../../../../hooks/fetch';
import { ShiftMonthGetParams, ShiftMonthResponse } from '../../../../hooks/shift';
import { getBudgeColor } from '../../Badge';
import { MultiDayIcon } from '../../Icon';
import { getHoursBetween } from '../../../../utils';

import styles from './index.module.scss';

export interface SelectedState {
  ref?: React.MutableRefObject<HTMLUListElement | null>;
  date: Dayjs;
}

interface DayProps {
  date: Dayjs;
  value: Dayjs;
  shiftMonthGet: FetchGet<ShiftMonthResponse, ShiftMonthGetParams, DefaultFetchError, ShiftMonthResponse>;
}

function Day({ date, value, shiftMonthGet }: DayProps) {
  const {
    selected, setSelected, handleMouseEnter, handleMouseLeave, hovered,
  } = useContextCalendar();
  const ref = useRef<HTMLUListElement | null>(null);

  useEffect(() => {
    if (ref.current && !selected?.ref) {
      if (selected?.date?.get('D') === value.get('D') && selected?.date?.get('M') === value.get('M')) {
        setSelected(
          (state) => ({
            ...state,
            ref,
          } as SelectedState),
        );
      }
    }
  }, [ref.current, value, selected]);

  if (!shiftMonthGet.data || value.month() !== date.month()) {
    return null;
  }

  return (
    <ul className={styles.day} ref={ref}>
      {shiftMonthGet.data[value.date()]?.length === 1
        ? shiftMonthGet.data[value.date()]?.map((item) => (
          <li
            key={item.id}
            onMouseEnter={() => handleMouseEnter(item.multiShift?.id)}
            onMouseLeave={handleMouseLeave}
            className={clsx(styles.item, hovered && item.multiShift?.id === hovered ? styles.hovered : '')}
          >
            <Badge
              color={getBudgeColor(item.status)}
              text={(
                <Space direction="vertical" className={styles.moreInfo}>
                  <div className={styles.locationRow}>
                    <div className={styles.textWrapper}>
                      <Typography.Text ellipsis>{item.location?.name}</Typography.Text>
                    </div>
                    {item.multiShift && <MultiDayIcon />}
                    {item.completedEdit && <HighlightOutlined style={{ color: '#BFBFBF' }} />}
                  </div>

                  <Typography.Text ellipsis>{item.locationRole?.name}</Typography.Text>
                  <Typography.Text ellipsis>{getHoursBetween(item.datetimeStart, item.datetimeEnd)}</Typography.Text>
                </Space>
                )}
            />
          </li>
        ))
        : shiftMonthGet.data[value.date()]?.map(({
          id, status, location, multiShift, completedEdit,
        }, i) => (i === 4 ? (
          <li
            key={id}
            className={clsx(styles.theeDot, hovered && multiShift?.id === hovered ? styles.hovered : '')}
            onMouseEnter={() => handleMouseEnter(multiShift?.id)}
            onMouseLeave={handleMouseLeave}
          >
            ...
          </li>
        ) : (
          <li
            key={id}
            onMouseEnter={() => handleMouseEnter(multiShift?.id)}
            onMouseLeave={handleMouseLeave}
            className={clsx(styles.item, hovered && multiShift?.id === hovered ? styles.hovered : '')}
          >
            <Badge
              color={getBudgeColor(status)}
              text={(
                <Space direction="vertical" className={styles.moreInfo}>
                  <div className={styles.locationRow}>
                    <div className={styles.textWrapper}>
                      <Typography.Text ellipsis>{location?.name}</Typography.Text>
                    </div>
                    {multiShift && <MultiDayIcon />}
                    {completedEdit && <HighlightOutlined style={{ color: '#BFBFBF' }} />}
                  </div>
                </Space>
                  )}
            />
          </li>
        )))}
    </ul>
  );
}

export default Day;
