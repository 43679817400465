import { useEffect, useMemo } from 'react';
import { App, Form } from 'antd';
import { isEqual } from 'lodash';
import { FieldData } from 'rc-field-form/lib/interface';
import { useSettingsContext } from '../Context';
import {
  useInductionCreate,
  useInductionDelete,
  useInductionsAllUpdate,
  useInductionsPaginGet,
} from '../../../../hooks/inductions';
import { tabList } from '../index';
import FormList from '../Common/List';
import { useSearchParams } from '../../../../hooks/useSearchParams';
import { pagingParamSearchList } from '../../../../contstant';
import { useMessageError } from '../../../../hooks/common';
import CreateModal from '../Common/CreateModal';

export default function Inductions() {
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const {
    setForm, setLoading, setChanging, isChanging, setValid, activeTab,
  } = useSettingsContext();
  const [searchParams] = useSearchParams();
  const inductions = useInductionsPaginGet();
  const inductionsAllUpdate = useInductionsAllUpdate();
  const inductionDelete = useInductionDelete();
  const inductionCreate = useInductionCreate();
  const inductionData = useMemo(() => {
    if (inductions.data) {
      return {
        inductions: inductions.data.data,
      };
    }

    return {
      inductions: [],
    };
  }, [inductions.data]);

  useEffect(() => fetch(), [searchParams]);

  const fetch = () => {
    const props = {};

    pagingParamSearchList.forEach((key) => {
      const value = searchParams.getAll(key);

      if (value.length || !(value.length === 1 && value[0] === '')) {
        // @ts-ignore @typescript-eslint/ban-ts-comment
        props[key] = value.length === 1 ? value[0] : value;
      }
    });

    inductions.fetch(props);
  };

  useEffect(() => {
    if (!isChanging) {
      form.setFieldsValue(inductionData);
    }
  }, [inductionData, isChanging]);

  useEffect(() => {
    setForm((state) => ({
      ...state,
      [tabList[1].key]: {
        form,
        update: inductionsAllUpdate,
      },
    }));
  }, []);

  useEffect(() => {
    if (inductionsAllUpdate.data) {
      message.success('Changes saved successfully!');
      setLoading(false);
      setChanging(false);
      fetch();
    }
  }, [inductionsAllUpdate.data]);

  useEffect(() => {
    if (inductionsAllUpdate.error) {
      setLoading(false);
    }
  }, [inductionsAllUpdate.error]);

  const onFieldsChange = (_: FieldData[], allFields: FieldData[]) => {
    if (allFields.every((field) => field?.errors?.length === 0)) {
      setValid(true);
    } else {
      setValid(false);
    }

    const values = allFields.find((item) => item.name.length === 1 && item.name[0] === 'inductions')?.value;

    if (!isEqual(values, inductionData?.inductions)) {
      setChanging(true);
    } else {
      setChanging(false);
    }
  };

  useMessageError([inductionsAllUpdate, inductions]);

  return (
    <Form
      form={form}
      name="dynamic_form_induction"
      autoComplete="off"
      initialValues={inductionData}
      onFieldsChange={onFieldsChange}
    >
      <FormList
        deleteAction={inductionDelete}
        removeTitle="Disable induction"
        removeButtonText="Disable anyway"
        nameButton="Add induction"
        name="inductions"
        valueName="title"
        placeholder="Some induction"
        fetch={fetch}
        items={inductions?.data}
        loading={inductions.loading}
      />
      {activeTab === 'inductions' && (
        <CreateModal
          createAction={inductionCreate}
          fetch={fetch}
          nameButton="Add induction"
          valueName="title"
          nameLabel="Induction name"
        />
      )}
    </Form>
  );
}
