import { ContactsOutlined, MedicineBoxOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { Switch, Typography } from 'antd';
import RadioButton, { RadioItem } from '../../../../Common/Tabs/RadioButton';
import { useSearchParams } from '../../../../../hooks/useSearchParams';
import { getValidSearchParams } from '../../../../../utils';
import { useRosterContext } from '../../Context';
import ShiftStatus, { assignedStatuses, unassignedStatuses } from '../../../../../enums/shift';
import styles from './index.module.scss';

export const list: RadioItem[] = [
  {
    key: 'role',
    icon: <MedicineBoxOutlined />,
    content: 'View by role',
  },
  {
    key: 'applicant',
    icon: <ContactsOutlined />,
    content: 'View by applicant',
  },
];

export default function Title() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [searchParams, setSearchParams, paramsWithoutTableProps] = useSearchParams();
  const { isApplicantPage } = useRosterContext();

  return (
    <div className={styles.title}>
      <div>
        <Typography.Title level={4}>Roster</Typography.Title>

        <RadioButton
          className={styles.tabs}
          defaultActive={isApplicantPage ? list[1].key : list[0].key}
          items={list}
          onChange={(key) => {
            navigate(`/roster/${key}${search}`);
            if (key === list[1].key) {
              setSearchParams({
                ...getValidSearchParams('*', searchParams),
                unassigned: [],
                page: '1',
              });
            } else {
              setSearchParams({
                ...getValidSearchParams('*', searchParams),
                page: '1',
              });
            }
          }}
        />
      </div>
      {isApplicantPage ? null : (
        <div className={styles.switch}>
          Show unassigned published shifts
          {' '}
          <Switch
            value={!searchParams.getAll('statuses').every((status) => assignedStatuses.includes(status as ShiftStatus))}
            onChange={(unassigned) => {
              setSearchParams({
                ...getValidSearchParams('*', searchParams),
                statuses: unassigned ? unassignedStatuses : assignedStatuses,
              });
            }}
          />
        </div>
      )}
    </div>
  );
}
