import React from 'react';
import { Select } from 'antd';
import { useRosterContext, viewList } from '../../../Context';

export default function View(): React.ReactNode {
  const { view, setView } = useRosterContext();

  return (
    <div>
      <label>View: </label>
      <Select
        value={view}
        placeholder="Select view"
        onChange={setView}
        options={viewList}
      />
    </div>
  );
}
