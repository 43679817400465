import React, { useState } from 'react';
import Layout from '../../../components/Layout';
import ContentClientList from '../../../components/Pages/Client/List';
import Filter from '../../../components/Pages/Client/Filter';

export default function ClientList(): React.ReactNode {
  document.title = `${process.env.REACT_APP_TITLE_NAME}: Client list`;

  const [reload, setReload] = useState<number>();

  return (
    <Layout minWidth leftMenu={<Filter reload={reload} />}>
      <ContentClientList setReload={setReload} />
    </Layout>
  );
}
