import React from 'react';
import Layout from '../../components/Layout';
import ContentLocation from '../../components/Pages/Location';

export default function Location(): React.ReactNode {
  document.title = `${process.env.REACT_APP_TITLE_NAME}: Location`;

  return (
    <Layout disableLeftMenu transparent>
      <ContentLocation />
    </Layout>
  );
}
