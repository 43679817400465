import { useEffect } from 'react';
import { Select, Form } from 'antd';
import { useInductionsLocationGet } from '../../../../../hooks/inductions';
import { useLocationContext } from '../../Context';
import { useMessageError } from '../../../../../hooks/common';

interface InductionsProps {
  inductionRequired: boolean;
}

export default function Inductions({ inductionRequired }: InductionsProps) {
  const inductions = useInductionsLocationGet();
  const { location } = useLocationContext();

  useEffect(() => {
    if (location?.id && !inductions?.data) {
      inductions.fetch({ locations: [location?.id] });
    }
  }, [location]);

  useMessageError([inductions]);

  return (
    <div>
      <Form.Item
        name="inductions"
        label="Inductions (multi-select)"
        rules={inductionRequired ? [{ required: true, message: 'Required field!' }] : []}
      >
        <Select
          loading={inductions.loading}
          options={inductions?.data?.map((level) => ({ label: level.title, value: level.id }))}
          mode="multiple"
          placeholder="Select Induction"
          filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
        />
      </Form.Item>
    </div>
  );
}
