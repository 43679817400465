import React from 'react';
import Layout from '../../../../components/Layout';
import ContentShiftListCollapse from '../../../../components/Pages/Shift/List/Collapse';
import Filter from '../../../../components/Pages/Shift/Filter';

export default function ShiftList(): React.ReactNode {
  document.title = `${process.env.REACT_APP_TITLE_NAME}: Open shift list`;

  return (
    <Layout leftMenu={<Filter />}>
      <ContentShiftListCollapse openShift />
    </Layout>
  );
}
