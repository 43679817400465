import React from 'react';
import Layout from '../../../../components/Layout';
import ContentClientUserCreate from '../../../../components/Pages/Client/User/Create';

export default function ClientUserCreate(): React.ReactNode {
  document.title = `${process.env.REACT_APP_TITLE_NAME}: Client user: create`;

  return (
    <Layout disableLeftMenu transparent>
      <ContentClientUserCreate />
    </Layout>
  );
}
