import { useState, useMemo, useEffect } from 'react';
import { Input } from 'antd';
import { debounce } from 'lodash';
import { pagingParamSearchList } from '../../../../../contstant';
import { useSearchParams } from '../../../../../hooks/useSearchParams';
import { getValidSearchParams } from '../../../../../utils';
import styles from './index.module.scss';

function Search() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState<string | null>('');

  useEffect(() => {
    if (!searchValue && searchParams.get('partialTitle')) {
      setSearchValue(searchParams.get('partialTitle'));
    }

    if (!searchParams.get('partialTitle')) {
      setSearchValue('');
    }
  }, [searchParams]);

  const handleSearch = useMemo(
    () => debounce((value: string) => {
      setSearchParams({
        ...getValidSearchParams(pagingParamSearchList, searchParams),
        partialTitle: value ? [value] : [],
        page: '1',
      });
    }, 500),
    [],
  );

  return (
    <Input.Search
      value={searchValue || ''}
      placeholder="Search"
      onChange={(e) => {
        setSearchValue(e.target.value || '');
        handleSearch(e.target.value || '');
      }}
      className={styles.search}
    />
  );
}

export default Search;
