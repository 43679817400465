import { useParams } from 'react-router-dom';
import Checkboxes from '../../../../Common/Filter/Checkboxes';
import SearchMenu from '../../../../Common/Filter/Search/Menu';
import FilterMenu from '../../../../Common/Filter/Menu';
import FilterWrapper from '../../../../Common/Filter/Wrapper';
import { useUserStatusesAllSelectGet, useUserStatusesSelectGet } from '../../../../../hooks/user';
import { useMessageError, useSelectData } from '../../../../../hooks/common';

interface FilterProps {
  disabled?: boolean;
  reload?: number;
}

function Filter({ disabled }: FilterProps) {
  const { clientId } = useParams();
  const defaultUserStatusesAllSelectGet = useUserStatusesAllSelectGet(clientId);
  const userStatusesSelectGet = useUserStatusesSelectGet(clientId);
  const items = useSelectData(userStatusesSelectGet);

  useMessageError([defaultUserStatusesAllSelectGet, userStatusesSelectGet]);

  return (
    <FilterWrapper clearFilters disabled={disabled}>
      <FilterMenu>
        <SearchMenu
          name="search"
          title="User"
        />

        <Checkboxes
          name="statuses"
          title="User status"
          defaultHook={defaultUserStatusesAllSelectGet}
          items={items}
          fetch={userStatusesSelectGet.fetch}
          loading={userStatusesSelectGet.loading || defaultUserStatusesAllSelectGet.loading}
          badge
          checkAll
        />
      </FilterMenu>
    </FilterWrapper>
  );
}

Filter.defaultProps = {
  reload: undefined,
  disabled: false,
};

export default Filter;
