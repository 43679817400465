import {
  Col, Form, Row, Typography, Input, Select,
} from 'antd';
import { useEffect } from 'react';
import Wrapper from '../Wrapper';
import { useIndemnitiesGet } from '../../../../hooks/indemnity';
import { createRulesForAntd, validationApplicant } from '../../../../utils/validations';

type IndemnityProps = {
  disabled?: boolean;
};
const validationRules = createRulesForAntd(validationApplicant);

function Indemnity({ disabled }: IndemnityProps) {
  const indemnities = useIndemnitiesGet();

  useEffect(() => {
    indemnities.fetch();
  }, []);

  return (
    <Wrapper>
      <Typography.Title style={{ margin: 0 }} level={5}>
        Indemnity insurance
      </Typography.Title>
      <Row gutter={[16, 0]}>
        <Col span={12}>
          <Form.Item name="indemnityProvider" label="Provider" style={{ marginBottom: 0 }}>
            <Select
              disabled={disabled}
              options={indemnities?.data?.map((item) => ({ label: item.title, value: item.id }))}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="indemnityNumber" label="Policy number" style={{ marginBottom: 0 }} rules={[validationRules]}>
            <Input disabled={disabled} />
          </Form.Item>
        </Col>
      </Row>
    </Wrapper>
  );
}

Indemnity.defaultProps = {
  disabled: false,
};

export default Indemnity;
