import React from 'react';
import Layout from '../../../components/Layout';
import ContentAdminList from '../../../components/Pages/Admin/Create';

export default function AdminCreate(): React.ReactNode {
  document.title = `${process.env.REACT_APP_TITLE_NAME}: Admin: create`;

  return (
    <Layout disableLeftMenu>
      <ContentAdminList />
    </Layout>
  );
}
