import { useEffect } from 'react';
import { Button, App } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { useLocationContext } from '../../Context';
import { getMessageInError } from '../../../../../hooks/fetch';
import { useOrientationCreate, useOrientationId } from '../../../../../hooks/orientation';

import styles from './index.module.scss';

type CopyOrienationProps = {
  id: string;
};

export default function CopyOrienation({ id }: CopyOrienationProps) {
  const createOrienation = useOrientationCreate();
  const getOrienation = useOrientationId();
  const { fetch, isChanging } = useLocationContext();
  const { message } = App.useApp();

  useEffect(() => {
    if (createOrienation.data) {
      fetch();
      message.info('You have successfully copied orientation');
    }
  }, [createOrienation.data]);

  useEffect(() => {
    if (getOrienation.data) {
      const orientation = getOrienation.data;

      createOrienation.fetch({
        location: orientation.location?.id,
        message: orientation.message,
        // files: orientation.files?.map((item) => item.id),
      });
    }
  }, [getOrienation.data]);

  useEffect(() => {
    if (getOrienation.error) {
      message.error(getMessageInError(getOrienation.error));
      getOrienation.clearError();
    }
    if (createOrienation.error) {
      message.error(getMessageInError(createOrienation.error));
      createOrienation.clearError();
    }
  }, [getOrienation.error, createOrienation.error]);

  const copyItem = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    getOrienation.fetch(undefined, id);
  };

  return (
    <Button type="text" disabled={isChanging} size="small" className={styles.btn} onClick={(e) => copyItem(e)}>
      <CopyOutlined className={styles.icon} />
    </Button>
  );
}
