import React from 'react';
import Detail from '../Common/Detail';
import { TabPaneItem } from '../../../Common/Tabs';
import ClientUserList from '../User/List';
import ClientForm from '../Form';
import { useClientCreate } from '../../../../hooks/client';
import ClientProvider, { useClientContext } from '../Context';

export default function ClientCreate(): React.ReactNode {
  const clientCreate = useClientCreate();

  const tabList: TabPaneItem[] = [
    {
      label: 'General info',
      key: 'general',
      children: <ClientForm />,
    },
    {
      label: 'List of users',
      key: 'users',
      children: <ClientUserList />,
    },
  ];

  return (
    <ClientProvider defaultTab={tabList[0].key} mode="create" action={clientCreate}>
      <Detail tabList={tabList} mode="create" useContext={useClientContext} />
    </ClientProvider>
  );
}
