import React from 'react';
import Layout from '../../../components/Layout';
import ContentEdit from '../../../components/Pages/Client/Edit';

export default function ClientEdit(): React.ReactNode {
  document.title = `${process.env.REACT_APP_TITLE_NAME}: Client: edit`;

  return (
    <Layout disableLeftMenu>
      <ContentEdit />
    </Layout>
  );
}
