import {
  Typography, Form, Button, App,
} from 'antd';
import { useEffect } from 'react';
import { useUserPhoneConfirm, useUserChangePhone } from '../../../../hooks/user';
import { useMessageError } from '../../../../hooks/common';
import InputCodeWrapper from '../../Input/Code';
import { useSettingsContext } from '../../../Pages/Profile/Applicant/Context';
import styles from './index.module.scss';

interface FormValues {
  [key: string]: number;
}

interface CodeConfirmationProps {
  setIsModalOpen: (state: boolean) => void;
  setShowOTP: (state: boolean) => void;
  phone: string;
  secretKey: string;
}

export default function CodeConfirmation({
  setIsModalOpen, phone, secretKey, setShowOTP,
}: CodeConfirmationProps) {
  const phoneConfirm = useUserPhoneConfirm();
  const changePhone = useUserChangePhone();
  const [form] = Form.useForm();
  const { message } = App.useApp();
  const { fetch } = useSettingsContext();

  const onFinishHandler = (values: FormValues) => {
    phoneConfirm.fetch({
      secretCode: Object.values(values).join(''),
      secretKey: changePhone.data?.secretKey || secretKey,
      phone,
    });
  };

  useEffect(() => {
    if (phoneConfirm.data) {
      setIsModalOpen(false);
      message.success('The phone number was updated successfully!');
      fetch();
      setShowOTP(false);
    }
  }, [phoneConfirm.data]);

  useMessageError([phoneConfirm, changePhone]);

  return (
    <Form form={form} autoComplete="off" layout="vertical" onFinish={onFinishHandler}>
      <Typography.Title className={styles.title} level={5}>
        Please enter the six-digit code sent to your mobile phone
      </Typography.Title>
      <span className={styles.text}>
        Didn&apos;t get the code? Go back and check your information is correct or
        {' '}
        <a
          href="/generate-code"
          onClick={(e) => {
            e.preventDefault();
            changePhone.fetch({ phone });
          }}
        >
          resend the code.
        </a>
      </span>

      <InputCodeWrapper form={form} style={{ marginTop: '32px' }} />

      <div>
        <Form.Item shouldUpdate className={styles.btn}>
          {() => (
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              block
              loading={phoneConfirm.loading || changePhone.loading}
              disabled={
                !Object.values(form.getFieldsValue()).every((number) => String(number))
                || form.getFieldsError().filter(({ errors }) => errors.length).length > 0
              }
            >
              Confirm
            </Button>
          )}
        </Form.Item>
      </div>
    </Form>
  );
}
