import React, { useMemo } from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import CommonTabs from '../../../../Common/Tabs';
import { useSettingsContext } from '../../Context';
import TopPanel from '../../../../Common/TopPanel';
import { SettingsTabPaneItem } from '../../index';

import styles from './index.module.scss';

interface TabsProps {
  tabList: SettingsTabPaneItem[];
}

function Tabs({ tabList }: TabsProps) {
  const navigate = useNavigate();
  const { activeTab, setActiveTab, isChanging } = useSettingsContext();

  const items = useMemo(
    () => tabList?.map((item) => ({
      ...item,
      disabled: isChanging && item.key !== activeTab,
    })),
    [isChanging, activeTab],
  );

  return (
    <CommonTabs
      destroyInactiveTabPane={false}
      className={styles.tabs}
      items={items}
      onChange={(tab) => {
        navigate(tabList.find(({ key }) => tab === key)?.url || tabList[0].url);
        setActiveTab(() => tab);
      }}
      activeKey={activeTab}
    />
  );
}

function RightContent() {
  const {
    loading, onSave, isChanging, setChanging, isValid,
  } = useSettingsContext();

  return (
    <div>
      {isChanging && (
        <Button
          type="text"
          onClick={() => {
            setChanging(false);
          }}
        >
          Cancel
        </Button>
      )}
      <Button
        type="primary"
        onClick={() => (onSave ? onSave() : null)}
        disabled={!isChanging || !isValid}
        loading={loading}
      >
        Save
      </Button>
    </div>
  );
}

interface DetailProps {
  arrowBack?: boolean;
  name?: string;
  tabList?: SettingsTabPaneItem[];
  children?: React.ReactNode;
}

function Detail({
  arrowBack, name, tabList, children,
}: DetailProps): React.ReactNode {
  return (
    <div className={styles.wrapper}>
      <TopPanel padding arrowBack={arrowBack} title={name} rightContent={<RightContent />} />
      {tabList ? <Tabs tabList={tabList} /> : null}

      {children}
    </div>
  );
}

Detail.defaultProps = {
  arrowBack: true,
  name: undefined,
  tabList: undefined,
  children: undefined,
};

export default Detail;
