import React, { useState } from 'react';
import Layout from '../../../../components/Layout';
import Filter from '../../../../components/Pages/Client/User/Filter';
import ClientUserTab from '../../../../components/Pages/Client/User/Tabs';

export default function ClientUserList(): React.ReactNode {
  document.title = `${process.env.REACT_APP_TITLE_NAME}: Client user: list`;

  const [reload, setReload] = useState<number>();

  return (
    <Layout minWidth leftMenu={<Filter reload={reload} />}>
      <ClientUserTab setReload={setReload} />
    </Layout>
  );
}
