import { Dropdown as AntdDropdown, Badge } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { getBudgeColor } from '../../../../Common/Badge';
import { useSettingsContext } from '../../Context';
import styles from './index.module.scss';

interface DropdownProps {
  itemId: string;
  itemPriority: string;
}

function Dropdown({ itemId, itemPriority }: DropdownProps) {
  const {
    form, activeTab, setChanging, setValid,
  } = useSettingsContext();

  const handleClick = (id: string | undefined, current: string, level: string) => {
    const instanse = form?.[activeTab]?.form;

    instanse.setFieldValue(
      activeTab,
      instanse.getFieldValue(activeTab).map((item: any) => {
        if (item.id === id) {
          return {
            ...item,
            priority: level,
          };
        }

        return item;
      }),
    );
    if (current !== level) {
      setChanging(true);
      setValid(true);
    }
  };

  return (
    <AntdDropdown
      menu={{
        items: [
          {
            key: 'high',
            label: <Badge color={getBudgeColor('high')} text="Display First" />,
            onClick: () => handleClick(itemId, itemPriority, 'high'),
          },
          {
            key: 'medium',
            label: <Badge color={getBudgeColor('medium')} text="Display Second" />,
            onClick: () => handleClick(itemId, itemPriority, 'medium'),
          },
          {
            key: 'low',
            label: <Badge color={getBudgeColor('low')} text="Display Third" />,
            onClick: () => handleClick(itemId, itemPriority, 'low'),
          },
        ],
        selectable: true,
        defaultSelectedKeys: [itemPriority || 'medium'],
      }}
    >
      <MoreOutlined className={styles.priority} />
    </AntdDropdown>
  );
}

export default Dropdown;
