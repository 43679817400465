import Layout from '../../../components/Layout';
import ContentUserList from '../../../components/Pages/User/List';
import Filters from '../../../components/Pages/User/Filters';

export default function UserList(): React.ReactNode {
  document.title = `${process.env.REACT_APP_TITLE_NAME}: User list`;

  return (
    <Layout minWidth leftMenu={<Filters />}>
      <ContentUserList />
    </Layout>
  );
}
