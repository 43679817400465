import React, {
  createContext, PropsWithChildren, useContext, useState,
} from 'react';
import { AnyObject } from '@triare/auth-redux';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { FormInstance } from 'antd';
import { UserCreateParams, useUserCreate } from '../../../../hooks/user';
import { DefaultFetchError, FetchCreate, FetchSuccess } from '../../../../hooks/fetch';
import {
  SignUpFinishCreateParams,
  SignUpFinishSuccess, useSignUpFinishCreate,
} from '../../../../hooks/signUpFinish';
import { list as listSteps } from '../Wrapper';

interface SignUpContextProps {
  list: {[name:string]: FormInstance}
  setForm: (name: string, form: FormInstance) => void
  listResponse: AnyObject
  setResponse: (name: string, state: AnyObject) => void
  pageNumber: number
  setPageNumber: (id: number) => void
  isPossibleTriggerBack: () => boolean
  onFinish: (name: string, values: AnyObject) => void
  onFinishFailed: (name: string, errorInfo: ValidateErrorEntity) => void
  triggerNext: () => void
  triggerBack: () => void
  userCreate: FetchCreate<FetchSuccess, DefaultFetchError, UserCreateParams> | null
  signUpFinish: FetchCreate<SignUpFinishSuccess, DefaultFetchError, SignUpFinishCreateParams>
  checkRequiredRequired: (payload: boolean) => void
}

const defaultValue: SignUpContextProps = {
  list: {},
  setForm: () => {
    // default
  },
  listResponse: {},
  setResponse: () => {
    // default
  },
  userCreate: null,
  pageNumber: 0,
  isPossibleTriggerBack: () => false,
  setPageNumber: () => {
    // default
  },
  onFinish: () => {
    // default
  },
  onFinishFailed: () => {
    // default
  },
  triggerNext: () => {
    // default
  },
  triggerBack: () => {
    // default
  },
  checkRequiredRequired: () => undefined,
  signUpFinish: {} as FetchCreate<SignUpFinishSuccess, DefaultFetchError, SignUpFinishCreateParams>,
};

export const SignUpContext = createContext<SignUpContextProps>(defaultValue);

function SignUpProvider({ children }: PropsWithChildren) {
  const signUpFinish = useSignUpFinishCreate();
  const [listResponse, setListResponse] = useState<{ [key: string]: AnyObject }>({});
  const [list, setList] = useState({});
  const [pageNumber, setPageNumber] = useState(0);

  const setResponse = (name: string, state: AnyObject) => {
    setListResponse((prevState) => ({
      ...prevState,
      [name]: state,
    }));
  };

  const setForm = (name: string, form: FormInstance) => {
    setList((values) => ({ ...values, [name]: form }));
  };

  const triggerNext = (name?: string, values?: AnyObject) => {
    const AHPRA = name === listSteps[2].name ? values : listResponse[listSteps[2].name];

    if (pageNumber < Object.keys(list).length - 1
      && !(pageNumber === 2 && AHPRA && AHPRA?.provisionalOrGeneral === 'Provisional')) {
      if (name === 'Indemnity' && !requiredDocuments) {
        setPageNumber((id) => id + 2);
      } else {
        setPageNumber((id) => id + 1);
      }
    } else {
      const experience = listResponse ? listResponse[listSteps[3].name] : {};
      const indemnity = listResponse ? listResponse[listSteps[4].name] : {};
      const documents = listResponse ? listResponse[listSteps[5].name] : {};

      const documentsKeys = Object.keys(documents || {}).filter((key) => documents[key]);
      const documentList = documentsKeys.map((key) => ({
        documentType: key,
        file: documents[key],
      }));

      signUpFinish.fetch({
        isGeneral: AHPRA?.provisionalOrGeneral !== 'Provisional',
        ahpra: AHPRA?.AHPRANumber,
        ahpraLicenseDate: AHPRA?.date,

        experiences: experience?.experience,

        indemnityNumber: indemnity?.indemnityNumber,
        indemnityProvider: indemnity?.indemnityProvider,

        documents: documentList.length ? documentList : undefined,

        isVerified: false,
      } as SignUpFinishCreateParams);
    }
  };

  const isPossibleTriggerBack = () => pageNumber !== 2;

  const triggerBack = () => {
    if (isPossibleTriggerBack()) {
      if (pageNumber === Object.keys(list).length - 1 && !requiredDocuments) {
        setPageNumber((id) => id - 2);
      } else {
        setPageNumber((id) => id - 1);
      }
    }
  };

  const onFinish = (name: string, values: AnyObject) => {
    setResponse(name, values);
    triggerNext(name, values);
  };

  const onFinishFailed = (name: string, errorInfo: ValidateErrorEntity) => {
    console.log('Failed:', errorInfo);
  };

  const userCreate = useUserCreate();

  const [requiredDocuments, setRequiredDocuments] = useState<boolean>(false);
  const checkRequiredRequired = (payload: boolean) => {
    setRequiredDocuments(payload);
  };

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <SignUpContext.Provider value={{
      list,
      setForm,
      listResponse,
      setResponse,
      pageNumber,
      isPossibleTriggerBack,
      setPageNumber,
      onFinish,
      onFinishFailed,
      triggerNext,
      triggerBack,
      userCreate,
      signUpFinish,
      checkRequiredRequired,
    }}
    >
      {children}
    </SignUpContext.Provider>
  );
}

export default SignUpProvider;

export const useContextSignUp = (): SignUpContextProps => useContext(SignUpContext);
