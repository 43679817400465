import React from 'react';
import Wrapper from '../Wrapper';
import RosterCalendar from '../Calendar';
import Pagination from '../Common/Pagination';

function RosterByApplicant(): React.ReactNode {
  return (
    <Wrapper>
      <RosterCalendar />
      <Pagination />
    </Wrapper>
  );
}

export default RosterByApplicant;
