import { useState, useEffect } from 'react';
import {
  Col, Row, Typography, Space, List, Button, App,
} from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import Role from '../Role';
import CreateRole from '../CreateRole';
import { useLocationContext } from '../../Context';
import { useLocationsRolesDelete } from '../../../../../hooks/locationsRoles';
import { getMessageInError } from '../../../../../hooks/fetch';
import SaveModal from '../SaveModal';
import CopyRole from '../CopyRole';

import styles from './index.module.scss';

export default function Roles() {
  const deleteRole = useLocationsRolesDelete();
  const {
    fetch, location, loading, isChanging,
  } = useLocationContext();
  const [activeId, setActiveId] = useState(location?.locationRoles[0]?.id);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const { message } = App.useApp();

  useEffect(() => {
    if (deleteRole.data) {
      fetch();
      message.info('You have successfully deleted role');
    }
  }, [deleteRole.data]);

  useEffect(() => {
    if (deleteRole.error) {
      message.error(getMessageInError(deleteRole.error));
      deleteRole.clearError();
    }
  }, [deleteRole.error]);

  const setActive = (i: number, id: string) => {
    if (isChanging) {
      setSaveModalOpen(true);
    } else {
      setActiveId(id);
    }
  };

  const removeItem = (e: React.MouseEvent<HTMLElement>, id: string) => {
    e.stopPropagation();
    deleteRole.fetch(id);
    if (activeId === id) {
      setActiveId('');
    }
  };

  const actions = (id: string) => (
    <span>
      <CopyRole id={id} />
      <Button type="text" size="small" className={styles.btn} onClick={(e) => removeItem(e, id)} disabled={isChanging}>
        <DeleteOutlined className={styles.icon} />
      </Button>
    </span>
  );

  return (
    <Space className={styles.wrapper} direction="vertical">
      <SaveModal isModalOpen={saveModalOpen} setModalOpen={setSaveModalOpen} />
      <CreateRole isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      <Typography.Title style={{ margin: 0 }} level={5}>
        Roles and their Requirements
      </Typography.Title>
      <Row gutter={[16, 0]}>
        <Col span={8}>
          <List
            className={styles.roles}
            itemLayout="horizontal"
            dataSource={location?.locationRoles}
            loading={deleteRole.loading || loading}
            renderItem={(item, index) => (
              <List.Item
                className={clsx(styles.item, activeId === item.id ? styles.active : '')}
                actions={[actions(item.id)]}
                onClick={() => setActive(index, item.id)}
              >
                <List.Item.Meta title={item.name} />
              </List.Item>
            )}
          />
          <Button
            onClick={() => setIsModalOpen(true)}
            className={styles.createBtn}
            disabled={isChanging || !location!.isActive}
          >
            <PlusOutlined />
            Create new role
          </Button>
        </Col>

        <Col span={16}>{activeId && <Role roleId={activeId} />}</Col>
      </Row>
    </Space>
  );
}
