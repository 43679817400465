import React, { useEffect } from 'react';
import {
  Alert, Button, Form, FormInstance,
} from 'antd';
import { AnyObject } from '@triare/auth-redux';
import { connect } from 'react-redux';
import {
  OTP as otpAction,
  OTPAction,
  OTPClear as otpClearAction,
  OTPGenerate as otpGenerateAction,
  OTPGenerateAction,
  OTPGenerateClear as otpGenerateClearAction,
} from '@triare/auth-redux/dist/saga/auth/OTP';
import InputCodeWrapper from '../../Input/Code';
import { moduleName } from '../../../../store/auth';
import { RootState } from '../../../../store';

interface FormValues {
  [key: string]: number;
}

interface VerificationProps {
  email: string;
  nameBtnFinish: string;

  beforeBtnFinish?: React.ReactNode;
  afterBtnFinish?: React.ReactNode;
  className?: string;
  onInit?: (form: FormInstance) => void;

  OTP: (payload: OTPAction) => void;
  OTPClear: () => void;
  loading: boolean;
  error: null | AnyObject;

  OTPGenerate: (payload: OTPGenerateAction) => void;
  OTPGenerateClear: () => void;
  otpGenerateLoading: boolean;
  otpGenerateError: null | AnyObject;

  secretKey: string;
}

function OTPVerification({
  email,
  nameBtnFinish,
  OTP,
  OTPClear,
  loading,
  error,
  OTPGenerate,
  OTPGenerateClear,
  otpGenerateLoading,
  otpGenerateError,
  secretKey,
  className,
  onInit,
  beforeBtnFinish,
  afterBtnFinish,
}: VerificationProps): React.ReactNode | null {
  const [form] = Form.useForm();

  useEffect(() => {
    if (onInit) {
      onInit(form);
    }
  }, [form]);

  const onFinishHandler = (values: FormValues) => {
    OTP({
      secretCode: Object.values(values).join(''),
      secretKey,
    });
  };

  return (
    <Form<FormValues> form={form} className={className} autoComplete="off" layout="vertical" onFinish={onFinishHandler}>
      <h2>Please enter the six-digit code sent to your mobile phone</h2>
      <span>
        Didn&apos;t get the code? Please go back and check your information is correct. You can also
        {' '}
        <a
          href="/generate-code"
          onClick={(e) => {
            e.preventDefault();

            OTPGenerate({
              secretKey,
            });
          }}
        >
          send a new code to your phone
        </a>
        {' '}
        or
        {' '}
        <a
          href="/generate-code"
          onClick={(e) => {
            e.preventDefault();

            OTPGenerate({
              secretKey,
              useEmail: true,
            });
          }}
        >
          send it to
          {' '}
          {email}
        </a>
        {' '}
        instead.
      </span>

      <InputCodeWrapper form={form} style={{ marginTop: '64px' }} />

      {error ? (
        <Alert
          type="error"
          message={error?.data?.error || error?.message}
          closable
          onClose={OTPClear}
          style={{ marginBottom: 16 }}
        />
      ) : null}

      {otpGenerateError ? (
        <Alert
          type="error"
          message={otpGenerateError?.data?.error || otpGenerateError?.message}
          closable
          onClose={OTPGenerateClear}
          style={{ marginBottom: 16 }}
        />
      ) : null}

      <div>
        {beforeBtnFinish}
        <Form.Item shouldUpdate className="submit">
          {() => (
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              loading={loading || otpGenerateLoading}
              disabled={
                  !Object.values(form.getFieldsValue()).every((number) => String(number))
                  || form.getFieldsError().filter(({ errors }) => errors.length).length > 0
                }
            >
              {nameBtnFinish}
            </Button>
          )}
        </Form.Item>
        {afterBtnFinish}
      </div>
    </Form>
  );
}

OTPVerification.defaultProps = {
  beforeBtnFinish: undefined,
  afterBtnFinish: undefined,
  className: undefined,
  onInit: undefined,
};

export default connect(
  (state: RootState) => ({
    error: state[moduleName].OTP.error,
    loading: state[moduleName].OTP.loading,
    otpGenerateResponse: state[moduleName].OTP.generate.response,
    otpGenerateError: state[moduleName].OTP.generate.error,
    otpGenerateLoading: state[moduleName].OTP.generate.loading,
    secretKey: state[moduleName].secretKey,
  }),
  {
    OTP: otpAction,
    OTPClear: otpClearAction,
    OTPGenerate: otpGenerateAction,
    OTPGenerateClear: otpGenerateClearAction,
  },
)(OTPVerification);
