/* eslint-disable */
// @ts-nocheck

import React, { useEffect, useMemo, useState } from 'react';
import { Checkbox } from 'antd';
import { TablePaginationConfig } from 'antd/es/table';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import clsx from 'clsx';
import { ProTable, ProTableProps } from '@ant-design/pro-components';
import type { ProColumns } from '@ant-design/pro-components';
import { AnyObject } from '@triare/auth-redux';
import { getValidSearchParams } from '../../../utils';
import { pageDefault, pageSizeDefault, pageSizePossible } from '../../../contstant';
import { PagingDataResponse } from '../../../hooks/fetch';
import { ProCoreActionType } from '@ant-design/pro-utils/es/typing';
import { useSearchParams } from '../../../hooks/useSearchParams';

import styles from './index.module.scss';

export const defaultTableData: PagingDataResponse<any> = {
  data: [],
  meta: {
    page: 1,
    take: 10,
    itemCount: 0,
    pageCount: 0,
    hasNextPage: false,
    hasPreviousPage: false,
  },
};

interface TableParams<D> {
  pagination?: TablePaginationConfig;
  filters?: Record<string, FilterValue>;
  sorter?: SorterResult<D> | SorterResult<D>[];
}

export type CheckedList = (string | number)[];

interface Pagination {
  page: number;
  pageSize: number;
}

interface TableProps<Data, RequiredActionProps, ActionProps> extends ProTableProps<Data, AnyObject> {
  rowKey: string;
  selectAll?: boolean | ((entity: Data) => boolean);
  listCount?: number;
  checkedList?: CheckedList;
  setCheckedList?: (changeState: (prevState: CheckedList) => CheckedList) => void;
  actions?: (props: ActionProps) => React.ReactNode;
  actionsProps?: RequiredActionProps;
  loading: boolean;
  pagingData: PagingDataResponse<Data> | undefined;
  columns: ProColumns<Data>[];
  orderByColumn?: string;
  className?: string;
  pagination?: Pagination;
  setPagination?: (pagination: Pagination) => void;
}

function Table<Data, RequiredActionProps = any, ActionProps = any>({
  rowKey,
  selectAll,
  listCount,
  checkedList,
  setCheckedList,
  actions,
  actionsProps,
  loading,
  pagingData,
  columns,
  orderByColumn,
  pagination: localPagination,
  setPagination: setLocalPagination,
  ...props
}: TableProps<Data, RequiredActionProps, ActionProps>) {
  const [checkedAll, setCheckedAll] = useState(false);
  const customColumns: ProColumns<Data>[] = useMemo(() => {
    const list: ProColumns<Data>[] = [];

    if (selectAll && setCheckedList) {
      list.push({
        title: (
          <>
            <Checkbox
              checked={checkedAll}
              className="pro-table-checkbox"
              onChange={() => {
                if (!checkedAll) {
                  setCheckedList(
                    () =>
                      pagingData?.data
                        ?.map((record) => {
                          if (typeof selectAll === 'function') {
                            if (!selectAll(record)) {
                              return (record as AnyObject)[rowKey];
                            } else {
                              return null;
                            }
                          } else {
                            return (record as AnyObject)[rowKey];
                          }
                        })
                        .filter((value) => value !== null) || [],
                  );
                } else {
                  setCheckedList(() => []);
                }
              }}
            />
            <span className="pro-table-checkbox-text">Checkbox</span>
          </>
        ),
        dataIndex: 'select',
        key: 'select',
        width: 34,
        render: (value, record) => {
          const identifier = (record as AnyObject)[rowKey];

          return (
            <Checkbox
              disabled={typeof selectAll === 'function' ? selectAll(record) : false}
              checked={checkedList?.includes(identifier)}
              onChange={() =>
                setCheckedList
                  ? setCheckedList((prevState) => {
                      if (prevState.includes(identifier)) {
                        return prevState.filter((key) => identifier !== key);
                      }

                      return [...prevState, identifier];
                    })
                  : undefined
              }
            />
          );
        },
      });
    }

    list.push(...columns);

    if (actions) {
      list.push({
        title: 'Actions',
        key: 'action',
        width: 70,
        render: (dom: React.ReactNode, entity: Data, index: number, action: ProCoreActionType) => {
          const Actions = actions;

          return (
            <div className={styles.actions}>
              <Actions dom={dom} entity={entity} index={index} action={action} {...actionsProps} />
            </div>
          );
        },
      });
    }

    return list;
  }, [selectAll, checkedList, checkedAll]);

  useEffect(() => {
    if (checkedList?.length === listCount) {
      setCheckedAll(true);
    } else {
      setCheckedAll(false);
    }
  }, [checkedList, listCount]);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (
      searchParams.get('page') !== pageDefault.toString() ||
      searchParams.get('pageSize') !== pageSizeDefault.toString()
    ) {
      setSearchParams(
        getValidSearchParams('*', searchParams, {
          page: pageDefault.toString(),
          pageSize: pageSizeDefault.toString(),
        }),
      );
    }
  }, []);

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Data>,
  ) => {
    const sort = Array.isArray(sorter) ? sorter[0] : sorter;

    const params = {
      ...getValidSearchParams('*', searchParams),
      orderBy: sort && sort?.order === 'descend' ? 'DESC' : [],
      orderByColumn: sort && sort?.order ? (sort && sort?.field) || orderByColumn || [] : [],
    };

    if (localPagination && setLocalPagination) {
      setLocalPagination({
        page: pagination.current || pageDefault,
        pageSize: pagination.pageSize || pageSizeDefault,
      });
    } else {
      params.page = pagination.current?.toString() || '';
      params.pageSize = pagination.pageSize?.toString() || '';
    }

    setSearchParams(params);
  };

  return (
    <ProTable
      rowKey={rowKey}
      columns={customColumns}
      pagination={{
        size: 'default',
        current: pagingData?.meta.page || pageDefault,
        total: pagingData?.meta.itemCount || 0,
        page: localPagination?.page
          ? parseInt(localPagination.page)
          : parseInt(searchParams.get('page') || '0', 10) || pageDefault,
        pageSize: localPagination?.pageSize
          ? parseInt(localPagination.pageSize)
          : parseInt(searchParams.get('pageSize') || '0', 10) || pageSizeDefault,
        pageSizeOptions: pageSizePossible,
        showTotal: null,
        showSizeChanger: true,
        showQuickJumper: true,
      }}
      onChange={handleTableChange}
      dataSource={pagingData?.data}
      loading={loading}
      {...props}
      className={clsx(styles.table, props?.className)}
    />
  );
}

Table.defaultProps = {
  selectAll: undefined,
  listCount: undefined,
  checkedList: [],
  setCheckedList: undefined,
  actions: undefined,
  className: undefined,
  orderByColumn: undefined,
  pagination: undefined,
  setPagination: undefined,
};

export default Table;
