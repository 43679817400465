import React from 'react';
import Layout from '../../../components/Layout';
import ContentSetting from '../../../components/Pages/Setting/List';

export default function SettingList(): React.ReactNode {
  document.title = `${process.env.REACT_APP_TITLE_NAME}: Setting list`;

  return (
    <Layout transparent disableLeftMenu>
      <ContentSetting />
    </Layout>
  );
}
