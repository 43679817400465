import { Row } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useShiftActionContext } from '../../../Pages/Shift/Create/Context';
import ViewItem from '../ViewItem';
import { capitalize, getDateTimeISO } from '../../../../utils';
import { dateFormatShort } from '../../../../contstant';
import { Allowances } from '../../../../hooks/shift';

import styles from './index.module.scss';

export default function AllowancesSummary() {
  const { form } = useShiftActionContext();
  const summary = form?.getFieldsValue();
  const { t } = useTranslation();
  const allowancesAmount = summary?.allowances?.reduce(
    (acc: number, allowance: Allowances) => (allowance?.amount ? acc + Number(allowance?.amount) : acc + 0),
    0,
  ) || 0;

  const getDate = () => {
    if (!summary?.date || !summary?.time) {
      return '';
    }

    return ` (${dayjs(getDateTimeISO(summary.date[0], summary.time[0]))?.format(dateFormatShort)}-${dayjs(
      getDateTimeISO(summary.date[1], summary.time[1]),
    )?.format(dateFormatShort)})`;
  };

  return (
    <div className={styles.wrapper}>
      <Row gutter={[16, 0]}>
        <ViewItem
          text="Allowance Type*"
          tooltipe={t('allowanceType') || ''}
          value={summary?.allowances?.map((item: Allowances) => item?.type && capitalize(item.type))}
        />
        <ViewItem
          text="Total Amount"
          tooltipe={t('allowanceAmount') || ''}
          value={summary?.allowances?.map((item: Allowances) => item?.amount && `$${item.amount}`)}
        />
      </Row>
      <Row gutter={[16, 0]}>
        <ViewItem text={`Allowance Total${getDate()}: $${allowancesAmount}`} />
      </Row>
      <Row gutter={[16, 0]}>
        {summary?.lunchBreak || summary?.customLunchBreak
          ? (
            <ViewItem
              text="Breaks"
              value={`${summary.lunchBreak === 'custom' ? summary.customLunchBreak : summary?.lunchBreak} min`}
            />
          )
          : null}
      </Row>
    </div>
  );
}
