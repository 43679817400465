import { Form, Input, Col } from 'antd';

function Contacts() {
  return (
    <>
      <Col span={12}>
        <Form.Item name="phone" label="Phone" style={{ marginBottom: 0 }}>
          <Input disabled />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="email" label="Email" style={{ marginBottom: 0 }}>
          <Input disabled />
        </Form.Item>
      </Col>
    </>
  );
}

export default Contacts;
