import { useState, useRef } from 'react';
import {
  Select, Form, Divider, Input, Space, Button,
} from 'antd';
import type { InputRef } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { FormItemProps } from 'antd/es/form/FormItem';
import { SelectProps } from '../../Pages/Location/Components/Specialities';

interface CustomDropdownProps extends FormItemProps {
  items: SelectProps[] | null;
  setItems: (items: SelectProps[]) => void;
  itemName: string;
  label: string;
}

export default function CustomDropdown({
  items, setItems, itemName, label, ...props
}: CustomDropdownProps) {
  const [name, setName] = useState('');
  const inputRef = useRef<InputRef>(null);

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const addItem = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    e.preventDefault();
    setItems([
      ...(items || []),
      {
        label: name,
        value: name,
      },
    ]);
    setName('');
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  return (
    <Form.Item
      name={itemName}
      label={label}
      rules={[{ required: true, message: 'Required field!' }]}
      {...props}
    >
      <Select
        placeholder="Select"
        style={{ width: '100%' }}
        options={items || []}
        showSearch
        // eslint-disable-next-line max-len
        filterOption={(input, option) => (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())}
        // eslint-disable-next-line react/no-unstable-nested-components
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider style={{ margin: '8px 0' }} />
            <Space style={{ padding: '0 8px 4px' }}>
              <Input placeholder="Please enter item" ref={inputRef} value={name} onChange={onNameChange} />
              <Button type="text" icon={<PlusOutlined />} disabled={!name} onClick={addItem}>
                Add item
              </Button>
            </Space>
          </>
        )}
      />
    </Form.Item>
  );
}
