import { Form, Input } from 'antd';
import { validationGeneralInfo, createRulesForAntd } from '../../../../../utils/validations';
import PhoneNumberInput from '../../../../Common/PhoneNumberInput';
import InputAddress from '../../../../Common/Input/Address';

const validationRules = createRulesForAntd(validationGeneralInfo);

export default function Fields() {
  return (
    <>
      <Form.Item label="Name" name="name" rules={[validationRules]}>
        <Input placeholder="Name" />
      </Form.Item>

      <InputAddress name="address" label="Address" />

      <Form.Item label="General Phone Number" name="phone" rules={[validationRules]}>
        <PhoneNumberInput placeholder="General Phone Number" />
      </Form.Item>

      <Form.Item label="General Contact Email" name="email" rules={[validationRules]}>
        <Input placeholder="General Contact Email" />
      </Form.Item>
    </>
  );
}
