import { useState, useEffect } from 'react';
import {
  Typography, Input, Button, Form, App,
} from 'antd';
import type { UploadFile } from 'antd';
import { useLocationContext } from '../../Context';
import { useOrientationId, useOrientationUpdate, Orientation } from '../../../../../hooks/orientation';
import { getMessageInError } from '../../../../../hooks/fetch';
import Loading from '../../../../Common/Loading';
import Uploader from '../Uploader';

import styles from './index.module.scss';

type MessageItemProps = {
  activeId: string;
  setActiveId: (activeId: string) => void;
};

export default function MessageItem({ activeId }: MessageItemProps) {
  const updateOrientation = useOrientationUpdate();
  const getOrientation = useOrientationId();
  const { message: antdMessage } = App.useApp();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [form] = Form.useForm();
  const {
    setEdit, isEdit, isChanging, setOnSave, setChanging, fetch, setLoading, isValid, setValid, loading,
  } = useLocationContext();

  const fetchOrientation = () => {
    getOrientation.fetch(undefined, activeId);
  };

  useEffect(() => () => setValid(() => true), []);

  useEffect(() => {
    setChanging(() => false);
    setOnSave(() => onSave);
    fetchOrientation();
  }, [activeId]);

  const onSave = () => {
    setLoading(true);
    form
      .validateFields()
      .then(() => {
        updateOrientation.fetch(form.getFieldsValue(), activeId);
      })
      .catch(() => {
        // error
      });
  };

  useEffect(() => {
    if (updateOrientation.data) {
      setEdit(() => false);
      fetch();
      fetchOrientation();
      setLoading(false);
      antdMessage.info('Orientation info has been successfully updated');
    }
  }, [updateOrientation.data]);

  useEffect(() => {
    if (getOrientation.data) {
      const orientation = getOrientation.data;

      setFileList(
        orientation!.files.map((file) => ({
          uid: file.id,
          name: file.originalName,
          fileName: file.originalName,
        })),
      );

      form.setFieldsValue({
        message: orientation?.message,
      });
    }
    setChanging(() => false);
  }, [getOrientation.data, isEdit]);

  useEffect(() => {
    if (getOrientation.error) {
      antdMessage.error(getMessageInError(getOrientation.error));
      getOrientation.clearError();
    }
    if (updateOrientation.error) {
      antdMessage.error(getMessageInError(updateOrientation.error));
      updateOrientation.clearError();
    }

    setLoading(false);
  }, [getOrientation.error, updateOrientation.error]);

  const onCancel = () => {
    setEdit(() => false);
  };

  const onFieldsChange = (_: any, allFields: any[]) => {
    setChanging(() => allFields.some(
      // eslint-disable-next-line max-len
      (field) => field.value !== (getOrientation.data ? getOrientation.data[field.name[0] as keyof Orientation] : undefined),
    ));

    setValid(() => !!allFields?.find((field) => field.name[0] === 'message').value.trim().length);
  };

  return getOrientation.loading || !getOrientation.data ? (
    <div className={styles.loading}>
      <Loading />
    </div>
  ) : (
    <div className={styles.wrapper}>
      <Form disabled={!isEdit} form={form} onFieldsChange={onFieldsChange}>
        <Typography.Title level={5} className={styles.title}>
          Message to be sent*
        </Typography.Title>
        <Form.Item name="message" rules={[{ required: true, message: 'Required field!' }]}>
          <Input.TextArea rows={5} />
        </Form.Item>

        <Typography.Title level={5}>Files to be sent</Typography.Title>

        <Uploader fileList={fileList} setFileList={setFileList} form={form} />

        <div className={styles.btnWrapper}>
          <Button onClick={onCancel} disabled={!isChanging}>
            Cancel
          </Button>
          <Button
            type="primary"
            loading={updateOrientation.loading || loading}
            disabled={!isChanging || !isEdit || !isValid}
            onClick={() => (onSave ? onSave() : null)}
          >
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
}
