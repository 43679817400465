import React from 'react';
import Layout from '../../../components/Layout';
import Filter from '../../../components/Pages/Team/Filters';
import ContentTeamList from '../../../components/Pages/Team/List';

export default function TeamsList(): React.ReactNode {
  document.title = `${process.env.REACT_APP_TITLE_NAME}: Teams list`;

  return (
    <Layout minWidth leftMenu={<Filter />}>
      <ContentTeamList />
    </Layout>
  );
}
