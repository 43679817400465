import {
  Button, Form, Input, Radio, Switch, Typography,
} from 'antd';
import { useParams } from 'react-router-dom';
import { CheckCircleOutlined, InfoCircleTwoTone, StopOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import { createRulesForAntd, validationGeneralInfo } from '../../../../../utils/validations';
import { useClientUpdate, useClientValidate } from '../../../../../hooks/client';
import { useClientContext } from '../../Context';
import { useSimpleModal } from '../../../../Common/Modal/Simple';
import { translateStatusAction } from '../../../../../utils';
import { COLOR_PRIMARY } from '../../../../../AntdConfigProvider';
import InputAddress from '../../../../Common/Input/Address';
import { useMessageError } from '../../../../../hooks/common';
import PhoneNumberInput from '../../../../Common/PhoneNumberInput';

const validationRules = createRulesForAntd(validationGeneralInfo);

interface FieldsProps {
  getResources?: () => void;
}

function Fields({ getResources }: FieldsProps) {
  const { resource } = useClientContext();
  const { open, contextHolder } = useSimpleModal();
  const { clientId } = useParams();
  const clientUpdate = useClientUpdate(clientId);
  const isActive = resource?.data?.isActive;
  const actionName = translateStatusAction(!isActive);
  const validateClient = useClientValidate();

  useEffect(() => {
    if (clientUpdate.data && getResources) {
      getResources();
    }
  }, [clientUpdate.data]);

  useEffect(() => {
    if (validateClient.data) {
      open({
        icon: isActive ? (
          <StopOutlined style={{ color: '#FF4D4F' }} />
        ) : (
          <CheckCircleOutlined style={{ color: COLOR_PRIMARY }} />
        ),
        title: `${actionName} client`,
        content: (
          <div>
            {!validateClient.data?.success && isActive ? (
              <Typography.Paragraph strong>
                This client has active locations. Please deactivate active locations and try again.
              </Typography.Paragraph>
            ) : (
              <Typography.Paragraph>
                {`Are you sure you want to ${actionName?.toLowerCase()} this client?`}
              </Typography.Paragraph>
            )}
          </div>
        ),
        cancelText: 'Cancel',
        okText: actionName,
        okButtonProps: { disabled: !validateClient.data?.success && isActive },
        onOk: () => clientUpdate.fetch({
          isActive: !isActive,
        }),
      });
    }
  }, [validateClient.data]);

  useMessageError([validateClient, clientUpdate]);

  return (
    <>
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, ...validationRules, message: 'Please input Name!' }]}
      >
        <Input placeholder="Name" />
      </Form.Item>

      <InputAddress name="address" label="Address" required />

      <Form.Item
        label="General Contact Phone"
        name="phone"
        rules={[{ required: true, ...validationRules, message: 'Phone must be a valid phone number' }]}
      >
        <PhoneNumberInput placeholder="General Phone Number" />
      </Form.Item>

      <Form.Item
        label="General Contact Email"
        name="email"
        rules={[{ required: true, ...validationRules, message: 'Please input Email!' }]}
      >
        <Input placeholder="General Contact Email" />
      </Form.Item>

      <Form.Item
        label="Do shifts have to be approved by Lokem administrators?"
        name="autoApproved"
        tooltip={{
          title: 'If yes, all new shifts will be created with a status of \'Under Review\' '
            + 'and will not be visible to applicants until approved.',
          icon: <InfoCircleTwoTone style={{ cursor: 'help' }} twoToneColor={COLOR_PRIMARY} />,
        }}
      >
        <Radio.Group>
          <Radio value>Yes</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        label="Two-factor authentication"
        name="twoFAtoggle"
        valuePropName="checked"
        tooltip={{
          title: 'Enabling 2FA will require Client Admins and Managers to submit a code each time they log in.',
          icon: <InfoCircleTwoTone style={{ cursor: 'help' }} twoToneColor={COLOR_PRIMARY} />,
        }}
      >
        <Switch />
      </Form.Item>

      {clientId ? (
        <Form.Item label="Status">
          <Button
            danger={isActive}
            type={!isActive ? 'primary' : undefined}
            loading={validateClient.loading}
            onClick={(e) => {
              e.preventDefault();
              validateClient.fetch(undefined, clientId);
            }}
          >
            {actionName}
          </Button>
        </Form.Item>
      ) : null}

      {contextHolder}
    </>
  );
}

Fields.defaultProps = {
  getResources: undefined,
};

export default Fields;
