export enum Action {
  COPY = 'copy',
  MOVE = 'move',
  ASSIGN = 'assing',
}

export enum ViewType {
  DAY = 'day',
  WEEK = 'week',
  TWO_WEEKS = '2 weeks',
  MONTH = 'month',
}
