export enum UserStatus {
  ACTIVE = 'active',
  DEACTIVATED = 'deactivated',
  PENDING = 'pending',
  ARCHIVED = 'archived',
  REJECTED = 'rejected',
}

export enum UserRoles {
  GUEST = 'guest',
  APPLICANT = 'applicant',
  MANAGER = 'manager',
  CLIENT_ADMIN = 'clientAdmin',
  LOKEM_ADMIN = 'lokemAdmin',
  ROOT = 'root',
}

export const ROLE_LIST = [
  UserRoles.GUEST,
  UserRoles.APPLICANT,
  UserRoles.MANAGER,
  UserRoles.CLIENT_ADMIN,
  UserRoles.LOKEM_ADMIN,
  UserRoles.ROOT,
];

export function isRoleEnough(
  currentRole: UserRoles | string | undefined,
  requiredRole: UserRoles | string,
): boolean {
  if (currentRole === requiredRole) {
    return true;
  }

  return (
    ROLE_LIST.indexOf(currentRole as UserRoles)
    >= ROLE_LIST.indexOf(requiredRole as UserRoles)
  );
}
