import { useState, useEffect } from 'react';
import {
  App, Form, Space, Button, Typography,
} from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import { CheckCircleOutlined, StopOutlined } from '@ant-design/icons';
import AdminFields from '../../Common/AdminFields';
import {
  User, useUserDelete, useUserId, useUserUpdate,
} from '../../../hooks/user';
import Loading from '../../Common/Loading';
import { UserRoles, isRoleEnough } from '../../../enums/user';
import TopPanel from '../../Common/TopPanel';
import { useAuthState } from '../../../store/auth';
import { useSimpleModal } from '../../Common/Modal/Simple';
import { useMessageError } from '../../../hooks/common';
import { COLOR_PRIMARY } from '../../../AntdConfigProvider';

import styles from './index.module.scss';

export default function Admin() {
  const userDelete = useUserDelete();
  const { user } = useAuthState();
  const { message } = App.useApp();
  const userById = useUserId();
  const userUpdate = useUserUpdate();
  const { adminId } = useParams();
  const [isChanging, setChanging] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [form] = Form.useForm();
  const isRoot = isRoleEnough(user?.role, UserRoles.ROOT);
  const { open, contextHolder } = useSimpleModal();
  const navigate = useNavigate();
  const isActive = userById?.data?.status === 'active';
  const actionName = isActive ? 'Deactivate' : 'Activate';

  const onSave = () => {
    form
      .validateFields()
      .then(() => {
        userUpdate.fetch(form.getFieldsValue(), adminId);
      })
      .catch(() => {
        setChanging(false);
      });
  };

  const fetchUser = () => {
    userById.fetch(undefined, adminId);
  };

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    if (userUpdate.data) {
      fetchUser();
      setChanging(false);
      setEdit(false);
      message.info('You have successfully edit admin!');
    }
  }, [userUpdate.data]);

  useEffect(() => {
    if (userById.data) {
      form.setFieldsValue(userById?.data);
      if (userById?.data?.createdAt) {
        form.setFieldValue('createdAt', new Date(userById.data.createdAt as string).toLocaleDateString('en-GB'));
      }
    }
  }, [userById.data, isEdit]);

  useMessageError([userById, userUpdate, userDelete]);

  useEffect(() => {
    if (userDelete.data) {
      message.success('Admin was successfully removed!');
      navigate('/admins');
    }
  }, [userDelete.data]);

  const onFieldsChange = (_: any, allFields: any[]) => {
    if (userById.data) {
      setChanging(allFields.some((field) => field.value !== userById.data![field.name[0] as keyof User]));
    }

    if (!allFields.every((field) => field.errors.length === 0)) {
      setChanging(false);
    }
  };

  return userById.loading || !userById.data ? (
    <div className={styles.loading}>
      <Loading />
    </div>
  ) : (
    <div className={styles.wrapper}>
      <TopPanel
        padding
        title={`${userById?.data?.firstName} ${userById?.data?.lastName}`}
        arrowBack="/admins"
        rightContent={(
          <>
            {isRoot && !isEdit && <Button onClick={() => setEdit(() => true)}>Edit</Button>}
            {isEdit && (
              <div>
                <Button
                  onClick={() => setEdit(() => false)}
                >
                  Cancel
                </Button>
                {' '}
                <Button
                  type="primary"
                  onClick={() => (onSave ? onSave() : null)}
                  loading={userUpdate.loading}
                  disabled={!isChanging}
                >
                  Save
                </Button>
              </div>
            )}
          </>
        )}
      />

      <div className={styles.inner}>
        <Form
          form={form}
          requiredMark={false}
          onFieldsChange={onFieldsChange}
          layout="vertical"
          className={styles.form}
        >
          <AdminFields showDate={!isEdit} disabled={!isEdit || !isRoot} />
          {isRoot && (
            <Space direction="vertical">
              <Space direction="vertical">
                <Typography.Text strong>Status</Typography.Text>
                <Button
                  danger={isActive}
                  type={!isActive ? 'primary' : undefined}
                  onClick={(e) => {
                    e.preventDefault();
                    open({
                      icon: isActive ? (
                        <StopOutlined style={{ color: '#FF4D4F' }} />
                      ) : (
                        <CheckCircleOutlined style={{ color: COLOR_PRIMARY }} />
                      ),
                      title: `${actionName} client`,
                      content: `Are you sure you want to ${actionName.toLowerCase()} this admin?`,
                      cancelText: 'Cancel',
                      okText: actionName,
                      onOk: () => userUpdate.fetch(
                        {
                          status: isActive ? 'deactivated' : 'active',
                        },
                        adminId,
                      ),
                    });
                  }}
                >
                  {actionName}
                </Button>
              </Space>
              {/* <Space direction="vertical" className={styles.statusBtn}> */}
              {/*  <Typography.Text strong>Delete admin</Typography.Text> */}
              {/*  <Button */}
              {/*    loading={userDelete.loading} */}
              {/*    danger */}
              {/*    onClick={() => open({ */}
              {/*      icon: <DeleteOutlined style={{ color: '#FF4D4F' }} />, */}
              {/*      title: 'Delete admin', */}
              {/*      content: 'Are you sure you want to Delete this admin?', */}
              {/*      cancelText: 'Cancel', */}
              {/*      okText: 'Delete', */}
              {/*      onOk: () => userDelete.fetch(userById?.data?.id), */}
              {/*    })} */}
              {/*    ghost */}
              {/*  > */}
              {/*    Delete */}
              {/*  </Button> */}
              {/* </Space> */}
            </Space>
          )}
        </Form>
      </div>
      {contextHolder}
    </div>
  );
}
