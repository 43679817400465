import React, { useEffect, useMemo } from 'react';
import { Badge, Button } from 'antd';
import { NavLink } from 'react-router-dom';
import type { ProColumns } from '@ant-design/pro-components';
import dayjs from 'dayjs';
import {
  Client,
  clientsGetParamList,
  ClientsGetParams,
  ClientUpdateParams,
  useClientsGet,
  useClientUpdate,
} from '../../../../hooks/client';
import Table, { defaultTableData } from '../../../Common/Table';
import { getBudgeColor } from '../../../Common/Badge';
import { PagingDataResponse } from '../../../../hooks/fetch';
import { useAuthState } from '../../../../store/auth';
import { isRoleEnough, UserRoles } from '../../../../enums/user';
import { useSimpleModal } from '../../../Common/Modal/Simple';
import { translateStatus } from '../../../../utils';
import { useSearchParams } from '../../../../hooks/useSearchParams';
import Action, { Statuses, RequiredActionProps, ActionProps } from '../Common/Table/Action';
import { dateFormat } from '../../../../contstant';
import TableWrapper from '../../../Common/Table/Wrapper';
import { useMessageError } from '../../../../hooks/common';

import styles from './index.module.scss';

interface ClientRow extends Client {
  url: string;
}

const columns: ProColumns<ClientRow>[] = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (text, client) => <NavLink to={client.url}>{client?.name}</NavLink>,
  },
  {
    title: 'Date created',
    dataIndex: 'createdAt',
    key: 'status',
    render: (node, entity) => dayjs(entity.createdAt).format(dateFormat),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (node, entity) => (
      <Badge className={styles.status} color={getBudgeColor(entity.isActive)} text={translateStatus(entity.isActive)} />
    ),
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
    render: (node, entity) => <NavLink to={`tel:${entity.phone}`}>{entity.phone}</NavLink>,
  },
  {
    title: 'Email',
    key: 'email',
    dataIndex: 'email',
    render: (node, entity) => <NavLink to={`mailto:${entity.email}`}>{entity.email}</NavLink>,
  },
];

const statuses: Statuses = {
  active: true,
  inactive: false,
};

interface ClientListProps {
  setReload?: (time: number) => void;
}

function ClientList({ setReload }: ClientListProps): React.ReactNode {
  const { open, contextHolder } = useSimpleModal();
  const clients = useClientsGet<PagingDataResponse<ClientRow>>();
  const clientUpdate = useClientUpdate();
  const { user } = useAuthState();
  const [searchParams] = useSearchParams();
  const urlParamsString = searchParams.toString();
  const urlParams = urlParamsString.length ? `?${urlParamsString}` : '';
  const isEditable = isRoleEnough(user?.role, UserRoles.LOKEM_ADMIN);

  const getClients = () => {
    const props: ClientsGetParams = {};

    clientsGetParamList.forEach((key) => {
      const value = searchParams.getAll(key);

      if (value.length || !(value.length === 1 && value[0] === '')) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        props[key] = value.length === 1 ? value[0] : value;
      }
    });

    const status = searchParams.getAll('status');

    if (status.length === 1) {
      props.isActive = status[0].toLowerCase() === 'active';
    }

    props.partialName = searchParams.get('search') || undefined;

    clients.fetch(props);
  };

  const clientsData = useMemo<PagingDataResponse<ClientRow>>(() => {
    if (!clients.loading && !clients.error && clients?.data?.data.length) {
      return {
        ...clients.data,
        data: clients.data.data.map((items) => ({
          ...items,
          url: items.id + urlParams,
        })),
      } as PagingDataResponse<ClientRow>;
    }

    return defaultTableData;
  }, [clients, urlParams]);

  useEffect(() => {
    if (clientUpdate.data && !clientUpdate.error && clients) {
      getClients();

      if (setReload) {
        setReload(Date.now());
      }
    }
  }, [clientUpdate.response, setReload]);

  useEffect(getClients, [searchParams]);

  useMessageError([clientUpdate]);

  const toolBarRender = () => [
    <NavLink key="create" to={`/clients/create${urlParams}`}>
      <Button type="primary">Create client</Button>
    </NavLink>,
  ];

  return (
    <TableWrapper>
      <Table<ClientRow, RequiredActionProps<ClientRow, ClientUpdateParams>, ActionProps<ClientRow, ClientUpdateParams>>
        rowKey="id"
        columnsState={{ persistenceKey: 'pro-table-clients', persistenceType: 'localStorage' }}
        selectAll
        search={false}
        headerTitle="Clients"
        toolBarRender={toolBarRender}
        options={{
          reload: false,
          density: false,
        }}
        actions={Action}
        actionsProps={{
          isEditable,
          open,
          hookAction: clientUpdate,
          statusName: 'isActive',
          statuses,
          title: (actionName) => `${actionName} client`,
          // eslint-disable-next-line max-len
          content: (actionName, entity) => `Are you sure you want to ${actionName?.toLowerCase()} this client (${entity.name})?`,
          isClient: true,
        }}
        columns={columns}
        loading={clients.loading}
        pagingData={clientsData}
      />

      {contextHolder}
    </TableWrapper>
  );
}

ClientList.defaultProps = {
  setReload: undefined,
};

export default ClientList;
