import React, { useEffect, useRef } from 'react';
import { ExpandOutlined } from '@ant-design/icons';
import dayjs, { Dayjs } from 'dayjs';
import { useSearchParams } from 'react-router-dom';
import clsx from 'clsx';
import { GridRow, useRosterContext } from '../../Context';
import {
  ShiftCopyParams, ShiftUpdateParams, useShiftCopy, useShiftUpdate,
} from '../../../../../hooks/shift';
import { useMessageError } from '../../../../../hooks/common';
import { Action } from '../../../../../enums/roster';
import styles from './index.module.scss';
import ShiftStatus from '../../../../../enums/shift';
import { CopyIcon, MoveIcon } from '../../../../Common/Icon';

interface ShiftVariantProps {
  row: GridRow;
  date: Dayjs;
  start?: string;
  end?: string;
}

const changeShiftDate = (dateVariant: Dayjs, shiftDate: Dayjs, difference?: number) => {
  const date = dateVariant.clone().startOf('day').set('hour', shiftDate.hour()).set('minute', shiftDate.minute())
    .utc();

  if (difference) {
    return date.add(difference, 'minutes').toISOString();
  }

  return date.toISOString();
};

const setTime = (shiftDate: Dayjs, hour: number, min: number) => {
  const roundedMinutes = Math.round(min / 5) * 5;

  return shiftDate.clone().set('hour', hour).set('minute', roundedMinutes);
};

function ShiftVariant({
  row, date, start, end,
}: ShiftVariantProps): React.ReactNode {
  const {
    selectedShift, setSelectedShift, getShifts, isApplicantPage, isDayView, grid, setSiderData,
  } = useRosterContext();

  const sideWidth = 450;
  const tableWidth = 1435;
  const updateShift = useShiftUpdate();
  const copyShift = useShiftCopy();
  const refVariant = useRef<HTMLDivElement | null>(null);
  const [searchParams] = useSearchParams();
  const shift = selectedShift;
  const setVariantPosition = () => {
    const startDayValueOf = dayjs(searchParams.get('period')).clone().startOf('date').valueOf();
    const DAY_SIZE = 86400000;
    const marginLeft = Math.max(0, ((dayjs(start).valueOf() - startDayValueOf) / DAY_SIZE) * 100);
    const width = `${((dayjs(end).valueOf() - startDayValueOf) / DAY_SIZE) * 100 - marginLeft}%`;

    if (refVariant?.current?.style) {
      refVariant.current.style.left = `${marginLeft}%`;
      refVariant.current.style.width = width;
    }
  };

  useEffect(() => {
    setVariantPosition();
  }, []);

  useEffect(() => {
    if (updateShift.data || copyShift.data) {
      getShifts();
      setSelectedShift(null);
      setSiderData(null);
    }
  }, [updateShift.data, copyShift.data]);

  useMessageError([updateShift, copyShift]);

  return (
    <div
      ref={refVariant}
      className={clsx(styles.variant, { [styles.dayView]: isDayView }, { [styles.isAppplicant]: isApplicantPage })}
      onClick={(e) => {
        e.preventDefault();
        if (!shift) {
          return;
        }

        if (isDayView) {
          const hoursFromSide = (e.clientX - sideWidth) / (tableWidth / 24);
          const hour = Math.floor(hoursFromSide);

          const changeDate = dayjs(changeShiftDate(date, shift.start));
          const startTime = setTime(changeDate, hour, Math.round((hoursFromSide - hour) * 60));
          const diff = dayjs(shift.datetimeEnd).diff(shift.datetimeStart) / 1000 / 3600;
          const endTime = startTime.add(diff, 'hour');

          const params: ShiftUpdateParams | ShiftCopyParams = {
            datetimeStart: startTime.toISOString(),
            datetimeEnd: endTime.toISOString(),
          };

          if (isApplicantPage) {
            params.applicant = row.id;
            // eslint-disable-next-line max-len
            (params as ShiftUpdateParams).status = shift.status === ShiftStatus.DRAFT ? ShiftStatus.DRAFT : shift.status;
          } else if (shift.applicant) {
            params.applicant = shift.applicant?.id;
          }

          const shifts = grid?.data.find((item) => item.id === row.id)?.days[0]?.shifts;

          shifts?.forEach((item) => {
            if (item.id !== shift.id && !endTime.isBefore(item.start) && !endTime.isAfter(item.end)) {
              const diffOverlap = dayjs(shift.datetimeEnd).diff(shift.datetimeStart);
              const startOverlap = dayjs(dayjs(item.datetimeStart).valueOf() - diffOverlap);

              params.datetimeStart = startOverlap.toISOString();
              params.datetimeEnd = startOverlap.add(diff, 'hour').toISOString();
            }
          });

          if (shift.action === Action.COPY) {
            copyShift.fetch(params as ShiftCopyParams, shift?.id);

            return;
          }

          updateShift.fetch(params as ShiftUpdateParams, shift?.id);

          return;
        }

        const daysDifference = shift.end.diff(shift.start, 'minutes');

        const params: ShiftUpdateParams = {
          datetimeStart: changeShiftDate(date, shift.start),
          datetimeEnd: changeShiftDate(date, shift.start, daysDifference),
        };

        if (isApplicantPage) {
          params.applicant = row.id;
          params.status = shift.status === ShiftStatus.DRAFT ? ShiftStatus.DRAFT : shift.status;
        } else if (shift.applicant) {
          params.applicant = shift.applicant?.id;
        }

        if (shift.action === Action.COPY) {
          copyShift.fetch(params as ShiftCopyParams, shift?.id);

          return;
        }

        updateShift.fetch(params, shift?.id);
      }}
      role="none"
    >
      {shift?.action === Action.COPY && (
        <>
          <CopyIcon />
          Copy here
        </>
      )}
      {shift?.action === Action.MOVE && (
        <>
          <MoveIcon />
          Move here
        </>
      )}
      {shift?.action === Action.ASSIGN && <ExpandOutlined spin={updateShift.loading || copyShift.loading} />}
    </div>
  );
}

ShiftVariant.defaultProps = {
  start: '',
  end: '',
};

export default ShiftVariant;
