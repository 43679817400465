import React, { useState } from 'react';
import clsx from 'clsx';
import { Button } from 'antd';
import { AnyObject } from '@triare/auth-redux';

import styles from './index.module.scss';

export interface RadioItem extends AnyObject {
  key: string
  icon?: React.ReactNode
  content: React.ReactNode
  className?: string
  onActive?: (beforeState: string | undefined) => void
}

type RadioButtonProps = {
  className?: string
  items: RadioItem[]
  defaultActive?: string
  onChange?: (key: string, beforeState: string | undefined) => void | boolean
};

function RadioButton({
  className, items, defaultActive, onChange,
}: RadioButtonProps): React.ReactNode | null {
  const [state, setState] = useState<string | undefined>(defaultActive);

  return (
    <div className={clsx(styles.radio, className)}>
      {items.map(({
        key, icon, content, className: radioClassName, onActive, ...props
      }) => (
        <Button
          key={key}
          icon={icon}
          type="text"
          className={clsx(
            { [styles.active]: state === key || (state === undefined && items[0].key === key) },
            radioClassName,
          )}
          onClick={(e) => {
            e.preventDefault();

            if (onChange) {
              if (onChange(key, state) === false) {
                return;
              }
            }

            if (onActive) {
              onActive(state);
            }

            setState(key);
          }}
          {...props}
        >
          {content}
        </Button>
      ))}
    </div>
  );
}

RadioButton.defaultProps = {
  className: undefined,
  defaultActive: undefined,
  onChange: undefined,
};

export default RadioButton;
