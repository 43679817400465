import { pagingParamList } from '../contstant';
import {
  DefaultFetchError,
  FetchCreate,
  FetchGet,
  useFetchCreate,
  useFetchGet,
  useFetchDelete,
  FetchSuccess,
  FetchUpdate,
  useFetchUpdate,
  PagingDataResponse,
  PagingParams,
  FetchGetId,
  useFetchGetId,
} from './fetch';

export interface Member {
  id: string
}

export interface Team {
  id: string;
  name: string;
  isActive: boolean;
  membersCount?: number
  members?: Member[]
}

export interface TeamsGetParams {
  isActive?: boolean
  isMine?: boolean
}

export const teamsGetParamList = [
  'orderBy',
  'orderByColumn',
  'partialName',
  'isActive',
  ...pagingParamList,
];

export function useTeamsGet<D = Team[], DD = D>(
  decorateData?: (data: D) => DD,
): FetchGet<D, TeamsGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, TeamsGetParams, DD>('teams', {
    decorateData,
    autoStart: false,
    startStateLoading: false,
  });
}

export interface TeamsPaginGetParams extends PagingParams {
  orderByColumn?: 'id'
}

export function useTeamsPaginGet<D = PagingDataResponse<Team>,
  DD = D>(decorateData?: (data: D) => DD): FetchGet<D, TeamsPaginGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, TeamsPaginGetParams, DD>(
    'teams/pagginate',
    {
      decorateData,
      autoStart: false,
      startStateLoading: false,
    },
  );
}

export interface TeamCreateParams {
  isActive: boolean;
  name: string;
  members: string[]
}

export const useTeamCreate = ():
  FetchCreate<FetchSuccess, DefaultFetchError, TeamCreateParams> => useFetchCreate('teams');

export interface TeamsUpdateParams {
  name?: string;
  isActive?: boolean;
  members?: string[]
}

export const useTeamUpdate = (): FetchUpdate<FetchUpdate, DefaultFetchError, TeamsUpdateParams> => (
  useFetchUpdate('teams')
);

export const useTeamId = (): FetchGetId<Team> => useFetchGetId('teams', '', {
  autoStart: false,
  startStateLoading: false,
});

export const useTeamDelete = () => useFetchDelete<FetchSuccess, DefaultFetchError, string>('teams');
