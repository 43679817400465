import React from 'react';
import Layout from '../../../components/Layout';
import ContentClientList from '../../../components/Pages/Client/Create';

export default function ClientCreate(): React.ReactNode {
  document.title = `${process.env.REACT_APP_TITLE_NAME}: Client: create`;

  return (
    <Layout disableLeftMenu>
      <ContentClientList />
    </Layout>
  );
}
