import ShiftActionProvider from './Context';
import ShiftForm from '../../../Common/Shift/ShiftForm';

export default function CreateShift() {
  return (
    <ShiftActionProvider mode="create">
      <ShiftForm />
    </ShiftActionProvider>
  );
}
