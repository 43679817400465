import { useNavigate } from 'react-router-dom';
import { Domain } from '../types/location';
import {
  DefaultFetchError,
  FetchCreate,
  FetchGet,
  FetchGetId,
  useFetchCreate,
  useFetchGet,
  useFetchGetId,
  useFetchDelete,
  FetchSuccess,
  PagingParams,
  FetchUpdate,
  useFetchUpdate,
} from './fetch';

export interface DomainCreateParams {
  title: string;
}

export interface DomainGetParams extends PagingParams {
  domains?: string[];
  status?: string;
  client?: string;
}

export const useDomainsCreate = (): FetchCreate<
  FetchSuccess,
  DefaultFetchError,
  DomainCreateParams
> => useFetchCreate('domains');

export const useDomainDelete = () => useFetchDelete<FetchSuccess, DefaultFetchError, string>('domains');

export function useDomainsGet<D = Domain[], DD = D>(
  decorateData?: (data: D) => DD,
): FetchGet<D, DomainGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, DomainGetParams, DD>('domains', {
    name: 'Domains',
    decorateData,
    autoStart: false,
    startStateLoading: false,
  });
}

interface SelectDomain extends Domain {
  locationsCount: number;
}

export function useDomainsSelectLocationsGet<D = SelectDomain[], DD = D>(
  decorateData?: (data: D) => DD,
): FetchGet<D, DomainGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, DomainGetParams, DD>(
    'domains/select/locations',
    {
      name: 'Domains',
      decorateData,
      autoStart: false,
      startStateLoading: false,
    },
  );
}

export function useDomainsAllGet<D = Domain[], DD = D>(
  decorateData?: (data: D) => DD,
): FetchGet<D, DomainGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, DomainGetParams, DD>('domains/all', {
    name: 'Domains',
    decorateData,
    autoStart: false,
    startStateLoading: false,
  });
}

export function useDomainAllGet<D = Domain[], DD = D>(
  decorateData?: (data: D) => DD,
): FetchGet<D, DomainGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, DomainGetParams, DD>('domains/all', {
    name: 'Domains',
    decorateData,
    autoStart: false,
    startStateLoading: false,
  });
}

export function useDomainShiftsGet<D = Domain[], DD = D>(
  decorateData?: (data: D) => DD,
): FetchGet<D, DomainGetParams, DefaultFetchError, DD> {
  const navigate = useNavigate();

  return useFetchGet<D, DefaultFetchError, DomainGetParams, DD>(
    'domains/shifts',
    {
      name: 'Domains',
      decorateData,
      autoStart: false,
      startStateLoading: false,

      beforeError(err) {
        if (err && err.response?.status === 422) {
          navigate('/');

          return true;
        }

        return false;
      },
    },
  );
}

// eslint-disable-next-line max-len
export const useDomainsValidate = (): FetchGetId<{ success: boolean }> => useFetchGetId('domains/validate/removal', '', { autoStart: false });

export type DomainUpdateParams = Partial<DomainCreateParams>;

export const useDomainUpdate = (): FetchUpdate<
  FetchSuccess,
  DefaultFetchError,
  DomainUpdateParams
> => useFetchUpdate('domains');

export interface DomainsAllUpdateParams extends DomainUpdateParams {
  id: string;
}

export const useDomainsAllUpdate = (): FetchUpdate<
  FetchSuccess,
  DefaultFetchError,
  DomainsAllUpdateParams[]
> => useFetchUpdate('domains/all');
