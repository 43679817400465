import React, { useEffect } from 'react';
import { EditOutlined, CheckCircleOutlined, StopOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import { ProCoreActionType } from '@ant-design/pro-utils/es/typing';
import {
  App, Button, Space, Tooltip, Typography,
} from 'antd';
import { UseSimpleModalProps } from '../../../../Common/Modal/Simple';
import { UserRoles, isRoleEnough } from '../../../../../enums/user';
import { useAuthState } from '../../../../../store/auth';
import { useLocationUpdate, useLocationValidate } from '../../../../../hooks/location';
import { useMessageError } from '../../../../../hooks/common';
import { COLOR_PRIMARY } from '../../../../../AntdConfigProvider';

export interface ActionProps<Data, Props> {
  dom: React.ReactNode;
  entity: Data & { id: string; isActive: boolean };
  index: number;
  action: ProCoreActionType;
  open: (props?: UseSimpleModalProps) => void;
  getLocations: () => void;
}

function Action<Data, Props>({ entity, open, getLocations }: ActionProps<Data, Props>) {
  const { id, isActive } = entity;
  const { user } = useAuthState();
  const { message } = App.useApp();
  const isAdmin = isRoleEnough(user?.role, UserRoles.LOKEM_ADMIN);
  const actionName = isActive ? 'Deactivate' : 'Activate';
  const validateLocation = useLocationValidate();
  const updateLocation = useLocationUpdate();

  useEffect(() => {
    if (validateLocation.data) {
      open({
        icon: isActive ? (
          <StopOutlined style={{ color: '#FF4D4F' }} />
        ) : (
          <CheckCircleOutlined style={{ color: COLOR_PRIMARY }} />
        ),
        title: !isActive ? 'Activate location' : 'Deactivate location',
        content: (
          <div>
            {!validateLocation.data.success && isActive ? (
              <Typography.Paragraph strong>
                This Location has active shifts assigned to applicants. Please remove applicants from active shifts and
                try again
              </Typography.Paragraph>
            ) : (
              <Typography.Paragraph>
                {`Are you sure you want to ${actionName.toLowerCase()}  this location?`}
              </Typography.Paragraph>
            )}
          </div>
        ),

        cancelText: 'Cancel',
        okText: actionName,
        centered: true,
        okButtonProps: { disabled: !validateLocation.data.success && isActive },
        onOk: () => updateLocation.fetch({ isActive: !isActive }, id),
      });
    }
  }, [validateLocation.data]);

  useEffect(() => {
    if (updateLocation.data) {
      message.success('You have successfully updated the location');
      getLocations();
    }
  }, [updateLocation.data]);

  useMessageError([updateLocation, validateLocation]);

  return (
    <Space>
      <Tooltip title="Edit">
        <NavLink to={`${id}?edit`}>
          <EditOutlined />
        </NavLink>
      </Tooltip>
      {isAdmin && (
        <Tooltip title={actionName}>
          <Button
            type="link"
            loading={validateLocation.loading || updateLocation.loading}
            onClick={() => {
              validateLocation.fetch(undefined, id);
            }}
          >
            {isActive ? (
              <StopOutlined style={{ color: '#FF4D4F' }} />
            ) : (
              <CheckCircleOutlined style={{ color: COLOR_PRIMARY }} />
            )}
          </Button>
        </Tooltip>
      )}
    </Space>
  );
}

Action.defaultProps = {
  isClient: false,
};

export default Action;
