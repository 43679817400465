import React from 'react';
import Layout from '../../../components/Layout';
import EditShiftForm from '../../../components/Pages/Shift/Edit';

export default function EditShift(): React.ReactNode {
  document.title = `${process.env.REACT_APP_TITLE_NAME}: Edit Shift`;

  return (
    <Layout disableLeftMenu>
      <EditShiftForm />
    </Layout>
  );
}
