import type { CollapseProps } from 'antd';
import { useEffect, useState } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import {
  App, Form, Button, Space, Badge, Tooltip, Typography,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import TopPanel from '../../TopPanel';
import Collapse from '../Collapse';
import DateTime from '../DateTime/index';
import DomainRole from '../DomainRole';
import Applicants from '../Applicants';
import SummaryModal from '../SummaryModal';
import { useSimpleModal } from '../../Modal/Simple';
import { useShiftActionContext } from '../../../Pages/Shift/Create/Context';
import { getBudgeColor } from '../../Badge';
import ShiftStatus, { editStatuses, getShiftStatus } from '../../../../enums/shift';
import { useShiftDelete } from '../../../../hooks/shift';
import { useSearchParams } from '../../../../hooks/useSearchParams';
import { dateFormat } from '../../../../contstant';
import Allowances from '../Allowances';
import { useAuthState } from '../../../../store/auth';
import { generateDefaultUrl } from '../../../../routes';

import styles from './index.module.scss';

const items: CollapseProps['items'] = [
  {
    key: 'date',
    label: 'Date & Time',
    children: <DateTime />,
  },
  {
    key: 'domain',
    label: 'Domain & Role',
    children: <DomainRole />,
  },
  {
    key: 'allowances',
    label: 'Allowances & Breaks',
    children: <Allowances />,
  },
  {
    key: 'applicants',
    label: 'Applicants',
    children: <Applicants />,
  },
];

export default function ShiftForm() {
  const deleteShift = useShiftDelete();
  const {
    form, onSummary, isValid, setValid, onCreateDraft, mode, shift, isAdmin,
  } = useShiftActionContext();
  const values = Form.useWatch([], form);
  const { open, contextHolder } = useSimpleModal();
  const [isValidDraft, setValidDraft] = useState(false);
  const navigate = useNavigate();
  const { message } = App.useApp();
  const { user } = useAuthState();
  const isEditMode = mode === 'edit';
  const isCompletedEdit = (isEditMode && shift && shift.status === ShiftStatus.COMPLETED) || false;

  useEffect(() => {
    if (deleteShift.data) {
      message.info('Shift successfully deleted');
      navigate(`/shifts/${generateDefaultUrl(user)}`);
    }
  }, [deleteShift.data]);

  if (mode === 'edit' && shift && !isAdmin && !editStatuses.includes(shift?.status as ShiftStatus)) {
    navigate(`/${generateDefaultUrl(user)}`);
  }

  const onFieldsChange = (_: any, fields: any[]) => {
    const requiredDraft = ['domain', 'client', 'location', 'date', 'time'];
    const notRequired = [
      'notes',
      'preferredApplicants',
      'timeFrame',
      'applicant',
      'additionalRequirements',
      'documents',
      'inductions',
      'lunchBreak',
    ];

    setValidDraft(
      fields.every((field) => {
        if (!requiredDraft.includes(field.name[0])) return true;

        return !field.errors.length && field.value;
      }),
    );
    const isAllowances = !!fields.find((item) => item.name[0] === 'allowances')?.value?.length;

    const timeRange = values?.date
      && values?.time
      && dayjs
        .utc(values.date[1])
        .startOf('day')
        .add(dayjs(values.time[1]).hour(), 'hour')
        .add(dayjs(values.time[1]).minute(), 'minute')
        .diff(
          dayjs
            .utc(values.date[0])
            .startOf('day')
            .add(dayjs(values.time[0]).hour(), 'hour')
            .add(dayjs(values.time[0]).minute(), 'minute'),
          'minute',
        ) / 60;

    if (timeRange <= 0) {
      form?.setFields([{ name: 'time', errors: ['The end time can not precede the start time.'] }]);
    }

    if (timeRange > 0) {
      form?.setFields([{ name: 'time', errors: [] }]);
      // eslint-disable-next-line no-param-reassign
      fields[fields.findIndex((field) => field.name[0] === 'time')].errors = [];
    }

    const onCallRange = values?.onCallDate
      && values?.onCallTime
      && dayjs
        .utc(values.onCallDate[1])
        .startOf('day')
        .add(dayjs(values.onCallTime[1]).hour(), 'hour')
        .add(dayjs(values.onCallTime[1]).minute(), 'minute')
        .diff(
          dayjs
            .utc(values.onCallDate[0])
            .startOf('day')
            .add(dayjs(values.onCallTime[0]).hour(), 'hour')
            .add(dayjs(values.onCallTime[0]).minute(), 'minute'),
          'minute',
        ) / 60;

    if (onCallRange <= 0) {
      form?.setFields([{ name: 'onCallTime', errors: ['The end time can not precede the start time.'] }]);
    }

    if (onCallRange > 0) {
      form?.setFields([{ name: 'onCallTime', errors: [] }]);
      // eslint-disable-next-line no-param-reassign
      fields[fields.findIndex((field) => field.name[0] === 'onCallTime')].errors = [];
    }

    setValid(
      fields.every((field) => {
        if (timeRange <= 0 || onCallRange <= 0) {
          return false;
        }

        if ([...notRequired, !isAllowances ? 'allowances' : ''].includes(field.name[0])) {
          return true;
        }

        if (field.name[(field.name as string[]).length - 1] === 'id') {
          return true;
        }

        // if ((field.name[0] === 'lunchBreak' || field.name[0] === 'customLunchBreak') && field.value === null) {
        //   return true;
        // }
        if ((field.name[0] === 'lunchBreak' || field.name[0] === 'customLunchBreak') && field.value === 0) {
          return true;
        }

        return !field.errors.length && field.value;
      }),
    );
  };

  function rightContent() {
    return (
      <Space size="middle">
        {mode === 'edit' && (isAdmin || editStatuses.includes(shift?.status as ShiftStatus)) && (
          <Button
            type="link"
            danger
            disabled={!shift}
            onClick={() => {
              open({
                title: 'Delete shift',
                content: 'Are you sure you want to delete the shift?',
                cancelText: 'Cancel',
                okText: 'Delete',
                centered: true,
                onOk: () => deleteShift.fetch(shift?.id),
              });
            }}
          >
            Delete
          </Button>
        )}
        {mode === 'create' && (
          <Tooltip title={isValidDraft ? null : 'Please select date and location fields first.'}>
            <Button type="primary" disabled={!isValidDraft} ghost onClick={onCreateDraft}>
              Save draft
            </Button>
          </Tooltip>
        )}
        <Button type="primary" disabled={!isValid} onClick={onSummary}>
          Continue
        </Button>
      </Space>
    );
  }

  return (
    <Form className={styles.inner} form={form} layout="vertical" requiredMark={false} onFieldsChange={onFieldsChange}>
      <TopPanel
        padding
        title={(
          <Space size="small">
            <Button
              type="text"
              className={styles.closeBtn}
              onClick={() => {
                open({
                  title: 'Exit',
                  content: 'Are you sure you want to exit? Applied changes will not be saved',
                  cancelText: 'Cancel',
                  okText: 'Exit',
                  centered: true,
                  onOk: () => navigate(-1),
                });
              }}
            >
              <ArrowLeftOutlined />
            </Button>
            {mode === 'edit' && 'Edit shift'}
            {mode === 'create' && 'Create shift'}
            {mode === 'copy' && 'Copy shift'}
            {shift && mode !== 'copy' && (
              <Badge
                className={styles.status}
                color={getBudgeColor(shift?.status)}
                text={getShiftStatus(shift?.status)}
              />
            )}
            {isCompletedEdit
            && (
            <Typography.Text className={styles.warning}>
              You are editing a completed shift. Only some fields are editable.
            </Typography.Text>
            )}
          </Space>
        )}
        rightContent={rightContent()}
      />

      <Collapse items={items} defaultActive={items!.map((item) => (item.key ? item.key.toString() : ''))} />
      <SummaryModal />
      <div className={styles.bottomBtns}>{rightContent()}</div>
      {contextHolder}
    </Form>
  );
}
