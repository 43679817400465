import { Modal } from 'antd';
import { useSettingsContext } from '../Context';

export default function SaveModal() {
  const {
    onSave, loading, setEdit, setChanging, saveModalOpen, setSaveModalOpen,
  } = useSettingsContext();

  const handleOk = () => {
    if (onSave) {
      onSave();
    }
    setSaveModalOpen(false);
  };

  const handleCancel = () => {
    setEdit(() => false);
    setChanging(() => false);
    setSaveModalOpen(false);
  };

  return (
    <Modal
      title="Unsaved changes"
      open={saveModalOpen}
      okText="Save changes"
      okButtonProps={{ loading }}
      onOk={handleOk}
      onCancel={handleCancel}
      closable={false}
      maskClosable={false}
      centered
    >
      You need to cancel or save your changes.
    </Modal>
  );
}
