import React from 'react';
import Simple from '../../../components/Layout/Simple';
import ContentSignUp from '../../../components/Pages/SignUp';

export default function SignUp(): React.ReactNode {
  document.title = `${process.env.REACT_APP_TITLE_NAME}: Sign up`;

  return (
    <Simple>
      <ContentSignUp />
    </Simple>
  );
}
