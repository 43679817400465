import React, { useMemo } from 'react';
import { GridCol, useRosterContext } from '../../Context';
import styles from './index.module.scss';

interface UnassignedShiftProps {
  col: GridCol;
}

export default function UnassignedShift({ col }: UnassignedShiftProps): React.ReactNode {
  const { setSelectedShift, setSiderData, setSelectedRef } = useRosterContext();

  return (
    <div
      className={styles.shifts}
      onClick={(e) => {
        e.preventDefault();
        setSelectedShift(null);
        setSiderData(col.shifts);
        setSelectedRef(undefined);
      }}
      role="none"
    >
      {col.shifts.length}
      {' '}
      {col.shifts.length === 1 ? 'shift' : 'shifts'}
    </div>
  );
}
