import React, { useEffect } from 'react';
import {
  Alert, Button, Form, Select,
} from 'antd';
// import { useTranslation } from 'react-i18next';
import { SignUpPageDefaultProp } from '../../types';
import { useContextSignUp } from '../../Context';
import { useExperiencesGet } from '../../../../../hooks/experience';
import Loading from '../../../../Common/Loading';
import { useAuthState } from '../../../../../store/auth';
import { getMessageInError } from '../../../../../hooks/fetch';

import styles from './index.module.scss';

interface FormValues {
  name: string;
}

type ExperienceProps = SignUpPageDefaultProp;

export default function Experience({ name, className }: ExperienceProps): React.ReactNode | null {
  const experiences = useExperiencesGet();
  const { authorized } = useAuthState();
  // const { t } = useTranslation();
  const [form] = Form.useForm();
  const {
    setForm, onFinish, triggerBack, isPossibleTriggerBack,
  } = useContextSignUp();

  useEffect(() => {
    if (authorized) {
      experiences.fetch();
    }
  }, [authorized]);

  useEffect(() => {
    setForm(name, form);
  }, [name, form]);

  const onFinishHandler = (values: FormValues) => {
    onFinish(name, values);
  };

  return (
    <Form<FormValues>
      form={form}
      className={className}
      name={name}
      autoComplete="off"
      layout="vertical"
      onFinish={onFinishHandler}
    >
      <h2>Your Medical Experience</h2>
      <span>Select all the roles you have experience in (multiselect)*</span>
      {experiences.loading || !experiences.data ? (
        <div className={styles.loading}>
          <Loading />
        </div>
      ) : (
        <Form.Item name="experience">
          <Select
            className={styles.row}
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder="Please select"
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            options={experiences.data?.map(({ title, id }) => ({ label: title, value: id }))}
          />
          {/*
          <Checkbox.Group>
            <Row className={styles.row}>
              {
                experiences.data?.map(({ id: experienceId, title }) => (
                  <Col
                    key={experienceId}
                    span={24}
                  >
                    <Checkbox value={experienceId}>{title}</Checkbox>
                  </Col>
                ))
              }
            </Row>
          </Checkbox.Group> */}
        </Form.Item>
      )}

      {experiences?.error ? (
        <Alert type="error" message={getMessageInError(experiences.error)} closable onClose={experiences.clearError} />
      ) : null}

      <div>
        {isPossibleTriggerBack() ? (
          <Button onClick={triggerBack} size="large">
            Go Back
          </Button>
        ) : null}
        <Form.Item shouldUpdate className="submit">
          {() => (
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              loading={experiences.loading}
              disabled={
                !(form.getFieldValue('experience') && form.getFieldValue('experience').length)
                || form.getFieldsError().filter(({ errors }) => errors.length).length > 0
              }
            >
              Save and Continue
            </Button>
          )}
        </Form.Item>
      </div>
    </Form>
  );
}
