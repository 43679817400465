import { InfoCircleTwoTone, PlusOutlined } from '@ant-design/icons';
import {
  App, Upload, UploadProps, UploadFile, Typography, Space, Tooltip,
} from 'antd';
import { useState, useEffect } from 'react';
import { useUploadFile } from '../../../../../hooks/file';
import { useMessageError } from '../../../../../hooks/common';
import { useSettingsContext } from '../Context';
import {
  useClientId,
  useClientLogoDelete,
  useClientLogoMiniDelete,
  useClientUpdate,
} from '../../../../../hooks/client';
import Loading from '../../../../Common/Loading';
import { UserRoles } from '../../../../../enums/user';
import { COLOR_PRIMARY } from '../../../../../AntdConfigProvider';
import { useAuthState } from '../../../../../store/auth';

import styles from './index.module.scss';

interface LayoutProps {
  type: string;
  title: string;
  tooltipText: string;
  text: string;
}

export default function Layout({
  type, title, tooltipText, text,
}: LayoutProps) {
  const { message } = App.useApp();
  const { user } = useAuthState();
  const client = useClientId();
  const logoDelete = useClientLogoDelete();
  const logoMiniDelete = useClientLogoMiniDelete();
  const updateClient = useClientUpdate();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const uploader = useUploadFile();
  const { setActiveTab, clientId } = useSettingsContext();
  const isLogoMini = type === 'square';

  const getClient = () => {
    client.fetch(undefined, clientId);
  };

  useEffect(() => {
    setActiveTab(() => 'layout');
    getClient();
  }, []);

  const props: UploadProps = {
    multiple: false,
    onRemove: () => {
      if (isLogoMini) {
        logoMiniDelete.fetch(clientId);
      } else {
        logoDelete.fetch(clientId);
      }
    },
    beforeUpload: (file) => {
      if (!['image/jpeg', 'image/png', 'image/gif', 'image/webp'].includes(file.type)) {
        message.error('You can only upload JPG/PNG/GIF/WEBP file!');

        return false;
      }

      if (!(file.size / 1024 / 1024 < 10)) {
        message.error('Image must smaller than 10MB!');

        return false;
      }

      const image = new Image();

      image.src = URL.createObjectURL(file);

      /* eslint-disable consistent-return */
      image.onload = () => {
        if (isLogoMini && (image.width < 32 || image.height < 32)) {
          message.error('Image dimensions must be at least 32x32 pixels.');

          return false;
        }
        if (!isLogoMini && (image.width < 160 || image.height < 32)) {
          message.error('Image dimensions must be at least 160x32 pixels.');

          return false;
        }

        const formData = new FormData();

        formData.append('file', file as Blob);
        uploader.fetch(formData);
      };

      return false;
    },
    fileList,
    listType: 'picture-card',
  };

  useEffect(() => {
    if (client.data) {
      const { logo, logoMini } = client.data;

      if (isLogoMini && logoMini) {
        setFileList([{ url: logoMini.location, uid: logoMini.id, name: logoMini.originalName }]);
      }
      if (!isLogoMini && logo) {
        setFileList([{ url: logo.location, uid: logo.id, name: logo.originalName }]);
      }
    }
  }, [client.data]);

  useEffect(() => {
    if (uploader.data) {
      const { id } = uploader.data;

      updateClient.fetch(isLogoMini ? { logoMini: id } : { logo: id }, clientId);
    }
  }, [uploader.data]);

  useEffect(() => {
    if (updateClient.data) {
      getClient();
      message.success('Logo has been successfully uploaded!');
    }
  }, [updateClient.data]);

  useEffect(() => {
    if (logoDelete.data || logoMiniDelete.data) {
      getClient();
      setFileList([]);
      message.success('You have successfully removed the logo!');
    }
  }, [logoDelete.data, logoMiniDelete.data]);

  useMessageError([uploader, client, updateClient, logoDelete, logoMiniDelete]);

  return (
    <div className={styles.wrapper}>
      <Typography.Text strong>
        {title}
        {[UserRoles.CLIENT_ADMIN].includes(user?.role as UserRoles) ? (
          <Tooltip
            className={styles.marginTooltip}
            title={tooltipText}
          >
            <InfoCircleTwoTone style={{ cursor: 'help' }} twoToneColor={COLOR_PRIMARY} />
          </Tooltip>
        ) : null}
      </Typography.Text>
      <Space align="start" size="large" className={styles.inner}>
        {client.loading || updateClient.loading || logoDelete.loading || logoMiniDelete.loading ? (
          <div className={styles[type]}>
            <div className={styles.loading}>
              <Loading />
            </div>
          </div>
        ) : (
          <div className={styles[type]}>
            <Upload {...props} className={styles.upload}>
              {fileList.length >= 1 ? null : (
                <Space direction="vertical">
                  <PlusOutlined />
                  <Typography.Text>Upload </Typography.Text>
                </Space>
              )}
            </Upload>
          </div>
        )}
        <Typography.Text className={styles.text}>
          {`File requirement: JPG, PNG, GIF or WEBP up to 10 MB.  ${text}`}
        </Typography.Text>
      </Space>
    </div>
  );
}
