import { useState, useEffect } from 'react';
import {
  Badge, Form, Input, Modal, Select,
} from 'antd';
import styles from './index.module.scss';
import { useSettingsContext } from '../../Context';
import { useMessageError } from '../../../../../hooks/common';
import { DocumentCreateParams } from '../../../../../hooks/document';
import { DomainCreateParams } from '../../../../../hooks/domain';
import { ExperienceCreateParams } from '../../../../../hooks/experience';
import { FetchCreate, FetchSuccess, DefaultFetchError } from '../../../../../hooks/fetch';
import { InductionsCreateParams } from '../../../../../hooks/inductions';
import { getBudgeColor } from '../../../../Common/Badge';

interface CreateModalProps {
  createAction:
    | FetchCreate<FetchSuccess, DefaultFetchError, ExperienceCreateParams>
    | FetchCreate<FetchSuccess, DefaultFetchError, DomainCreateParams>
    | FetchCreate<FetchSuccess, DefaultFetchError, DocumentCreateParams>
    | FetchCreate<FetchSuccess, DefaultFetchError, InductionsCreateParams>;
  fetch?: () => void;
  nameButton: string;
  valueName: string;
  requiredCheckbox?: boolean;
  nameLabel: string;
  showPriority?: boolean;
}

function CreateModal({
  createAction,
  fetch,
  nameButton,
  valueName,
  requiredCheckbox,
  nameLabel,
  showPriority,
}: CreateModalProps) {
  const [isValid, setValid] = useState(false);
  const [form] = Form.useForm();
  const { isModalOpen, setModalOpen } = useSettingsContext();

  useEffect(() => {
    if (createAction.data) {
      createAction.clearResponse();
      form.setFieldValue('name', '');
      setModalOpen(false);
      if (fetch) {
        fetch();
      }
    }
  }, [createAction.data]);

  const onFieldsChange = (_: any, allFields: any[]) => {
    if (allFields.every((field) => field.errors.length === 0)) {
      setValid(() => true);
    } else {
      setValid(() => false);
    }
  };

  useMessageError([createAction]);

  return (
    <Modal
      title={nameButton}
      open={isModalOpen}
      destroyOnClose
      onOk={() => {
        const params = {
          [valueName]: form.getFieldValue('name'),
        };

        if (requiredCheckbox) {
          params.isRequired = !!form.getFieldValue('isRequired');
          params.signUp = true;
        }

        if (showPriority) {
          params.priority = form.getFieldValue('priority');
        }

        // @ts-ignore error documents
        createAction.fetch(params);
      }}
      onCancel={() => setModalOpen(false)}
      okButtonProps={{ disabled: !isValid, loading: createAction.loading }}
      okText="Save"
      className={styles.modal}
      closable={false}
      centered
    >
      <Form form={form} requiredMark={false} layout="vertical" onFieldsChange={onFieldsChange}>
        <Form.Item
          name="name"
          label={nameLabel}
          rules={[{
            required: true,
            message: 'Should be at least one character long!',
          }, {
            max: 30, message: 'Maximum number of characters - 30',
          }]}
        >
          <Input placeholder={nameLabel} />
        </Form.Item>
        {requiredCheckbox && (
          <Form.Item
            name="isRequired"
            label="Required during registration?"
            rules={[{ required: true, message: 'Required field!' }]}
          >
            <Select
              defaultValue={false}
              style={{ width: '100%' }}
              options={[
                {
                  label: 'Yes',
                  value: true,
                },
                {
                  label: 'No',
                  value: false,
                },
              ]}
            />
          </Form.Item>
        )}

        {showPriority && (
          <Form.Item name="priority" label="Select priority" rules={[{ required: true, message: 'Required field!' }]}>
            <Select
              defaultValue="medium"
              style={{ width: '100%' }}
              options={[
                { value: 'high', label: <Badge color={getBudgeColor('high')} text="High" /> },
                {
                  value: 'medium',
                  label: <Badge color={getBudgeColor('medium')} text="Medium" />,
                },
                { value: 'low', label: <Badge color={getBudgeColor('low')} text="Low" /> },
              ]}
            />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
}

CreateModal.defaultProps = {
  fetch: undefined,
  requiredCheckbox: false,
  showPriority: false,
};

export default CreateModal;
