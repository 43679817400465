import { Button } from 'antd';
import { EyeFilled } from '@ant-design/icons';
import { useEffect } from 'react';
import { useDownloadFile } from '../../../../hooks/file';
import { FILE_FORMAT } from '../../../../hooks/fetch';
import { useMessageError } from '../../../../hooks/common';

import styles from './index.module.scss';

type ViewActionProps = {
  fileId: string;
  name: string;
};

export default function ViewAction({ fileId, name }: ViewActionProps) {
  const controller = new AbortController();
  const downloadFile = useDownloadFile(controller);

  const downloadFromAnchor = (blobPart: ArrayBuffer) => {
    const arrayName = name.split('.');
    const type = FILE_FORMAT[arrayName[arrayName.length - 1]];
    const file = new Blob([blobPart], { type });
    const fileURL = window.URL.createObjectURL(file);
    const link = document.createElement('a');

    link.href = fileURL;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getDownloadFile = (id: string) => downloadFile.fetch(undefined, id);

  useEffect(() => {
    if (downloadFile.data) {
      downloadFromAnchor(downloadFile.data);
    }
  }, [downloadFile.data]);

  useMessageError([downloadFile]);

  return (
    <Button
      type="text"
      key={fileId}
      loading={downloadFile.loading}
      className={styles.btn}
      onClick={() => getDownloadFile(fileId)}
      disabled={false}
    >
      <EyeFilled className={styles.icon} />
      View
    </Button>
  );
}
