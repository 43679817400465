import React, { useState } from 'react';
import clsx from 'clsx';
import ClearFilter from '../ClearFilters';

import styles from './index.module.scss';

interface MenuWrapperProps {
  children: React.ReactNode;
  disabled?: boolean;
  clearFilters?: boolean;
}

function FilterWrapper({ children, clearFilters, disabled }: MenuWrapperProps) {
  const [key, setKey] = useState('default');

  return (
    <>
      <div key={key} className={clsx(styles.wrapper, { [styles.clearFilters]: clearFilters })}>
        {children}
        {disabled ? <div className={styles.disabledBlock} /> : null}
      </div>
      {clearFilters ? <ClearFilter setKey={setKey} /> : null}
    </>
  );
}

FilterWrapper.defaultProps = {
  disabled: false,
  clearFilters: false,
};

export default FilterWrapper;
