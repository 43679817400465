import React, { useRef } from 'react';
import { MoreOutlined } from '@ant-design/icons';
import styles from './index.module.scss';
import { GridShift, useRosterContext } from '../../Context';

interface OptionsProps {
  data: GridShift;
}

function Options({ data }: OptionsProps): React.ReactNode {
  const ref = useRef<HTMLDivElement | null>(null);
  const { setSelectedRef } = useRosterContext();

  return (
    <div
      className={styles.options}
      role="none"
      ref={ref}
      onClick={(e) => {
        setSelectedRef({
          place: 'calendar',
          shift: data,
          ref,
        });
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <MoreOutlined />
    </div>
  );
}

export default Options;
