import { useMemo, useState } from 'react';
import {
  Button, Flex, Input, Typography,
} from 'antd';
import { debounce } from 'lodash';
import { CloseOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import dayjs, { Dayjs } from 'dayjs';
import Shift from '../Shift';
import { useRosterContext } from '../../Context';
import styles from './index.module.scss';

export default function Sider() {
  const { setSiderData, siderData } = useRosterContext();
  const [search, setSearch] = useState('');
  const [closed, setClosed] = useState(false);

  const getShift = () => {
    if (!siderData) {
      return [];
    }

    const data = siderData.map((shift) => ({
      ...shift,
      start: shift.multiShift ? dayjs(shift.multiShift.datetimeStart) : dayjs(shift.datetimeStart),
      end: shift.multiShift ? dayjs(shift.multiShift.datetimeEnd) : dayjs(shift.datetimeEnd),
      time: `${dayjs(shift.datetimeStart).format('HH-mm')} - ${dayjs(shift.datetimeEnd).format('HH-mm')}`,
    }));

    if (!search) {
      return data;
    }

    return data.filter(
      (shift) => shift?.client?.name.toLowerCase().includes(search.toLowerCase())
        || shift?.locationRole?.name.toLowerCase().includes(search.toLowerCase())
        || shift?.location?.name.toLowerCase().includes(search.toLowerCase()),
    );
  };

  const handleSearch = useMemo(() => debounce((value: string) => setSearch(value), 300), []);

  return (
    <div className={clsx(styles.wrapper, 'sider_content', { [styles.closed]: closed })}>
      <Flex className={styles.panel} justify="space-between" align="center">
        <Typography.Text strong>Unassigned shifts</Typography.Text>
        <Button
          type="text"
          onClick={() => {
            setClosed(true);
            setTimeout(() => {
              setSiderData(null);
              setClosed(false);
            }, 500);
          }}
        >
          <CloseOutlined />
        </Button>
      </Flex>
      <div className={styles.inner}>
        <Input.Search className={styles.search} placeholder="Search" onChange={(e) => handleSearch(e.target.value)} />
        <div className={styles.items}>
          {getShift().length ? (
            getShift().map((shift) => <Shift shift={shift} key={shift.id} />)
          ) : (
            <div className={styles.found}>Nothing found</div>
          )}
        </div>
      </div>
    </div>
  );
}
