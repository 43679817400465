import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import {
  Col, Typography, Button, App, Space,
} from 'antd';
import { useEffect } from 'react';
import { useShiftContext } from '../../../Pages/Shift/Context';
import { useUpdateApplicant } from '../../../../hooks/applicant';
import { Applicant } from '../../../../types/applicant';
import { getMessageInError } from '../../../../hooks/fetch';
import ShiftStatus from '../../../../enums/shift';
import { COLOR_PRIMARY } from '../../../../AntdConfigProvider';

import styles from './index.module.scss';

interface ApplicantInfoProps {
  title: string;
  applicant: Applicant | null;
  fetchApplicant: () => void;
}

export default function Inductions({ title, applicant, fetchApplicant }: ApplicantInfoProps) {
  const { shift, fetch, isApplicant } = useShiftContext();
  const updateApplicant = useUpdateApplicant();
  const { message } = App.useApp();
  const isInductionStatus = !isApplicant && shift?.status === ShiftStatus.INDUCTION;

  const markInduction = (id: string) => {
    const inductions = applicant?.inductions.map((item) => item.id);

    updateApplicant.fetch({ inductions: inductions ? [...inductions, id] : [id] }, shift?.applicant?.id);
  };

  useEffect(() => {
    if (updateApplicant.data) {
      message.success('Induction successfully completed!');
      fetchApplicant();
      fetch();
    }
  }, [updateApplicant.data]);

  useEffect(() => {
    if (updateApplicant.error) {
      message.error(getMessageInError(updateApplicant.error));
      updateApplicant.clearError();
    }
  }, [updateApplicant.error]);

  return (
    <Col span={12}>
      {applicant && (
        <Space direction="vertical" size="small" className={styles.space}>
          <Typography.Text strong>{title}</Typography.Text>

          {shift?.inductions.length
            ? shift.inductions.map((item) => (applicant?.inductions?.map((exp) => exp?.id)?.includes(item?.id) ? (
              <Space className={styles.induction} key={item?.id}>
                <Typography.Text>{item.title}</Typography.Text>
                <CheckOutlined style={{ color: COLOR_PRIMARY }} />
                Completed
              </Space>
            ) : (
              <Space className={styles.induction} key={item?.id}>
                {!isInductionStatus && (
                  <>
                    {item.title}
                    <CloseOutlined style={{ color: '#FF4D4F' }} />
                    Not completed
                  </>
                )}
                {isInductionStatus && (
                  <>
                    {item.title}
                    <Button onClick={() => markInduction(item.id)} loading={updateApplicant.loading} type="primary">
                      Mark complete
                    </Button>
                  </>
                )}
              </Space>
            )))
            : null}
          {!shift?.inductions?.length && !isApplicant ? 'There are no required inductions.' : null}
          {!shift?.inductions?.length && isApplicant
            ? 'You do not need to complete any inductions for this shift.'
            : null}
        </Space>
      )}
    </Col>
  );
}
