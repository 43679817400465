import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ClientUserForm from './Form';
import Detail from '../Common/Detail';
import { useUserId, useUserUpdate } from '../../../../hooks/user';
import ClientUserProvider, { useClientUserContext } from './Context';
import { useClientId } from '../../../../hooks/client';

import styles from './index.module.scss';

export default function ClientUser(): React.ReactNode {
  const { userId, clientId } = useParams();
  const client = useClientId();
  const user = useUserId();
  const userUpdate = useUserUpdate(userId);

  useEffect(() => {
    user.fetch(undefined, userId);
    client.fetch(undefined, clientId);
  }, [userId]);

  return (
    <ClientUserProvider resource={user} action={userUpdate}>
      <Detail name={`${client.data?.name} - ${user.data?.fullName}`} useContext={useClientUserContext}>
        <div className={styles.wrapper}>
          <ClientUserForm />
        </div>
      </Detail>
    </ClientUserProvider>
  );
}
