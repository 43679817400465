import Checkboxes from '../../../Common/Filter/Checkboxes/index';
import Select from '../../../Common/Filter/Select';
import FilterMenu from '../../../Common/Filter/Menu/index';
import Search from '../../../Common/Filter/Search';
import { useLocationsSelectGet } from '../../../../hooks/location';
import FilterWrapper from '../../../Common/Filter/Wrapper';
import { useMessageError, useSelectData } from '../../../../hooks/common';
import {
  useUserClientRolesSelectAllGet,
  useUserClientRolesSelectGet,
  useUserStatusesAllSelectGet,
  useUserStatusesSelectGet,
} from '../../../../hooks/user';
import { getBudgeColor } from '../../../Common/Badge';

interface FiltersProps {
  disabled?: boolean;
}

function Filter({ disabled }: FiltersProps) {
  const defaultLocationsGet = useLocationsSelectGet();
  const locationsGet = useLocationsSelectGet();
  const locations = useSelectData(locationsGet);

  const defaultUserClientRolesSelectGet = useUserClientRolesSelectAllGet();
  const userClientRolesSelectGet = useUserClientRolesSelectGet();
  const roles = useSelectData(userClientRolesSelectGet);

  const defaultUserStatusesAllSelectGet = useUserStatusesAllSelectGet();
  const userStatusesSelectGet = useUserStatusesSelectGet();
  const statuses = useSelectData(userStatusesSelectGet, (data, id, item) => ({
    ...item,
    color: getBudgeColor(item.value),
  }));

  useMessageError([
    defaultUserStatusesAllSelectGet, userStatusesSelectGet,
    defaultUserClientRolesSelectGet, userClientRolesSelectGet,
    defaultLocationsGet, locationsGet,
  ]);

  return (
    <FilterWrapper clearFilters disabled={disabled}>
      <FilterMenu>
        <Select
          title="Location"
          name="location"
          defaultHook={defaultLocationsGet}
          items={locations}
          loading={locationsGet.loading || defaultLocationsGet.loading}
          fetch={locationsGet.fetch}
          params={{ pageSize: 1000 }}
        />

        <Select
          title="Role"
          name="roles"
          defaultHook={defaultUserClientRolesSelectGet}
          items={roles}
          fetch={userClientRolesSelectGet.fetch}
          loading={userClientRolesSelectGet.loading || defaultUserClientRolesSelectGet.loading}
        />

        <Checkboxes
          title="User status"
          name="statuses"
          defaultHook={defaultUserStatusesAllSelectGet}
          items={statuses}
          fetch={userStatusesSelectGet.fetch}
          loading={userStatusesSelectGet.loading || defaultUserStatusesAllSelectGet.loading}
          badge
          checkAll
        />

        <Search
          name="partialName"
        />
      </FilterMenu>
    </FilterWrapper>
  );
}

Filter.defaultProps = {
  disabled: false,
};

export default Filter;
