import {
  DefaultFetchError,
  FetchCreate,
  useFetchCreate,
  useFetchUpdate,
  FetchUpdate,
  FetchSuccess,
} from './fetch';

export interface ApplicantDocumentsCreateParams {
  documentType: string;
  applicant: string;
  file: string;
}

interface SuccessCreateDocument extends FetchSuccess {
  id: string
}

export const useApplicantDocumentsCreate = ():
 FetchCreate<SuccessCreateDocument, DefaultFetchError, ApplicantDocumentsCreateParams> => (
  useFetchCreate('applicant-documents')
);

export const useApplicantDocumentsUpdate = ():
 FetchUpdate<FetchSuccess, DefaultFetchError, ApplicantDocumentsCreateParams> => (
  useFetchUpdate('applicant-documents')
);
