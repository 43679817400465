import React from 'react';
import Layout from '../../components/Layout';
import HomeContent from '../../components/Pages/Home';
import { useAuthState } from '../../store/auth';
import { UserRoles } from '../../enums/user';

function LeftMenu() {
  return (
    <div>
      example left menu
    </div>
  );
}

export default function Home(): React.ReactNode | null {
  const { user } = useAuthState();

  document.title = `${process.env.REACT_APP_TITLE_NAME}: Home page`;

  return (
    <Layout
      disableLeftMenu={user && user.role ? user?.role === UserRoles.APPLICANT : true}
      leftMenu={<LeftMenu />}
    >
      <HomeContent />
    </Layout>
  );
}
