import { ExclamationCircleFilled } from '@ant-design/icons';
import {
  Button, Modal, Typography, Form, Input, App, Space, Badge, Alert, Row, Col, Tooltip,
} from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { FieldData } from 'rc-field-form/lib/interface';
import { Applicant } from '../../../../types/applicant';
import { Shift, useShiftApply, useShiftReject } from '../../../../hooks/shift';
import { useApplicantId, useUpdateApplicant } from '../../../../hooks/applicant';
import { getMessageInError } from '../../../../hooks/fetch';
import { getHoursBetween } from '../../../../utils';
import { getBudgeColor } from '../../Badge';
import ShiftStatus from '../../../../enums/shift';
import InputFile from '../../Input/File';
import { useApplicantDocumentsCreate } from '../../../../hooks/applicantDocument';
import { useAuthState } from '../../../../store/auth';
import { useMessageError } from '../../../../hooks/common';
import { UserStatus } from '../../../../enums/user';
import { generateDefaultUrl } from '../../../../routes';

import styles from './index.module.scss';

interface ApplyProps {
  shift: Shift | null;
  textBtn?: string;
  showReject?: boolean;
  disabledBtn?: boolean | string;
  withRedirect?: boolean;
  callback?: (id: string) => void;
  loading?: boolean;
}

function Apply({
  shift, textBtn, showReject, disabledBtn, withRedirect, callback, loading,
}: ApplyProps) {
  const { user } = useAuthState();
  const applicantId = user?.applicant?.id;
  const updateApplicant = useUpdateApplicant();
  const applicantById = useApplicantId();
  const createDocument = useApplicantDocumentsCreate();
  const shiftApply = useShiftApply();
  const shiftReject = useShiftReject();
  const { message } = App.useApp();
  const navigate = useNavigate();
  const [documentModal, setDocumentModal] = useState(false);
  const [bankModal, setBankModal] = useState(false);
  const [overlapModal, setOverlapModal] = useState(false);
  const [overlaping, setOverlaping] = useState<Shift[] | null>(null);
  const [applicant, setApplicant] = useState<Applicant | null>(null);
  const [isValid, setValid] = useState(false);
  const [bankForm] = Form.useForm();
  const [documentForm] = Form.useForm();
  const documents = Form.useWatch([], documentForm);
  const thisApplicantApply = shift && !!shift.applicants.find((item) => item?.id === applicantId);
  const isStatusPending = user?.status === UserStatus.PENDING;

  const getApplicant = () => {
    applicantById.fetch(undefined, applicantId);
  };

  useEffect(() => {
    if (applicantById.data) {
      setApplicant(applicantById.data);
      handleApply(applicantById.data);
    }
  }, [applicantById.data]);

  const apply = () => {
    if (shift) {
      shiftApply.fetch(undefined, shift?.id);
    }
  };

  const handleApply = (applicantData: Applicant) => {
    const documentsIds = applicantData?.documents?.map((item) => item.documentType?.id);
    const isDocumentsMatch = shift?.documents?.every((item) => documentsIds?.includes(item?.id));

    if (!applicantData.bsb || !applicantData.accountNumber || !applicantData.abn) {
      bankForm.setFieldsValue(applicantData);
      setBankModal(true);
    } else if (!isDocumentsMatch) {
      setDocumentModal(true);
    } else {
      apply();
    }
  };

  const handleBankData = () => {
    updateApplicant.fetch(bankForm.getFieldsValue(), applicantId);
  };

  const handleDocumentData = async () => {
    const formData = documentForm.getFieldsValue();
    const results = await Promise.all(
      Object.keys(formData).map((key) => createDocument.fetch({
        documentType: key,
        applicant: applicantId!,
        file: formData[key],
      })),
    );

    if (results.every((response) => response?.id)) {
      apply();
      setDocumentModal(false);
      message.success('The documents have been succsessfully uploaded');
    }
  };

  useEffect(() => {
    if (updateApplicant.data) {
      setBankModal(false);
      message.success('Data updated successfully!');
      getApplicant();
    }
  }, [updateApplicant.data]);

  useEffect(() => {
    if (shiftApply.data) {
      const { data } = shiftApply;

      if (Array.isArray(data)) {
        // @ts-ignore response as array
        setOverlaping(shiftApply.data);
        setOverlapModal(true);
      } else {
        message.success('Application successfull');
        if (withRedirect) {
          navigate(`/shifts/${generateDefaultUrl(user)}`);
        }

        if (callback) {
          callback(data.id);
        }
      }
    }
  }, [shiftApply.data]);

  useEffect(() => {
    if (shiftReject.data) {
      message.success('Shift successfully rejected');
      navigate(`/shifts/${generateDefaultUrl(user)}`);
    }
  }, [shiftReject.data]);

  useEffect(() => {
    if (updateApplicant.error) {
      message.error(getMessageInError(updateApplicant.error));
      updateApplicant.clearError();
      setBankModal(false);
    }
    if (createDocument.error) {
      message.error(getMessageInError(createDocument.error));
      createDocument.clearError();
      setDocumentModal(false);
    }
  }, [updateApplicant.error, createDocument.error]);

  useMessageError([shiftApply, shiftReject]);

  const onFieldsChange = (_: FieldData[], allFields: FieldData[]) => {
    if (allFields.every((field) => !field?.errors?.length && field.value)) {
      setValid(true);
    } else {
      setValid(false);
    }
  };

  return (
    <div>
      {thisApplicantApply
        ? showReject
          && shift.status === ShiftStatus.PENDING && (
            <Button
              type="primary"
              loading={shiftReject.loading}
              onClick={() => shiftReject.fetch(undefined, shift?.id)}
              danger
            >
              Reject
            </Button>
        )
        : !isStatusPending && (
        <Tooltip title={isStatusPending || disabledBtn}>
          <Button
            type="primary"
            disabled={!!disabledBtn || isStatusPending}
            onClick={getApplicant}
            loading={shiftApply.loading || applicantById.loading || loading}
          >
            {textBtn}
          </Button>
        </Tooltip>
        )}

      <Modal
        title={(
          <Space>
            <ExclamationCircleFilled style={{ color: '#FAAD14', fontSize: '16px' }} />
            Documents needed
          </Space>
        )}
        okButtonProps={{ disabled: !isValid, loading: updateApplicant.loading }}
        open={documentModal}
        closable={false}
        centered
        maskClosable={false}
        footer={[
          <Row key="link" justify="space-between">
            <Col>
              <Button
                onClick={() => {
                  setDocumentModal(false);
                  apply();
                }}
                type="text"
              >
                Upload later
              </Button>
            </Col>
            <Col>
              <Button onClick={() => setDocumentModal(false)}>Cancel</Button>
              <Button
                type="primary"
                disabled={
                  (documents && Object.keys(documents).length === 0)
                  || (documents && !Object.values(documents).every((item) => item))
                }
                onClick={handleDocumentData}
              >
                Submit
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Typography.Text>To apply for this shift, you need to upload the following documents:</Typography.Text>

        <Form form={documentForm} requiredMark={false} layout="vertical" onFieldsChange={onFieldsChange}>
          {shift?.documents
            ?.filter((item) => !applicant?.documents?.map(({ documentType }) => documentType?.id)?.includes(item.id))
            ?.map(({ id, title }, i) => (
              <Form.Item label={title} name={id} rules={[{ required: true, message: 'Required file!' }]} key={id}>
                <InputFile i={i} size="large" name={id} form={documentForm} />
              </Form.Item>
            ))}
        </Form>
      </Modal>

      <Modal
        title={(
          <Space>
            <ExclamationCircleFilled style={{ color: '#FAAD14', fontSize: '16px' }} />
            Overlapping shift
          </Space>
        )}
        open={overlapModal}
        onCancel={() => setOverlapModal(false)}
        footer={[]}
        className={styles.overlapPopup}
      >
        <Typography.Text>
          Sorry, you cannot apply for this shift as it overlaps with a shift you are already scheduled to work.
        </Typography.Text>
        <div className={styles.overlaping}>
          <Space direction="vertical">
            {overlaping?.map((item) => (
              <Badge
                key={item.id}
                color={getBudgeColor(item.status)}
                text={`${getHoursBetween(item.datetimeStart, item.datetimeEnd)} ${item.client?.name}`}
              />
            ))}
          </Space>
        </div>
        <Alert
          message={(
            <span>
              Please
              {' '}
              <NavLink to="/contact-us">contact us</NavLink>
              {' '}
              if you need assistance
            </span>
          )}
          type="warning"
          showIcon
        />
      </Modal>

      <Modal
        title={(
          <Space>
            <ExclamationCircleFilled style={{ color: '#FAAD14', fontSize: '16px' }} />
            Missing Bank and ABN Information
          </Space>
        )}
        okText="Apply"
        okButtonProps={{ disabled: !isValid, loading: updateApplicant.loading }}
        open={bankModal}
        onOk={handleBankData}
        centered
        onCancel={() => setBankModal(false)}
        closable={false}
        maskClosable={false}
      >
        <Typography.Text>
          Uh oh! It looks like we don&apos;t have your bank details and ABN on file. Please add them here and press
          {' '}
          <strong> Apply </strong>
          {' '}
          to continue.
          <p>
            <strong> Note</strong>
            : You can also update your details directly on your profile page
          </p>
        </Typography.Text>

        <Form form={bankForm} requiredMark={false} layout="vertical" onFieldsChange={onFieldsChange}>
          <Form.Item name="bsb" label="BSB*" rules={[{ min: 5, message: 'Must be longer than or equal to 5 numbers' }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="accountNumber"
            label="Account number*"
            rules={[{ min: 5, message: 'Must be longer than or equal to 5 numbers' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="abn" label="ABN*" rules={[{ min: 5, message: 'Must be longer than or equal to 5 numbers' }]}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
Apply.defaultProps = {
  textBtn: 'Apply',
  showReject: false,
  disabledBtn: false,
  withRedirect: false,
  callback: () => null,
  loading: false,
};

export default Apply;
