import {
  Tabs, Typography, Row, Button, App,
} from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import List from './List';
import Loading from '../../Common/Loading';
import { useSearchParams } from '../../../hooks/useSearchParams';
import { useNotificationsCategoryGet, useNotificationsSawAll } from '../../../hooks/notification';
import { capitalize } from '../../../utils';
import { useMessageError } from '../../../hooks/common';
import { notificationAction } from '../../../store/notifications';
import styles from './index.module.scss';

export default function Notifications() {
  const category = useNotificationsCategoryGet();
  const sawAll = useNotificationsSawAll();
  const dispatch = useDispatch();
  const [activeKey, setActiveKey] = useState('');
  const { message } = App.useApp();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    category.fetch();
    const value = searchParams.get('category');

    if (value) {
      setActiveKey(value);
    }
  }, []);

  useEffect(() => {
    if (category?.data && !activeKey) {
      setActiveKey(category?.data[0]);
    }
  }, [category?.data]);

  const onChange = (key: string) => {
    setActiveKey(key);
    setSearchParams({ category: key || '' });
  };

  useEffect(() => {
    if (sawAll.data) {
      message.success('All notifications have been marked as read');
      dispatch(notificationAction());
    }
  }, [sawAll.data]);

  useMessageError([sawAll]);

  return (
    <div className={styles.inner}>
      <Row justify="space-between">
        <Typography.Title level={3}>Notifications</Typography.Title>
        <Button type="link" loading={sawAll.loading} onClick={() => sawAll.fetch()}>
          Mark all as read
        </Button>
      </Row>

      {category.loading ? (
        <div className={styles.loading}>
          <Loading />
        </div>
      ) : (
        <Tabs
          destroyInactiveTabPane
          className={styles.tabs}
          activeKey={activeKey}
          onChange={onChange}
          items={
            category?.data?.map((cat) => ({
              key: cat,
              label: capitalize(cat),
              children: <List category={cat} />,
            })) || []
          }
        />
      )}
    </div>
  );
}
