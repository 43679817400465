import React from 'react';
import { CalendarOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import RadioButton, { RadioItem } from '../../../../Common/Tabs/RadioButton';
import { useAuthState } from '../../../../../store/auth';
import { isRoleEnough, UserRoles } from '../../../../../enums/user';
import { useSimpleModal } from '../../../../Common/Modal/Simple';
import { useSearchParams } from '../../../../../hooks/useSearchParams';

import styles from './index.module.scss';

interface TitleProps {
  defaultActive: string
}

export const list: RadioItem[] = [{
  key: 'list',
  icon: <UnorderedListOutlined />,
  content: 'List view',
}, {
  key: '',
  icon: <CalendarOutlined />,
  content: 'Calendar view',
}];

export default function Title({ defaultActive }: TitleProps) {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { open, contextHolder } = useSimpleModal();
  const { user } = useAuthState();
  const { search } = useLocation();
  const navigate = useNavigate();
  const [searchParams, , paramsWithoutTableProps] = useSearchParams();
  const isOpenShiftPage = pathname.includes('/open-shifts');
  // eslint-disable-next-line no-nested-ternary
  const title = user?.role === UserRoles.APPLICANT ? (isOpenShiftPage ? 'Open Shifts' : 'My Shifts') : 'Shifts';

  return (
    <div className={styles.title}>
      <Typography.Title level={4}>
        {title}
      </Typography.Title>
      <RadioButton
        className={styles.tabs}
        defaultActive={defaultActive}
        items={list}
        onChange={(key, beforeState) => {
          if (beforeState !== key) {
            if (key === list[0].key) {
              let searchFixed = search
                .replace(/&?year=[0-9]+/, '')
                .replace('?&', '?')
                .replace(/&?month=[0-9]+/, '')
                .replace('?&', '?');

              if (searchFixed !== '?') {
                searchFixed += '&';
              }

              searchFixed += `from=${dayjs()
                .set('month', parseInt(paramsWithoutTableProps.month as string, 10) - 1)
                .set('year', parseInt(paramsWithoutTableProps.year as string, 10))
                .toISOString()}`;

              navigate(isOpenShiftPage ? `/open-shifts${searchFixed}` : `/shifts${searchFixed}`);
            }

            const calendarUrl = pathname.split('/')[1] === '' && user?.role !== UserRoles.APPLICANT
              ? `shifts/calendar${search}` : `calendar${search}`;

            if (key === list[1].key) {
              if ((searchParams.getAll('locations').length === 0
                || searchParams.getAll('clients').length === 0)
                && user?.role === UserRoles.APPLICANT
              ) {
                open({
                  title: 'Select a Location',
                  content: (
                    <>
                      Please select a
                      {' '}
                      <b>Location</b>
                      {' '}
                      and
                      {' '}
                      <b>Client</b>
                      {' '}
                      from the sidebar to view
                      {' '}
                      <b>
                        {isOpenShiftPage ? 'Open ' : ''}
                        Shifts
                      </b>
                      {' '}
                      in calendar view
                    </>
                  ),
                  cancelText: t('Cancel'),
                  okText: t('Ok'),
                  centered: true,
                  onOk: () => navigate(calendarUrl),
                });

                return false;
              }

              if (searchParams.getAll('clients').length === 0 && user?.role === UserRoles.CLIENT_ADMIN) {
                open({
                  title: 'Select a Location',
                  content: (
                    <>
                      Please select a
                      {' '}
                      <b>Location</b>
                      {' '}
                      from the sidebar to view
                      {' '}
                      <b>
                        {isOpenShiftPage ? 'Open ' : ''}
                        Shifts
                      </b>
                      {' '}
                      in calendar view
                    </>
                  ),
                  cancelText: t('Cancel'),
                  okText: t('Ok'),
                  centered: true,
                  onOk: () => navigate(calendarUrl),
                });

                return false;
              }

              if (searchParams.getAll('clients').length === 0 && isRoleEnough(user?.role, UserRoles.LOKEM_ADMIN)) {
                open({
                  title: 'Select a Сlient',
                  content: (
                    <>
                      Please select a
                      {' '}
                      <b>Client</b>
                      {' '}
                      from the sidebar to view
                      {' '}
                      <b>
                        {isOpenShiftPage ? 'Open ' : ''}
                        Shifts
                      </b>
                      {' '}
                      in calendar view.
                    </>
                  ),
                  cancelText: t('Cancel'),
                  okText: t('Ok'),
                  centered: true,
                  onOk: () => navigate(calendarUrl),
                });

                return false;
              }

              navigate(calendarUrl);
            }
          }

          return true;
        }}
      />
      {contextHolder}
    </div>
  );
}
