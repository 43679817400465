import React, { useEffect, useMemo } from 'react';
import { App, Form } from 'antd';
import { isEqual } from 'lodash';
import { FieldData } from 'rc-field-form/lib/interface';
import { useSettingsContext } from '../Context';
import {
  useExperienceCreate,
  useExperienceDelete,
  useExperiencesAllUpdate,
  useExperiencesPaginGet,
} from '../../../../hooks/experience';
import { tabList } from '../index';
import FormList from '../Common/List';
import { useMessageError } from '../../../../hooks/common';
import { useSearchParams } from '../../../../hooks/useSearchParams';
import { pagingParamSearchList } from '../../../../contstant';
import CreateModal from '../Common/CreateModal';

export default function Experiences() {
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const {
    setForm, setLoading, setChanging, setValid, isChanging, activeTab,
  } = useSettingsContext();
  const [searchParams] = useSearchParams();
  const experiences = useExperiencesPaginGet();
  const experiencesAllUpdate = useExperiencesAllUpdate();
  const experienceDelete = useExperienceDelete();
  const experienceCreate = useExperienceCreate();
  const experienceData = useMemo(() => {
    if (experiences.data) {
      return {
        experiences: experiences.data.data,
      };
    }

    return {
      experiences: [],
    };
  }, [experiences.data]);

  useEffect(() => fetch(), [searchParams]);

  const fetch = () => {
    const props = {};

    pagingParamSearchList.forEach((key) => {
      const value = searchParams.getAll(key);

      if (value.length || !(value.length === 1 && value[0] === '')) {
        // @ts-ignore @typescript-eslint/ban-ts-comment
        props[key] = value.length === 1 ? value[0] : value;
      }
    });

    experiences.fetch(props);
  };

  useEffect(() => {
    if (!isChanging) {
      form.setFieldsValue(experienceData);
    }
  }, [experienceData, isChanging]);

  useEffect(() => {
    setForm((state) => ({
      ...state,
      [tabList[0].key]: {
        form,
        update: experiencesAllUpdate,
      },
    }));
  }, []);

  useEffect(() => {
    if (experiencesAllUpdate.data) {
      message.success('Changes saved successfully!');
      setLoading(false);
      setChanging(false);
      fetch();
    }
  }, [experiencesAllUpdate.data]);

  useEffect(() => {
    if (experiencesAllUpdate.error) {
      setLoading(false);
    }
  }, [experiencesAllUpdate.error]);

  const onFieldsChange = (_: FieldData[], allFields: FieldData[]) => {
    if (allFields.every((field) => field?.errors?.length === 0)) {
      setValid(true);
    } else {
      setValid(false);
    }

    const values = allFields.find((item) => item.name.length === 1 && item.name[0] === 'experiences')?.value;

    if (!isEqual(values, experienceData?.experiences)) {
      setChanging(true);
    } else {
      setChanging(false);
    }
  };

  useMessageError([experiencesAllUpdate, experiences]);

  return (
    <Form
      form={form}
      onFieldsChange={onFieldsChange}
      name="dynamic_form_experience"
      autoComplete="off"
      initialValues={experienceData}
    >
      <FormList
        deleteAction={experienceDelete}
        removeTitle="Disable experience"
        nameButton="Add experience"
        removeButtonText="Disable anyway"
        name="experiences"
        valueName="title"
        placeholder="Some experience"
        fetch={fetch}
        items={experiences?.data}
        loading={experiences.loading}
      />
      {activeTab === 'experiences' && (
        <CreateModal
          createAction={experienceCreate}
          fetch={fetch}
          nameButton="Add experience"
          valueName="title"
          nameLabel="Experience name"
          showPriority
        />
      )}
    </Form>
  );
}
