import React from 'react';
import Layout from '../../../components/Layout';
import ContentApplicantShiftList from '../../../components/Pages/Shift/List';
import ContentShiftListCollapse from '../../../components/Pages/Shift/List/Collapse';
import Filter from '../../../components/Pages/Shift/Filter';
import { useAuthState } from '../../../store/auth';
import { UserRoles } from '../../../enums/user';

const roleList: { [key: string]: React.ReactNode } = {
  [UserRoles.APPLICANT]: <ContentApplicantShiftList />,
  [UserRoles.CLIENT_ADMIN]: <ContentShiftListCollapse />,
  [UserRoles.MANAGER]: <ContentShiftListCollapse />,
  [UserRoles.LOKEM_ADMIN]: <ContentShiftListCollapse />,
  [UserRoles.ROOT]: <ContentShiftListCollapse />,
};

export default function ShiftList(): React.ReactNode {
  const { user } = useAuthState();

  document.title = `${process.env.REACT_APP_TITLE_NAME}: `
    + `${user?.role === UserRoles.APPLICANT ? 'My shift list' : 'Shift list'}`;

  return (
    <Layout minWidth leftMenu={<Filter />}>
      {roleList[user?.role as string]}
    </Layout>
  );
}
