import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Detail from './Common/Detail';
import { TabPaneItem } from '../../Common/Tabs';
import ClientForm from './Form';
import ClientUserList from './User/List';
import { useClientId, useClientUpdate } from '../../../hooks/client';
import ClientProvider, { useClientContext } from './Context';

export const tabList: TabPaneItem[] = [
  {
    label: 'General info',
    key: 'general',
    children: <ClientForm />,
  },
  {
    label: 'List of users',
    key: 'users',
    children: <ClientUserList />,
  },
];

export default function Client(): React.ReactNode {
  const client = useClientId();
  const { clientId } = useParams();
  const clientUpdate = useClientUpdate(clientId);

  useEffect(() => {
    client.fetch(undefined, clientId);
  }, [clientId]);

  return (
    <ClientProvider defaultTab={tabList[0].key} action={clientUpdate} resource={client}>
      <Detail arrowBack="/clients" tabList={tabList} name={client.data?.name} useContext={useClientContext} />
    </ClientProvider>
  );
}
