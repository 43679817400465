import React, { useEffect, useState } from 'react';
import { Layout as AntdLayout, Button, theme } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';
import { NavLink, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { userMe } from '@triare/auth-redux/dist/saga/auth/useMe';
import Menu, { paramsToString } from './Menu';
import { Logo, LogoMini } from '../Common/Icon';
import { useAuthState } from '../../store/auth';
import { UserRoles } from '../../enums/user';
import { defaultStatuses } from '../../enums/shift';
import { generateDefaultUrl } from '../../routes';
import ReduxRedirect from '../Common/ReduxRedirect';

import styles from './index.module.scss';

const { Header, Sider, Content } = AntdLayout;

interface LayoutProps {
  LeftMenu?: React.FC;
  leftMenu?: React.ReactNode;
  transparent?: boolean;
  disableLeftMenu?: boolean;
  children: React.ReactNode;
  colapsedByDefault?: boolean;
  minWidth?: boolean;
}

let defaultStateCollapsed = false;

function Layout({
  disableLeftMenu,
  LeftMenu,
  leftMenu,
  transparent,
  children,
  colapsedByDefault,
  minWidth,
}: LayoutProps): React.ReactNode {
  const { user, userMe: userMeState } = useAuthState();
  const [collapsed, setCollapsed] = useState(defaultStateCollapsed);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const dispatch = useDispatch();
  const location = useLocation();
  const isTablet = useMediaQuery({
    query: '(max-width: 1024px)',
  });

  useEffect(() => {
    if (!userMeState.loading) {
      dispatch(userMe());
    }
  }, [location.pathname]);

  useEffect(() => {
    defaultStateCollapsed = collapsed;
  }, [collapsed]);

  useEffect(() => {
    if ((isTablet && !disableLeftMenu) || colapsedByDefault) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [isTablet, disableLeftMenu]);

  const urlParams = user?.role === UserRoles.APPLICANT ? undefined : { statuses: defaultStatuses };

  return (
    <>
      <ReduxRedirect />
      <AntdLayout hasSider className={styles.layout}>
        {disableLeftMenu ? null : (
          <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            className={clsx(styles.slider, { [styles.absolute]: isTablet && !disableLeftMenu })}
            width={224}
            collapsedWidth={48}
          >
            <NavLink
              to={`/${generateDefaultUrl(user)}`}
              className={clsx(styles.logo, { [styles.collapsed]: collapsed })}
            >
              {collapsed
                && (user?.logo ? (
                  <img src={user.logoMini} className={styles.logoSvg} alt="client logo" />
                ) : (
                  <LogoMini className={styles.logoSvg} />
                ))}

              {!collapsed
                && (user?.logo ? (
                  <img src={user.logo} className={styles.logoSvg} alt="client logo" />
                ) : (
                  <Logo className={styles.logoSvg} />
                ))}
            </NavLink>

            <div className={clsx(styles.filter, { [styles.collapsed]: collapsed })}>
              {LeftMenu ? <LeftMenu /> : leftMenu}
            </div>

            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              className={clsx(styles.btn, { [styles.collapsed]: collapsed })}
            />
          </Sider>
        )}
        <AntdLayout className={clsx(styles.wrapper, { [styles.tablet]: isTablet && !disableLeftMenu })}>
          <Header
            style={{ background: colorBgContainer }}
            className={clsx(styles.header, { [styles.headerLogo]: disableLeftMenu })}
          >
            {disableLeftMenu ? (
              <NavLink
                to={`/${paramsToString(user, urlParams)}`}
                className={clsx(styles.logo, { [styles.tablet]: isTablet })}
              >
                {collapsed
                  && (user?.logo ? (
                    <img src={user.logoMini} className={styles.logoSvg} alt="client logo" />
                  ) : (
                    <LogoMini className={styles.logoSvg} />
                  ))}

                {!collapsed
                  && (user?.logo ? (
                    <img src={user.logo} className={styles.logoSvg} alt="client logo" />
                  ) : (
                    <Logo className={styles.logoSvg} />
                  ))}
              </NavLink>
            ) : null}

            <Menu />
          </Header>
          <div className={clsx(styles.scroll, { [styles.collapsed]: collapsed })}>
            <Content
              className={clsx(
                styles.content,
                { [styles.transparent]: transparent },
                { [styles.minWidth]: isTablet && minWidth },
              )}
              id="layout-content"
            >
              {children}
            </Content>
          </div>
        </AntdLayout>
      </AntdLayout>
    </>
  );
}

Layout.defaultProps = {
  disableLeftMenu: false,
  colapsedByDefault: false,
  transparent: undefined,
  LeftMenu: undefined,
  leftMenu: undefined,
  minWidth: undefined,
};

export default Layout;
