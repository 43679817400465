import {
  DefaultFetchError,
  FetchGet,
  useFetchGet,
  PagingDataResponse,
  PagingParams,
} from './fetch';
import { pagingParamList } from '../contstant';
import { Faq } from '../types/faq';

export interface faqArticlesGetParams extends PagingParams {
  title?: string;
  category?: string;
}

export const faqArticlesGetParamList = [
  'category', ...pagingParamList,
];

export function useFaqArticlesGet<D = PagingDataResponse<Faq>,
  DD = D>(decorateData?: (data: D) => DD): FetchGet<D, faqArticlesGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, faqArticlesGetParams, DD>(
    'faq-articles',
    {
      decorateData,
      autoStart: false,
      startStateLoading: false,
    },
  );
}
