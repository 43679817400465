import { Link } from 'react-router-dom';
import {
  Space, Typography, Badge, Divider,
} from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useAuthState } from '../../../../../store/auth';
import { useSettingsContext } from '../../Context';
import StatusAction from '../../../../Common/Applicant/StatusAction';
import { getBudgeColor } from '../../../../Common/Badge';

import styles from './index.module.scss';

export default function TopBar() {
  const { applicant, fetch } = useSettingsContext();
  const { user } = useAuthState();
  const clientId = user?.locationAdmin?.client.id || user?.manager?.client.id;

  return (
    applicant && (
      <Space className={styles.main}>
        <Space>
          <Link to="/applicants">
            <ArrowLeftOutlined style={{ color: '#8C8C8C' }} />
          </Link>
          <Typography.Title level={4} style={{ margin: 0 }}>
            {`${applicant?.title} ${applicant?.user.firstName} ${applicant?.user.lastName}`}
          </Typography.Title>
          <Badge
            className={styles.badge}
            style={{ textTransform: 'capitalize' }}
            color={getBudgeColor(applicant.user?.status)}
            text={applicant?.user?.status}
          />
          <Divider type="vertical" className={styles.divider} />

          {applicant?.isVerified && clientId && (
            <>
              <StatusAction applicant={applicant} clientId={clientId} listName="preferred" fetch={fetch} />
              <StatusAction applicant={applicant} clientId={clientId} listName="excluded" fetch={fetch} />
            </>
          )}
        </Space>
      </Space>
    )
  );
}
