import { RefreshResponse } from '@triare/auth-redux';
import {
  DefaultFetchError,
  FetchCreate,
  useFetchCreate,
} from './fetch';

export interface SignUpFinishSuccess extends RefreshResponse {
  applicant: {
    id: string
    userId: string
  }
}

export interface SignUpFinishCreateParams {
  isVerified: boolean
  bsb: number
  accountNumber: number
  abn: number
  title: string
  ahpra: number
  ahpraLicenseDate: Date
  isGeneral: boolean
  indemnityNumber: number
  indemnityProvider: string
  experiences: string[]
  documents: {
    documentType: string
    file: string
  }[]
  user: string
}

export const useSignUpFinishCreate = (): FetchCreate<
  SignUpFinishSuccess,
  DefaultFetchError,
  SignUpFinishCreateParams
> => (
  useFetchCreate('auth/sign-up-finalize')
);
