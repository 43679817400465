import { useState, useEffect } from 'react';
import { Button, Space } from 'antd';
import type { CollapseProps } from 'antd';
import TopPanel from '../../../../Common/TopPanel';
import { useClientsSelectLocationsGet } from '../../../../../hooks/client';
import Loading from '../../../../Common/Loading';
import NestedDomains from '../NestedDomains';
import CreateLocation from '../CreateLocation';
import Collapse from '../../../../Common/Collapse';
import { useSearchParams } from '../../../../../hooks/useSearchParams';
import { useMessageError } from '../../../../../hooks/common';
import NoData from '../../../../Common/NoData';
import Counter from '../../../../Common/Counter';

import styles from './index.module.scss';

export default function LocationList() {
  const clientsSelectLocationsGet = useClientsSelectLocationsGet();
  const [clientId, setClientId] = useState<string | undefined>(undefined);
  const [locationsModal, setLocationsModal] = useState(false);
  const [updateLocations, setUpdateLocations] = useState(false);
  const [updateDomains, setUpdateDomains] = useState(false);
  const [,, paramsWithoutTableProps] = useSearchParams();

  useEffect(() => {
    clientsSelectLocationsGet.fetch(paramsWithoutTableProps);
  }, [paramsWithoutTableProps]);

  useMessageError([clientsSelectLocationsGet]);

  const items: CollapseProps['items'] = clientsSelectLocationsGet?.data?.map((item) => ({
    key: item.id,
    label: (
      <Space>
        {item.name}
        <Counter count={item?.locationsCount || 0} />
      </Space>
    ),
    extra: (
      <Button
        type="primary"
        ghost
        className={styles.btn}
        onClick={(event) => {
          event.stopPropagation();
          setLocationsModal(true);
          setClientId(item.id);
        }}
      >
        Add location
      </Button>
    ),
    children: (
      <NestedDomains
        clientId={item.id}
        updateLocation={updateLocations}
        setUpdateLocation={setUpdateLocations}
        updateDomains={updateDomains}
        setUpdateDomains={setUpdateDomains}
      />
    ),
  }));

  return (
    <div className={styles.wrapper}>
      <TopPanel padding title="Locations" rightContent={null} />
      {clientsSelectLocationsGet.loading ? (
        <div className={styles.loading}>
          <Loading />
        </div>
      ) : (
        <>
          <CreateLocation
            locationsModal={locationsModal}
            setLocationsModal={setLocationsModal}
            clientId={clientId}
            setUpdateLocation={setUpdateLocations}
            setUpdateDomains={setUpdateDomains}
          />
          {items?.length ? <Collapse items={items} /> : <NoData name="locations" />}
        </>
      )}
    </div>
  );
}
