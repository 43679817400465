import React, { useEffect } from 'react';
import { Row, Col } from 'antd';
import Calendar from '../../Common/Calendar';
import Earnings from '../../Common/Earnings';
import Notifications from '../../Common/Notifications';
import { useShiftEarnings } from '../../../hooks/shift';
import Loading from '../../Common/Loading';

import styles from './index.module.scss';

export default function Home(): React.ReactNode | null {
  const earnings = useShiftEarnings();

  useEffect(() => {
    earnings.fetch();
  }, []);

  return (
    <div className={styles.content}>
      <Row gutter={16}>
        <Col span={7}>
          {earnings.loading || !earnings.data ? (
            <div className={styles.loading}>
              <Loading />
            </div>
          ) : (
            <>
              <Earnings
                earning={earnings.data?.lastMonth?.toLocaleString('en-US')}
                title="Last Month’s Earnings"
                marginBottom
              />
              <Earnings
                earning={earnings.data?.projected?.toLocaleString('en-US')}
                title="This Month’s Projected Earnings"
              />
            </>
          )}
        </Col>
        <Col span={17}>
          <Notifications />
        </Col>
      </Row>

      <Calendar name="Upcoming shifts" />
    </div>
  );
}
