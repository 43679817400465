import React from 'react';
import Layout from '../../components/Layout';
import ContentSetting from '../../components/Pages/Setting';

export default function Settings(): React.ReactNode {
  document.title = `${process.env.REACT_APP_TITLE_NAME}: Settings`;

  return (
    <Layout transparent disableLeftMenu>
      <ContentSetting />
    </Layout>
  );
}
