import { Space, Card, Typography } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { Induction } from '../../../../hooks/inductions';
import styles from './index.module.scss';

interface InductionsProps {
  inductions: Induction[];
  checked?: boolean;
}

function Inductions({ inductions, checked }: InductionsProps) {
  return (
    <Space className={styles.wrapper} direction="vertical">
      <Typography.Text strong>Completed inductions</Typography.Text>
      <Card title="  " className={styles.card} size="small">
        {inductions?.map((item) => (
          <p key={item.id} className={styles.item}>
            {checked && <CheckOutlined className={styles.icon} />}
            {item.title}
          </p>
        ))}
      </Card>
    </Space>
  );
}
Inductions.defaultProps = {
  checked: true,
};

export default Inductions;
