import React from 'react';
import Simple from '../../../components/Layout/Simple';
import ContentResetPassword from '../../../components/Pages/ResetPassword/index';

export default function ResetPassword(): React.ReactNode {
  document.title = `${process.env.REACT_APP_TITLE_NAME}: New Password`;

  return (
    <Simple>
      <ContentResetPassword />
    </Simple>
  );
}
