export enum EmploymentType {
    CONTRACTOR = 'contractor',
    CASUAL = 'casual',
    PART_TIME = 'part-time',
    FULL_TIME = 'full-time',
  }

export const contractedEmployment = [
  EmploymentType.PART_TIME,
  EmploymentType.FULL_TIME,
];

export default EmploymentType;
