import React from 'react';
import Layout from '../../components/Layout';
import FaqPage from '../../components/Pages/Faq';

export default function Faq(): React.ReactNode {
  document.title = `${process.env.REACT_APP_TITLE_NAME}: Frequently Asked Questions`;

  return (
    <Layout disableLeftMenu>
      <FaqPage />
    </Layout>
  );
}
