import Layout from '../../../components/Layout';
import Filter from '../../../components/Pages/Guest/Filters';
import ContentGuestList from '../../../components/Pages/Guest/List';

export default function GuestList(): React.ReactNode {
  document.title = `${process.env.REACT_APP_TITLE_NAME}: Guest list`;

  return (
    <Layout minWidth leftMenu={<Filter />}>
      <ContentGuestList />
    </Layout>
  );
}
