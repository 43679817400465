import React, { useEffect, useRef } from 'react';
import {
  Alert, Button, Col, Form, Input, Row, Select,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { SignUpPageDefaultProp } from '../../types';
import { useContextSignUp } from '../../Context';
import { useIndemnitiesGet } from '../../../../../hooks/indemnity';
import { useAuthState } from '../../../../../store/auth';
import { getMessageInError } from '../../../../../hooks/fetch';
import { useMessageError } from '../../../../../hooks/common';
import { useCheckIndemnityNumber } from '../../../../../hooks/auth';

interface FormValues {
  name: string;
}

type IndemnityProps = SignUpPageDefaultProp;

export default function Indemnity({ name, className }: IndemnityProps): React.ReactNode | null {
  const timeout = useRef<NodeJS.Timeout | undefined>();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const indemnities = useIndemnitiesGet();
  const { authorized } = useAuthState();
  const {
    setForm, onFinish, triggerBack, isPossibleTriggerBack,
  } = useContextSignUp();

  const indemnityNumber = Form.useWatch('indemnityNumber', form);
  const checkIndemnityNumber = useCheckIndemnityNumber();

  useEffect(() => {
    if (indemnityNumber && indemnityNumber.toString().length > 4 && indemnityNumber.toString().length < 31) {
      checkIndemnityNumber.fetch({
        indemnityNumber,
      });

      if (timeout.current) {
        clearTimeout(timeout.current);
      }

      timeout.current = setTimeout(() => {
        form.setFields([
          {
            name: 'indemnityNumber',
            errors: ['Number check. Loading...'],
          },
        ]);
      }, 5);
    }

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [form, indemnityNumber]);

  useEffect(() => {
    if (checkIndemnityNumber.error) {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }

      timeout.current = setTimeout(() => {
        form.setFields([
          {
            name: 'indemnityNumber',
            errors: [getMessageInError(checkIndemnityNumber.error)],
          },
        ]);
      }, 5);
    }
  }, [form, checkIndemnityNumber.error]);

  useEffect(() => {
    if (checkIndemnityNumber.data) {
      if (checkIndemnityNumber.data?.unique === true) {
        if (timeout.current) {
          clearTimeout(timeout.current);
        }

        timeout.current = setTimeout(() => {
          form.setFields([
            {
              name: 'indemnityNumber',
              errors: [],
            },
          ]);
          form
            .validateFields()
            .then(() => null)
            .catch(() => null);
        }, 5);
      }

      if (checkIndemnityNumber.data?.unique === false) {
        if (timeout.current) {
          clearTimeout(timeout.current);
        }

        timeout.current = setTimeout(() => {
          form.setFields([
            {
              name: 'indemnityNumber',
              errors: ['This indemnity number is already in use'],
            },
          ]);
        }, 5);
      }
    }
  }, [form, checkIndemnityNumber.data]);

  useMessageError([checkIndemnityNumber]);

  useEffect(() => {
    if (authorized) {
      indemnities.fetch();
    }
  }, [authorized]);

  useEffect(() => {
    setForm(name, form);
  }, [name, form]);

  const onFinishHandler = (values: FormValues) => {
    onFinish(name, values);
  };

  return (
    <Form<FormValues>
      form={form}
      className={className}
      name={name}
      autoComplete="off"
      layout="vertical"
      onFinish={onFinishHandler}
    >
      <h2>Medical Indemnity</h2>
      <span>
        NOTE: It is optional to include indemnity information during registration, however you will
        <br />
        need to provide it before shifts you apply for can be confirmed. You can add this information
        <br />
        on your profile settings page.
      </span>
      <Row gutter={24}>
        <Col span={24}>
          <span>{`${t('Medical Indemnity Provider')}`}</span>
          <Form.Item name="indemnityProvider">
            <Select
              loading={indemnities.loading}
              options={(indemnities.data || []).map(({ id, title }) => ({
                value: id,
                label: title,
              }))}
              placeholder="Medical Indemnity Provider"
              size="large"
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <span>{`${t('Indemnity Policy Number')}`}</span>
          <Form.Item
            name="indemnityNumber"
            rules={[
              { min: 5, message: 'Indemnity number should contain at least 5 characters' },
              { max: 30, message: 'Indemnity Number must be shorter than or equal to 30 characters' },
            ]}
          >
            <Input
              placeholder="Indemnity Policy Number"
              size="large"
              readOnly
              onFocus={(e) => e.currentTarget.removeAttribute('readonly')}
            />
          </Form.Item>
        </Col>
      </Row>

      {indemnities.error ? (
        <Alert type="error" message={getMessageInError(indemnities.error)} closable onClose={indemnities.clearError} />
      ) : null}

      <div>
        {isPossibleTriggerBack() ? (
          <Button onClick={triggerBack} size="large">
            Go Back
          </Button>
        ) : null}
        <Form.Item shouldUpdate className="submit">
          {() => {
            const fieldsValue = form.getFieldsValue();

            return (
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                // loading={loading}
                disabled={
                  !form.isFieldsTouched(true)
                  || form.getFieldsError().filter(({ errors }) => errors.length).length > 0
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  || Object.keys(fieldsValue).some((key) => !fieldsValue[key])
                }
              >
                Save and Continue
              </Button>
            );
          }}
        </Form.Item>
      </div>
    </Form>
  );
}
