import { InfoCircleTwoTone } from '@ant-design/icons';
import {
  Col, Form, FormInstance, InputNumber, Select,
} from 'antd';
import { COLOR_PRIMARY } from '../../../../../AntdConfigProvider';

export interface BreaksProps {
form: FormInstance
}

export const intervalBreaks = [0, 10, 15, 30, 60];

export default function Breaks({ form }: BreaksProps) {
  const values = Form.useWatch([], form);

  return (
    <>
      <Col span={12}>
        <Form.Item
          name="lunchBreak"
          label="Breaks"
          tooltip={{
            title: 'Break time is deducted from the total for hourly shifts',
            icon: <InfoCircleTwoTone style={{ cursor: 'help' }} twoToneColor={COLOR_PRIMARY} />,
          }}
        >
          <Select
            placeholder="Select"
            style={{ width: '100%' }}
            allowClear
            options={[
              { label: '0 min', value: 0 },
              { label: '10 min', value: 10 },
              { label: '15 min', value: 15 },
              { label: '30 min', value: 30 },
              { label: '60 min', value: 60 },
              { label: 'Custom', value: 'custom' },
            ]}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        {(values && values?.lunchBreak && !intervalBreaks.includes(values.lunchBreak))
          ? (
            <Form.Item
              name="customLunchBreak"
              label=" "
              rules={[{ required: true, message: 'Required field!' }]}
            >
              <InputNumber addonAfter="min" min={1} max={60} />
            </Form.Item>
          ) : null}
      </Col>
    </>
  );
}
