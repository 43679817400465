import React from 'react';
import { Typography } from 'antd';
import { NavLink } from 'react-router-dom';
import styles from './index.module.scss';

const { Title, Paragraph } = Typography;

export default function Privacy(): React.ReactNode {
  return (
    <div className={styles.wrapper}>
      <Title level={3}>Privacy Policy</Title>
      <Paragraph>Effective Date: October 2023</Paragraph>

      <Title level={5}>Introduction</Title>
      <Paragraph>
        Lokem (&quot;we,&quot; &quot;our,&quot; or &quot;us&quot;) is committed to protecting the privacy and security
        of your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your
        personal data when you use our staffing services, including our software-as-a-service (SaaS) platform. This
        policy applies to all users of our services, including applicants seeking shifts and clients seeking staffing
        solutions.
      </Paragraph>
      <Paragraph>
        Please take a moment to read this Privacy Policy carefully to understand how we handle your personal
        information.
      </Paragraph>

      <Title level={5}>Information We Collect</Title>
      <Paragraph>
        <Paragraph>We may collect and process the following types of personal information:</Paragraph>
        <Paragraph>2.1. Applicant Data:</Paragraph>
        <ul>
          <li>Contact information (e.g., name, email address, phone number).</li>
          <li>Employment history, qualifications, and work experience.</li>
          <li>Availability, shift preferences, and work schedule.</li>
        </ul>
        <Paragraph>2.2. Client Data:</Paragraph>
        <ul>
          <li>Contact information of the client&apos;s representatives (e.g., name, email address, phone number).</li>
          <li>Information about staffing requirements and preferences</li>
          <li>Feedback and evaluations related to the applicants provided.</li>
        </ul>
        <Paragraph>2.3. Usage Data:</Paragraph>
        <ul>
          <li>Information about how you interact with our platform and services.</li>
          <li>Log files, IP addresses, browser type, and operating system.</li>
        </ul>
      </Paragraph>

      <Title level={5}>How We Use Your Information</Title>
      <Paragraph>
        <Paragraph>We use your personal information for the following purposes:</Paragraph>
        <Paragraph>3.1. Matching Applicants with Shifts:</Paragraph>
        <ul>
          <li>
            To facilitate the matching of applicants with relevant shifts based on their qualifications, availability,
            and preferences.
          </li>
        </ul>
        <Paragraph>3.2. Communication:</Paragraph>
        <ul>
          <li>To communicate with you regarding your application, shifts, and other relevant updates.</li>
          <li>To respond to inquiries and provide customer support.</li>
        </ul>
        <Paragraph>3.3. Improving Our Services:</Paragraph>
        <ul>
          <li>To analyze and improve the effectiveness of our staffing services.</li>
          <li>To customize and enhance your user experience.</li>
        </ul>
        <Paragraph>3.4. Legal Compliance:</Paragraph>
        <ul>
          <li>To comply with legal obligations, such as record-keeping and reporting.</li>
        </ul>
      </Paragraph>
      <Title level={5}>How We Share Your Information</Title>
      <Paragraph>
        <Paragraph>We may share your personal information with the following parties:</Paragraph>
        <Paragraph>4.1. Clients:</Paragraph>
        <ul>
          <li>
            Your applicant data may be shared with our clients who have staffing requirements matching your
            qualifications and availability.
          </li>
        </ul>
        <Paragraph>4.2. Service Providers:</Paragraph>
        <ul>
          <li>
            We may engage third-party service providers to assist with the provision of our services, including data
            storage, hosting, and analytics.
          </li>
        </ul>
        <Paragraph>4.3. Legal Requirements:</Paragraph>
        <ul>
          <li>We may disclose your information when required by law, court order, or other legal processes.</li>
        </ul>
      </Paragraph>

      <Title level={5}>Data Security</Title>
      <Paragraph>
        We take reasonable steps to protect your personal information from unauthorized access, use, or disclosure. We
        employ industry-standard security measures to safeguard your data.
      </Paragraph>

      <Title level={5}>Your Rights</Title>
      <Paragraph>
        You have certain rights regarding your personal data, including the right to access, correct, delete, or
        restrict its use. If you have any questions or requests concerning your data, please contact us using the
        information provided below.
      </Paragraph>

      <Title level={5}>Changes to this Privacy Policy</Title>
      <Paragraph>
        We may update this Privacy Policy from time to time to reflect changes in our practices or for legal reasons.
        Any updates will be posted on our website, and the effective date will be revised accordingly.
      </Paragraph>

      <Title level={5}>Contact Us</Title>
      <Paragraph>
        If you have any questions, concerns, or requests regarding this Privacy Policy or our data handling practices,
        please contact us at
        {' '}
        <NavLink to="mailto:support@lokem.com.au"> support@lokem.com.au </NavLink>
      </Paragraph>
      <Paragraph>By using our services, you consent to the terms outlined in this Privacy Policy.</Paragraph>
    </div>
  );
}
