/* eslint-disable @typescript-eslint/no-unused-vars, no-param-reassign */

import {
  config,
  State as AuthState,
  setActions,
  saga as authSaga,
  updateConfig,
  getLocalState,
  Action,
  reducers,
  FETCH_ME_AFTER_SUCCESS_AUTHORIZATION,
  getState,
  AnyObject,
  PayloadAction,
  UrlObject,
  createUrl,
  defaultProcessingSaga,
} from '@triare/auth-redux';
import { createSlice } from '@reduxjs/toolkit';
import {
  spawn, delay, takeLatest, take, put, call,
} from 'redux-saga/effects';
import { EnhancedStore } from '@reduxjs/toolkit/src/configureStore';
import { useSelector } from 'react-redux';
import { OTPResponse } from '@triare/auth-redux/dist/saga/auth/OTP';
import { SignInAction } from '@triare/auth-redux/dist/saga/auth/signIn';
import { signOut } from '@triare/auth-redux/dist/saga/auth/signOut';
import { RootState } from '../index';
import { UserRoles, UserStatus } from '../../enums/user';
import { generateDefaultUrl } from '../../routes';
import {
  notificationRequestSaga,
  auth as notifications,
} from '../notifications/index';

export * from '@triare/auth-redux';

export default updateConfig({
  fetchDelay: parseInt(process.env.REACT_APP_FETCH_DELAY || '0', 10),
  api: {
    url: process.env.REACT_APP_API,
  },
  userMe: {
    url: `users/me?timezone=${new Date().getTimezoneOffset().toString()}`,
  },
  signIn: {
    ...config.signIn,
    requestBody: ({ email, password }: SignInAction) => JSON.stringify({ email, password }),
    fetchMeAfterSuccess: FETCH_ME_AFTER_SUCCESS_AUTHORIZATION,
  },
  OTP: {
    ...config.OTP,
    fetchMeAfterSuccess: false,
  },
  signOut: {
    ...config.signOut,
    inactivity: {
      ...config.signOut.inactivity,
      interval: 60 * 1000, // 1 minutes
      lifetime: 60 * 60 * 1000, // 1 hour
    },
  },

  createUrl: (
    url: string | UrlObject,
    addToUrl?: string,
    payload?: PayloadAction,
    data?: AnyObject,
    _query?: AnyObject,
  ) => createUrl(url, addToUrl, payload, data, {
    ..._query,
    // lang: 'en',
  }),
});

export const moduleName = config.auth.name;

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  status: string;
  phone: string;
  role: string;
  logo: string;
  logoMini: string;
  receiveEmails: boolean;
  receiveUserEmails: boolean;
  [key: string]: any;
  settings: {
    isEmailVerified: boolean;
    isPhoneVerified: boolean;
  };
  applicant?: {
    id: string;
  };
  manager?: {
    id: string;
    client: {
      id: string;
    };
  };
  locationAdmin?: {
    id: string;
    isActive: boolean;
    client: {
      id: string;
    };
  };
}

export interface State extends AuthState {
  user: User | null;
  secretKey: string;

  redirect: AnyObject | null;
}

export function useAuthState(): State {
  return useSelector((state: RootState) => state[moduleName]);
}

export const auth = createSlice({
  name: moduleName,
  initialState: getLocalState<State>({
    redirect: null,
  }),
  reducers: {
    ...reducers,
    signInSuccess: (state, { payload }) => {
      state.signIn.loading = false;
      state.signIn.response = payload || null;
      state.authorized = !!(payload.access && payload.refresh);

      Object.assign(state, payload);

      if (payload?.remember !== undefined) {
        state.remember = payload?.remember;
      }
    },
    signUpFinish: (state, { payload }) => {
      state.signIn.loading = false;
      state.signIn.response = payload || null;
      state.authorized = true;

      Object.assign(state, payload);

      if (payload?.remember !== undefined) {
        state.remember = payload?.remember;
      }
    },
    triggerRedirect: (state, { payload }) => {
      state.redirect = payload;
    },
  },
});

export const { reducer, actions } = auth;

setActions(actions);

export function* saga(store: EnhancedStore) {
  yield spawn(authSaga, store);

  yield takeLatest(
    actions.OTPSuccess.toString(),
    function* trigger({ payload }: Action<OTPResponse>) {
      yield put({
        type: actions.signInSuccess.type,
        payload,
      });
    },
  );

  yield takeLatest(actions.signInSuccess.type, function* trigger() {
    const { payload: user } = yield take(actions.userMeSuccess.type);
    const params = `/${generateDefaultUrl(user)}`;
    const path = window.location.pathname;

    if (path === '/') {
      yield put({
        type: actions.triggerRedirect.type,
        payload: {
          to: params,
          options: {
            replace: true,
          },
        },
      });
    }
  });

  yield takeLatest(actions.userMeError.type, function* trigger() {
    yield put(signOut());
    yield delay(100);
    window.location.href = '/';
  });

  yield takeLatest(actions.userMeSuccess.type, function* trigger() {
    const authData = (yield getState()) as State;
    const status = authData?.userMe?.response?.status;
    const path = window.location.pathname;

    if (status === UserStatus.DEACTIVATED || status === UserStatus.REJECTED) {
      yield put(signOut());
      yield delay(100);
      window.location.href = '/';
    }

    if (
      authData?.userMe?.response?.role === UserRoles.GUEST
      && path !== '/sign-up'
      && path !== '/privacy'
    ) {
      yield put(signOut());
      yield delay(100);
      window.location.href = '/';
    }

    yield call(notificationRequestSaga);
  });
}
