import React from 'react';
import Layout from '../../../components/Layout';
import PageProfile from '../../../components/Pages/Profile/Client';

export default function Profile(): React.ReactNode {
  document.title = `${process.env.REACT_APP_TITLE_NAME}: Profile`;

  return (
    <Layout transparent disableLeftMenu>
      <PageProfile />
    </Layout>
  );
}
