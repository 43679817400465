import { Modal } from 'antd';
import { useLocationContext } from '../../Context';

type SaveModalProps = {
  isModalOpen: boolean;
  setModalOpen: (isModalOpen: boolean) => void;
};
export default function SaveModal({ isModalOpen, setModalOpen }: SaveModalProps) {
  const {
    onSave, loading, setEdit, setChanging,
  } = useLocationContext();

  const handleOk = () => {
    if (onSave) {
      onSave();
    }
    setModalOpen(false);
  };

  const handleCancel = () => {
    setEdit(() => false);
    setChanging(() => false);
    setModalOpen(false);
  };

  return (
    <Modal
      title="Unsaved changes"
      open={isModalOpen}
      okText="Save changes"
      okButtonProps={{ loading }}
      onOk={handleOk}
      onCancel={handleCancel}
      closable={false}
      maskClosable={false}
      centered
    >
      You need to cancel or save your changes.
    </Modal>
  );
}
