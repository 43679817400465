import { DefaultFetchError, FetchGet, useFetchGet } from './fetch';
import { FaqCategory } from '../types/faq';

export interface faqArticlesGetParams {
  title?: string;
}

export function useFaqCategoriesGet<D = FaqCategory[], DD = D>(
  decorateData?: (data: D) => DD,
): FetchGet<D, faqArticlesGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, faqArticlesGetParams, DD>('faq-categories', {
    decorateData,
    autoStart: false,
    startStateLoading: false,
  });
}
