import { useEffect, useState } from 'react';
import { Form, Alert, App } from 'antd';
import { FieldData } from 'rc-field-form/lib/interface';
import { useNavigate, useParams } from 'react-router-dom';
import { AnyObject } from '@triare/auth-redux';
import Fields from './Fields';
import { useClientUserContext } from '../Context';
import { getMessageInError } from '../../../../../hooks/fetch';
import { User } from '../../../../../hooks/user';
import { UserRoles } from '../../../../../enums/user';
import { useLocationsActiveGet } from '../../../../../hooks/location';

import styles from './index.module.scss';

export interface FieldsType {
  name: string;
  phone: string;
  email: string;
  role?: string;
  location: string[];
}

export const initialValues: FieldsType = {
  name: '',
  phone: '',
  email: '',
  role: undefined,
  location: [],
};

export default function ClientUserForm() {
  const { clientId, userId } = useParams();
  const {
    resource, action, mode, isEdit, setForm, setOnSave, setChanging,
  } = useClientUserContext();
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const [showLocation, setShowLocation] = useState(false);
  const locationsGet = useLocationsActiveGet();
  const navigate = useNavigate();
  const checkShowLocation = () => {
    setShowLocation((form.getFieldValue('role') || '').toLowerCase() === UserRoles.MANAGER.toLowerCase());
  };

  useEffect(() => {
    locationsGet.fetch({ clients: clientId });
  }, []);

  useEffect(() => {
    if (resource?.data && locationsGet.data) {
      form.setFieldsValue({
        ...resource.data,
        location: resource.data?.manager?.locations?.map((item) => item.id),
      });
      checkShowLocation();
    }
  }, [form, isEdit, resource?.data, locationsGet.data]);

  useEffect(() => {
    if (action?.data && !action?.error && resource) {
      resource.fetch(undefined, userId);
    }
  }, [action?.data, userId]);

  useEffect(() => {
    if (action?.data && !action?.error) {
      navigate(`/clients/${clientId}/users`);
      if (mode === 'create') {
        message.info("User has been successfully created. An email has been sent to the user's email address");

        return;
      }

      message.info('You have successfully edited this user!');
    }
  }, [action?.data]);

  useEffect(() => {
    setChanging(() => false);
    setForm(() => form);
    setOnSave(() => () => {
      if (action) {
        if (mode === 'edit') {
          action.fetch({ ...form.getFieldsValue(), clientId });
        } else {
          action.fetch({
            ...form.getFieldsValue(),
            clientId,
          });
        }
      }
    });
  }, []);

  useEffect(() => {
    if (action?.data) {
      setChanging(() => false);
    }
  }, [action?.data]);

  // eslint-disable-next-line
  const onFieldsChange = (changedFields: FieldData[], allFields: FieldData[]) => {
    checkShowLocation();
    setChanging(() => {
      let filteredFields = allFields;

      if (!showLocation) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line implicit-arrow-linebreak
        filteredFields = filteredFields.filter((field) => field.name[0] !== 'location');
      }

      const getValidValues = (field: AnyObject, initialValue: User | FieldsType | undefined) => {
        const value = typeof field.value === 'undefined' ? '' : field.value;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line implicit-arrow-linebreak
        let resourceValue = initialValue?.[field.name[0]];

        // eslint-disable-next-line
        resourceValue = typeof resourceValue === 'undefined' ? (Array.isArray(value) ? [] : '') : resourceValue;

        return [resourceValue, value];
      };

      if (resource?.data) {
        return (
          filteredFields.every((field) => field.errors?.length === 0)
          && filteredFields.filter((field) => {
            const [initialValue, value] = getValidValues(field, resource?.data);

            return Array.isArray(initialValue) ? initialValue.toString() !== value.toString() : value !== initialValue;
          }).length > 0
        );
      }

      return (
        filteredFields.every((field) => field.errors?.length === 0)
        && filteredFields.filter((field) => {
          const [initialValue, value] = getValidValues(field, initialValues);

          return Array.isArray(initialValue) ? initialValue.toString() === value.toString() : value === initialValue;
        }).length === 0
      );
    });
  };

  return (
    <div className={styles.wrapper}>
      {action?.error ? (
        <Alert type="error" message={getMessageInError(action?.error)} closable onClose={action?.clearError} />
      ) : null}

      {resource?.error ? (
        <Alert type="error" message={getMessageInError(resource.error)} closable onClose={resource.clearError} />
      ) : null}

      <Form<FieldsType>
        form={form}
        initialValues={initialValues}
        requiredMark={false}
        disabled={!isEdit}
        onFieldsChange={onFieldsChange}
        layout="vertical"
      >
        <Fields locationsGet={locationsGet} showLocation={showLocation} />
      </Form>
    </div>
  );
}
