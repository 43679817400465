import React from 'react';
import { Layout as AntdLayout } from 'antd';
import ReduxRedirect from '../../Common/ReduxRedirect';

import styles from './index.module.scss';

interface SimpleProps {
  children: React.ReactNode
}

export default function Simple({ children }: SimpleProps): React.ReactNode {
  return (
    <>
      <ReduxRedirect />
      <AntdLayout>
        <AntdLayout.Content className={styles.content}>
          {children}
        </AntdLayout.Content>
      </AntdLayout>
    </>
  );
}
