import { useEffect, useMemo, useState } from 'react';
import { AnyObject } from '@triare/auth-redux';
import { App } from 'antd';
import { DefaultOptionType } from 'rc-select/lib/Select';
import {
  FetchCreate, FetchDelete, FetchGet, FetchUpdate, getMessageInError, PagingDataResponse,
} from './fetch';

export interface Item extends DefaultOptionType {
  key?: string | string[];
  color?: string;
}

export function sortByDisable(list: Item[]): Item[] {
  return [
    ...list.filter(({ disabled }) => !disabled),
    ...list.filter(({ disabled }) => disabled),
  ];
}

export type SelectData = string[] | AnyObject[] | PagingDataResponse<string[] | AnyObject[]>

export function selectData<D = SelectData>(
  // eslint-disable-next-line
  resource: D,
  decorateData?: (data: D, id: number, item: Item) => Item,
  defaultList?: Item[],
) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const data = Array.isArray(resource) ? resource : resource?.data;

  if (data) {
    return data.map((value: string | AnyObject, id: number) => {
      if (typeof value === 'string') {
        const result = {
          label: value,
          key: value,
          value,
        };

        if (decorateData) {
          return decorateData(data, id, result);
        }

        return result;
      }

      const key = value?.ids || value?.id || value?.key || value?.value;
      const result: Item = {
        label: value?.name || value?.title || value?.description || value?.value,
        value: key,
        key,
      };

      if (decorateData) {
        return decorateData(data, id, result);
      }

      return result;
    }) as Item[];
  }

  return defaultList || [];
}

export function useLoadingTimeout(resources: FetchGet[], time = 500, defaultState = true) {
  const [loading, setLoading] = useState(defaultState);

  useEffect(() => {
    let id: NodeJS.Timeout | undefined;

    if (resources.some((resource) => resource.loading)) {
      setLoading(true);
    } else {
      id = setTimeout(() => {
        setLoading(false);
      }, time);
    }

    return () => clearTimeout(id);
  }, resources.map((resource) => resource.loading));

  return loading;
}

export function useSelectData<D = SelectData>(
  // eslint-disable-next-line
  resource?: FetchGet<D, any, any, any>,
  decorateData?: (data: D, id: number, item: Item) => Item,
  defaultList?: Item[],
): Item[] {
  return useMemo(() => {
    if (resource && !resource.error && resource.data) {
      return selectData<D>(resource.data, decorateData, defaultList);
    }

    return defaultList || [];
  }, [resource?.data, defaultList]);
}

export function useMessageError(
  listFetch: (FetchCreate | FetchGet | FetchUpdate | FetchDelete)[],
) {
  const { message } = App.useApp();

  useEffect(() => {
    listFetch.forEach((fetch) => {
      if (fetch.error) {
        message.error(fetch.name ? `${fetch.name}: ${getMessageInError(fetch.error)}` : getMessageInError(fetch.error));
        fetch.clearError();
      }
    });
  }, [...listFetch.map((fetch) => fetch.error)]);
}

export default {
  useSelectData,
};
