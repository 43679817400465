import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Detail from '../../Common/Detail';
import { TabPaneItem } from '../../../../Common/Tabs';
import ClientForm from '../Form';
import ClientUserList from '../List';
import ClientUserProvider, { useClientUserContext } from '../Context';
import { useClientId } from '../../../../../hooks/client';

export const tabList: TabPaneItem[] = [
  {
    label: 'General info',
    key: 'general',
    children: <ClientForm />,
  },
  {
    label: 'List of users',
    key: 'users',
    children: (
      <div style={{ margin: '-24px -8px 0' }}>
        <ClientUserList />
      </div>
    ),
  },
];

interface ClientUserTabProps {
  setReload?: (time: number) => void;
}

function ClientUserTab({ setReload }: ClientUserTabProps): React.ReactNode {
  const { clientId } = useParams();
  const client = useClientId();

  useEffect(() => {
    client.fetch(undefined, clientId);
  }, [clientId]);

  const newTabList = useMemo(() => {
    const list = [...tabList];

    list[1].children = (
      <div style={{ margin: '-24px -8px 0' }}>
        <ClientUserList setReload={setReload} />
      </div>
    );

    return list;
  }, [setReload]);

  return (
    <ClientUserProvider defaultTab={newTabList[1].key}>
      <Detail arrowBack="/clients" tabList={newTabList} name={client.data?.name} useContext={useClientUserContext} />
    </ClientUserProvider>
  );
}

ClientUserTab.defaultProps = {
  setReload: undefined,
};

export default ClientUserTab;
