// import React from 'react';
import React, { useState } from 'react';
import LocationProvider, { useLocationContext } from './Context';
import Roles from './Components/Roles';
import TopBar from './Components/TopBar';
import Info from './Components/Info';
import InductionsTransfer from './Components/InductionsTransfer';
import Orientations from './Components/Orientations';
import Tabs from '../../Common/Tabs';
import SaveModal from './Components/SaveModal';

import styles from './index.module.scss';

function Wrapper() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [activeKey, setActiveKey] = useState('roles');
  const { isChanging, setActiveTab } = useLocationContext();

  const onChange = (key: string) => {
    if (isChanging) {
      setModalOpen(true);
      setActiveTab(() => key);
    } else {
      setActiveKey(key);
    }
  };

  return (
    <div className={styles.wrapper}>
      <TopBar />
      <SaveModal isModalOpen={isModalOpen} setModalOpen={setModalOpen} />
      <Tabs
        onChange={onChange}
        activeKey={activeKey}
        items={[
          {
            label: 'Roles and Requirements',
            key: 'roles',
            children: <Roles />,
          },
          {
            label: 'General info',
            key: 'info',
            children: <Info />,
          },
          {
            label: 'Inductions',
            key: 'inductions',
            children: <InductionsTransfer />,
          },
          {
            label: 'Orientations',
            key: 'orientations',
            children: <Orientations />,
          },
        ]}
      />
    </div>
  );
}

export default function Location(): React.ReactNode | null {
  return (
    <LocationProvider>
      <Wrapper />
    </LocationProvider>
  );
}
