import React, { useEffect, useMemo } from 'react';
import { Badge, Button, Tooltip } from 'antd';
import { NavLink } from 'react-router-dom';
import {
  CheckCircleOutlined, EditOutlined, EyeTwoTone, StopOutlined,
} from '@ant-design/icons';
import type { ProColumns } from '@ant-design/pro-components';
import { ProCoreActionType } from '@ant-design/pro-utils/es/typing';
import {
  User, useUsersGet, usersGetParamList, UsersGetParams, useUserUpdate,
} from '../../../../hooks/user';
import { PagingDataResponse } from '../../../../hooks/fetch';
import { useAuthState } from '../../../../store/auth';
import { getBudgeColor } from '../../../Common/Badge';
import Table from '../../../Common/Table';
import { UserRoles, isRoleEnough } from '../../../../enums/user';
import { useSearchParams } from '../../../../hooks/useSearchParams';
import { useSimpleModal } from '../../../Common/Modal/Simple';
import { useMessageError } from '../../../../hooks/common';
import { COLOR_PRIMARY } from '../../../../AntdConfigProvider';

import styles from './index.module.scss';

export interface ActionProps<D> {
  dom: React.ReactNode;
  entity: D;
  index: number;
  action: ProCoreActionType | undefined;
}

const columns: ProColumns<User>[] = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (text, record) => (
      <NavLink to={record.id}>
        {record.firstName}
        {' '}
        {record.lastName}
      </NavLink>
    ),
  },
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
    render: (_, record) => {
      if (record.locationCount === 1) {
        return record.locationName;
      }
      if (record.locationCount > 1) {
        return (
          <Tooltip title={record.locationNames.join(', ')} trigger="click">
            {`${record.locationCount} locations `}
            <EyeTwoTone style={{ cursor: 'help' }} twoToneColor={COLOR_PRIMARY} />
          </Tooltip>
        );
      }

      return 'All';
    },
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
    render: (text, entity) => <NavLink to={`tel:${entity?.phone}`}>{entity.phone}</NavLink>,
  },
  {
    title: 'Email login',
    key: 'email',
    dataIndex: 'email',
    sorter: true,
    render: (text, entity) => <NavLink to={`mailto:${entity?.email}`}>{entity.email}</NavLink>,
  },
  {
    title: 'Role',
    dataIndex: 'role',
    key: 'role',
    render: (role) => (
      <span style={{ textTransform: 'capitalize' }}>{role === UserRoles.CLIENT_ADMIN ? 'Client Admin' : role}</span>
    ),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (text) => <Badge className={styles.status} color={getBudgeColor(text)} text={text} />,
  },
];

export default function UserList() {
  const { user } = useAuthState();
  const { open, contextHolder } = useSimpleModal();
  const clientId = user?.locationAdmin?.client.id || user?.manager?.client.id;
  const users = useUsersGet();
  const isAdmin = isRoleEnough(user?.role, UserRoles.CLIENT_ADMIN);
  const [searchParams] = useSearchParams();
  const userUpdate = useUserUpdate();

  const getUsers = () => {
    const props: UsersGetParams = { client: clientId };

    usersGetParamList.forEach((key) => {
      const value = searchParams.getAll(key);

      if (value.length || !(value.length === 1 && value[0] === '')) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        props[key] = value.length === 1 ? value[0] : value;
      }
    });

    if (props.roles && !Array.isArray(props.roles)) {
      props.roles = props.roles.split(' ');
    } else {
      props.roles = [UserRoles.CLIENT_ADMIN, UserRoles.MANAGER];
    }

    users.fetch(props);
  };

  useEffect(() => {
    if (userUpdate.data) getUsers();
  }, [userUpdate.data]);

  useEffect(getUsers, [searchParams]);

  const usersData = useMemo<PagingDataResponse<User>>(() => {
    if (users.data && users.data.data && users.data.data.length) {
      return {
        ...users.data,
        data: users.data.data.map((items) => ({
          ...items,
        })),
      } as PagingDataResponse<User>;
    }

    return users.data as PagingDataResponse<User>;
  }, [users.data?.data]);

  const toolBarRender = () => [
    isAdmin ? (
      <NavLink key="add" to="/users/add">
        <Button type="primary">Add user</Button>
      </NavLink>
    ) : null,
  ];

  const actions = ({ entity }: ActionProps<User>) => (
    <>
      <Tooltip title="Edit">
        <NavLink to={`${entity.id}?edit`}>
          <EditOutlined className={styles.icon} />
        </NavLink>
      </Tooltip>
      <Tooltip title={entity?.status === 'active' ? 'Deactivate' : 'Activate'}>
        <Button
          type="link"
          className={styles.btn}
          onClick={(e) => {
            e.preventDefault();
            const isActive = entity?.status === 'active';
            const actionName = isActive ? 'Deactivate' : 'Activate';

            open({
              icon: isActive ? (
                <StopOutlined style={{ color: '#FF4D4F' }} />
              ) : (
                <CheckCircleOutlined style={{ color: COLOR_PRIMARY }} />
              ),
              title: `${actionName} user`,
              content: `Are you sure you want to ${actionName.toLowerCase()} this user (${entity.firstName} ${
                entity.lastName
              })?`,
              cancelText: 'Cancel',
              okText: actionName,
              onOk: () => userUpdate.fetch({ status: isActive ? 'deactivated' : 'active' }, entity.id),
            });
          }}
        >
          {entity?.status === 'active' ? <StopOutlined /> : <CheckCircleOutlined />}
        </Button>
      </Tooltip>
    </>
  );

  useMessageError([userUpdate, users]);

  return (
    <div>
      <Table<User>
        rowKey="id"
        search={false}
        headerTitle={user?.role === UserRoles.CLIENT_ADMIN ? 'List of users' : 'Users'}
        toolBarRender={toolBarRender}
        actions={actions}
        columnsState={{ persistenceKey: 'pro-table-users', persistenceType: 'localStorage' }}
        options={{
          reload: false,
          density: false,
        }}
        columns={columns}
        loading={users.loading}
        pagingData={usersData}
      />

      {contextHolder}
    </div>
  );
}
