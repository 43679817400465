import {
  DefaultFetchError,
  FetchCreate,
  FetchGet,
  FetchSuccess, FetchUpdate,
  PagingDataResponse,
  PagingParams,
  useFetchCreate, useFetchDelete,
  useFetchGet, useFetchUpdate,
} from './fetch';

export interface DocumentCreateParams {
  title: string
  isRequired: boolean
  signUp: boolean
}

export interface Document extends DocumentCreateParams {
  id: string
}

export const useDocumentCreate = (): FetchCreate<FetchSuccess, DefaultFetchError, DocumentCreateParams> => (
  useFetchCreate('documents')
);

export interface DocumentsGetParams extends PagingParams {
  isRequired?: boolean
  signUp?: boolean
  partialTitle?: boolean
  orderByColumn?: 'id' | 'title'
}

export function useDocumentsGet<D = Document[], DD = D>(
  params?: DocumentsGetParams,
  decorateData?: (data: D) => DD,
): FetchGet<D, DocumentsGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, DocumentsGetParams, DD>(
    'documents',
    {
      params,
      decorateData,
      autoStart: false,
      startStateLoading: false,
    },
  );
}

export function useDocumentsPaginGet<D = PagingDataResponse<Document>,
  DD = D>(decorateData?: (data: D) => DD): FetchGet<D, DocumentsGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, DocumentsGetParams, DD>(
    'documents/pagginate',
    {
      decorateData,
      autoStart: false,
      startStateLoading: false,
    },
  );
}

export function useDocumentRequired<D = Document[], DD = D>(
  params?: DocumentsGetParams,
  decorateData?: (data: D) => DD,
): FetchGet<D, DocumentsGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, DocumentsGetParams, DD>(
    'documents/required',
    {
      params,
      decorateData,
      autoStart: false,
      startStateLoading: false,
    },
  );
}

export type DocumentUpdateParams = Partial<DocumentCreateParams>

export const useDocumentUpdate = (): FetchUpdate<FetchSuccess, DefaultFetchError, DocumentUpdateParams> => (
  useFetchUpdate('documents')
);

export const useDocumentDelete = () => (
  useFetchDelete<FetchSuccess, DefaultFetchError, string>('documents')
);

export interface DocumentsAllUpdateParams extends DocumentUpdateParams {
  id: string
}

export const useDocumentsAllUpdate = (): FetchUpdate<
  FetchSuccess,
  DefaultFetchError,
  DocumentsAllUpdateParams[]
> => (
  useFetchUpdate('documents/all')
);
