import { pagingParamList } from '../contstant';
import {
  DefaultFetchError,
  FetchGet,
  FetchGetId,
  FetchSuccess,
  FetchUpdate,
  PagingDataResponse,
  PagingParams,
  useFetchDelete,
  useFetchGet,
  useFetchGetId,
  useFetchUpdate,
} from './fetch';

export interface Notification {
  id: string;
  link: string;
  title: string;
  message: string;
  data: any;
  type: string;
  entity: string;
  entityId: string;
  createdAt: string;
  updatedAt: string;
  category: string
  status: {
    saw: boolean;
    removed: boolean;
  };
}

export const notificationsGetParamList = [
  'category', 'removed', ...pagingParamList,
];

export interface NotificationsGetParams extends PagingParams {
  saw?: boolean;
  category?: string
  removed?: boolean
}

export function useNotificationsGet<D = PagingDataResponse<Notification>, DD = D>(
  decorateData?: (data: D) => DD,
): FetchGet<D, NotificationsGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, NotificationsGetParams, DD>('notifications', {
    decorateData,
    autoStart: false,
    startStateLoading: false,
  });
}

export function useNotificationsCategoryGet<D = string[], DD = D>(
  decorateData?: (data: D) => DD,
): FetchGet<D, never, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, never, DD>('notifications/categories', {
    decorateData,
    autoStart: false,
    startStateLoading: false,
    cacheLifetime: 10 * 60 * 1000,
    multiple: 'category',
  });
}

export interface NotificationsUpdateParams {
  status?: {
    saw: boolean;
    removed: boolean;
  };
}

export const useNotificationsSaw = (
  id?: string,
): FetchUpdate<FetchSuccess, DefaultFetchError, null> => useFetchUpdate('notifications', id);

// eslint-disable-next-line max-len
export const useNotificationsSawAll = (): FetchUpdate<FetchSuccess, DefaultFetchError, null> => useFetchUpdate('notifications/all/saw');

export const useNotificationsUpdate = (
  id?: string,
): FetchUpdate<FetchSuccess, DefaultFetchError, NotificationsUpdateParams> => useFetchUpdate('notifications', id);

export const useNotificationId = (id?: string): FetchGetId<Notification> => (
  useFetchGetId('notifications', id, { autoStart: false })
);

export const useNotificationsDelete = () => useFetchDelete<FetchSuccess, DefaultFetchError, string>('notifications');
