import { AnyObject } from '@triare/auth-redux';

export const groupArrayBy = (array: AnyObject[], field: string, fieldMask?: (data: any) => string): AnyObject => {
  const groupedResult: AnyObject = {};

  array.forEach((item) => {
    const objectKey = fieldMask ? fieldMask(item[field]) : field;

    groupedResult[objectKey] = [...(groupedResult[objectKey] || []), item];
  });

  return groupedResult;
};

export default {
  groupArrayBy,
};
