import { InfoCircleTwoTone } from '@ant-design/icons';
import {
  DatePicker, Form, TimePicker, Checkbox, Tooltip, Row, Col, Radio,
} from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import type { RangePickerProps } from 'antd/es/date-picker';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useTranslation } from 'react-i18next';
import { useShiftActionContext } from '../../../Pages/Shift/Create/Context';
import { COLOR_PRIMARY } from '../../../../AntdConfigProvider';

import styles from './index.module.scss';
import ShiftStatus from '../../../../enums/shift';

dayjs.extend(customParseFormat);

export default function DateTime() {
  const { t } = useTranslation();
  const { form, shift, mode } = useShiftActionContext();
  const [onCallRequired, setOnCallRequired] = useState(false);
  const disabledDate: RangePickerProps['disabledDate'] = (current) => current && current < dayjs().endOf('day');
  const futureDate: RangePickerProps['disabledDate'] = (current) => current && current > dayjs().endOf('day');
  const values = Form.useWatch([], form);
  const [showDuration, setShowDuration] = useState(false);
  const isEditMode = mode === 'edit';
  const isCompletedEdit = (isEditMode && shift && shift.status === ShiftStatus.COMPLETED) || false;

  useEffect(() => {
    if (!values?.date || !values?.time) return;

    const range = dayjs(
      `${dayjs(values?.date[1]).format('DD-MM-YYYY')} ${dayjs(values?.time[1]).format('HH:mm')}`,
      'DD-MM-YYYY HH:mm',
    ).diff(dayjs(
      `${dayjs(values?.date[0]).format('DD-MM-YYYY')} ${dayjs(values?.time[0]).format('HH:mm')}`,
      'DD-MM-YYYY HH:mm',
    ), 'minutes');

    setShowDuration(range > 1440); // 1 day
  }, [values]);

  useEffect(() => {
    if (shift?.onCallDatetimeStart) {
      setOnCallRequired(true);
    }
  }, [shift?.onCallDatetimeStart]);

  return (
    <div className={styles.wrapper}>
      <Row gutter={[16, 0]}>
        <Col span={12} lg={8}>
          <Form.Item
            rules={[{ required: true, message: 'Required field!' }]}
            name="date"
            label="Day(s)* "
            tooltip={{
              title: t('shiftDays'),
              icon: <InfoCircleTwoTone style={{ cursor: 'help' }} twoToneColor={COLOR_PRIMARY} />,
            }}
          >
            <DatePicker.RangePicker
              disabledDate={isCompletedEdit ? futureDate : disabledDate}
              format="dddd, MMM D"
              className={styles.fullWidth}
            />
          </Form.Item>
        </Col>
        <Col span={12} lg={8}>
          <Form.Item
            rules={[{ required: true, message: 'Required field!' }]}
            name="time"
            label="Hours*"
            tooltip={{
              title: t('shiftHours'),
              icon: <InfoCircleTwoTone style={{ cursor: 'help' }} twoToneColor={COLOR_PRIMARY} />,
            }}
          >
            <TimePicker.RangePicker
              format="HH:mm"
              className={styles.fullWidth}
              popupClassName={styles.timePopup}
              changeOnBlur
              minuteStep={5}
              order={false}
            />
          </Form.Item>
        </Col>

        {showDuration && (
          <Col span={24} lg={8}>
            <Form.Item
              label="Shift duration*"
              name="shiftDuration"
              tooltip={{
                title: (
                  <>
                    {t('shiftDurationFirst')}
                    <br />
                    {t('shiftDurationSecond')}
                  </>
                ),
                icon: <InfoCircleTwoTone style={{ cursor: 'help' }} twoToneColor={COLOR_PRIMARY} />,
              }}
            >
              <Radio.Group disabled={isEditMode}>
                <Radio value="single_day">Create one shift for each day</Radio>
                <Radio value="multi_day">Create one multi-day shift</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        )}
      </Row>
      <Checkbox
        checked={onCallRequired}
        onChange={(e) => {
          form?.validateFields();
          setOnCallRequired(e.target.checked);
        }}
        className={styles.checkbox}
      >
        On-call required?
      </Checkbox>

      <Tooltip title={t('shiftOnCall')}>
        <InfoCircleTwoTone style={{ cursor: 'help' }} twoToneColor={COLOR_PRIMARY} />
      </Tooltip>

      {onCallRequired && (
        <Row gutter={[16, 0]}>
          <Col span={12} lg={8}>
            <Form.Item name="onCallDate" rules={[{ required: true, message: 'Required field!' }]}>
              <DatePicker.RangePicker
                disabledDate={isCompletedEdit ? futureDate : disabledDate}
                format="dddd, MMM D"
                className={styles.fullWidth}
              />
            </Form.Item>
          </Col>
          <Col span={12} lg={8}>
            <Form.Item name="onCallTime" rules={[{ required: true, message: 'Required field!' }]}>
              <TimePicker.RangePicker
                format="HH:mm"
                className={styles.fullWidth}
                popupClassName={styles.timePopup}
                changeOnBlur
                minuteStep={5}
                order={false}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
    </div>
  );
}
