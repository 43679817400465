/* eslint-disable max-len */

import * as yup from 'yup';
import 'yup-phone';
import { Rule, RuleObject } from 'rc-field-form/lib/interface';

export const textField: yup.StringSchema = yup.string()
  .min(2, 'Must be at least 2 characters')
  .max(100, 'Cannot be more than 100 characters.')
  .required('Required');

export const name: yup.StringSchema = yup.string()
  .min(2, 'Must be at least 2 characters')
  .max(100, 'Cannot be more than 100 characters.')
  .required('Required');

export const username: yup.StringSchema = yup.string()
  .max(50, 'Cannot be more than 50 characters.')
  .required('Required');

export const password: yup.StringSchema = yup.string()
  .min(12, 'Password must be at least 12 characters')
  .max(100, 'Too Long! Maximum 100 characters')
  .matches(/^(?=.*[0-9])(?=.*[\D])([\D0-9]+)$/, 'Must contain numbers and letters')
  .matches(/[^A-Za-z0-9]|[A-Z]/, 'Must contain one uppercase letter or special symbol')
  .matches(/[a-z]/, 'Must contain one lowercase letter')
  .required('Required');

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const phone: yup.StringSchema = yup.string()
  .min(8, 'Phone must be a valid phone number')
  .max(15, 'Phone must be a valid phone number')
  .required('Required');

export const email: yup.StringSchema = yup.string().email('Invalid email').required('Required').matches(/^.+.\w{2}$/, 'At least two characters after the period');

export const confirmEmail: yup.StringSchema = yup.string()
  .oneOf([yup.ref('email')], 'Email must match')
  .required('Required');

export const zipCode: yup.StringSchema = yup.string()
  .min(5, 'Please enter a valid Zip Code.')
  .max(10, 'Please enter a valid Zip Code.')
  .matches(/^\d{5}$|^\d{5}-\d{4}$/, 'Please enter a valid Zip Code.')
  .required('Required');

export const validationSignUpEmailOnly = yup.object().shape({
  email,
});

export const validationSignUpEmailAndPassword = yup.object().shape({
  password,
  email,
});

export const validationSignUpPhoneOnly = yup.object().shape({
  phone,
});

export const validationSignUp = yup.object().shape({
  password,
  email,
  phone,
  firstName: name,
  surname: name,
  address: name,
});

export const validationGeneralInfo = yup.object().shape({
  name,
  address: textField,
  phone,
  email,
});

export const validationUserInfo = yup.object().shape({
  firstName: name,
  lastName: name,
  phone,
  email,
});

export const validationChangePassword = yup.object().shape({
  currentPassword: password,
  password,
});

export const validationNewPassword = yup.object().shape({
  password,
});

export const validationChangeEmail = yup.object().shape({
  email,
  confirmEmail,
});

export const validationSignInEmailAndPassword = yup.object().shape({
  email,
  password,
});

export const validationUserAdd = yup.object().shape({
  lastName: textField,
  firstName: textField,
  phone,
  email,
});

export const validationApplicant = yup.object().shape({
  indemnityNumber: name,
  ahpra: yup.string()
    .min(5, 'Must be at least 5 characters')
    .max(15, 'Cannot be more than 15 characters.')
    .required('Required'),
  address: textField,
  lastName: textField,
  firstName: textField,
  phone,
  email,
});

export const validationSignInUsernameAndPassword = yup.object().shape({
  username,
  password,
});

export const validationSignInPhoneAndPassword = yup.object().shape({
  phone,
  password,
});

export const validationForgotPassword = yup.object().shape({
  email,
});

const { shape } = yup.object();

export const createRulesForAntd = (yupRule: ReturnType<typeof shape>): Rule => ({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  async validator({ field }: RuleObject, value) {
    await yupRule.validateSyncAt(field, { [field]: value });
  },
});

const validatorRules: {[key: string]: Rule} = {};

export const getRulesForAntd = (yupRule: ReturnType<typeof shape>): Rule => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (validatorRules[yupRule.name]) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return validatorRules[yupRule.name];
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  validatorRules[yupRule.name] = createRulesForAntd(yupRule);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return validatorRules[yupRule.name];
};

export default {
  validationSignUp,
  validationChangePassword,
  validationNewPassword,
  validationChangeEmail,
  validationSignIn: validationSignInEmailAndPassword,
  validationForgotPassword,
  password,
  email,
  confirmEmail,
};
