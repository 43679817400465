import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Simple from '../../../components/Layout/Simple';
import { useAuthState } from '../../../store/auth';
import { generateDefaultUrl } from '../../../routes';
import SignInContent from '../../../components/Pages/SignIn';

interface SignInProps {
  redirect?: boolean
}

function SignIn({ redirect }: SignInProps): React.ReactNode {
  const navigate = useNavigate();
  const { authorized, user } = useAuthState();

  document.title = `${process.env.REACT_APP_TITLE_NAME}: Sign in`;

  useEffect(() => {
    if (redirect && authorized && user) {
      navigate(`/${generateDefaultUrl(user)}`);
    }
  }, [authorized, user]);

  return (
    <Simple>
      <SignInContent />
    </Simple>
  );
}

SignIn.defaultProps = {
  redirect: true,
};

export default SignIn;
