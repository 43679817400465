import React, { useCallback, useEffect, useState } from 'react';
import { Input, Select } from 'antd';
import parsePhoneNumber, { CountryCode, getCountryCallingCode } from 'libphonenumber-js';
import Flag from 'country-flag-icons/react/3x2';
import { SizeType } from 'antd/es/config-provider/SizeContext';

import styles from './index.module.scss';

// eslint-disable-next-line
interface PhoneNumberFormItemProps extends React.ComponentProps<any> {
  size?: SizeType;
  value?: string;
  onChange?: (value: string) => void;
}

const defaultCounty = 'AU';

const countryOptions: { value: string; label: React.ReactElement }[] = [defaultCounty].map((code) => {
  // @ts-ignore @typescript-eslint/ban-ts-comment
  const FlagElement = Flag[code];

  return {
    key: code,
    value: code,
    label: (
      <div className={styles.options}>
        <FlagElement className={styles.flag} />
        {' '}
        {code}
      </div>
    ),
    style: {
      padding: '5px 8px',
    },
  };
});

const DefaultFlagElement = Flag[defaultCounty];

const getPhoneNumber = (() => {
  let result: string;
  let beforeValue: string;
  let beforeCountryCode: CountryCode;

  return (value: string, countryCode: CountryCode): string => {
    if (beforeValue === value && beforeCountryCode === countryCode) {
      return result;
    }

    beforeValue = value;
    beforeCountryCode = countryCode;
    const countryNumber = getCountryCallingCode(countryCode);

    let phoneNumber = `${value}`.replace(/[^0-9]*/gim, '');

    if (phoneNumber.indexOf(countryNumber) === 0) {
      phoneNumber = phoneNumber.substring(countryNumber.length);
    } else if (countryNumber.indexOf(phoneNumber) === 0) {
      phoneNumber = phoneNumber.substring(countryNumber.length);
    }

    result = parsePhoneNumber(phoneNumber, countryCode)?.number || `+${countryNumber}${phoneNumber}`;

    return result;
  };
})();

export function PhoneNumberInput({
  size, value = '', onChange, ...props
}: PhoneNumberFormItemProps): React.ReactNode {
  const [countryCode, setCountryCode] = useState<CountryCode>(defaultCounty);

  const findAndSetCountryCode = useCallback(
    (phone: string) => {
      if (phone) {
        const data = parsePhoneNumber(phone, countryCode);

        if (data && data.country) {
          setCountryCode(data.country);
        }
      }
    },
    [setCountryCode],
  );

  useEffect(() => {
    if (typeof value === 'string') {
      findAndSetCountryCode(value);
    }
  }, [value]);

  const numberInputChanged = useCallback(
    (phone: string) => {
      if (typeof onChange === 'function') {
        onChange(getPhoneNumber(phone, countryCode));
      }
    },
    [onChange, countryCode],
  );

  const selectCountry = useCallback(
    (code: CountryCode) => {
      setCountryCode(code);
    },
    [setCountryCode, onChange, value],
  );

  return (
    <Input
      {...props}
      size={size}
      className={styles.inputPhone}
      onChange={(e) => numberInputChanged(e.target.value)}
      value={getPhoneNumber(value, countryCode)}
      readOnly
      onFocus={(e) => e.currentTarget.removeAttribute('readonly')}
      addonBefore={(
        <Select
          size={size}
          showSearch
          options={countryOptions}
          onSelect={selectCountry}
          value={countryCode}
          placeholder={<DefaultFlagElement className={styles.flag} />}
          className={styles.select}
        />
      )}
    />
  );
}

PhoneNumberInput.defaultProps = {
  size: 'middle',
  value: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: () => {},
};

export default PhoneNumberInput;
