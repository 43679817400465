import { App, Modal, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import type { UploadProps } from 'antd';
import { useUploadFile } from '../../../../hooks/file';
import { getMessageInError } from '../../../../hooks/fetch';

type UploadModalProps = {
  modalOpen: boolean;
  setModalOpen: (modalOpen: boolean) => void;
  updateHandler: (id: string) => void;
};

export default function UploadModal({ modalOpen, setModalOpen, updateHandler }: UploadModalProps) {
  const { message } = App.useApp();
  const uploader = useUploadFile();

  useEffect(() => {
    if (uploader.data) {
      updateHandler(uploader.data.id);
    }
  }, [uploader.data]);

  useEffect(() => {
    if (uploader.error) {
      message.error(getMessageInError(uploader.error));
      uploader.clearError();
    }
  }, [uploader.error]);

  const props: UploadProps = {
    beforeUpload: (file) => {
      const formData = new FormData();

      formData.append('file', file as Blob);
      uploader.fetch(formData);

      return false;
    },
  };

  return (
    <Modal
      title="Upload file"
      open={modalOpen}
      onCancel={() => setModalOpen(false)}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <Upload.Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
      </Upload.Dragger>
    </Modal>
  );
}
