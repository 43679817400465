import { App, Button } from 'antd';
import { UploadOutlined, SwapOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useApplicantDocumentsCreate, useApplicantDocumentsUpdate } from '../../../../hooks/applicantDocument';
import UploadModal from '../UploadModal';
import { useMessageError } from '../../../../hooks/common';

import styles from './index.module.scss';

type UploadActionProps = {
  applicantId: string | undefined;
  typeId: string;
  fetch: () => void;
  replace?: boolean;
  fileId?: string | null;
};
function UploadAction({
  applicantId, typeId, fetch, replace, fileId,
}: UploadActionProps) {
  const { message } = App.useApp();
  const createDocument = useApplicantDocumentsCreate();
  const updateDocument = useApplicantDocumentsUpdate();
  const [modalOpen, setModalOpen] = useState(false);

  const updateHandler = (id: string) => {
    setModalOpen(false);
    if (fileId) {
      updateDocument.fetch(
        {
          documentType: typeId,
          applicant: applicantId!,
          file: id,
        },
        fileId,
      );
    } else {
      createDocument.fetch({
        documentType: typeId,
        applicant: applicantId!,
        file: id,
      });
    }
  };

  useEffect(() => {
    if (createDocument.data || updateDocument.data) {
      message.success('The file was successfully uploaded');
      fetch();
    }
  }, [createDocument.data, updateDocument.data]);

  useMessageError([createDocument, updateDocument]);

  return (
    <>
      <UploadModal modalOpen={modalOpen} setModalOpen={setModalOpen} updateHandler={updateHandler} />
      {!replace && (
        <Button onClick={() => setModalOpen(true)} loading={createDocument.loading}>
          <UploadOutlined className={styles.uploadIcon} />
          Upload
        </Button>
      )}
      {replace && (
        <Button
          type="text"
          className={styles.swapBtn}
          onClick={() => setModalOpen(true)}
          loading={updateDocument.loading}
        >
          <SwapOutlined className={styles.swapIcon} />
          Replace
        </Button>
      )}
    </>
  );
}

UploadAction.defaultProps = {
  replace: false,
  fileId: null,
};

export default UploadAction;
