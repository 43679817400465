import { InfoCircleTwoTone } from '@ant-design/icons';
import clsx from 'clsx';
import {
  Space, Tooltip, Col, Typography,
} from 'antd';
import { COLOR_PRIMARY } from '../../../../AntdConfigProvider';

import styles from './index.module.scss';

interface ViewItemProps {
  text: string;
  value?: string | string[] | undefined;
  tooltipe?: string;
  node?: React.ReactNode;
  span?: number;
  margin?: boolean;
}
function ViewItem({
  text, value, tooltipe, span, margin, node,
}: ViewItemProps) {
  return (
    <Col span={span}>
      <Space direction="vertical" className={clsx(styles.wrapper, !margin && styles.noMargin)}>
        <Typography.Text strong>
          {text || ''}
          {tooltipe && (
            <Tooltip title={tooltipe}>
              {' '}
              <InfoCircleTwoTone style={{ cursor: 'help' }} twoToneColor={COLOR_PRIMARY} />
            </Tooltip>
          )}
        </Typography.Text>
        <Typography.Text>
          {Array.isArray(value) ? value?.map((item) => (
            <div key={item}>
              {' '}
              {item}
            </div>
          )) : value || ''}

          {node && node}
        </Typography.Text>
      </Space>
    </Col>
  );
}

ViewItem.defaultProps = {
  tooltipe: '',
  span: 8,
  margin: true,
  value: undefined,
  node: undefined,
};

export default ViewItem;
