import React, { useEffect, useMemo, useState } from 'react';
import {
  App, Button, Checkbox, Modal, Row,
} from 'antd';
import Title from '../Title/Title';
import Team from './Team';
import View from './View';
import Period from './Period';
import { ShiftDecorated, useRosterContext } from '../../Context';
import ShiftStatus from '../../../../../enums/shift';
import { useShiftPublish } from '../../../../../hooks/shift';
import { useMessageError } from '../../../../../hooks/common';
import { getDaysBetween, getHoursBetween } from '../../../../../utils';

import styles from './index.module.scss';

function Header(): React.ReactNode {
  const {
    isApplicantPage, setSelectedShift, data, getShifts, selectedShift,
  } = useRosterContext();
  const publishShifts = useShiftPublish();
  const { message } = App.useApp();
  const [checkedShifts, setCheckedShifts] = useState<string[] | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const publishList = useMemo(() => {
    const result: ShiftDecorated[] = [];

    if (data) {
      data.forEach(({ shifts }) => {
        if (shifts) {
          const filtered = shifts.filter(({ status }) => status === ShiftStatus.DRAFT);

          if (filtered && filtered.length) {
            result.push(...filtered);
          }
        }
      });
    }
    setCheckedShifts(result.map((shift) => shift.id));

    return result;
  }, [data, isModalOpen]);

  useMessageError([publishShifts]);

  useEffect(() => {
    if (publishShifts.data && !publishShifts.error && !publishShifts.loading) {
      message.info('Shifts has been published');
      getShifts();
      setIsModalOpen(false);
    }
  }, [publishShifts.data]);

  return (
    <div className={styles.header}>
      <Title />

      <div className={styles.filter}>
        <div>
          {isApplicantPage ? <Team /> : null}
          <View />
          <Period />
        </div>
        <div className={styles.btnWrapper}>
          {selectedShift && (
            <Button
              onClick={() => {
                setSelectedShift(null);
              }}
            >
              Cancel
            </Button>
          )}
          {publishList.length ? (
            <Button loading={publishShifts.loading} type="primary" onClick={() => setIsModalOpen(true)}>
              Publish (
              {publishList.length}
              )
            </Button>
          ) : null}
        </div>
      </div>

      <Modal
        title="Publish shifts"
        open={isModalOpen}
        okText={`Publish  ${checkedShifts?.length ? `(${checkedShifts?.length})` : ''} `}
        onOk={() => publishShifts.fetch({
          ids: checkedShifts || [],
        })}
        okButtonProps={{ disabled: !checkedShifts?.length, loading: publishShifts.loading }}
        onCancel={() => setIsModalOpen(false)}
        centered
        className={styles.modal}
        destroyOnClose
      >
        <>
          {'Are you sure you want to publish the following shift'
            + `${publishList.length > 1 ? '(s)' : ''} and notify the applicant`
            + `${publishList.length > 1 ? '(s)' : ''}?`}

          <div style={{ padding: '10px 0 12px' }}>
            <Checkbox.Group
              onChange={(values) => setCheckedShifts(values as string[])}
              className={styles.wrapper}
              defaultValue={checkedShifts || []}
            >
              <Row gutter={[16, 5]} className={styles.modalRow}>
                {publishList.map((shift) => (
                  <Checkbox key={shift.id} value={shift.id} className={styles.checkbox}>
                    {`${getHoursBetween(shift.datetimeStart, shift.datetimeEnd)} |
                    ${
                      shift.multiShift
                        ? getDaysBetween(shift?.multiShift.datetimeStart, shift?.multiShift.datetimeEnd)
                        : getDaysBetween(shift?.datetimeStart, shift?.datetimeEnd)
                    }
                    |
                    ${shift.client?.name} | ${shift.locationRole?.name || ''}`}
                  </Checkbox>
                ))}
              </Row>
            </Checkbox.Group>
          </div>
        </>
      </Modal>
    </div>
  );
}

export default Header;
