import React, {
  createContext, useState, useEffect, PropsWithChildren,
} from 'react';
import { useParams } from 'react-router-dom';
import { App } from 'antd';
import { Applicant } from '../../../../types/applicant';
import { useApplicantId } from '../../../../hooks/applicant';
import { getMessageInError } from '../../../../hooks/fetch';

type SettingsContextProps = {
  applicant: Applicant | null;
  fetch: () => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  isVerified: boolean;
};

const defaultValue: SettingsContextProps = {
  applicant: null,
  fetch: () => {
    // default
  },
  loading: false,
  setLoading: () => {
    // default
  },
  isVerified: false,
};

export const SettingsContext = createContext<SettingsContextProps>(defaultValue);

function SettingsProvider({ children }: PropsWithChildren) {
  const { message } = App.useApp();
  const { applicantId } = useParams();
  const getApplicant = useApplicantId();
  const [loading, setLoading] = useState(true);
  const [applicant, setApplicant] = useState<Applicant | null>(null);
  const isVerified = !!applicant?.isVerified;

  const fetch = () => {
    getApplicant.fetch(undefined, applicantId);
    setLoading(true);
  };

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    if (getApplicant.data) {
      setApplicant(getApplicant.data);
      setLoading(false);
    }
  }, [getApplicant.data]);

  useEffect(() => {
    if (getApplicant.error) {
      message.error(getMessageInError(getApplicant.error));
      getApplicant.clearError();
      setLoading(false);
    }
  }, [getApplicant.error]);

  return (
    <SettingsContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        applicant,
        fetch,

        loading,
        setLoading,
        isVerified,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

export default SettingsProvider;

export const useSettingsContext = (): SettingsContextProps => React.useContext(SettingsContext);
