import { AnyObject } from '@triare/auth-redux';
import { pagingParamList } from '../contstant';
import { Applicant } from '../types/applicant';
import {
  DefaultFetchError,
  FetchGet,
  FetchGetId,
  useFetchGet,
  useFetchGetId,
  FetchUpdate,
  useFetchUpdate,
  PagingDataResponse,
  PagingParams,
  FetchCreate,
  FetchSuccess,
  useFetchCreate,
  useFetchDelete,
} from './fetch';
import { EmploymentType as EmploymentTypeEnum } from '../enums/applicant';

export interface ApplicantsGetParams extends PagingParams {
  take?: number;
  experiences?: string[];
  locations?: string[];
  status?: string;
  orderByColumn?: 'id' | 'LastShift';
  clients?: string;
  partialFullName?: string
  isGeneral?: boolean
  userStatuses?: string[]
}

export const applicantsGetParamList = [
  'experiences',
  'take',
  'statuses',
  'userStatuses',
  'orderBy',
  'orderByColumn',
  'locations',
  'clients',
  'partialFullName',
  'isGeneral',
  ...pagingParamList,
];

export function useApplicantsGet<D = PagingDataResponse<Applicant>, DD = D>(
  decorateData?: (data: D) => DD,
): FetchGet<D, ApplicantsGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, ApplicantsGetParams, DD>('applicants', {
    decorateData,
    autoStart: false,
    startStateLoading: false,
  });
}

export function useApplicantsSelectGet<D = Applicant[], DD = D>(
  decorateData?: (data: D) => DD,
) {
  return useFetchGet<D, DefaultFetchError, AnyObject | undefined, DD>('applicants/select', {
    name: 'Applicants',
    decorateData,
    autoStart: false,
    startStateLoading: false,
  });
}

export function useApplicantsSelectShiftsGet<D = Applicant[], DD = D>(
  decorateData?: (data: D) => DD,
) {
  return useFetchGet<D, DefaultFetchError, AnyObject | undefined, DD>('applicants/select/shifts', {
    name: 'Applicants',
    decorateData,
    autoStart: false,
    startStateLoading: false,

    beforeError(err) {
      if (err && err.response?.status === 422) {
        return true;
      }

      return false;
    },
  });
}

export const useApplicantId = (): FetchGetId<Applicant> => useFetchGetId('applicants', '', {
  autoStart: false,
  startStateLoading: false,
});

export interface ApplicantsUpdateParams {
  isVerified?: boolean;
  bsb?: string;
  accountNumber?: string;
  abn?: string;
  title?: string;
  ahpra?: string;
  isGeneral?: boolean;
  ahpraLicenseDate?: string;
  indemnityNumber?: string;
  personalInfoNotes?: string;
  indemnityProvider?: string;
  experiences?: string[];
  interviewScheduled?: boolean
  interviewSuccessful?: boolean
  user?: {
    firstName?: string,
    lastName?: string,
    address?: string,
    status?: string
    phone?: string
    email?: string
    receiveEmails?: boolean
  };
  inductions?: string[]
  teamsIds?: string[]
}

interface AplicantUpdateSuccess {
  isVerified?: boolean;
  user?: {
    status?: string
  };
}

export const useUpdateApplicant = (): FetchUpdate<AplicantUpdateSuccess, DefaultFetchError, ApplicantsUpdateParams> => (
  useFetchUpdate('applicants')
);

interface MoreInfoCreateParams {
  text: string
}

export const useRequestInfo = (): FetchCreate<FetchSuccess, DefaultFetchError, MoreInfoCreateParams> => (
  useFetchCreate('applicants')
);

export const useApplicantDelete = () => useFetchDelete<FetchSuccess, DefaultFetchError, string>('applicants');

export const useApplicantEnable = (): FetchUpdate<AplicantUpdateSuccess, DefaultFetchError, Record<string, never>> => (
  useFetchUpdate('applicants/enable')
);

interface DeactivateResponse {
  success: boolean
}

export const useApplicantDeactivate = (): FetchGetId<DeactivateResponse> => useFetchGetId('applicants/deactivate', '', {
  autoStart: false,
  startStateLoading: false,
});

export const useApplicantStatusesAllSelectGet = (): FetchGet<string[]> => useFetchGet(
  'applicants/statuses/all',
  {
    startStateLoading: false,
    autoStart: false,
  },
);

export const useApplicantStatusesSelectGet = (): FetchGet<string[]> => useFetchGet(
  'applicants/statuses',
  {
    startStateLoading: false,
    autoStart: false,
  },
);

interface EmploymentType {
  label: string,
  value: EmploymentTypeEnum
}

export const useApplicantEmploymentTypesGet = (): FetchGet<EmploymentType[]> => useFetchGet(
  'applicants/employment-types',
  {
    startStateLoading: false,
    autoStart: false,
  },
);

export const useApplicantClientStatusesAllSelectGet = (): FetchGet<string[]> => useFetchGet(
  'applicants/client/statuses/all',
  {
    startStateLoading: false,
    autoStart: false,
  },
);

export const useApplicantClientStatusesSelectGet = (): FetchGet<string[]> => useFetchGet(
  'applicants/client/statuses',
  {
    startStateLoading: false,
    autoStart: false,
  },
);

export interface TimeSlotParams {
  view?: string
  from?: string
  to?: string
  shiftTimeStart?: string
  shiftTimeEnd?: string
  shiftId?: string
  applicants?: string[]
  copy: boolean
  isRoleView?: boolean
  posting: boolean
}

export const timeSlotParamList = [
  'from',
  'to',
  'view',
  'shiftTimeStart',
  'shiftTimeEnd',
  'shiftId',
  'applicants',
  'isRoleView',
  'posting',
];

export interface dateRange {
  start: string
  end: string
}

export interface TimeSlot {
  id: string
  timeSlots: number[]
  freeDateRanges: dateRange[]
}

export function useApplicantTimeSlots<D = TimeSlot[], DD = D>(
  decorateData?: (data: D) => DD,
): FetchGet<D, TimeSlotParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, TimeSlotParams, DD>('applicants/open-time-slots', {
    decorateData,
    autoStart: false,
    startStateLoading: false,
  });
}

export function useApplicantDayimeSlots<D = TimeSlot[], DD = D>(
  decorateData?: (data: D) => DD,
): FetchGet<D, TimeSlotParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, TimeSlotParams, DD>('applicants/open-time-slots/by-day', {
    decorateData,
    autoStart: false,
    startStateLoading: false,
  });
}
