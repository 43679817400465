import { Button } from 'antd';
import { useRef } from 'react';
import clsx from 'clsx';
import { getRate } from '../../../Shift/List';
import { getHoursBetween, getStartDate } from '../../../../../utils';
import { ShiftDecorated, useRosterContext, GridShift } from '../../Context';
import Icons from './Icons';
import styles from './index.module.scss';
import { Action } from '../../../../../enums/roster';

interface ShiftItemProps {
  shift: ShiftDecorated;
}

export default function Shift({ shift }: ShiftItemProps) {
  const ref = useRef<HTMLDivElement | null>(null);
  const { setSelectedRef, setSelectedShift, selectedShift } = useRosterContext();
  const isActive = shift.id === selectedShift?.id;

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={clsx(styles.item, { [styles.active]: isActive, [styles.disable]: !shift.locationRole })}
      ref={ref}
      onClick={() => {
        if (!shift.locationRole) {
          return;
        }

        if (isActive) {
          setSelectedShift(null);

          return;
        }
        setSelectedShift({ ...(shift as GridShift), action: Action.ASSIGN });
      }}
    >
      <Icons shift={shift} />
      <div className={styles.row}>{`${shift?.client?.name} - ${shift?.location?.name}`}</div>
      <div className={styles.row}>{shift?.locationRole?.name}</div>
      <div className={styles.row}>{getRate(shift)}</div>
      <Button
        type="link"
        className={styles.date}
        onClick={(e) => {
          e.stopPropagation();

          setSelectedRef({ ref, place: 'sider', shift: shift as GridShift });
        }}
      >
        {shift.multiShift
          ? getStartDate(shift?.multiShift.datetimeStart, 'DD.MM.YY')
          : getStartDate(shift?.datetimeStart, 'DD.MM.YY')}
        {` | ${getHoursBetween(shift?.datetimeStart, shift?.datetimeEnd)}`}
      </Button>
    </div>
  );
}
