import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useUserCreate } from '../../../../../hooks/user';
import ClientUserProvider, { useClientUserContext } from '../Context';
import Detail from '../../Common/Detail';
import ClientUserForm from '../Form';

import styles from '../index.module.scss';
import { useClientId } from '../../../../../hooks/client';

export default function ClientUserCreate(): React.ReactNode {
  const userCreate = useUserCreate();
  const { clientId } = useParams();
  const client = useClientId();

  useEffect(() => {
    client.fetch(undefined, clientId);
  }, [clientId]);

  return (
    <ClientUserProvider
      mode="create"
      action={userCreate}
    >
      <Detail
        mode="create"
        name={client.data?.name || null}
        useContext={useClientUserContext}
      >
        <div className={styles.wrapper}>
          <ClientUserForm />
        </div>
      </Detail>
    </ClientUserProvider>
  );
}
