import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useUserId, useUserUpdate } from '../../../../../hooks/user';
import ClientUserProvider, { useClientUserContext } from '../Context';
import Detail from '../../Common/Detail';
import ClientUserForm from '../Form';

import styles from '../index.module.scss';

export default function ClientUserEdit(): React.ReactNode {
  const { userId } = useParams();
  const user = useUserId();
  const userUpdate = useUserUpdate(userId);

  useEffect(() => {
    user.fetch(undefined, userId);
  }, [userId]);

  return (
    <ClientUserProvider
      mode="edit"
      resource={user}
      action={userUpdate}
    >
      <Detail
        name={user.data?.fullName}
        mode="edit"
        useContext={useClientUserContext}
      >
        <div className={styles.wrapper}>
          <ClientUserForm />
        </div>
      </Detail>
    </ClientUserProvider>
  );
}
