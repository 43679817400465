import { useEffect } from 'react';
import {
  Typography, Space, App, Form, Input, Button,
} from 'antd';
import { NavLink } from 'react-router-dom';
import { StopOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { FieldData } from 'rc-field-form/lib/interface';
import { useLocationContext } from '../../Context';
import { Locations } from '../../../../../types/location';
import { useLocationUpdate, useLocationValidate } from '../../../../../hooks/location';
import { validationGeneralInfo, createRulesForAntd } from '../../../../../utils/validations';
import { UserRoles, isRoleEnough } from '../../../../../enums/user';
import { useAuthState } from '../../../../../store/auth';
import { useSimpleModal } from '../../../../Common/Modal/Simple';
import { COLOR_PRIMARY } from '../../../../../AntdConfigProvider';
import PhoneNumberInput from '../../../../Common/PhoneNumberInput';
import InputAddress from '../../../../Common/Input/Address';
import { useMessageError } from '../../../../../hooks/common';

import styles from './index.module.scss';

const validationRules = createRulesForAntd(validationGeneralInfo);

export default function Info() {
  const { user } = useAuthState();
  const updateLocation = useLocationUpdate();
  const [form] = Form.useForm();
  const validateLocation = useLocationValidate();
  const {
    setOnSave, setChanging, location, fetch, setLoading, setEdit, isEdit,
  } = useLocationContext();
  const { message } = App.useApp();
  const isAdmin = isRoleEnough(user?.role, UserRoles.LOKEM_ADMIN);
  const { open, contextHolder } = useSimpleModal();
  const isActive = location?.isActive;
  const actionName = isActive ? 'Deactivate' : 'Activate';

  useEffect(() => {
    setEdit(() => true);
    setChanging(() => false);
    setOnSave(() => onSave);
  }, []);

  useEffect(() => {
    if (validateLocation.data) {
      open({
        icon: isActive ? (
          <StopOutlined style={{ color: '#FF4D4F' }} />
        ) : (
          <CheckCircleOutlined style={{ color: COLOR_PRIMARY }} />
        ),
        title: !isActive ? 'Activate location' : 'Deactivate location',
        content: (
          <div>
            {!validateLocation.data.success && isActive ? (
              <Typography.Paragraph strong>
                This Location has active shifts assigned to applicants. Please remove applicants from active shifts and
                try again
              </Typography.Paragraph>
            ) : (
              <Typography.Paragraph>
                {`Are you sure you want to ${actionName.toLowerCase()}  this location?`}
              </Typography.Paragraph>
            )}
          </div>
        ),

        cancelText: 'Cancel',
        okText: actionName,
        centered: true,
        okButtonProps: { disabled: !validateLocation.data.success && isActive },
        onOk: () => updateLocation.fetch({ isActive: !isActive }, location?.id),
      });
    }
  }, [validateLocation.data]);

  useEffect(() => {
    form.setFieldsValue(location);
    setEdit(() => true);
  }, [form, isEdit]);

  const onSave = () => {
    form
      .validateFields()
      .then(() => {
        setLoading(true);
        updateLocation.fetch(form.getFieldsValue(), location?.id);
      })
      .catch(() => setChanging(() => false));
  };

  useEffect(() => {
    if (updateLocation.data) {
      message.info('General info has been successfully updated');
      setChanging(() => false);
      fetch();
      setLoading(false);
    }
  }, [updateLocation.data]);

  useEffect(() => {
    setLoading(false);
  }, [updateLocation.error]);

  useMessageError([validateLocation, updateLocation]);

  return (
    <Space className={styles.wrapper} direction="vertical">
      <Form
        form={form}
        className={styles.form}
        requiredMark={false}
        layout="vertical"
        onFieldsChange={(_: FieldData[], allFields: FieldData[]) => {
          if (location) {
            setChanging(() => allFields.some((field) => field.value !== location![field.name[0] as keyof Locations]));
          }

          if (allFields.some((field) => field?.errors?.length)) {
            setChanging(() => false);
          }
        }}
      >
        <Typography.Title className={styles.title} level={5}>
          General info
        </Typography.Title>
        <Form.Item label="Name" name="name" className={styles.input} rules={[validationRules]}>
          <Input />
        </Form.Item>
        <InputAddress label="Address" name="address" required />
        <Form.Item label="General Contact Phone" name="phone" className={styles.input} rules={[validationRules]}>
          <PhoneNumberInput placeholder="General Phone Number" />
        </Form.Item>
        <Form.Item label="General Contact Email" name="email" className={styles.input} rules={[validationRules]}>
          <Input />
        </Form.Item>

        <Typography.Paragraph strong>Status</Typography.Paragraph>
        {isAdmin ? (
          <Button
            danger={isActive}
            loading={validateLocation.loading}
            type={!isActive ? 'primary' : undefined}
            onClick={() => validateLocation.fetch(undefined, location?.id)}
          >
            {actionName}
          </Button>
        ) : (
          <Typography.Text>
            If you want to change the status of this location, please
            {' '}
            <NavLink to="/contact-us" style={{ color: COLOR_PRIMARY }}>
              contact us.
            </NavLink>
          </Typography.Text>
        )}
      </Form>
      {contextHolder}
    </Space>
  );
}
