import React, { createContext, useState, PropsWithChildren } from 'react';
import { FormInstance } from 'antd';
import { DefaultFetchError, FetchSuccess, FetchUpdate } from '../../../../hooks/fetch';
import { ExperiencesAllUpdateParams } from '../../../../hooks/experience';
import { DocumentsAllUpdateParams } from '../../../../hooks/document';
import { InductionsAllUpdateParams } from '../../../../hooks/inductions';

interface StateFormData {
  form: FormInstance;
  update: FetchUpdate<
    FetchSuccess,
    DefaultFetchError,
    ExperiencesAllUpdateParams[] | DocumentsAllUpdateParams[] | InductionsAllUpdateParams[]
  >;
}

interface StateForm {
  [key: string]: StateFormData;
}

export interface SettingsContextProps {
  loading: boolean;
  setLoading: (loading: boolean) => void;
  form: StateForm;
  setForm: (callback: (state: StateForm) => StateForm) => void;
  activeTab: string;
  setActiveTab: (callback: () => string) => void;
  onSave: (() => void) | undefined;
  isEdit: boolean;
  setEdit: (isEdit: boolean) => void;
  isChanging: boolean;
  setChanging: (isChanging: boolean) => void;
  isValid: boolean;
  setValid: (isChanging: boolean) => void;
  isModalOpen: boolean;
  setModalOpen: (isChanging: boolean) => void;
}

const defaultValue: SettingsContextProps = {
  loading: false,
  setLoading: () => {
    // default
  },
  form: {},
  setForm: () => {
    // default
  },

  activeTab: '',
  setActiveTab: () => {
    // default
  },

  onSave: () => {
    // default
  },
  isEdit: false,
  setEdit: () => {
    // default
  },
  isChanging: false,
  setChanging: () => {
    // default
  },
  isValid: false,
  setValid: () => {
    // default
  },
  isModalOpen: false,
  setModalOpen: () => {
    // default
  },
};

export const SettingsContext = createContext<SettingsContextProps>(defaultValue);

interface SettingsProviderProps extends PropsWithChildren {
  defaultTab: string;
}

export default function SettingsProvider({ defaultTab, children }: SettingsProviderProps) {
  const [form, setForm] = useState<StateForm>({});
  const [activeTab, setActiveTab] = useState(defaultTab);
  const [loading, setLoading] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [isChanging, setChanging] = useState(false);
  const [isValid, setValid] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const onSave = () => {
    setLoading(true);
    const values = form?.[activeTab]?.form?.getFieldsValue();

    form?.[activeTab]?.update.fetch(values?.[activeTab]);
  };

  return (
    <SettingsContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        loading,
        setLoading,
        form,
        setForm,

        activeTab,
        setActiveTab,

        onSave,

        isEdit,
        setEdit,
        isChanging,
        setChanging,
        isValid,
        setValid,
        isModalOpen,
        setModalOpen,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

export const useSettingsContext = (): SettingsContextProps => React.useContext(SettingsContext);
