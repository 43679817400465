import React, { useEffect, useState } from 'react';
import { Alert, CollapseProps, Space } from 'antd';
import Header from '../Common/Header';
import Collapse from '../../../../Common/Collapse';
import Loading from '../../../../Common/Loading';
import { useSearchParams } from '../../../../../hooks/useSearchParams';
import { useDomainShiftsGet } from '../../../../../hooks/domain';
import NestedLocations from './NestedLocations';
import { getMessageInError } from '../../../../../hooks/fetch';
import { useCollapseContext } from '../../../../Common/Context';
import NoData from '../../../../Common/NoData';
import Counter from '../../../../Common/Counter';
import { getValidSearchParams } from '../../../../../utils';
import { useLoadingTimeout } from '../../../../../hooks/common';
import { useFilterData } from '../../../../../store/filter';
import styles from './index.module.scss';

interface ShiftListCollapseProps {
  openShift?: boolean;
}

function ShiftListCollapse({ openShift }: ShiftListCollapseProps): React.ReactNode {
  const [searchParams, setSearchParams, paramsWithoutTableProps] = useSearchParams();
  const domainShiftsGet = useDomainShiftsGet();
  const filterData = useFilterData();
  const [rollbackToDefaultDate, setRollbackToDefaultDate] = useState(false);
  const loading = useLoadingTimeout([domainShiftsGet]) || filterData.isFiltering;

  useEffect(() => {
    if (!domainShiftsGet.error && !rollbackToDefaultDate
      && domainShiftsGet.data && !domainShiftsGet.data?.length
      && paramsWithoutTableProps.from && !paramsWithoutTableProps.to
    ) {
      setSearchParams({ ...getValidSearchParams('*', searchParams), from: [] });
      setRollbackToDefaultDate(true);
    }
  }, [rollbackToDefaultDate, domainShiftsGet.data, searchParams]);

  useEffect(() => {
    domainShiftsGet.fetch(paramsWithoutTableProps);
  }, [paramsWithoutTableProps]);

  const items: CollapseProps['items'] = (domainShiftsGet.data || []).map(({ id, title, shiftCount }) => ({
    key: id,
    label: (
      <Space>
        {title}
        <Counter count={shiftCount} />
      </Space>
    ),
    children: <NestedLocations domainId={id} openShift={openShift} />,
  }));

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Header />
      </div>

      <div className={styles.collapseWrapper}>
        {loading ? (
          <div className={styles.loading}>
            <Loading />
          </div>
        ) : null}

        {domainShiftsGet.error ? (
          <Alert
            type="error"
            message={getMessageInError(domainShiftsGet.error)}
            closable
            onClose={domainShiftsGet.clearError}
          />
        ) : null}

        {!loading && !domainShiftsGet.error ? <Collapse items={items} /> : null}
        {!loading && !domainShiftsGet.error && !items.length ? <NoData /> : null}
      </div>
    </div>
  );
}

ShiftListCollapse.defaultProps = {
  openShift: false,
};

export default function ShiftListProvider({ openShift }: ShiftListCollapseProps) {
  const { setList } = useCollapseContext();

  useEffect(
    () => () => {
      setList([]);
    },
    [],
  );

  return (
    <div className={styles.wrapper}>
      <ShiftListCollapse openShift={openShift} />
    </div>
  );
}

ShiftListProvider.defaultProps = {
  openShift: false,
};
