import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Collapse as AntdCollapse } from 'antd';
import type { CollapseProps } from 'antd';
import { useCollapseContext } from '../Context';

import styles from './index.module.scss';

const getExpandIcon = (isActive = false) => (isActive ? <MinusOutlined /> : <PlusOutlined />);

type CommonCollapseProps = {
  items: CollapseProps['items'];
  accordion?: boolean;
  destroyInactivePanel?: boolean;
};

function Collapse({ accordion, destroyInactivePanel, items }: CommonCollapseProps) {
  const { list, setList } = useCollapseContext();
  const itemsIds = items?.map((item) => item.key);
  const activeKeys = list.filter((id) => items?.find((item) => item.key === id));
  const onChange = (key: string | string[]) => {
    const keys = Array.isArray(key) ? key : [key];

    setList([...list.filter((id) => !itemsIds?.includes(id)), ...keys]);
  };

  return (
    <AntdCollapse
      defaultActiveKey={activeKeys.length ? activeKeys : [(items && items[0] ? items[0]?.key || '' : '') as string]}
      accordion={accordion}
      destroyInactivePanel={destroyInactivePanel}
      size="large"
      items={items}
      onChange={onChange}
      expandIcon={({ isActive }) => getExpandIcon(isActive)}
      className={styles.collapse}
      ghost
    />
  );
}

Collapse.defaultProps = {
  destroyInactivePanel: undefined,
  accordion: undefined,
};

export default Collapse;
