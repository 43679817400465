import React from 'react';
import Simple from '../../components/Layout/Simple';

export default function NotFound(): React.ReactNode {
  document.title = `${process.env.REACT_APP_TITLE_NAME}: Not found`;

  return (
    <Simple>
      <div>Not found</div>
    </Simple>
  );
}
