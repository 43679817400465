import {
  App, Button, Space, Input, Badge, Alert,
} from 'antd';
import { useNavigate, NavLink, useLocation } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import ShiftProvider, { useShiftContext } from './Context';
import TopPanel from '../../Common/TopPanel';
import Details from '../../Common/Shift/Details';
import { useShiftApprove, useShiftUpdate } from '../../../hooks/shift';
import ShiftStatus, {
  applyStatuses, editStatuses, adminNotEditStatuses, getShiftStatus,
} from '../../../enums/shift';
import { useSimpleModal } from '../../Common/Modal/Simple';
import { getBudgeColor } from '../../Common/Badge';
import Apply from '../../Common/Shift/Apply';
import { useMessageError } from '../../../hooks/common';
import { generateDefaultUrl } from '../../../routes';
import { useAuthState } from '../../../store/auth';

import styles from './index.module.scss';

function ShiftForm() {
  const { user } = useAuthState();
  const shiftApprove = useShiftApprove();
  const updateShift = useShiftUpdate();
  const {
    shift, isAdmin, isApplicant, applicant,
  } = useShiftContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { open, contextHolder } = useSimpleModal();
  const reason = useRef('');
  const { message } = App.useApp();
  const thisApplicantChoosen = applicant && applicant?.id === shift?.applicant?.id;
  const canAdminEdit = (shift && isAdmin && !adminNotEditStatuses.includes(shift?.status as ShiftStatus)) || false;

  useEffect(() => {
    if (updateShift.data) {
      message.info('Shift successfully rejected');
      navigate(`/shifts/${generateDefaultUrl(user)}`);
    }
  }, [updateShift.data]);

  useEffect(() => {
    if (shiftApprove.data) {
      message.info('Shift successfully approved');
      navigate(`/shifts/${generateDefaultUrl(user)}`);
    }
  }, [shiftApprove.data]);

  useMessageError([updateShift, shiftApprove]);

  return (
    <div className={styles.inner}>
      <TopPanel
        padding
        title={(
          <Space size="small">
            Shift details
            <Badge
              className={styles.status}
              color={getBudgeColor(shift?.status)}
              text={getShiftStatus(shift?.status)}
            />
          </Space>
        )}
        arrowBack={location?.key === 'default' ? `/shifts/${generateDefaultUrl(user)}` : true}
        rightContent={(
          <Space size="middle">
            {applicant && shift && isApplicant ? (
              applyStatuses.includes(shift?.status as ShiftStatus) && (
                <Apply
                  shift={shift}
                  withRedirect
                  showReject
                  disabledBtn={
                    // eslint-disable-next-line no-nested-ternary
                    shift?.experiences?.some((item) => applicant?.experiences?.map(({ id }) => id)?.includes(item.id))
                      ? false || shift?.applicants?.length >= shift?.maxApplicantAmount
                        ? 'The shift has reached the max number of applicants.'
                        : false
                      : 'Your experience does not meet the requirements for this shift.'
                  }
                />
              )
            ) : (
              <>
                {isAdmin && shift?.status === ShiftStatus.APPROVAL && (
                  <Button
                    type="link"
                    danger
                    onClick={() => {
                      open({
                        title: 'Please specify the rejection reason',
                        content: <Input.TextArea
                          rows={4}
                          onChange={(e) => {
                            reason.current = e.target.value;
                          }}
                        />,
                        okButtonProps: { loading: updateShift.loading },
                        cancelText: 'Cancel',
                        okText: 'Reject',
                        onOk: () => {
                          updateShift.fetch(
                            {
                              status: ShiftStatus.REJECTED,
                              notes: reason.current,
                            },
                            shift?.id,
                          );
                        },
                      });
                    }}
                  >
                    Reject
                  </Button>
                )}
                {(canAdminEdit || editStatuses.includes(shift?.status as ShiftStatus)) && (
                  <Button onClick={() => navigate('edit')}>Edit</Button>
                )}
                {isAdmin && shift?.status === ShiftStatus.APPROVAL && (
                  <Button
                    onClick={() => shiftApprove.fetch(undefined, shift?.id)}
                    type="primary"
                    loading={updateShift.loading}
                  >
                    Approve
                  </Button>
                )}
              </>
            )}
          </Space>
        )}
      />

      <Details />

      {thisApplicantChoosen && (
        <Alert
          className={styles.alert}
          message={(
            <span>
              Please
              {' '}
              <NavLink to="mailto:support@lokem.com.au">contact us</NavLink>
              {' '}
              immediately if you cannot fulfill
              this shift.
            </span>
          )}
          type="warning"
          showIcon
        />
      )}

      {contextHolder}
    </div>
  );
}

export default function Shift() {
  return (
    <div className={styles.wrapper}>
      <ShiftProvider>
        <ShiftForm />
      </ShiftProvider>
    </div>
  );
}
