import {
  Col, Form, Row, Typography, Input,
} from 'antd';
import Wrapper from '../Wrapper';

type BankDetailsProps = {
  disabled?: boolean;
};

function BankDetails({ disabled }: BankDetailsProps) {
  return (
    <Wrapper>
      <Typography.Title style={{ margin: 0 }} level={5}>
        Bank details & ABN
      </Typography.Title>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item
            name="bsb"
            label="BSB"
            style={{ marginBottom: 0 }}
            rules={[{ min: 5, message: 'Must be longer than or equal to 5 numbers' }]}
          >
            <Input disabled={disabled} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="accountNumber"
            label="Account number"
            style={{ marginBottom: 0 }}
            rules={[{ min: 5, message: 'Must be longer than or equal to 5 numbers' }]}
          >
            <Input disabled={disabled} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="abn"
            label="ABN"
            style={{ marginBottom: 0 }}
            rules={[{ min: 5, message: 'Must be longer than or equal to 5 numbers' }]}
          >
            <Input disabled={disabled} />
          </Form.Item>
        </Col>
      </Row>
    </Wrapper>
  );
}

BankDetails.defaultProps = {
  disabled: false,
};

export default BankDetails;
