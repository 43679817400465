import {
  Typography, Modal, Form, Button, Input, App,
} from 'antd';
import { useEffect } from 'react';
import { createRulesForAntd, validationNewPassword } from '../../../utils/validations';
import { useAuthState } from '../../../store/auth';
import { useUserUpdate } from '../../../hooks/user';
import { useMessageError } from '../../../hooks/common';

import styles from './index.module.scss';

const validationRules = createRulesForAntd(validationNewPassword);

type ChangePasswordProps = {
  isModalOpen: boolean;
  setIsModalOpen: (state: boolean) => void;
  fetch?: () => void;
};

export default function ChangePassword({ isModalOpen, setIsModalOpen, fetch }: ChangePasswordProps) {
  const { message } = App.useApp();
  const { user } = useAuthState();
  const updateUser = useUserUpdate();
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      password: '',
      confirmPassword: '',
    });
  }, [isModalOpen]);

  useEffect(() => {
    if (updateUser.data) {
      message.info('You have successfully updated your password');
      setIsModalOpen(false);
      if (fetch) {
        fetch();
      }
    }
  }, [updateUser.data]);

  useMessageError([updateUser]);

  return (
    <Modal
      className={styles.modal}
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={() => {
          updateUser.fetch({ password: form.getFieldValue('password') }, user?.id);
        }}
        autoComplete="off"
      >
        <Typography.Title level={5} className={styles.title}>
          Change password for your Lokem account
        </Typography.Title>

        <Form.Item name="password" label="New password *" className={styles.input} rules={[validationRules]}>
          <Input.Password
            placeholder="New password"
            onChange={() => {
              if (form.getFieldValue('confirmPassword')) {
                form.validateFields();
              }
            }}
            size="large"
          />
        </Form.Item>

        <Form.Item
          name="confirmPassword"
          label="Confirm new password *"
          className={styles.input}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }

                return Promise.reject(new Error('Passwords that you entered do not match!'));
              },
            }),
          ]}
        >
          <Input.Password placeholder="Confirm new password" size="large" />
        </Form.Item>

        <Form.Item shouldUpdate>
          {({ getFieldsValue, getFieldsError }) => {
            const { confirmPassword: confirmPasswordValue, password: passwordValue } = getFieldsValue();
            const fieldsError = getFieldsError();

            return (
              <Button
                disabled={!confirmPasswordValue || !passwordValue || fieldsError.some((item) => item.errors.length)}
                size="large"
                type="primary"
                htmlType="submit"
                block
                className={styles.btn}
                loading={updateUser.loading}
              >
                Change password
              </Button>
            );
          }}
        </Form.Item>
      </Form>
    </Modal>
  );
}

ChangePassword.defaultProps = {
  fetch: () => {
    // default
  },
};
