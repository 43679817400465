import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { SignInAction, SignInResponse } from '@triare/auth-redux/dist/saga/auth/signIn';
import { Logo } from '../../Common/Icon';
import OTPCode from './OTPCode';
import EmailAndPassword from './EmailAndPassword';
import { useAuthState } from '../../../store/auth';

import styles from './index.module.scss';

export default function SignIn() {
  const { signIn: { response } } = useAuthState();
  const [state, setState] = useState<SignInResponse>();
  const [requestData, setRequestData] = useState<SignInAction | null>(null);

  useEffect(() => {
    if (response && response.secretKey) {
      setState(response);
    }
  }, [response]);

  return (
    <Row justify="center">
      <Col className={styles.wrapper}>
        <Logo className="logo" />

        {state && state.secretKey && requestData ? (
          <OTPCode
            onBack={() => setState(undefined)}
            email={requestData?.email || 'email'}
          />
        ) : (
          <EmailAndPassword onSubmit={setRequestData} />
        )}
      </Col>
    </Row>
  );
}
