import React, { useEffect, useRef } from 'react';
import { FileTextOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { COLOR_PRIMARY } from '../../../../../../AntdConfigProvider';
import { useSimpleModal } from '../../../../../Common/Modal/Simple';
import { ShiftUpdateParams, SuccessUpdateShift } from '../../../../../../hooks/shift';
import { DefaultFetchError, FetchUpdate } from '../../../../../../hooks/fetch';
import NoteProvider, { useNoteContext } from './Context';

import styles from './index.module.scss';

function TextArea() {
  const { state, setState } = useNoteContext();

  return (
    <Input.TextArea
      rows={4}
      value={state}
      onChange={(e) => {
        setState(e.currentTarget.value);
      }}
    />
  );
}

interface NoteIconProps {
  id: string;
  shiftUpdate: FetchUpdate<SuccessUpdateShift, DefaultFetchError, ShiftUpdateParams>;
}

function NoteIcon({ id, shiftUpdate }: NoteIconProps) {
  const { open, contextHolder } = useSimpleModal();
  const { state: notes } = useNoteContext();
  const refState = useRef<string>(notes);

  useEffect(() => {
    refState.current = notes;
  }, [notes]);

  return (
    <>
      <FileTextOutlined
        style={notes ? { color: COLOR_PRIMARY } : { color: '#8C8C8C' }}
        onClick={() => {
          open({
            className: styles.changePositionTextarea,
            style: {
              top: 'calc((100vh - 184px) / 2)',
            },
            icon: <FileTextOutlined style={{ color: COLOR_PRIMARY }} />,
            title: 'Edit notes',
            content: <TextArea />,
            cancelText: 'Cancel',
            okText: 'Save',
            onOk: () => {
              shiftUpdate.fetch({ notes: refState.current }, id);
            },
          });
        }}
      />
      {contextHolder}
    </>
  );
}

interface NoteProps extends NoteIconProps {
  notes: string;
}

export default function Note({ id, notes, shiftUpdate }: NoteProps): React.ReactNode {
  return (
    <NoteProvider notes={notes}>
      <NoteIcon id={id} shiftUpdate={shiftUpdate} />
    </NoteProvider>
  );
}
