import { useEffect, useState } from 'react';
import { InfoCircleTwoTone } from '@ant-design/icons';
import { useLevelsGet } from '../../../../../hooks/levels';
import CustomDropdown from '../../../../Common/CustomDropdown';
import { useMessageError } from '../../../../../hooks/common';
import { COLOR_PRIMARY } from '../../../../../AntdConfigProvider';

export interface SelectProps {
  label: string;
  value: string;
}

export default function Levels() {
  const levelsGet = useLevelsGet();
  const [levels, setLevels] = useState<SelectProps[] | null>(null);

  useEffect(() => {
    levelsGet.fetch();
  }, []);

  useEffect(() => {
    if (levelsGet.data) {
      setLevels(levelsGet?.data?.map(({ title }) => ({ label: title, value: title })));
    }
  }, [levelsGet.data]);

  useMessageError([levelsGet]);

  return (
    <CustomDropdown
      items={levels}
      setItems={setLevels}
      itemName="level"
      label="Level"
      tooltip={{
        title: '\'Level\' allows you to create Roles with different experience levels.',
        icon: <InfoCircleTwoTone style={{ cursor: 'help' }} twoToneColor={COLOR_PRIMARY} />,
      }}
    />
  );
}
