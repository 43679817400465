import React from 'react';
import { Provider } from 'react-redux';
import localeData from 'dayjs/plugin/localeData';
import dayjs from 'dayjs';
// import { GoogleOAuthProvider } from '@react-oauth/google';
import store from './store';
import Routes from './routes';
import AntdConfigProvider from './AntdConfigProvider';
import CollapseProvider from './components/Common/Context';
import './index.scss';

dayjs.extend(localeData);

export default function App(): React.ReactNode {
  document.title = process.env.REACT_APP_TITLE_NAME || '';

  return (
    // <GoogleOAuthProvider clientId={`${process.env.REACT_APP_AUTH_GOOGLE_CLIENT_ID}`}>
    <Provider store={store}>
      <AntdConfigProvider>
        <CollapseProvider>
          <Routes />
        </CollapseProvider>
      </AntdConfigProvider>
    </Provider>
    // </GoogleOAuthProvider>
  );
}
