import {
  Form, Typography, Select, App, InputNumber,
} from 'antd';
import { useEffect } from 'react';
import { FieldData } from 'rc-field-form/lib/interface';
import { useSettingsContext } from '../Context';
import { Applicant } from '../../../../../types/applicant';
import Wrapper from '../../../../Common/Applicant/Wrapper';
import { useApplicantEmploymentTypesGet, useUpdateApplicant } from '../../../../../hooks/applicant';
import { useMessageError } from '../../../../../hooks/common';
import { EmploymentType } from '../../../../../enums/applicant';
import { UserStatus } from '../../../../../enums/user';
import styles from './index.module.scss';

export default function Employment() {
  const employmentTypes = useApplicantEmploymentTypesGet();
  const updateApplicant = useUpdateApplicant();
  const {
    applicant, fetch, setActiveTab, applicantId, setChanging, isAdmin, isEdit, setEdit, setLoading, setOnSave,
  } = useSettingsContext();
  const isPending = (isAdmin && applicant && applicant.user.status === UserStatus.PENDING) || false;
  const [form] = Form.useForm();
  const { message } = App.useApp();
  const fromValues = Form.useWatch([], form);

  const onSave = () => {
    form
      .validateFields()
      .then(() => {
        setLoading(true);
        updateApplicant.fetch(form.getFieldsValue(), applicantId);
      })
      .catch(() => {
        setChanging(() => false);
      });
  };

  useEffect(() => {
    form.setFieldsValue({ ...applicant });
  }, [applicant, form, isEdit]);

  useEffect(() => {
    if (updateApplicant.data) {
      message.success('Applicant info has been successfully updated');
      setEdit(() => true);
      setChanging(() => false);
      fetch();
      setLoading(false);
    }
  }, [updateApplicant.data]);

  useEffect(() => {
    setActiveTab(() => 'employment');
    employmentTypes.fetch();

    setEdit(() => true);
    setChanging(() => false);
    setOnSave(() => onSave);
  }, []);

  const onFieldsChange = (_: FieldData[], allFields: FieldData[]) => {
    if (applicant) {
      setChanging(() => allFields.some((field) => field.value !== applicant![field.name[0] as keyof Applicant]));
    }

    if (allFields.some((field) => field?.errors?.length)) {
      setChanging(() => false);
    }
  };

  useMessageError([employmentTypes, updateApplicant]);

  useEffect(() => {
    setLoading(false);
  }, [updateApplicant.error, employmentTypes.error]);

  return (
    <div className={styles.wrapper}>
      <Form<Applicant> form={form} layout="vertical" disabled={!isAdmin || isPending} onFieldsChange={onFieldsChange}>
        <Wrapper>
          <Typography.Title className={styles.title} level={5}>
            Employment type
          </Typography.Title>
          <Form.Item
            name="employmentType"
            label="Please specify this person’s employment type"
            className={styles.label}
          >
            <Select
              loading={employmentTypes.loading}
              className={styles.select}
              options={employmentTypes?.data?.length ? employmentTypes.data : []}
            />
          </Form.Item>
        </Wrapper>
        {(fromValues?.employmentType === EmploymentType.PART_TIME
          || fromValues?.employmentType === EmploymentType.FULL_TIME) && (
          <Wrapper>
            <Typography.Title className={styles.title} level={5}>
              Rostering
            </Typography.Title>
            <Typography.Paragraph>
              If this team member is on a contracted number of hours per week, please enter them below.
            </Typography.Paragraph>
            <Form.Item name="contractedHoursPerWeek" label="Contracted hours per week" className={styles.label}>
              <InputNumber placeholder="Input" max={168} className={styles.select} />
            </Form.Item>
            <Form.Item name="hourlyRate" label="Hourly rate" className={styles.label}>
              <InputNumber placeholder="Input" className={styles.select} />
            </Form.Item>
          </Wrapper>
        )}
        {fromValues?.employmentType === EmploymentType.CASUAL && (
          <Wrapper>
            <Typography.Title className={styles.title} level={5}>
              Casual
            </Typography.Title>
            <Typography.Paragraph>
              For this casual team member, please specify the loading percentage.
            </Typography.Paragraph>
            <Form.Item name="loadingPercentage" label="Loading percentage" className={styles.label}>
              <InputNumber placeholder="Input" max={100} className={styles.select} />
            </Form.Item>
          </Wrapper>
        )}
      </Form>
    </div>
  );
}
