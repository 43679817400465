import { useEffect } from 'react';
import { Button, App } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { useLocationContext } from '../../Context';
import { useLocationsRolesCreate, useLocationsRolesId } from '../../../../../hooks/locationsRoles';
import { useMessageError } from '../../../../../hooks/common';

import styles from './index.module.scss';

type CopyRoleProps = {
  id: string;
};

export default function CopyRole({ id }: CopyRoleProps) {
  const getRole = useLocationsRolesId();
  const createRole = useLocationsRolesCreate();
  const { fetch, isChanging } = useLocationContext();
  const { message } = App.useApp();

  useEffect(() => {
    if (createRole.data) {
      fetch();
      message.info('You have successfully copied role');
    }
  }, [createRole.data]);

  useEffect(() => {
    if (getRole.data) {
      const { orientation, id: roleId, ...role } = getRole.data;

      createRole.fetch({
        ...role,
        level: `${role.level} copy`,
        location: role.location?.id,
        experiences: role.experiences?.map((item) => item.id),
        documents: role.documents?.map((item) => item.id),
        inductions: role.inductions?.map((item) => item.id),
      });
    }
  }, [getRole.data]);

  useMessageError([getRole, createRole]);

  return (
    <Button
      type="text"
      size="small"
      className={styles.btn}
      onClick={(e) => {
        e.stopPropagation();
        getRole.fetch(undefined, id);
      }}
      disabled={isChanging}
    >
      <CopyOutlined className={styles.icon} />
    </Button>
  );
}
