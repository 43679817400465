import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Collapse as AntdCollapse } from 'antd';
import type { CollapseProps } from 'antd';
import clsx from 'clsx';
import { isRoleEnough, UserRoles } from '../../../../enums/user';
import { useAuthState } from '../../../../store/auth';

import styles from './index.module.scss';

type CommonCollapseProps = {
  items: CollapseProps['items'];
  defaultActive?: string[];
  onChange?: (key: string | string[]) => void;
};

const getExpandIcon = (isActive = false) => (isActive ? <MinusOutlined /> : <PlusOutlined />);

function Collapse({ items, defaultActive, onChange }: CommonCollapseProps) {
  const { user } = useAuthState();
  const isAdmin = isRoleEnough(user?.role, UserRoles.LOKEM_ADMIN);

  return (
    <AntdCollapse
      onChange={onChange}
      size="large"
      items={items}
      defaultActiveKey={defaultActive}
      expandIcon={({ isActive }) => getExpandIcon(isActive)}
      className={clsx(styles.collapse, { [styles.extraMarging]: !isAdmin })}
      ghost
    />
  );
}

Collapse.defaultProps = {
  defaultActive: ['1', '2', '3', '4'],
  onChange: undefined,
};

export default Collapse;
