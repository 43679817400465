import { useEffect } from 'react';
import { Form, App } from 'antd';
import { FieldData } from 'rc-field-form/lib/interface';
import { useNavigate, useParams } from 'react-router-dom';
import Fields from './Fields';
import { useClientContext } from '../Context';
import { getMessageInError } from '../../../../hooks/fetch';

import styles from './index.module.scss';

export interface FieldsType {
  name: string;
  address: string;
  phone: string;
  email: string;
  autoApproved: boolean;
  twoFAtoggle: boolean;
}

export const initialValues: FieldsType = {
  name: '',
  address: '',
  phone: '',
  email: '',
  autoApproved: true,
  twoFAtoggle: true,
};

export default function ClientForm() {
  const { clientId } = useParams();
  const {
    resource, action, isEdit, setForm, setOnSave, setChanging, mode,
  } = useClientContext();
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const getResources = () => {
    if (resource) {
      resource.fetch(undefined, clientId);
    }
  };

  useEffect(() => {
    form.setFieldsValue(resource?.data);
  }, [form, isEdit, resource?.data]);

  useEffect(() => {
    if (action?.data && !action?.error) {
      getResources();
    }
  }, [action?.data, clientId]);

  useEffect(() => {
    setChanging(() => false);
    setForm(() => form);
    setOnSave(() => () => {
      if (action) {
        action.fetch(form.getFieldsValue(), clientId);
      }
    });
  }, []);

  useEffect(() => {
    if (action?.data) {
      setChanging(() => false);
    }
  }, [action?.data]);

  useEffect(() => {
    if (action?.data && !action?.error) {
      if (mode === 'create') {
        message.info('Client has been successfully created.');
        navigate('/clients');

        return;
      }

      message.info('Client successfully updated');
    }
  }, [action?.data]);

  // eslint-disable-next-line
  const onFieldsChange = (changedFields: FieldData[], allFields: FieldData[]) => {
    setChanging(() => {
      if (resource?.data) {
        return (
          allFields.every((field) => field.errors?.length === 0)
          && allFields.filter((field) => {
            const value = typeof field.value === 'undefined' ? '' : field.value;

            // @ts-ignore @typescript-eslint/ban-ts-comment
            // eslint-disable-next-line implicit-arrow-linebreak
            return value !== resource?.data?.[field.name[0]];
          }).length > 0
        );
      }

      const fields = allFields.filter(
        ({ name }) => name.toString() !== 'twoFAtoggle' && name.toString() !== 'autoApproved',
      );

      return (
        fields.every((field) => field.errors?.length === 0)
        && fields.filter((field) => {
          const value = typeof field.value === 'undefined' ? '' : field.value;

          // @ts-ignore @typescript-eslint/ban-ts-comment
          // eslint-disable-next-line implicit-arrow-linebreak
          return value === initialValues[field.name[0]];
        }).length === 0
      );
    });
  };

  useEffect(() => {
    if (action?.error) {
      message.error(getMessageInError(action.error));
      action.clearError();
    }

    if (resource?.error) {
      message.error(getMessageInError(resource.error));
      resource.clearError();
    }
  }, [action?.error, resource?.error]);

  return (
    <div className={styles.wrapper}>
      <Form<FieldsType>
        form={form}
        initialValues={initialValues}
        requiredMark={false}
        disabled={!isEdit}
        onFieldsChange={onFieldsChange}
        layout="vertical"
      >
        <Fields getResources={getResources} />
      </Form>
    </div>
  );
}
