enum ShiftStatus {
  DRAFT = 'draft',
  APPROVAL = 'approval',
  REJECTED = 'rejected',
  DELETE = 'delete',
  REQUEST = 'request',
  OPEN = 'open',
  PENDING = 'pending',
  CANCELLED = 'cancelled',
  INDUCTION = 'induction',
  FILLED = 'filled',
  COMPLETED = 'completed',
  EXPIRED = 'expired',
}

// eslint-disable-next-line
export const getShiftStatus = (status?: any): string => {
  switch (typeof status === 'string' ? status?.toLowerCase() : status) {
    case ShiftStatus.DELETE:
      return 'Deleted';
    case ShiftStatus.INDUCTION:
      return 'Induction Required';
    case ShiftStatus.PENDING:
      return 'Pending Selection';
    case ShiftStatus.REQUEST:
      return 'Open to Preferred';
    case ShiftStatus.APPROVAL:
      return 'Under Review';
    default:
      return status;
  }
};

export const editStatuses = [ShiftStatus.DRAFT, ShiftStatus.APPROVAL, ShiftStatus.REJECTED];
export const adminNotEditStatuses = [ShiftStatus.EXPIRED, ShiftStatus.DELETE];
export const applyStatuses = [ShiftStatus.REQUEST, ShiftStatus.OPEN, ShiftStatus.PENDING];

export const assignedStatuses = [
  ShiftStatus.DRAFT,
  ShiftStatus.INDUCTION,
  ShiftStatus.FILLED,
];

export const unassignedStatuses = [
  ...assignedStatuses,
  ShiftStatus.PENDING,
  ShiftStatus.APPROVAL,
  ShiftStatus.REQUEST,
  ShiftStatus.OPEN,
];

export const activeStatuses = [
  ShiftStatus.REQUEST,
  ShiftStatus.OPEN,
  ShiftStatus.PENDING,
  ShiftStatus.INDUCTION,
  ShiftStatus.FILLED,
];

export const deleteStatuses = [
  ShiftStatus.DRAFT,
  ShiftStatus.APPROVAL,
  ShiftStatus.REJECTED,
  ShiftStatus.EXPIRED,
  ShiftStatus.CANCELLED,
];

export const defaultStatuses = [
  ShiftStatus.OPEN,
  ShiftStatus.DRAFT,
  ShiftStatus.APPROVAL,
  ShiftStatus.PENDING,
  ShiftStatus.REQUEST,
  ShiftStatus.INDUCTION,
  ShiftStatus.FILLED,
];

export const notCanceledStatuses = [
  ShiftStatus.COMPLETED,
  ShiftStatus.CANCELLED,
  ShiftStatus.EXPIRED,
  ShiftStatus.DRAFT,
];

export default ShiftStatus;
