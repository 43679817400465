import { useEffect } from 'react';
import {
  Typography, List, App, Upload, Form, Space,
} from 'antd';
import type { UploadProps, UploadFile } from 'antd';
import { FormInstance } from 'antd/lib/form';
import {
  DeleteOutlined, InboxOutlined, FilePdfFilled, LoadingOutlined,
} from '@ant-design/icons';
import clsx from 'clsx';
import { useLocationContext } from '../../Context';
import { getMessageInError } from '../../../../../hooks/fetch';
import { useUploadFile } from '../../../../../hooks/file';
import ViewAction from '../../../../Common/Applicant/ViewAction';

import styles from './index.module.scss';

type UploaderProps = {
  fileList: UploadFile[];
  setFileList: (fileList: UploadFile[]) => void;
  form: FormInstance;
};

export default function Uploader({ fileList, setFileList, form }: UploaderProps) {
  const uploader = useUploadFile();
  const { isEdit, setChanging, setLoading } = useLocationContext();
  const { message } = App.useApp();

  const handleChange = () => {
    setChanging(() => true);
  };

  useEffect(() => {
    if (!fileList) {
      setChanging(() => false);
    }

    form.setFieldValue(
      'files',
      fileList.map((file) => file.uid),
    );
  }, [fileList]);

  useEffect(() => {
    if (uploader.data) {
      setFileList([...fileList, { name: uploader.data.originalName, uid: uploader.data.id }]);
      handleChange();
      setLoading(false);
    }
  }, [uploader.data]);

  useEffect(() => {
    if (uploader.error) {
      message.error(getMessageInError(uploader.error));
      uploader.clearError();
    }
    setLoading(false);
  }, [uploader.error]);

  const props: UploadProps = {
    multiple: true,
    showUploadList: false,
    beforeUpload: (file) => {
      setLoading(true);
      const formData = new FormData();

      formData.append('file', file as Blob);
      uploader.fetch(formData);

      return false;
    },
  };

  const handleRemove = (file: UploadFile) => {
    setFileList(fileList.filter((f) => f.uid !== file.uid));
    handleChange();
  };

  return (
    <div className={styles.wrapper}>
      {fileList.length > 0 && (
        <List
          className={styles.fileList}
          itemLayout="horizontal"
          dataSource={fileList}
          renderItem={(file) => (
            <List.Item
              actions={[
                <Space key="actions">
                  <ViewAction fileId={file.uid} name={file.name} />
                  {isEdit && <DeleteOutlined key="delete" onClick={() => handleRemove(file)} />}
                </Space>,
              ]}
              className={styles.fileRow}
            >
              <List.Item.Meta
                title={(
                  <div className={clsx(styles.fileItem, file.status === 'error' && styles.fileError)}>
                    {file.status === 'uploading' ? (
                      <LoadingOutlined className={styles.fileIcon} spin />
                    ) : (
                      <FilePdfFilled className={styles.fileIcon} />
                    )}
                    <Typography.Text ellipsis className={styles.fileName}>
                      {file.name}
                    </Typography.Text>
                  </div>
                )}
              />
            </List.Item>
          )}
        />
      )}
      <Form.Item name="files">
        <Upload.Dragger {...props} className={styles.dragger} fileList={fileList}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
          <p className="ant-upload-hint">You can upload one or multiple files. </p>
        </Upload.Dragger>
      </Form.Item>
    </div>
  );
}
