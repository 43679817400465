import React, { useEffect, useState } from 'react';
import { Form as AntdForm, Input, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { signIn as signInAction, SignInAction } from '@triare/auth-redux/dist/saga/auth/signIn';
import { Action } from '@triare/auth-redux';
import { NavLink } from 'react-router-dom';
import { Error, AnyObject } from '@triare/auth-redux/dist/saga/common';
import Form from '../Form';
import { validationSignInEmailAndPassword, createRulesForAntd } from '../../../../utils/validations';
import { RootState } from '../../../../store';
import { moduleName } from '../../../../store/auth';

import styles from './index.module.scss';

const validationRules = createRulesForAntd(validationSignInEmailAndPassword);

interface EmailAndPasswordProps {
  authorized: boolean;
  response: AnyObject | null;
  error: Error | null;
  onSubmit?: (values: SignInAction | null) => void
  signIn: (payload: SignInAction) => Action
}

function EmailAndPassword({
  authorized, response, error, signIn, onSubmit,
}: EmailAndPasswordProps): React.ReactNode | null {
  const [signInData, setSignInData] = useState<SignInAction | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (!error && response && !authorized && onSubmit) {
      onSubmit(signInData);
    }
  }, [authorized, response, error, onSubmit]);

  return (
    <>
      <Form<SignInAction>
        validationRules={validationRules}
        signIn={(props) => {
          // if (onSubmit) {
          //   onSubmit(props);
          // }
          const data: SignInAction = {
            ...props,
            // _query: {
            //   lang: 'en',
            // },
          };

          setSignInData(data);
          signIn(data);
        }}
      >
        <AntdForm.Item name="email" label={`${t('email')} *`} rules={[validationRules]}>
          <Input
            placeholder="Email"
            size="large"
            readOnly
            onFocus={(e) => e.currentTarget.removeAttribute('readonly')}
          />
        </AntdForm.Item>

        <AntdForm.Item
          name="password"
          label={`${t('password')} *`}
          className={styles.password}
          rules={[validationRules]}
        >
          <Input.Password placeholder="Password" size="large" />
        </AntdForm.Item>
      </Form>

      <Typography.Text strong style={{ textAlign: 'center', display: 'block', width: '100%' }}>
        Don’t have an account? &thinsp;
        <NavLink to="/sign-up">
          Sign Up
        </NavLink>
      </Typography.Text>
    </>
  );
}

EmailAndPassword.defaultProps = {
  onSubmit: undefined,
};

export default connect((state: RootState) => ({
  authorized: state[moduleName].authorized,
  response: state[moduleName].signIn.response,
  error: state[moduleName].signIn.error,
}), {
  signIn: signInAction,
})(EmailAndPassword);
