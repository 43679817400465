import React from 'react';
import SignUpProvider from './Context';
import Wrapper from './Wrapper';

export default function SignUp(): React.ReactNode | null {
  return (
    <SignUpProvider>
      <Wrapper />
    </SignUpProvider>
  );
}
