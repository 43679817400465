import React, {
  createContext, useState, useEffect, PropsWithChildren,
} from 'react';
import { useParams } from 'react-router-dom';
import { App } from 'antd';
import { getMessageInError } from '../../../../hooks/fetch';
import { Team, useTeamId } from '../../../../hooks/team';

type SettingsContextProps = {
  team: Team | null;
  fetch: () => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  isModalOpen: boolean;
  setModalOpen: (loading: boolean) => void;
  members: string[] | null;
  setMembers: (members: string[] | null) => void;
  addMembers: () => void;
};

const defaultValue: SettingsContextProps = {
  team: null,
  fetch: () => {
    // default
  },
  loading: false,
  setLoading: () => {
    // default
  },
  isModalOpen: false,
  setModalOpen: () => {
    // default
  },
  members: null,
  setMembers: () => {
    // default
  },
  addMembers: () => {
    // default
  },
};

export const SettingsContext = createContext<SettingsContextProps>(defaultValue);

function SettingsProvider({ children }: PropsWithChildren) {
  const { message } = App.useApp();
  const { teamId } = useParams();
  const getTeam = useTeamId();
  const [isModalOpen, setModalOpen] = useState(false);
  const [members, setMembers] = useState<string[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [team, setTeam] = useState<Team | null>(null);

  const fetch = () => {
    getTeam.fetch(undefined, teamId);
    setLoading(true);
  };

  useEffect(() => {
    if (teamId) {
      fetch();
    }
  }, []);

  useEffect(() => {
    if (getTeam.data) {
      setTeam(getTeam.data);
      setLoading(false);
    }
  }, [getTeam.data]);

  useEffect(() => {
    if (getTeam.error) {
      message.error(getMessageInError(getTeam.error));
      getTeam.clearError();
      setLoading(false);
    }
  }, [getTeam.error]);

  const addMembers = () => {
    /// test
  };

  return (
    <SettingsContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        team,
        fetch,
        loading,
        setLoading,
        isModalOpen,
        setModalOpen,
        members,
        setMembers,
        addMembers,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

export default SettingsProvider;

export const useSettingsContext = (): SettingsContextProps => React.useContext(SettingsContext);
