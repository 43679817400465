import {
  Badge, Button, Space, Tooltip,
} from 'antd';
import { DollarCircleOutlined, HighlightOutlined, PhoneOutlined } from '@ant-design/icons';
import styles from './index.module.scss';
import { Shift } from '../../../../../../hooks/shift';
import { capitalize, getDaysBetween, getHoursBetween } from '../../../../../../utils';
import { getBudgeColor } from '../../../../../Common/Badge';
import { getShiftStatus } from '../../../../../../enums/shift';
import { MultiDayIcon } from '../../../../../Common/Icon';

interface ShiftItemProps {
  shift: Shift;
}

export default function Icons({ shift }: ShiftItemProps) {
  return (
    <div className={styles.icons}>
      <Tooltip title={`Shift status: ${capitalize(getShiftStatus(shift.status))}`}>
        <Badge className={styles.status} color={getBudgeColor(getShiftStatus(shift.status))} />
      </Tooltip>

      {shift.onCallDatetimeStart ? (
        <Tooltip
          title={(
            <Space direction="vertical" size="small">
              On-call hours:
              {getDaysBetween(shift.onCallDatetimeStart, shift.onCallDatetimeEnd!)}
              {getHoursBetween(shift.onCallDatetimeStart, shift.onCallDatetimeEnd!)}
            </Space>
          )}
        >
          <PhoneOutlined className={styles.icon} />
        </Tooltip>
      ) : null}

      {shift.allowances.length ? (
        <Tooltip
          title={
            shift?.allowances?.length ? (
              <Space direction="vertical" size="small">
                Shift allowance:
                {shift?.allowances?.map((item) => `${capitalize(item.type)} - ${item.amount}$`)}
              </Space>
            ) : null
          }
        >
          <DollarCircleOutlined className={styles.icon} />
        </Tooltip>
      ) : null}

      {shift.multiShift ? (
        <Tooltip title="Multi-day shift">
          <MultiDayIcon className={styles.icon} />
        </Tooltip>
      ) : null}

      {shift.completedEdit ? (
        <Tooltip title="Edited completed shift">
          <HighlightOutlined className={styles.icon} />
        </Tooltip>
      ) : null}
    </div>
  );
}
