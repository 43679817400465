import { PropsWithChildren, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Header from '../Common/Header';
import AssignModal from '../Common/AssignModal';
import { useSimpleModal } from '../../../Common/Modal/Simple';
import { useRosterContext } from '../Context';
import styles from './index.module.scss';

export default function Wrapper({ children }: PropsWithChildren) {
  const [searchParams] = useSearchParams();
  const { assignModal } = useRosterContext();
  const { open, contextHolder } = useSimpleModal();
  const isDeactivated = searchParams.getAll('locations').length === 0 && searchParams.getAll('clients').length === 0;

  useEffect(() => {
    if (isDeactivated) {
      open({
        title: 'Select client or location',
        content: 'Please select a client or location from the sidebar to view the roster.',
        centered: true,
      });
    }
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Header />
      </div>

      <div className={styles.content}>{children}</div>

      {searchParams.getAll('locations').length === 0 && searchParams.getAll('clients').length === 0 && (
        <div className={styles.blocked}>Please select a client or location from the sidebar to view the roster.</div>
      )}

      {assignModal && <AssignModal />}
      {contextHolder}
    </div>
  );
}
