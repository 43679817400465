import React, { useMemo } from 'react';
import { Input } from 'antd';
import { debounce } from 'lodash';
import { useSearchParams } from '../../../../../hooks/useSearchParams';
import { getValidSearchParams } from '../../../../../utils';
import { useRosterContext } from '../../Context';

export default function SearchCalendar(): React.ReactNode {
  const { isApplicantPage } = useRosterContext();
  const [searchParams, setSearchParams, paramsWithoutTableProps] = useSearchParams();
  const handleSearch = useMemo(
    () => debounce((search: string) => {
      setSearchParams({
        ...getValidSearchParams('*', searchParams),
        search: search || [],
        page: '1',
      });
    }, 500),
    [searchParams],
  );

  return (
    <Input.Search
      allowClear
      defaultValue={paramsWithoutTableProps.search || ''}
      placeholder={isApplicantPage ? 'Search applicant' : 'Search role'}
      onChange={({ target: { value } }) => handleSearch(value)}
    />
  );
}
