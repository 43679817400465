import {
  Typography, Modal, Form, Button, Input, App,
} from 'antd';
import { useEffect } from 'react';
import { createRulesForAntd, validationSignInEmailAndPassword } from '../../../utils/validations';
import { useUserUpdate } from '../../../hooks/user';
import { useAuthState } from '../../../store/auth';
import { getMessageInError } from '../../../hooks/fetch';

import styles from './index.module.scss';

const validationRules = createRulesForAntd(validationSignInEmailAndPassword);

type ChangeEmailProps = {
  isModalOpen: boolean;
  setIsModalOpen: (state: boolean) => void;
  fetch?: () => void;
};

function ChangeEmail({ isModalOpen, setIsModalOpen, fetch }: ChangeEmailProps) {
  const { message } = App.useApp();
  const { user } = useAuthState();
  const updateUser = useUserUpdate();
  const [form] = Form.useForm();

  const onFinish = () => {
    const { email } = form.getFieldsValue();

    updateUser.fetch({ email }, user?.id);
  };

  useEffect(() => {
    form.setFieldsValue({
      email: '',
    });
  }, [isModalOpen]);

  useEffect(() => {
    if (updateUser.data) {
      message.info('You have successfully updated your email address');
      setIsModalOpen(false);
      if (fetch) {
        fetch();
      }
    }
  }, [updateUser.data]);

  useEffect(() => {
    if (updateUser.error) {
      message.error(getMessageInError(updateUser.error));
      updateUser.clearError();
    }
  }, [updateUser.error]);

  return (
    <Modal
      className={styles.modal}
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <Form form={form} layout="vertical" onFinish={onFinish} autoComplete="off">
        <Typography.Title level={5} className={styles.title}>
          Change email address for your Lokem account
        </Typography.Title>

        <Form.Item name="email" label="New email address *" className={styles.input} rules={[validationRules]}>
          <Input placeholder="Email" size="large" />
        </Form.Item>

        <Form.Item shouldUpdate>
          {({ getFieldsValue, getFieldsError }) => {
            const { email: emailValue } = getFieldsValue();
            const fieldsError = getFieldsError();

            return (
              <Button
                disabled={!emailValue || fieldsError.some((item) => item.errors.length)}
                size="large"
                type="primary"
                htmlType="submit"
                block
                className={styles.btn}
                loading={updateUser.loading}
              >
                Change email
              </Button>
            );
          }}
        </Form.Item>
      </Form>
    </Modal>
  );
}

ChangeEmail.defaultProps = {
  fetch: () => {
    // default
  },
};

export default ChangeEmail;
