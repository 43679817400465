import { Menu, Select as AntdSelect, SelectProps as AntdSelectProps } from 'antd';
import clsx from 'clsx';
import type { MenuProps } from 'antd';
import { useMemo } from 'react';
import { AnyObject } from '@triare/auth-redux';
import { getValidSearchParams } from '../../../../utils';
import { getItem } from '../Menu';
import { useSearchParams } from '../../../../hooks/useSearchParams';
import Loading from '../../Loading';
import { Item, sortByDisable } from '../../../../hooks/common';
import { FetchGet } from '../../../../hooks/fetch';
import Logic, { LogicExportProps } from '../Logic';

import styles from './index.module.scss';

type MenuItem = Required<MenuProps>['items'][number];

export interface SelectViewProps extends AntdSelectProps {
  items: Item[];
  title: string;
  name: string;
  loading?: boolean;
  isMine?: boolean;
  params?: AnyObject;
  fetch?: (props: AnyObject) => Promise<AnyObject | null>;
  decorateData?: (data: AnyObject, id: number, item: Item) => Item;
  defaultHook?: FetchGet;
  addFilter?: AnyObject;
  itemsAll: Item[];
}

function SelectView({
  title, name, loading, itemsAll, mode,
}: SelectViewProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const onChange = (value: string | string[]) => {
    setSearchParams({
      ...getValidSearchParams('*', searchParams),
      // eslint-disable-next-line no-nested-ternary
      [name]: Array.isArray(value) ? value : value ? [value] : [],
    });
  };

  const select: MenuItem[] = useMemo(
    () => [
      getItem(
        title,
        'subMenu',
        null,
        loading && itemsAll && !itemsAll.length
          ? [
            getItem(
              <div className={styles.loading}>
                <Loading />
              </div>,
              'loading',
            ),
          ]
          : [
            getItem(
              <AntdSelect
                popupClassName={styles.popup}
                showSearch
                value={
                    mode === 'multiple'
                      ? searchParams
                        .getAll(name)
                        .map((value) => itemsAll.find(({ key }) => key === value))
                        .filter((value) => value !== undefined)
                        .filter(
                          // eslint-disable-next-line max-len
                          (item) => item && itemsAll.some((option) => option.disabled === false && option.key === item.key),
                        )
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                        .map(({ value }) => value)
                      : searchParams.get(name)
                  }
                onChange={onChange}
                placeholder="Please select"
                options={sortByDisable(itemsAll)}
                  // eslint-disable-next-line max-len
                filterOption={(input, option) => (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())}
                className={styles?.select}
                allowClear
                mode={mode}
              />,
              'select',
              null,
            ),
          ],
      ),
    ],
    [loading, itemsAll, searchParams],
  );

  // if (!loading && defaultState.length === 0) {
  //   return null;
  // }

  return (
    <div className={styles.wrapper}>
      <Menu className={clsx(styles.menu, 'custom-menu')} defaultOpenKeys={['subMenu']} mode="inline" items={select} />

      {loading && itemsAll && itemsAll.length ? (
        <div className={styles.loading}>
          <Loading />
        </div>
      ) : null}
    </div>
  );
}

SelectView.defaultProps = {
  addFilter: undefined,
  params: {},
  loading: undefined,
  isMine: undefined,
  fetch: undefined,
  decorateData: undefined,
  defaultHook: undefined,
};

export default function Select(props: LogicExportProps) {
  return <Logic {...props} Component={SelectView} />;
}
