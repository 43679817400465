import React, { useState, useEffect, useMemo } from 'react';
import { Input } from 'antd';
import { debounce } from 'lodash';
import { SearchProps as AntdSearchProps } from 'antd/lib/input';
import { useSearchParams } from '../../../../hooks/useSearchParams';

import styles from './index.module.scss';

interface SearchProps extends AntdSearchProps {
  name: string;
  placeholder?: string
}

function Search({ name, placeholder, ...props }: SearchProps) {
  const [searchParams, setSearchParams, paramsWithoutTableProps] = useSearchParams();
  const [searchValue, setSearchValue] = useState<string | null>(searchParams.get(name));

  useEffect(() => {
    setSearchValue(searchParams.get(name) || '');
  }, [paramsWithoutTableProps]);

  const handleSearch = useMemo(
    () => debounce((value: string) => {
      setSearchParams({
        ...paramsWithoutTableProps,
        [name]: value ? [value] : [],
      });
    }, 500),
    [paramsWithoutTableProps],
  );

  const handleChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(value);
    handleSearch(value);
  };

  return (
    <div className={styles.wrapper}>
      <Input.Search
        {...props}
        value={searchValue || ''}
        placeholder={placeholder}
        className={styles.search}
        onChange={handleChange}
        allowClear
      />
    </div>
  );
}

Search.defaultProps = {
  placeholder: 'Search',
};

export default Search;
