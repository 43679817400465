import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';

dayjs.extend(utc);

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
createRoot(document.getElementById('root')!).render(<App />);

serviceWorker.unregister();
