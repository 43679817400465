import React from 'react';
import Layout from '../../../components/Layout';
import ContentAdminList from '../../../components/Pages/Admin/List';
import Filter from '../../../components/Pages/Admin/Filter';

export default function AdminList(): React.ReactNode {
  document.title = `${process.env.REACT_APP_TITLE_NAME}: Admin list`;

  return (
    <Layout leftMenu={<Filter />}>
      <ContentAdminList />
    </Layout>
  );
}
