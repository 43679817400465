import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSearchParams } from '../../../../hooks/useSearchParams';
import Title, { list } from '../Common/Title/Title';
import Calendar from '../../../Common/Calendar';
import { isRoleEnough, UserRoles } from '../../../../enums/user';
import { useAuthState } from '../../../../store/auth';

import styles from './index.module.scss';

export default function ShiftCalendar(): React.ReactNode {
  const { user } = useAuthState();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const isDeactivated = (searchParams.getAll('clients').length === 0
      && isRoleEnough(user?.role, UserRoles.LOKEM_ADMIN))

    || (searchParams.getAll('locations').length === 0
      && user?.role === UserRoles.CLIENT_ADMIN)

    || ((searchParams.getAll('locations').length === 0
      || searchParams.getAll('clients').length === 0)
      && user?.role === UserRoles.APPLICANT);

  const isOpenShiftPage = pathname.includes('/open-shifts');

  return (
    <>
      <div className={styles.calendar}>
        <Calendar
          name={<Title defaultActive={list[1].key} />}
          deactivated={isDeactivated}
        />
      </div>

      {isDeactivated ? (
        <div className={styles.blocked}>
          {/* eslint-disable-next-line no-nested-ternary */}
          {user?.role === UserRoles.APPLICANT ? (
            'Please select a Location and Client from the sidebar to view '
            + `${isOpenShiftPage ? 'Open ' : ''}Shifts in calendar view`
          )
            : user?.role === UserRoles.CLIENT_ADMIN ? (
              'Please select a Location from the sidebar to view '
              + `${isOpenShiftPage ? 'Open ' : ''}Shifts in calendar view`
            ) : (
              'Please select a Client from the sidebar to view '
              + `${isOpenShiftPage ? 'Open ' : ''}Shifts in calendar view.`
            )}
        </div>
      ) : null}
    </>
  );
}
