import { Switch, Tooltip, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { userMe } from '@triare/auth-redux/dist/saga/auth/useMe';
import { InfoCircleTwoTone } from '@ant-design/icons';
import styles from './index.module.scss';
import { useMessageError } from '../../../hooks/common';
import { useUserUpdate } from '../../../hooks/user';
import { useAuthState } from '../../../store/auth';
import { COLOR_PRIMARY } from '../../../AntdConfigProvider';

interface ReceiveEmailsProps {
  needUpdate?: boolean;
  text?: string;
  property?: string
  tooltipe?: string
}

function ReceiveEmails({
  needUpdate, text, property, tooltipe,
}: ReceiveEmailsProps) {
  const { user } = useAuthState();
  const userUpdate = useUserUpdate();
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(user?.[property as string] || false);
  }, [user?.[property as string]]);

  useEffect(() => {
    if (userUpdate.data && needUpdate) {
      dispatch(userMe());
    }
  }, [userUpdate.data]);

  useMessageError([userUpdate]);

  return (
    <div>
      <Typography.Text className={styles.emailControl} strong>
        {text}
        {tooltipe && (
        <Tooltip title={tooltipe} className={styles.tooltipe}>
          <InfoCircleTwoTone style={{ cursor: 'help' }} twoToneColor={COLOR_PRIMARY} />
        </Tooltip>
        )}
      </Typography.Text>
      <Switch
        disabled={false}
        checked={checked}
        loading={userUpdate.loading}
        onChange={(checkedEvent) => {
          setChecked(checkedEvent);
          userUpdate.fetch({ [property as string]: !checked }, user?.id);
        }}
      />
    </div>
  );
}

ReceiveEmails.defaultProps = {
  needUpdate: false,
  text: 'Receive email notifications?',
  property: 'receiveEmails',
  tooltipe: '',
};

export default ReceiveEmails;
