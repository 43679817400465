import React, { useCallback, useMemo } from 'react';
import { Button, DatePicker, Space } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Dayjs } from 'dayjs';
import { useRosterContext } from '../../../Context';

import styles from './index.module.scss';

export function switchPeriod(view: string, date: Dayjs, type: 'add' | 'subtract'): Dayjs {
  const clone = date.clone();

  switch (view) {
    case 'day':
      return clone[type](1, 'day');

    case 'week':
      return clone[type](7, 'day');

    case '2 weeks': {
      return clone[type](14, 'day');
    }

    default: // month
      return clone[type](1, 'month');
  }
}

function createBeforePeriod(view: string, date: Dayjs): Dayjs {
  return switchPeriod(view, date, 'subtract');
}

function createNextPeriod(view: string, date: Dayjs): Dayjs {
  return switchPeriod(view, date, 'add');
}

export const createStringState = (view: string, date: Dayjs): string => {
  switch (view) {
    case 'day':
      return date.format('MMM D');

    case 'week':
      return (
        `${date.format('MMM')} `
        + `${date.clone().startOf('week').format('D')}-${date.clone().endOf('week').format('D')}`
      );

    case '2 weeks': {
      const start = date.clone().startOf('week');
      const end = start.clone().add(10, 'days').endOf('week');

      if (start.format('MMM') === end.format('MMM')) {
        // eslint-disable-next-line no-useless-concat
        return `${start.format('MMM')} ` + `${start.format('D')}-${end.format('D')}`;
      }

      // eslint-disable-next-line no-useless-concat
      return `${start.format('MMM')} ${start.format('D')}` + `-${end.format('MMM')} ${end.format('D')}`;
    }

    default: // month
      return date.format('MMMM');
  }
};

export default function Period(): React.ReactNode {
  const { view, period, setPeriod } = useRosterContext();

  const onTeamChange = useCallback(
    (value: Dayjs) => (e: React.MouseEvent) => {
      e.preventDefault();
      setPeriod(value);
    },
    [],
  );

  const value = useMemo(() => createStringState(view, period), [period, view]);
  const beforePeriod = useMemo(() => createBeforePeriod(view, period), [period, view]);
  const nextPeriod = useMemo(() => createNextPeriod(view, period), [period, view]);

  return (
    <div className={styles.wrapper}>
      <label>Period: </label>
      <Space.Compact>
        <Button icon={<LeftOutlined />} onClick={onTeamChange(beforePeriod)} />
        {view === 'day' ? (
          <DatePicker
            className={styles.dayPicker}
            allowClear={false}
            value={period}
            format="MMM D"
            onChange={(date, dateString) => {
              if (date) {
                setPeriod(date);
              }
            }}
          />
        ) : (
          <div className={styles.value}>{value}</div>
        )}
        <Button icon={<RightOutlined />} onClick={onTeamChange(nextPeriod)} />
      </Space.Compact>
      <DatePicker
        onChange={(date) => {
          if (date) {
            setPeriod(period.set('year', date.get('year')));
          }
        }}
        value={period}
        picker="year"
        className={styles.picker}
      />
    </div>
  );
}
