import React, { createContext, useState, PropsWithChildren } from 'react';
import { FormInstance } from 'antd';
import { useAuthState } from '../../../../../store/auth';

type SettingsContextProps = {
  form: FormInstance | undefined;
  setForm: (callback: () => FormInstance) => void;
  isEdit: boolean;
  setEdit: (callback: () => boolean) => void;
  isChanging: boolean;
  setChanging: (callback: () => boolean) => void;
  activeTab: string;
  setActiveTab: (callback: () => string) => void;
  onSave: (() => void) | undefined;
  setOnSave: (onSave: () => void) => void;
  clientId: string | undefined;
};

const defaultValue: SettingsContextProps = {
  form: undefined,
  setForm: () => {
    // default
  },

  isEdit: false,
  setEdit: () => {
    // default
  },

  isChanging: false,
  setChanging: () => {
    // default
  },

  activeTab: '',
  setActiveTab: () => {
    // default
  },

  onSave: () => {
    // default
  },
  setOnSave: () => {
    // default
  },
  clientId: '',
};

export const SettingsContext = createContext<SettingsContextProps>(defaultValue);

function SettingsProvider({ children }: PropsWithChildren) {
  const [isChanging, setChanging] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [form, setForm] = useState<FormInstance | undefined>();
  const [onSave, setOnSave] = useState<(() => void) | undefined>();
  const [activeTab, setActiveTab] = useState('');
  const { user } = useAuthState();
  const clientId = user?.locationAdmin?.client.id || user?.manager?.client.id;

  return (
    <SettingsContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        form,
        setForm,

        isEdit,
        setEdit,

        isChanging,
        setChanging,

        activeTab,
        setActiveTab,

        onSave,
        setOnSave,

        clientId,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

export default SettingsProvider;

export const useSettingsContext = (): SettingsContextProps => React.useContext(SettingsContext);
