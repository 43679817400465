import React from 'react';
import Layout from '../../../../components/Layout';
import ContentClientUserEdit from '../../../../components/Pages/Client/User/Edit';

export default function ClientUserEdit(): React.ReactNode {
  document.title = `${process.env.REACT_APP_TITLE_NAME}: Client user: edit`;

  return (
    <Layout disableLeftMenu transparent>
      <ContentClientUserEdit />
    </Layout>
  );
}
