import React from 'react';
import Layout from '../../components/Layout';
import ContactUsContent from '../../components/Pages/ContactUs';

export default function ContactUs(): React.ReactNode {
  document.title = `${process.env.REACT_APP_TITLE_NAME}: Contact Us`;

  return (
    <Layout disableLeftMenu>
      <ContactUsContent />
    </Layout>
  );
}
