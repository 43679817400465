import React from 'react';
import Layout from '../../components/Layout';
import ContentShift from '../../components/Pages/Shift';

export default function Shift(): React.ReactNode {
  document.title = `${process.env.REACT_APP_TITLE_NAME}: Shift`;

  return (
    <Layout disableLeftMenu>
      <ContentShift />
    </Layout>
  );
}
