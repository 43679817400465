import { Tabs, Typography } from 'antd';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import List from './List';
import { useFaqCategoriesGet } from '../../../hooks/faqCategories';
import Loading from '../../Common/Loading';
import { useSearchParams } from '../../../hooks/useSearchParams';
import { useMessageError } from '../../../hooks/common';
import { useAuthState } from '../../../store/auth';
import { UserRoles, isRoleEnough } from '../../../enums/user';

import styles from './index.module.scss';

export default function FaqPage() {
  const [activeKey, setActiveKey] = useState('');
  const category = useFaqCategoriesGet();
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useAuthState();
  const isApplicant = user?.role === UserRoles.APPLICANT;
  const isClient = user?.role === UserRoles.MANAGER || user?.role === UserRoles.CLIENT_ADMIN;
  const isAdmin = isRoleEnough(user?.role, UserRoles.LOKEM_ADMIN);

  useEffect(() => {
    category.fetch();
    const value = searchParams.get('category');

    if (value && !isClient && !isApplicant) {
      setActiveKey(value);
    }
  }, []);

  useEffect(() => {
    if (category?.data?.length) {
      const { data } = category;

      if (isApplicant) {
        setActiveKey(data.find((cat) => cat.title.toLowerCase() === 'applicant')?.id || data[0].id);

        return;
      }
      if (isClient) {
        setActiveKey(data.find((cat) => cat.title.toLowerCase() === 'client')?.id || data[0].id);

        return;
      }

      if (!activeKey) {
        setActiveKey(category?.data[0]?.id);
      }
    }
  }, [category?.data]);

  const onChange = (key: string) => {
    setActiveKey(key);
    setSearchParams({ category: key || '' });
  };

  useMessageError([category]);

  return (
    <div className={styles.inner}>
      <Typography.Title level={3}>Frequently Asked Questions</Typography.Title>
      {category.loading && isAdmin ? (
        <div className={styles.loading}>
          <Loading />
        </div>
      ) : (
        <Tabs
          destroyInactiveTabPane
          className={clsx({ [styles.hideTabs]: !isAdmin })}
          activeKey={activeKey}
          onChange={onChange}
          items={
            category?.data?.map((cat) => ({
              key: cat.id,
              label: cat.title,
              children: <List loading={category.loading} category={cat.id} />,
            })) || []
          }
        />
      )}
    </div>
  );
}
