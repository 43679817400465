import { AnyObject } from '@triare/auth-redux';
import { pagingParamList } from '../contstant';
import ShiftStatus from '../enums/shift';
import { Applicant } from '../types/applicant';
import { LocationRoles } from '../types/location';
import { Client } from './client';
import { Document } from './document';
import { Experience } from './experience';
import {
  DefaultFetchError,
  FetchCreate,
  FetchGet,
  FetchGetId,
  useFetchCreate,
  useFetchGet,
  useFetchGetId,
  useFetchDelete,
  PagingParams,
  FetchSuccess,
  PagingDataResponse,
  FetchUpdate,
  useFetchUpdate,
} from './fetch';
import { Induction } from './inductions';
import { Location } from './location';
import { Orientation } from './orientation';
import { Row } from '../components/Pages/Roster/Context';

export const shiftsGetParamList = [
  'locations',
  ...pagingParamList,
];

export interface Allowances {
  id: string;
  type: string;
  amount: number;
}

export interface Shift {
  id: string;
  status: string;
  maxApplicantAmount: number;
  datetimeStart: string;
  datetimeEnd: string;
  onCallDatetimeStart: string | null;
  onCallDatetimeEnd: string | null;
  rateType: string;
  rate: number | null;
  rateRangeFrom: number | null;
  rateRangeTo: number | null;
  notes: string;
  additionalRequirements: string;
  shiftType: string;
  client: Client;
  experiences: Experience[];
  inductions: Induction[];
  documents: Document[];
  completedEdit: boolean
  domain: {
    title: string;
    id: string;
  };
  preferredApplicants: Applicant[];
  lunchBreak: number
  location: Location;
  locationRole: LocationRoles;
  applicants: Applicant[];
  orientation: Orientation;
  applicant: Applicant | null;
  multiShift: {
    id: string
    datetimeEnd: string
    datetimeStart: string
  }
  allowances: Allowances[]
}

export interface ShiftCreateParams {
  client: string;
  datetimeStart: string;
  datetimeEnd: string;
  onCallDatetimeStart?: string;
  onCallDatetimeEnd?: string;
  notes: string;
  shiftType: string;
  experiences: string[];
  inductions: string[];
  lunchBreak: number
  documents: string[];
  domain: string;
  preferredApplicants: string[];
  location: string;
  status: string;
  locationRole: string;
  rateType: string;
  rate?: number;
  rateRangeFrom?: number;
  rateRangeTo?: number;
  applicants: string[];
  orientation: string;
  additionalRequirements: string;
  applicant: string;
  maxApplicantAmount: number
  shiftDuration: string
}

export interface ShiftUpdateParams {
  client?: string;
  datetimeStart?: string;
  datetimeEnd?: string;
  onCallDatetimeStart?: string;
  onCallDatetimeEnd?: string;
  notes?: string;
  shiftType?: string;
  experiences?: string[];
  inductions?: string[];
  documents?: string[];
  domain?: string;
  preferredApplicants?: string[];
  lunchBreak?: number
  location?: string;
  status?: string;
  locationRole?: string;
  rateType?: string;
  rate?: number;
  rateRangeFrom?: number;
  rateRangeTo?: number;
  applicants?: string[];
  orientation?: string;
  additionalRequirements?: string;
  applicant?: string | null;
  maxApplicantAmount?: number;
}

export interface ShiftGetParams extends PagingParams {
  id?: string
  isMine?: boolean
  statuses?: string | string[]
  domain?: string
  location?: string
  client?: string
  applicants?: string[]
}

export interface SuccessUpdateShift extends FetchUpdate {
  status: ShiftStatus
  applicant: Applicant
}

export const useShiftUpdate = (id?: string): FetchUpdate<SuccessUpdateShift, DefaultFetchError, ShiftUpdateParams> => (
  useFetchUpdate('shifts', id)
);

export const useShiftCreate = (): FetchCreate<FetchSuccess, DefaultFetchError, ShiftCreateParams> => (
  useFetchCreate('shifts')
);

export interface ShiftPublishParams {
  ids: string[]
}

export const useShiftPublish = (): FetchUpdate<FetchSuccess, DefaultFetchError, ShiftPublishParams> => (
  useFetchUpdate('shifts/publish')
);

export interface ShiftCopyParams {
  id?: string
  datetimeStart?: string;
  datetimeEnd?: string;
  locationRole?: string;
  applicant?: string;
  client?: string;
  domain?: string;
  location?: string;
}

export const useShiftCopy = (id?: string): FetchCreate<FetchSuccess, DefaultFetchError, ShiftCopyParams> => (
  useFetchCreate('shifts/copy')
);

export const useShiftDelete = () => useFetchDelete<FetchSuccess, DefaultFetchError, string>('shifts');

export function useShiftGet<D = PagingDataResponse<Shift>, DD = D>(
  decorateData?: (data: D) => DD,
): FetchGet<D, ShiftGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, ShiftGetParams, DD>('shifts', {
    decorateData,
    autoStart: false,
    startStateLoading: false,
  });
}

export interface ShiftStatusesParams {
  isMine?: boolean
}

export function useShiftStatusesAllGet<D = string[], DD = D>(
  decorateData?: (data: D) => DD,
) {
  return useFetchGet<D, DefaultFetchError, ShiftStatusesParams, DD>('shifts/statuses/all', {
    name: 'Shift statuses',
    decorateData,
    autoStart: false,
    startStateLoading: false,
  });
}

export function useShiftStatusesGet<D = string[], DD = D>(
  decorateData?: (data: D) => DD,
) {
  return useFetchGet<D, DefaultFetchError, ShiftStatusesParams, DD>('shifts/select/statuses', {
    name: 'Shift statuses',
    decorateData,
    autoStart: false,
    startStateLoading: false,
    beforeError(err) {
      if (err && err.response?.status === 422) {
        return true;
      }

      return false;
    },
  });
}

export function useShiftTypesGet<D = string[], DD = D>(
  decorateData?: (data: D) => DD,
) {
  return useFetchGet<D, DefaultFetchError, AnyObject | undefined, DD>('shifts/types', {
    name: 'Shift types',
    decorateData,
    autoStart: false,
    startStateLoading: false,
  });
}

export interface ShiftMonthGetParams {
  isMine?: boolean
  clients?: string[]
  limit?: number
  year: number | string
  month: number | string
}

export interface ShiftMonthResponse {
  [key: number]: Shift[]
}

export function useShiftMonthGet<D = ShiftMonthResponse, DD = D>(
  decorateData?: (data: D) => DD,
): FetchGet<D, ShiftMonthGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, ShiftMonthGetParams, DD>('shifts/month', {
    decorateData,
    autoStart: false,
    startStateLoading: false,
  });
}

interface ShiftDayGetParams extends ShiftMonthGetParams {
  day: number
}

export function useShiftDayGet<D = Shift[], DD = D>(
  decorateData?: (data: D) => DD,
): FetchGet<D, ShiftDayGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, ShiftDayGetParams, DD>('shifts/day', {
    decorateData,
    autoStart: false,
    startStateLoading: false,
  });
}

export const useShiftId = (): FetchGetId<Shift> => useFetchGetId('shifts', '', { autoStart: false });

export const useShiftApply = (id = ''): FetchUpdate<Shift> => useFetchUpdate(
  'shifts/apply',
  id,
);

export const useShiftReject = (): FetchUpdate<Shift> => useFetchUpdate(
  'shifts/reject',
  '',
);

export const useShiftApprove = (): FetchUpdate<Shift> => useFetchUpdate(
  'shifts/approve',
  '',
);

interface ShiftEarningsResponse {
  lastMonth: number
  projected: number
}

export function useShiftEarnings<D = ShiftEarningsResponse, DD = D>(
  decorateData?: (data: D) => DD,
) {
  return useFetchGet<D, DefaultFetchError, undefined, DD>('shifts/earnings', {
    decorateData,
    autoStart: false,
    startStateLoading: false,
  });
}

export interface ShiftsRosterGetParams extends PagingParams {
  view?: string;
  period?: string;
  statuses?: string[];
  search?: string
  teams?: string[]
  clients?: string[]
  domain?: string
  locations?: string[]
  applicants?: string[]
  roles?: string[]
}

export const shiftsRosterGetParamList = [
  'view',
  'period',
  'statuses',
  'search',
  'teams',
  'clients',
  'domain',
  'locations',
  'applicants',
  'roles',
  ...pagingParamList,
];

export function useShiftGetByRole<D = PagingDataResponse<Row>, DD = D>(
  decorateData?: (data: D) => DD,
): FetchGet<D, ShiftGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, ShiftGetParams, DD>('shifts/by-role', {
    decorateData,
    autoStart: false,
    startStateLoading: false,
  });
}

export function useShiftGetByApplicant<D = PagingDataResponse<Row>, DD = D>(
  decorateData?: (data: D) => DD,
): FetchGet<D, ShiftGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, ShiftGetParams, DD>('shifts/by-applicant', {
    decorateData,
    autoStart: false,
    startStateLoading: false,
  });
}
export function useShiftGetPlain<D = Row[], DD = D>(
  decorateData?: (data: D) => DD,
): FetchGet<D, ShiftGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, ShiftGetParams, DD>('shifts/plain', {
    decorateData,
    autoStart: false,
    startStateLoading: false,
  });
}
