import {
  Action,
  config,
  defaultProcessingSaga,
  defaultResponseProcessing,
  fetchAuthSaga,
  FetchResponse,
  AnyObject,
} from '@triare/auth-redux';
import {
  put, delay, takeLatest, call,
} from 'redux-saga/effects';
import { actions } from './index';
import { PaginationProps } from '../types';

export type UserListData = AnyObject

export type UserListResponse = UserListData

export type UserList = PaginationProps

export const userList = (payload: UserList): Action<UserList> => ({
  type: actions.userListTrigger.toString(),
  payload,
});

export function* userListRequestSaga({ payload }: Action<UserList>) {
  yield put({
    type: actions.userListStart.toString(),
  });

  if (!payload) {
    yield put({
      type: actions.userListError.toString(),
      payload: {
        message: 'UserList: Parameters not passed.',
      },
    });

    return;
  }

  const response = (yield call(
    fetchAuthSaga,
    'users',
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    },
    {},
    config.fetchDelay,
  )) as FetchResponse;

  yield defaultResponseProcessing<UserListData, UserListResponse>(
    response,
    actions.userListSuccess.toString(),
    actions.userListError.toString(),
    false,
    (userListData) => userListData,
  );
}

export default function* saga() {
  yield takeLatest(
    actions.userListTrigger.toString(),
    defaultProcessingSaga(
      userListRequestSaga,
      {
        error: actions.userListError.toString(),
        locationCancel: actions.userListClear.toString(),
      },
    ),
  );

  yield takeLatest(actions.userListSuccess.toString(), function* clear() {
    if (config.delaySuccess) {
      yield delay(config.delaySuccess);
    }

    yield put({
      type: actions.userListClear.toString(),
    });
  });

  yield takeLatest(actions.userListError.toString(), function* errorReset() {
    if (config.delayError) {
      yield delay(config.delayError);
    }

    yield put({
      type: actions.userListErrorReset.toString(),
    });
  });
}
