import {
  DefaultFetchError,
  FetchCreate,
  FetchGet,
  FetchSuccess, FetchUpdate,
  PagingDataResponse,
  PagingParams,
  useFetchCreate, useFetchDelete,
  useFetchGet, useFetchUpdate,
} from './fetch';

export interface Experience {
  id: string
  title: string
}

export interface ExperienceCreateParams {
  title: string
}

export const useExperienceCreate = (): FetchCreate<FetchSuccess, DefaultFetchError, ExperienceCreateParams> => (
  useFetchCreate('experiences')
);

export interface ExperiencesGetParams extends PagingParams {
  title?: string
  partialTitle?: string
  orderByColumn?: 'id' | 'title'
}

export function useExperiencesAllGet<D = Experience[],
  DD = D>(decorateData?: (data: D) => DD): FetchGet<D, ExperiencesGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, ExperiencesGetParams, DD>(
    'experiences/all',
    {
      decorateData,
      autoStart: false,
      startStateLoading: false,
    },
  );
}

export function useExperiencesGet<D = Experience[],
  DD = D>(decorateData?: (data: D) => DD): FetchGet<D, ExperiencesGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, ExperiencesGetParams, DD>(
    'experiences',
    {
      decorateData,
      autoStart: false,
      startStateLoading: false,
    },
  );
}

export function useExperiencesPaginGet<D = PagingDataResponse<Experience>,
  DD = D>(decorateData?: (data: D) => DD): FetchGet<D, ExperiencesGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, ExperiencesGetParams, DD>(
    'experiences/pagginate',
    {
      decorateData,
      autoStart: false,
      startStateLoading: false,
    },
  );
}

export type ExperienceUpdateParams = Partial<ExperienceCreateParams>

export const useExperienceUpdate = (): FetchUpdate<FetchSuccess, DefaultFetchError, ExperienceUpdateParams> => (
  useFetchUpdate('experiences')
);

export interface ExperiencesAllUpdateParams extends ExperienceUpdateParams {
  id: string
}

export const useExperiencesAllUpdate = (): FetchUpdate<
  FetchSuccess,
  DefaultFetchError,
  ExperiencesAllUpdateParams[]
> => (
  useFetchUpdate('experiences/all')
);

export const useExperienceDelete = () => useFetchDelete<FetchSuccess, DefaultFetchError, string>('experiences');
