import { useState, useEffect } from 'react';
import { Collapse as AntdCollapse, Pagination } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import type { PaginationProps } from 'antd';
import { useFaqArticlesGet, faqArticlesGetParamList, faqArticlesGetParams } from '../../../../hooks/faqArticles';
import Loading from '../../../Common/Loading';
import { getValidSearchParams } from '../../../../utils';
import { pageDefault, pageSizeDefault, pageSizePossible } from '../../../../contstant';
import { useSearchParams } from '../../../../hooks/useSearchParams';
import { useMessageError } from '../../../../hooks/common';

import styles from './index.module.scss';

type ListProps = {
  category: string;
  loading: boolean;
};

type Pagination = {
  current: number;
  pageSize: number;
  pageSizeOptions: number;
  total: number;
};

const getExpandIcon = (isActive = false) => (isActive ? <MinusOutlined /> : <PlusOutlined />);

export default function List({ category, loading }: ListProps) {
  const getFaq = useFaqArticlesGet();
  const [searchParams, setSearchParams] = useSearchParams();
  const [pagination, setPagination] = useState({
    current: pageDefault,
    pageSize: pageSizeDefault,
    pageSizeOptions: pageSizePossible,
    total: 10,
  });

  useEffect(() => {
    const props: faqArticlesGetParams = {};

    faqArticlesGetParamList.forEach((key) => {
      const value = searchParams.getAll(key);

      if (value.length || !(value.length === 1 && value[0] === '')) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        props[key] = value.length === 1 ? value[0] : value;
      }
    });

    props.category = category;
    getFaq.fetch(props);
  }, [searchParams, category]);

  useEffect(() => {
    if (getFaq.data?.data.length) {
      setPagination({
        ...pagination,
        total: getFaq?.data?.meta.itemCount,
        current: getFaq?.data?.meta.page,
      });
    }
  }, [getFaq.data]);

  const handlePageChange: PaginationProps['onShowSizeChange'] = (page, pageSize) => {
    setPagination({
      ...pagination,
      current: page,
      pageSize,
    });

    setSearchParams({
      ...getValidSearchParams(faqArticlesGetParamList, searchParams),
      page: page?.toString() || '',
      pageSize: pageSize?.toString() || '',
    });
  };

  useMessageError([getFaq]);

  return getFaq.loading || loading ? (
    <div className={styles.loading}>
      <Loading />
    </div>
  ) : (
    getFaq?.data?.data && (
      <div className={styles.wrapper}>
        <AntdCollapse
          defaultActiveKey={['1']}
          size="large"
          expandIcon={({ isActive }) => getExpandIcon(isActive)}
          className={styles.collapse}
          ghost
        >
          {getFaq.data.data.map((item) => (
            <AntdCollapse.Panel className={styles.panel} header={item.title} key={item.id}>
              <p>{item.text}</p>
            </AntdCollapse.Panel>
          ))}
        </AntdCollapse>
        <Pagination
          current={pagination.current}
          onChange={handlePageChange}
          pageSize={pagination.pageSize}
          className={styles.pagination}
          total={pagination.total}
          showSizeChanger
          showQuickJumper
        />
      </div>
    )
  );
}
