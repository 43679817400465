import React, { useEffect } from 'react';
import {
  Badge, Button, App, Space, Tag, Tooltip,
} from 'antd';
import dayjs from 'dayjs';
import { DeleteOutlined } from '@ant-design/icons';
import type { ProColumns } from '@ant-design/pro-components';
import { ProCoreActionType } from '@ant-design/pro-utils/es/typing';
import { NavLink } from 'react-router-dom';
import {
  User,
  useIncompleteGet,
  usersGetParamList,
  UsersGetParams,
  useUserDeleteGeneral,
} from '../../../../hooks/user';
import { getBudgeColor } from '../../../Common/Badge';
import Table from '../../../Common/Table';
import { useSearchParams } from '../../../../hooks/useSearchParams';
import { useSimpleModal } from '../../../Common/Modal/Simple';
import { useMessageError } from '../../../../hooks/common';
import RadioSwitch from '../RadioSwitch/index';

import styles from './index.module.scss';
import { COLOR_PRIMARY } from '../../../../AntdConfigProvider';
import { dateFormat } from '../../../../contstant';

export interface ActionProps<D> {
  dom: React.ReactNode;
  entity: D;
  index: number;
  action: ProCoreActionType | undefined;
}

const columns: ProColumns<User>[] = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (text, entity) => (
      <Space>
        {entity.firstName || entity.lastName ? `${entity.firstName} ${entity.lastName} ` : '-'}
        {entity?.applicant && <Tag color={COLOR_PRIMARY}>Provisional</Tag>}
      </Space>
    ),
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
    render: (text, entity) => <NavLink to={`tel:${entity?.phone}`}>{entity.phone}</NavLink>,
  },
  {
    title: 'Email login',
    key: 'email',
    dataIndex: 'email',
    render: (text, entity) => <NavLink to={`mailto:${entity?.email}`}>{entity.email}</NavLink>,
  },
  {
    title: 'End date of license',
    key: 'endDate',
    dataIndex: 'endDate',
    // eslint-disable-next-line max-len
    render: (text, record) => (record?.applicant?.ahpraLicenseDate ? dayjs(record?.applicant?.ahpraLicenseDate).utc()?.format(dateFormat) : '-'),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (text) => <Badge className={styles.status} color={getBudgeColor(text)} text={text} />,
  },
];

export default function GuestList() {
  const { open, contextHolder } = useSimpleModal();
  const users = useIncompleteGet();
  const [searchParams] = useSearchParams();
  const deleteUser = useUserDeleteGeneral();
  const { message } = App.useApp();

  useMessageError([deleteUser]);

  const getUsers = () => {
    const props: UsersGetParams = {};

    usersGetParamList.forEach((key) => {
      const value = searchParams.getAll(key);

      if (value.length || !(value.length === 1 && value[0] === '')) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        props[key] = value.length === 1 ? value[0] : value;
      }
    });

    // props.roles = UserRoles.GUEST;

    users.fetch(props);
  };

  useEffect(getUsers, [searchParams]);

  useEffect(() => {
    if (deleteUser.data) {
      getUsers();
      message.success('You have successfully deleted this registration.');
    }
  }, [deleteUser.data]);

  const actions = ({ entity }: ActionProps<User>) => (
    <Tooltip title="Delete">
      <Button
        type="link"
        className={styles.btn}
        danger
        onClick={(e) => {
          e.preventDefault();
          open({
            icon: <DeleteOutlined style={{ color: '#FF4D4F' }} />,
            title: 'Delete registration',
            content: 'Are you sure you want to delete this registration?',
            cancelText: 'Cancel',
            okText: 'Delete',
            onOk: () => deleteUser.fetch(entity.id),
          });
        }}
      >
        <DeleteOutlined />
      </Button>
    </Tooltip>
  );

  return (
    <div>
      <Table<User>
        rowKey="id"
        search={false}
        headerTitle={(
          <Space>
            Incomplete Registrations
            <RadioSwitch defaultActive="incomplete-registrations" />
          </Space>
        )}
        actions={actions}
        columnsState={{ persistenceKey: 'pro-table-users', persistenceType: 'localStorage' }}
        options={{
          reload: false,
          density: false,
        }}
        columns={columns}
        loading={users.loading}
        pagingData={users.data}
      />

      {contextHolder}
    </div>
  );
}
