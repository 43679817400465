import React from 'react';
import Layout from '../../../components/Layout';
import ContentInvoiceList from '../../../components/Pages/Invoice/List';
import Filter from '../../../components/Pages/Invoice/Filters';

export default function InvoiceList(): React.ReactNode {
  document.title = `${process.env.REACT_APP_TITLE_NAME}: Invoice list`;

  return (
    <Layout minWidth leftMenu={<Filter />}>
      <ContentInvoiceList />
    </Layout>
  );
}
