import ShiftActionProvider from '../Create/Context';
import ShiftForm from '../../../Common/Shift/ShiftForm';

export default function EditShift() {
  return (
    <ShiftActionProvider mode="edit">
      <ShiftForm />
    </ShiftActionProvider>
  );
}
