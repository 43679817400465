import {
  Modal, Form, Input, App, Select,
} from 'antd';
import { useEffect } from 'react';
import { useLocationCreate } from '../../../../../hooks/location';
import { validationGeneralInfo, createRulesForAntd } from '../../../../../utils/validations';
import { useDomainsAllGet, useDomainsSelectLocationsGet } from '../../../../../hooks/domain';
import { useMessageError, useSelectData } from '../../../../../hooks/common';
import PhoneNumberInput from '../../../../Common/PhoneNumberInput';
import InputAddress from '../../../../Common/Input/Address';
import { useClientUpdate } from '../../../../../hooks/client';

import styles from './index.module.scss';

type locationsModalProps = {
  locationsModal: boolean;
  setLocationsModal: React.Dispatch<React.SetStateAction<boolean>>;
  activeDomain?: string | null;
  setUpdateLocation?: (value: boolean) => void;
  setUpdateDomains?: (value: boolean) => void;
  clientId: string | undefined;
};

const validationRules = createRulesForAntd(validationGeneralInfo);

export default function CreateLocation({
  locationsModal,
  setLocationsModal,
  activeDomain,
  setUpdateLocation,
  clientId,
  setUpdateDomains,
}: locationsModalProps) {
  const { message } = App.useApp();
  const createLocation = useLocationCreate();
  const domainsAllGet = useDomainsAllGet();
  const clientDomains = useDomainsSelectLocationsGet();
  const updateClient = useClientUpdate();
  const domains = useSelectData(domainsAllGet);
  const [form] = Form.useForm();

  const getClientDomains = () => {
    clientDomains.fetch({ clients: clientId || '' });
  };

  useEffect(() => {
    if (!activeDomain && clientId) {
      domainsAllGet.fetch();
      getClientDomains();
    }
  }, [clientId]);

  useEffect(() => {
    if (createLocation.data) {
      setLocationsModal(false);

      message.info('You have successfully added a new location');
      if (setUpdateLocation) {
        setUpdateLocation(true);
      }

      if (
        setUpdateDomains
        && clientDomains.data
        && !clientDomains.data?.map((item) => item.id).includes(form.getFieldValue('domains'))
      ) {
        updateClient.fetch(
          { domains: [...clientDomains.data.map((item) => item.id), form.getFieldValue('domains')] },
          clientId,
        );
      }
      form.resetFields();
    }
  }, [createLocation.data]);

  useEffect(() => {
    if (updateClient.data && setUpdateDomains) {
      setUpdateDomains(true);
      getClientDomains();
    }
  }, [updateClient.data]);

  useMessageError([createLocation, domainsAllGet, updateClient]);

  return (
    <Modal
      title="Add new location"
      open={locationsModal}
      onOk={() => {
        form
          .validateFields()
          .then(() => {
            const params = {
              ...form.getFieldsValue(),
              domains: activeDomain ? [activeDomain] : [form.getFieldValue('domains')],
              client: clientId,
            };

            createLocation.fetch(params);
          })
          .catch((errorInfo) => console.log('Validation failed:', errorInfo));
      }}
      onCancel={() => {
        setLocationsModal(false);
        form.resetFields();
      }}
      okText="Save"
      className={styles.modal}
      closable={false}
    >
      <Form form={form} requiredMark={false} layout="vertical">
        <Form.Item name="name" label="Name" rules={[validationRules]}>
          <Input placeholder="Type here" />
        </Form.Item>

        {activeDomain && (
          <>
            <InputAddress name="address" label="Address" placeholder="Type here" required />
            <Form.Item name="phone" label="General Contact Phone" rules={[validationRules]}>
              <PhoneNumberInput placeholder="Type here" />
            </Form.Item>
            <Form.Item name="email" label="General Contact Email" rules={[validationRules]}>
              <Input placeholder="Type here" />
            </Form.Item>
          </>
        )}

        {!activeDomain && (
          <Form.Item
            name="domains"
            label="Select domain"
            rules={[{ required: true, message: 'Please select domain!' }]}
          >
            <Select placeholder="Select domain" options={domains || []} />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
}

CreateLocation.defaultProps = {
  activeDomain: null,
  setUpdateLocation: () => null,
  setUpdateDomains: undefined,
};
