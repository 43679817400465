import React, {
  useMemo, useState, useEffect,
} from 'react';
import { Menu, Checkbox, Input } from 'antd';
import { debounce } from 'lodash';
import { getItem, MenuItem } from '../../Menu';
import { useSearchParams } from '../../../../../hooks/useSearchParams';
import { getValidSearchParams } from '../../../../../utils';

import styles from './index.module.scss';

type SearchMenuProps = {
  title: string;
  name: string;
  checkAll?: boolean;
};

function SearchMenu({ title, name, checkAll }: SearchMenuProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState<string | null>(searchParams.get(name));

  useEffect(() => {
    setSearchValue(searchParams.get(name));
  }, [searchParams]);

  const handleSearch = useMemo(
    () => debounce((value: string) => {
      setSearchParams({
        ...getValidSearchParams('*', searchParams),
        [name]: value ? [value] : [],
      });
    }, 1000),
    [searchParams],
  );

  const handleChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(value);
    handleSearch(value);
  };
  const items: MenuItem[] = [
    getItem(title, 'subMenu', checkAll ? <Checkbox onClick={(e) => e.stopPropagation()} /> : null, [
      getItem(
        <Input.Search
          value={searchValue || ''}
          placeholder="Search"
          className={styles?.search}
          onChange={handleChange}
          allowClear
        />,
        'search',
        null,
      ),
    ]),
  ];

  return <Menu defaultOpenKeys={['subMenu']} mode="inline" items={items} />;
}

SearchMenu.defaultProps = {
  checkAll: false,
};

export default SearchMenu;
