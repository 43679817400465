import React from 'react';
import PrivacyContent from '../../components/Pages/Privacy';
import Simple from '../../components/Layout/Simple';

export default function Privacy(): React.ReactNode {
  document.title = `${process.env.REACT_APP_TITLE_NAME}: Privacy & Terms`;

  return (
    <Simple>
      <PrivacyContent />
    </Simple>
  );
}
