import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import {
  Calendar as AntdCalendar,
} from 'antd';
import type { Dayjs } from 'dayjs';
import type { CellRenderInfo } from 'rc-picker/lib/interface';
import { AnyObject } from '@triare/auth-redux';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { useLocation, useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import Header, { HeaderPropsExport } from './Header';
import Day from './Day';
import CalendarProvider, { useContextCalendar } from './Context';
import Modal from './Day/Modal';
import { useShiftMonthGet } from '../../../hooks/shift';
import { useSearchParams } from '../../../hooks/useSearchParams';
import Loading from '../Loading';
import { dateFormat } from '../../../contstant';
import { isRoleEnough, UserRoles } from '../../../enums/user';
import { useAuthState } from '../../../store/auth';
import { getValidSearchParams, ValidSearchParams } from '../../../utils';

import styles from './index.module.scss';

interface WrapperProps extends AnyObject {
  action?: boolean;
  deactivated?: boolean
}

function Wrapper(props: WrapperProps): React.ReactNode {
  return (
    <CalendarProvider>
      <Calendar {...props} />
    </CalendarProvider>
  );
}

Wrapper.defaultProps = {
  action: undefined,
  deactivated: undefined,
};

interface CalendarProps extends WrapperProps {
  name?: string;
}

function Calendar({
  action, name, className, deactivated, ...props
}: CalendarProps): React.ReactNode | null {
  const dateNow = useRef(dayjs().utc());
  const { user } = useAuthState();
  const navigate = useNavigate();
  const [date, setDate] = useState<Dayjs>(dayjs().utc());
  const { setSelected } = useContextCalendar();
  const shiftMonthGet = useShiftMonthGet();
  const [searchParams, setSearchParams, paramsWithoutTableProps] = useSearchParams();
  const { pathname } = useLocation();
  const isOpenShiftPage = pathname.includes('/open-shifts');
  const actionVisible = typeof action === 'undefined' ? !(!user?.role || user.role === UserRoles.APPLICANT) : action;
  const showPlusIcon = isRoleEnough(user?.role, UserRoles.MANAGER);

  const getYearMonthDate = useCallback((params: ValidSearchParams): [string, string, Dayjs] => {
    const year = (params.year || dateNow.current.get('year')).toString();
    const month = (parseInt((params.month || (dateNow.current.get('month') + 1)).toString(), 10) - 1).toString();

    return [year, month, dayjs().set('month', parseInt(month, 10)).set('year', parseInt(year, 10))];
  }, []);

  const setSearchDate = useCallback((value: Dayjs) => {
    setSearchParams({
      ...getValidSearchParams('*', searchParams),
      from: [],
      to: [],
      year: value.get('year').toString(),
      month: (value.get('month') + 1).toString(),
    });
  }, [searchParams]);

  useEffect(() => {
    setSearchDate(getYearMonthDate(paramsWithoutTableProps)[2]);
  }, []);

  useEffect(() => {
    const [year, month, currentDate] = getYearMonthDate(paramsWithoutTableProps);

    setDate(currentDate);

    if (deactivated !== true) {
      setSelected(undefined);
      shiftMonthGet.fetch({
        ...paramsWithoutTableProps,
        isMine: !isOpenShiftPage && user?.role === UserRoles.APPLICANT,
        limit: 5,
        year,
        month: (parseInt(month, 10) + 1),
      });
    }
  }, [paramsWithoutTableProps]);

  const customHeader = useCallback((propsHeader: HeaderPropsExport) => (
    <Header
      {...propsHeader}
      name={name}
      setDate={setSearchDate}
    />
  ), [setSearchDate]);

  const cellRender = (current: Dayjs, info: CellRenderInfo<Dayjs>) => {
    if (info.type === 'date') {
      if (showPlusIcon
        && dayjs(current).startOf('d').diff(dayjs().startOf('d')) > 0
        && shiftMonthGet.data
        && (!shiftMonthGet.data[current.date()] || current.month() !== date?.get('month'))
      ) {
        return (
          <div className="plus-wrapper">
            <PlusOutlined />
          </div>
        );
      }

      return (
        <Day date={date} value={current} shiftMonthGet={shiftMonthGet} />
      );
    }

    return info.originNode;
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div onClick={(e) => {
      const cell = (e.target as HTMLDivElement).closest('.ant-picker-cell');

      if (cell && cell.getAttribute('title')
      ) {
        const value = dayjs(cell.getAttribute('title'), 'YYYY-MM-DD');

        if (showPlusIcon
          && dayjs(value).startOf('d').diff(dayjs().startOf('d')) > 0
          && shiftMonthGet.data
          && !shiftMonthGet.data[value.date()]
        ) {
          navigate(`/shifts/create?date=${value.format(dateFormat)}`);
        }
      }
    }}
    >
      <AntdCalendar
        {...props}
        value={date}
        className={clsx(className, styles.calendar)}
        headerRender={customHeader}
        cellRender={cellRender}
        onSelect={(value, { source }) => {
          if (source !== 'customize') {
            setSelected((beforeDate) => {
              if (beforeDate && beforeDate.date && beforeDate.date.format(dateFormat) === value.format(dateFormat)) {
                return beforeDate;
              }

              if (value.month() !== date.month()) {
                setSearchDate(value);
              }

              return { date: value };
            });
          }
        }}
      />

      {shiftMonthGet.loading ? (
        <div className={styles.loading}>
          <Loading size={48} />
        </div>
      ) : null}

      <Modal action={actionVisible} shiftMonthGet={shiftMonthGet} contentId="#layout-content" />
    </div>
  );
}

Calendar.defaultProps = {
  name: undefined,
  ...Wrapper.defaultProps,
};

export default Wrapper;
