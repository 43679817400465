import React, { useEffect, useState } from 'react';
import {
  FormInstance, Upload, Button, UploadFile, UploadProps, Input, Alert,
} from 'antd';
import clsx from 'clsx';
import { PaperClipOutlined, UploadOutlined } from '@ant-design/icons';
import { useUploadFile } from '../../../../hooks/file';
import { getMessageInError } from '../../../../hooks/fetch';

import styles from './index.module.scss';

interface InputCodeWrapperProps {
  form: FormInstance
  name: string
  uploadUrl?: string
  placeholder?: string
  multiple?: boolean
  size?: 'large' | 'middle'
  className?: string
  i?: number
  setLoadingList?: (callback: (list: {[key:number]: boolean}) => ({[key:number]: boolean})) => void
}

function InputFile({
  form, name, uploadUrl, size, multiple, placeholder, className, i, setLoadingList,
}: InputCodeWrapperProps) {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const uploader = useUploadFile(uploadUrl);

  useEffect(() => {
    if (uploader.data) {
      form.setFieldValue(name, uploader.data.id);
    }
  }, [uploader.data]);

  useEffect(() => {
    if (setLoadingList && i) {
      setLoadingList((list) => ({ ...list, [i]: uploader.loading }));
    }
  }, [uploader.loading, setLoadingList, i]);

  const props: UploadProps = {
    multiple,
    showUploadList: false,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();

      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      if (multiple) {
        setFileList([...fileList, file as UploadFile]);
      } else {
        setFileList([file as UploadFile]);
      }

      const formData = new FormData();

      formData.append('file', file as Blob);
      uploader.fetch(formData);

      return false;
    },
    fileList,
  };

  return (
    <div className={clsx(styles.wrapper, className)}>
      <Input
        placeholder={placeholder}
        size={size}
        prefix={<PaperClipOutlined />}
        value={fileList[fileList.length - 1]?.name}
      />
      <Upload
        {...props}
        className={styles.upload}
      >
        <Button
          size={size === 'large' ? 'middle' : 'small'}
          icon={<UploadOutlined />}
          loading={uploader.loading}
        >
          Select File
        </Button>
      </Upload>

      {uploader.error ? (
        <Alert
          style={{ marginTop: 8 }}
          type="error"
          message={getMessageInError(uploader.error)}
          closable
          onClose={uploader.clearError}
        />
      ) : null}
    </div>
  );
}

InputFile.defaultProps = {
  uploadUrl: 'files/upload',
  placeholder: 'File name',
  multiple: false,
  size: undefined,
  className: undefined,
  i: undefined,
  setLoadingList: undefined,
};

export default InputFile;
