import React from 'react';
import Layout from '../../../components/Layout';
import ConfirmationContent from '../../../components/Pages/Shift/Confirmation';

export default function Confirmation(): React.ReactNode {
  document.title = `${process.env.REACT_APP_TITLE_NAME}: Shift confirmation`;

  return (
    <Layout disableLeftMenu>
      <ConfirmationContent />
    </Layout>
  );
}
