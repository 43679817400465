import {
  DefaultFetchError,
  FetchCreate,
  FetchGet,
  FetchGetId,
  FetchUpdate,
  useFetchCreate, useFetchDelete,
  useFetchGet,
  useFetchGetId,
  useFetchUpdate,
  FetchSuccess,
  PagingDataResponse,
  PagingParams,
} from './fetch';

export interface File {
  id: string,
  createdAt: string,
  mimeType: string,
  originalName: string
}

export interface Orientation {
  id: string
  name: string;
  location: Location;
  message: string;
  files: File[];
}

interface Location {
id: string
}

export interface OrientationCreateParams {
  locationRole?: string
  location: string;
  message?: string;
  files?: string[];
}

export interface OrientationUpdateParams {
  name?: string;
  location?: string;
  message?: string;
  files?: string[];
  locationRole? : string | null
}
interface CreatePayload {
  id: string
}

export const useOrientationCreate = (): FetchCreate<CreatePayload, DefaultFetchError, OrientationCreateParams> => (
  useFetchCreate('orientation')
);

export const useOrientationUpdate = (): FetchUpdate<FetchSuccess, DefaultFetchError, OrientationUpdateParams> => (
  useFetchUpdate('orientation')
);

export interface OrientationsGetParams extends PagingParams {
  location?: string;
}

export function useOrientationsGet<D = PagingDataResponse<Orientation>,
  DD = D>(decorateData?: (data: D) => DD): FetchGet<D, OrientationsGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, OrientationsGetParams, DD>(
    'orientation',
    {
      decorateData,
      autoStart: false,
      startStateLoading: false,
    },
  );
}

export const useOrientationId = (): FetchGetId<Orientation> => (
  useFetchGetId('orientation', '', { autoStart: false })
);

export const useOrientationDelete = () => useFetchDelete<FetchSuccess, DefaultFetchError, string>('orientation');
