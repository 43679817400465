import { useEffect, useState, useRef } from 'react';
import {
  Space, Transfer, Typography, App,
} from 'antd';
import { AnyObject } from '@triare/auth-redux';
import { useSettingsContext } from '../Context';
import { useUpdateApplicant } from '../../../../../hooks/applicant';
import { useMessageError } from '../../../../../hooks/common';
import { useTeamsGet } from '../../../../../hooks/team';
import Wrapper from '../../../Applicant/Components/Wrapper';
import styles from './index.module.scss';

export interface RecordType {
  key: string;
  title: string;
  description?: string;
  chosen?: boolean;
}

export default function Teams() {
  const {
    setActiveTab, setEdit, setOnSave, isEdit, setChanging, fetch, setLoading, applicant,
  } = useSettingsContext();
  const teams = useTeamsGet();
  const updateApplicant = useUpdateApplicant();
  const [targetKeys, setTargetKeys] = useState<string[] | null>(null);
  const { message } = App.useApp();
  const targets = useRef<string[]>([]);

  useEffect(() => {
    setActiveTab(() => 'teams');
    teams.fetch();
    setEdit(() => true);
    setChanging(() => false);
    setOnSave(() => onSave);
  }, []);

  useEffect(() => {
    if (updateApplicant.data) {
      message.info('The applicant has been successfully updated');
      setChanging(() => false);
      fetch();
      setLoading(false);
    }
  }, [updateApplicant.data]);

  useEffect(() => {
    if (applicant?.inductions) {
      setTargetKeys(applicant.teams.map((item) => item.id));
    }
    setEdit(() => true);
  }, [isEdit]);

  useMessageError([teams, updateApplicant]);

  const onChange = (nextSourceKeys: string[]) => {
    setTargetKeys(nextSourceKeys);
    targets.current = nextSourceKeys;
    setChanging(() => true);
  };

  const onSave = (attachData: AnyObject = {}) => {
    setLoading(true);
    updateApplicant.fetch({ teamsIds: targets.current, ...attachData }, applicant?.id);
  };

  return (
    <Wrapper>
      <Typography.Title className={styles.title} level={5}>
        Teams
      </Typography.Title>
      <Space direction="vertical" className={styles.wrapper}>
        <div className={styles.titles}>
          <Typography.Text strong className={styles.title}>
            Allocated Teams
          </Typography.Text>
          <Typography.Text strong className={styles.title}>
            Available Teams
          </Typography.Text>
        </div>
        <Transfer<RecordType>
          className={styles.transfer}
          dataSource={teams.data?.map((item) => ({ title: item.name, key: item.id })) || []}
          targetKeys={targetKeys || []}
          // @ts-ignore string[] as Key[]
          onChange={onChange}
          selectAllLabels={['All', 'All']}
          render={(item) => item.title}
        />
      </Space>
    </Wrapper>
  );
}
