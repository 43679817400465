import React from 'react';
import { Space } from 'antd';

import styles from './index.module.scss';

type WrapperProps = {
  children: React.ReactNode;
  width?: string;
};

export default function Wrapper({ children, width }: WrapperProps) {
  return (
    <Space className={styles.wrapper} style={{ width }} direction="vertical" size={4}>
      {children}
    </Space>
  );
}

Wrapper.defaultProps = {
  width: '100%',
};
