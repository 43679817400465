import React from 'react';
import Layout from '../../../../components/Layout';
import ContentLocationList from '../../../../components/Pages/Location/List/Client';
import Filter from '../../../../components/Pages/Location/Filters';

export default function LocationList(): React.ReactNode {
  document.title = `${process.env.REACT_APP_TITLE_NAME}: Location list`;

  return (
    <Layout minWidth leftMenu={<Filter />}>
      <ContentLocationList />
    </Layout>
  );
}
