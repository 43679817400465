import { useEffect, useState } from 'react';
import { Table, type TableColumnsType } from 'antd';
import dayjs from 'dayjs';
import lodash from 'lodash';
import { AnyObject } from '@triare/auth-redux';
import { useMessageError } from '../../../../hooks/common';
import {
  Logger, LoggerShiftData, useLoggerId,
} from '../../../../hooks/logger';
import { capitalize } from '../../../../utils';

interface NestedLogProps {
  item: Logger
}

interface LoggerNested extends LoggerShiftData {
  type: string
}

const formatTime = (time: string) => dayjs(time).format('MMM DD, YYYY hh:mm A');

const columnDefault: TableColumnsType<LoggerNested> = [
  {
    title: '', dataIndex: 'type', key: 'type', width: '58px',
  },
  {
    title: 'Start Date and Time',
    dataIndex: 'datetimeStart',
    key: 'datetimeStart',
    render: (item) => formatTime(item),
  },
  {
    title: 'End Date and Time',
    dataIndex: 'datetimeEnd',
    key: 'datetimeEnd',
    render: (item) => formatTime(item),
  },
  { title: 'Allowances Type?', dataIndex: 'AllowancesType', key: 'AllowancesType' },
  {
    title: 'On Call Start Date and Time',
    dataIndex: 'onCallDatetimeStart',
    key: 'onCallDatetimeStart',
    render: (item) => (item ? formatTime(item) : '_'),
  },
  {
    title: 'On Call End Date and Time',
    dataIndex: 'onCallDatetimeEnd',
    key: 'onCallDatetimeEnd',
    render: (item) => (item ? formatTime(item) : '_'),
  },
  {
    title: 'Rate',
    dataIndex: 'rate',
    key: 'rate',
    render: (_, record) => {
      if (record?.rate && record?.rateType && record?.rateType === 'hourly') {
        return `$${record.rate} per hour`;
      }
      if (record?.rate) {
        return `$${record.rate}`;
      }
      if (record?.rateRangeFrom && record?.rateRangeTo) {
        return `$${record?.rateRangeFrom} - $${record?.rateRangeTo}`;
      }

      return 'Not set';
    },

  },
  {
    title: 'Allowances',
    dataIndex: 'allowances',
    key: 'allowances',
    render: (_, record) => record?.allowances?.map((item) => {
      if (!item.type || !item.amount) {
        return <div key={item.id}>-</div>;
      }

      return <div key={item.id}>{`${capitalize(item.type)} - $${item.amount || '-'}`}</div>;
    }) || '-',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (_, record) => capitalize(record.status),
  },
  {
    title: 'Lunch Break',
    dataIndex: 'lunchBreak',
    key: 'lunchBreak',
    render: (_, record) => (record.lunchBreak ? `${record.lunchBreak} min` : '_'),
  },
];

export default function NestedLog({ item }: NestedLogProps) {
  const loggerById = useLoggerId();

  const [data, setData] = useState<LoggerNested[] | undefined>(undefined);
  const [columns, setColumns] = useState<TableColumnsType<LoggerNested> | undefined>(undefined);

  useEffect(() => {
    loggerById.fetch(undefined, item.id);
  }, []);

  const isArrayEqual = (x: AnyObject[], y: AnyObject[]) => lodash(x).xorWith(y, lodash.isEqual).isEmpty();

  useEffect(() => {
    if (loggerById.data) {
      const { data: logger } = loggerById;

      setData([
        { type: 'Before', ...logger.before },
        { type: 'After', ...logger.after },
      ]);

      setColumns(columnDefault.filter(({ key }) => {
        if (key === 'type') {
          return true;
        }

        if (key === 'allowances' && isArrayEqual(logger.before.allowances, logger.after.allowances)) {
          return false;
        }

        // @ts-ignore use kay as name of field
        if (logger.before[key] !== logger.after[key]) {
          return true;
        }

        return false;
      }));
    }
  }, [loggerById.data]);

  useMessageError([loggerById]);

  return (
    <Table
      rowKey="type"
      columns={columns}
      loading={loggerById.loading}
      dataSource={data}
      pagination={false}
    />
  );
}
