import React, { useEffect } from 'react';
import { CheckCircleOutlined, EditOutlined, StopOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import { ProCoreActionType } from '@ant-design/pro-utils/es/typing';
import { Button, Tooltip, Typography } from 'antd';
import { translateStatusAction } from '../../../../../../utils';
import { DefaultFetchError, FetchSuccess, FetchUpdate } from '../../../../../../hooks/fetch';
import { UseSimpleModalProps } from '../../../../../Common/Modal/Simple';
import { COLOR_PRIMARY } from '../../../../../../AntdConfigProvider';
import { useMessageError } from '../../../../../../hooks/common';
import { useClientValidate } from '../../../../../../hooks/client';

export interface Statuses {
  active: string | boolean;
  inactive: string | boolean;
}

export interface RequiredActionProps<Data, Props> {
  isEditable: boolean;
  open: (props?: UseSimpleModalProps) => void;
  hookAction: FetchUpdate<FetchSuccess, DefaultFetchError, Props>;
  statusName: keyof Data;
  statuses: {
    active: string | boolean;
    inactive: string | boolean;
  };
  title: (actionName: string, entity: Data) => string;
  content: (actionName: string, entity: Data) => string;
  isClient?: boolean;
}

export interface ActionProps<Data, Props> extends RequiredActionProps<Data, Props> {
  dom: React.ReactNode;
  entity: Data & { id: string | number };
  index: number;
  action: ProCoreActionType;
}

function Action<Data, Props>({
  entity,
  isEditable,
  open,
  hookAction,
  statusName,
  statuses,
  title,
  content,
  isClient,
}: ActionProps<Data, Props>) {
  const { id } = entity;
  const status = entity[statusName];
  const isActive = status === statuses.active;
  const actionName = translateStatusAction(!isActive);
  const validateClient = useClientValidate();

  const changeStatus = (isValide?: boolean) => {
    const onOk = () => hookAction.fetch(
        {
          [statusName]: isActive ? statuses.inactive : statuses.active,
        } as Props,
        id,
    );

    open({
      icon: isActive ? (
        <StopOutlined style={{ color: '#FF4D4F' }} />
      ) : (
        <CheckCircleOutlined style={{ color: COLOR_PRIMARY }} />
      ),
      title: title(actionName, entity),
      content: (
        <div>
          {isValide !== undefined && !isValide && isActive ? (
            <Typography.Paragraph strong>
              This client has active locations. Please deactivate active locations and try again.
            </Typography.Paragraph>
          ) : (
            <Typography.Paragraph>{content(actionName, entity)}</Typography.Paragraph>
          )}
        </div>
      ),
      cancelText: 'Cancel',
      okText: actionName,
      okButtonProps: { disabled: isValide !== undefined && !isValide && isActive },
      onOk,
      centered: true,
    });
  };

  useEffect(() => {
    if (validateClient.data) {
      changeStatus(validateClient.data.success);
    }
  }, [validateClient.data]);

  useMessageError([validateClient, hookAction]);

  return (
    <>
      {isEditable ? (
        <Tooltip title="Edit">
          <Button type="text" style={{ padding: '0px 6px', height: '26px' }}>
            <NavLink to={`${id}/edit`}>
              <EditOutlined />
            </NavLink>
          </Button>
        </Tooltip>
      ) : null}

      <Tooltip title={isActive ? 'Deactivate' : 'Activate'}>
        <Button
          type="text"
          style={{ padding: '0px 6px', height: '26px' }}
          loading={validateClient.loading}
          onClick={(e) => {
            e.preventDefault();
            if (isClient && id) {
              validateClient.fetch(undefined, id);

              return;
            }

            changeStatus();
          }}
        >
          {isActive ? <StopOutlined /> : <CheckCircleOutlined />}
        </Button>
      </Tooltip>
    </>
  );
}

Action.defaultProps = {
  isClient: false,
};

export default Action;
