import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Button, Space, DatePicker, Dropdown, Modal, Typography, Tooltip,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import type { ProColumns } from '@ant-design/pro-components';
import dayjs from 'dayjs';
import Table from '../../../Common/Table';
import { useSearchParams } from '../../../../hooks/useSearchParams';
import {
  Invoice,
  InvoiceGetParams,
  invoicesGetParamList,
  useInvoiceClient,
  useInvoiceApplicant,
  useInvoiceComplete,
  useInvoicesGet,
  invoicesGenerateList,
  InvoiceCompleteParams,
  useInvoiceGeneral,
  invoicesGenerateSimpleList,
  InvoiceGenerateSimpleParams,
} from '../../../../hooks/invoice';
import {
  capitalize, getDateISO, getDaysBetween, getHoursBetween,
} from '../../../../utils';
import { useMessageError } from '../../../../hooks/common';
import Loading from '../../../Common/Loading';
import { FetchGet } from '../../../../hooks/fetch';
import { useClientsGet } from '../../../../hooks/client';
import styles from './index.module.scss';

function numberWithCommas(x: string) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const columns: ProColumns<Invoice>[] = [
  {
    title: 'Client',
    dataIndex: 'client',
    key: 'client',
    sorter: true,
    render: (_, record) => record.client?.name,
  },
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
    render: (_, record) => record.location?.name,
  },
  {
    title: 'Date & Time',
    dataIndex: 'datetime',
    key: 'datetime',
    sorter: true,
    render: (_, record) => (
      <NavLink to={`/shifts/${record.id}`}>
        {`${getDaysBetween(record?.datetimeStart, record?.datetimeEnd)}: `}
        {getHoursBetween(record?.datetimeStart, record?.datetimeEnd)}
      </NavLink>
    ),
  },
  {
    title: 'Role',
    dataIndex: 'role',
    key: 'role',
    sorter: true,
    render: (_, record) => record?.locationRole?.name,
  },
  {
    title: 'Applicant',
    dataIndex: 'applicant',
    key: 'applicant',
    sorter: true,
    render: (_, record) => {
      const applicant = record?.applicant;

      return `${applicant?.title} ${applicant?.user?.firstName} ${applicant?.user?.lastName}`;
    },
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
    sorter: true,
    render: (_, record) => {
      if (record?.rate && record?.rateType && record?.rateType === 'hourly') {
        return `${record.quantity} hours`;
      }

      return '1 shift';
    },
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
    sorter: true,
    render: (_, record) => {
      if (record?.rateType === 'no_rate') {
        return '';
      }

      if (record?.rate && record?.rateType && record?.rateType === 'hourly') {
        return `$${record.unitPrice} per hour`;
      }

      return `$${record.amount} per shift`;
    },
  },
  {
    title: 'Allowance Total',
    dataIndex: 'allowanceTotal',
    key: 'allowanceTotal',
    render: (_, record) => (
      <Tooltip
        title={
          record?.allowances?.length ? (
            <Space direction="vertical" className={styles.tooltip} size="small">
              {record?.allowances?.map((item) => `${capitalize(item.type)} - ${item.amount}$`)}
            </Space>
          ) : null
        }
      >
        {`$${
          record?.allowances?.reduce(
            (acc: number, allowance: any) => (allowance?.amount ? acc + Number(allowance?.amount) : acc + 0),
            0,
          ) || 0
        }`}
      </Tooltip>
    ),
  },
  {
    title: 'Lunch Break',
    dataIndex: 'lunchBreak',
    key: 'lunchBreak',
    sorter: false,
    render: (_, record) => (record.lunchBreak ? `${record.lunchBreak} min` : '-'),
  },
  {
    title: 'Amount AUD',
    dataIndex: 'amount',
    key: 'amount',
    sorter: true,
    render: (_, record) => `$${numberWithCommas(record.amount)}`,
  },
];

export default function InvoiceList() {
  const controller = new AbortController();
  const invoices = useInvoicesGet();
  const clients = useClientsGet();
  const generateClient = useInvoiceClient(controller);
  const generateApplicant = useInvoiceApplicant(controller);
  const generateGeneral = useInvoiceGeneral(controller);
  const checkComplete = useInvoiceComplete();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clientsText, setClientsText] = useState('Client Invoice Export');
  const [type, setType] = useState('');
  const action: FetchGet = type === 'byApplicant' ? generateApplicant : generateClient;
  const from = dayjs(searchParams.get('from'));
  const to = dayjs(searchParams.get('to'));

  useEffect(() => {
    const props: InvoiceGetParams = {};

    invoicesGetParamList.forEach((key) => {
      const value = searchParams.getAll(key);

      if (value.length || !(value.length === 1 && value[0] === '')) {
        // @ts-ignore @typescript-eslint/ban-ts-comment
        props[key] = value.length === 1 ? value[0] : value;
      }
    });

    invoices.fetch(props);
  }, [searchParams]);

  useEffect(() => {
    if (checkComplete.data) {
      const isPartial = checkComplete.data.partial;
      const clientsIds = searchParams.getAll('clients');

      if (isPartial && type === 'byClient' && clientsIds.length) {
        clients.fetch({ clients: clientsIds });
      } else {
        setClientsText('Client Invoice Export');
      }

      if (isPartial) {
        setIsModalOpen(true);
      } else {
        getDownloadFetch(false);
      }
    }
  }, [checkComplete.data]);

  useEffect(() => {
    if (clients.data) {
      const clientsNames = clients.data?.data?.map((client) => client.name).join(', ');

      setClientsText(`Client Invoice Export for: ${clientsNames || ''}`);
    }
  }, [clients.data]);

  const checkCompleteHandler = (entityType: string) => {
    const props: InvoiceCompleteParams = {};

    invoicesGenerateList.forEach((key) => {
      const value = searchParams.getAll(key);

      if (value.length || !(value.length === 1 && value[0] === '')) {
        // @ts-ignore @typescript-eslint/ban-ts-comment
        props[key] = value.length === 1 ? value[0] : value;
      }
    });

    props.type = entityType;
    setType(entityType);
    checkComplete.fetch(props);
  };

  const getDownloadFetch = (all: boolean) => {
    setIsModalOpen(false);

    const props: InvoiceCompleteParams = {};

    invoicesGenerateList.forEach((key) => {
      const value = searchParams.getAll(key);

      if (value.length || !(value.length === 1 && value[0] === '')) {
        // @ts-ignore @typescript-eslint/ban-ts-comment
        props[key] = value.length === 1 ? value[0] : value;
      }
    });

    if (all) {
      props.all = true;
    }

    action.fetch(props);
  };

  useEffect(() => {
    if (action.response && action.data) {
      const contentType = action?.response?.headers['content-type'];

      if (contentType === 'application/zip') {
        downloadFile(action.data, 'application/zip', 'invoices.zip');
      }

      if (contentType === 'multipart/mixed; boundary="csv_boundary"') {
        new Blob([action.data], { type: 'text/csv' }).text().then((text) => text
          .split('--csv_boundary')
          .filter((part) => part.trim() !== '' && part.trim() !== '--')
          .forEach((item, i) => {
            const lines = item.split('\n');
            const fileName = lines[1].match(/filename=([^;]+)/);
            const itemWithoutHeader = lines.splice(3).join('\n');

            setTimeout(() => {
              downloadFile(
                itemWithoutHeader,
                'text/csv',
                fileName && fileName.length >= 1 ? fileName[1].trim() : 'invoice_export.csv',
              );
            }, 100 * i);
          }));
      }
    }
  }, [action.response]);

  const downloadFile = (file: any, format: string, name = 'invoices') => {
    const newFile = new Blob([file], { type: format });

    const fileURL = window.URL.createObjectURL(newFile);
    const link = document.createElement('a');

    link.href = fileURL;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    if (generateGeneral.response && generateGeneral.data) {
      const fileName: string[] = generateGeneral.response.headers['content-disposition'].match(/filename=([^;]+)/);

      downloadFile(generateGeneral.data, 'text/csv', fileName.length >= 1 ? fileName[1].trim() : 'invoice_export.csv');
    }
  }, [generateGeneral.response]);

  const toolBarRender = () => [
    <Space key="toolBar" size="middle">
      Period:
      <DatePicker.RangePicker
        defaultValue={[from.isValid() ? from : null, to.isValid() ? to : null]}
        onChange={(date: any) => setSearchParams({
          from: getDateISO(date ? date[0] : null) || [],
          to: getDateISO(date ? date[1] : null) || [],
        })}
        format="dddd, MMM D"
        className={styles.fullWidth}
      />
      <Dropdown.Button
        disabled={!invoices.data?.data?.length}
        onClick={() => {
          const props: InvoiceGenerateSimpleParams = {};

          invoicesGenerateSimpleList.forEach((key) => {
            const value = searchParams.getAll(key);

            if (value.length || !(value.length === 1 && value[0] === '')) {
              // @ts-ignore @typescript-eslint/ban-ts-comment
              props[key] = value.length === 1 ? value[0] : value;
            }
          });

          const columnsParams = JSON.parse(localStorage.getItem('pro-table-invoice') || '');

          const skipColumns: string[] = [];

          Object.keys(columnsParams).forEach((column) => {
            if (Object.prototype.hasOwnProperty.call(columnsParams, column) && columnsParams[column].show === false) {
              skipColumns.push(column);
            }
          });

          props.skipColumns = skipColumns;

          // @ts-ignore @typescript-eslint/ban-ts-comment
          generateGeneral.fetch(props);
        }}
        trigger={['click']}
        type="primary"
        icon={<DownOutlined />}
        loading={
          generateClient.loading || generateApplicant.loading || checkComplete.loading || generateGeneral.loading
        }
        menu={{
          items: [
            {
              label: 'Export Applicant Invoice',
              key: 'applicant',
              onClick: () => checkCompleteHandler('byApplicant'),
            },
            {
              label: 'Export Client Invoice',
              key: 'client',
              onClick: () => checkCompleteHandler('byClient'),
            },
          ],
        }}
      >
        Export
      </Dropdown.Button>
    </Space>,
  ];

  useMessageError([invoices, generateClient, checkComplete, generateApplicant, generateGeneral]);

  return invoices.data ? (
    <>
      <Table
        rowKey="id"
        search={false}
        headerTitle="Invoices"
        columnsState={{ persistenceKey: 'pro-table-invoice', persistenceType: 'localStorage' }}
        listCount={invoices.data.data.length}
        options={{
          reload: false,
          density: false,
        }}
        toolBarRender={toolBarRender}
        columns={columns}
        tableAlertRender={false}
        loading={invoices.loading}
        pagingData={invoices.data}
      />
      <Modal
        className={styles.modal}
        title={type === 'byApplicant' ? 'Applicant Invoice Export' : clientsText}
        footer={[]}
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        centered
      >
        <Typography.Text>
          {type === 'byApplicant'
            ? `Not all clients, locations, and roles are selected which could result in missing data.
               Select all necessary   filters or continue with the current selection?`
            : `Not all locations, roles, and applicant filters have been enabled for the selected clients.
               This could result in missing data. Select all available filters for chosen clients or continue
               with the current selection?`}
        </Typography.Text>
        <div className={styles.btns}>
          <Button onClick={() => getDownloadFetch(false)}>Continue with Current Selection</Button>
          <Button onClick={() => getDownloadFetch(true)} type="primary">
            Select all and Continue
          </Button>
        </div>
      </Modal>
    </>
  ) : (
    <div className={styles.loading}>
      <Loading />
    </div>
  );
}
