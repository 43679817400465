import React, { useMemo } from 'react';
import { Button } from 'antd';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CommonTabs, { TabPaneItem } from '../../../../Common/Tabs';
import { ClientContextProps, useClientContext } from '../../Context';
import TopPanel from '../../../../Common/TopPanel';
import Loading from '../../../../Common/Loading';
import { ContextMode } from '../../../../../types';
import { ClientUserContextProps } from '../../User/Context';

import styles from './index.module.scss';

interface TabsProps {
  tabList: TabPaneItem[];
}

function Tabs({ tabList }: TabsProps) {
  const { clientId } = useParams();
  const navigate = useNavigate();
  const { isEdit, setActiveTab } = useClientContext();
  const { pathname } = useLocation();
  const activeTab = pathname.includes('/users') ? tabList[1].key : tabList[0].key;
  const items = useMemo(
    () => tabList.map((item) => ({
      ...item,
      disabled: isEdit && item.key !== activeTab,
    })),
    [isEdit, activeTab],
  );

  return (
    <CommonTabs
      className={styles.tabs}
      items={items}
      onChange={(tab) => {
        if (tab === tabList[1].key) {
          navigate(`/clients/${clientId}/users`);
        } else {
          navigate(`/clients/${clientId}`);
        }
        setActiveTab(() => tab);
      }}
      activeKey={pathname.includes('/users') ? tabList[1].key : tabList[0].key}
    />
  );
}

interface RightContentProps extends ContextMode {
  useContext: () => ClientContextProps | ClientUserContextProps;
}

function RightContent({ mode, useContext }: RightContentProps) {
  const {
    action, activeTab, isEdit, setEdit, isChanging, onSave,
  } = useContext();

  if (activeTab && activeTab !== 'general') {
    return null;
  }

  const actionLoading = !!action?.loading;

  if (mode === 'edit') {
    return (
      <>
        {!isEdit && <Button onClick={() => setEdit(() => true)}>Edit</Button>}
        {isEdit && (
          <>
            <Button
              type="text"
              onClick={() => setEdit(() => false)}
              style={{ marginRight: 16 }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => (onSave ? onSave() : null)}
              disabled={actionLoading || !isChanging}
              loading={actionLoading}
            >
              Save
            </Button>
          </>
        )}
      </>
    );
  }

  if (mode === 'create') {
    return (
      <Button
        type="primary"
        onClick={() => (onSave ? onSave() : null)}
        disabled={actionLoading || !isChanging}
        loading={actionLoading}
      >
        Create
      </Button>
    );
  }

  return (
    <>
      {!isEdit && <Button onClick={() => setEdit(() => true)}>Edit</Button>}
      {isEdit && (
        <>
          <Button
            type="text"
            onClick={() => setEdit(() => false)}
            style={{ marginRight: 16 }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={() => (onSave ? onSave() : null)}
            disabled={actionLoading || !isChanging}
            loading={actionLoading}
          >
            Save
          </Button>
        </>
      )}
    </>
  );
}

RightContent.defaultProps = {
  mode: undefined,
};

interface DetailProps extends RightContentProps {
  arrowBack?: boolean | string;
  name?: string | null;
  tabList?: TabPaneItem[];
  children?: React.ReactNode;
  onlyView?: boolean;
}

function Detail({
  arrowBack, name, tabList, onlyView, mode, children, useContext,
}: DetailProps): React.ReactNode {
  let title = mode === 'create' ? 'Create' : <Loading />;

  if (name) {
    title = name;
  }

  return (
    <div className={styles.wrapper}>
      <TopPanel
        padding
        arrowBack={arrowBack}
        title={title}
        rightContent={onlyView === true ? null : <RightContent mode={mode} useContext={useContext} />}
      />
      {tabList ? <Tabs tabList={tabList} /> : null}

      {children}
    </div>
  );
}

Detail.defaultProps = {
  arrowBack: true,
  name: undefined,
  tabList: undefined,
  children: undefined,
  client: undefined,
  onlyView: undefined,
  ...RightContent.defaultProps,
};

export default Detail;
