import React from 'react';
import Layout from '../../components/Layout';
import NotificationsPage from '../../components/Pages/Notifications';

export default function Notifications(): React.ReactNode {
  document.title = `${process.env.REACT_APP_TITLE_NAME}: Notifications`;

  return (
    <Layout disableLeftMenu>
      <NotificationsPage />
    </Layout>
  );
}
