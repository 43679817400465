import {
  Typography, Space, Row,
} from 'antd';
import React from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';

import styles from './index.module.scss';

type TopPanelProps = {
  arrowBack?: boolean | string
  padding?: boolean
  title: string | React.ReactNode
  rightContent: React.ReactNode
};

function TopPanel({
  padding, title, arrowBack, rightContent,
}: TopPanelProps) {
  const navigate = useNavigate();

  return (
    <Row
      justify="space-between"
      className={clsx(styles.wrapper, { [styles.padding]: padding })}
    >
      <Typography.Title level={4} className={styles.title}>
        {arrowBack ? (
          <ArrowLeftOutlined
            className={styles.arrowBack}
            onClick={(e) => {
              e.preventDefault();

              if (typeof arrowBack === 'string') {
                navigate(arrowBack);
              } else {
                navigate(-1);
              }
            }}
          />
        ) : null}

        {title}
      </Typography.Title>

      <Space align="center">
        {rightContent}
      </Space>
    </Row>
  );
}

TopPanel.defaultProps = {
  padding: undefined,
  arrowBack: undefined,
};

export default TopPanel;
