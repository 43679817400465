import React, { useEffect, useMemo } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Badge, Button } from 'antd';
import { ProColumns } from '@ant-design/pro-components';
import {
  User,
  usersGetParamList,
  UsersGetParams,
  UserUpdateParams,
  useUsersGet,
  useUserUpdate,
} from '../../../../../hooks/user';
import { PagingDataResponse } from '../../../../../hooks/fetch';
import Table, { defaultTableData } from '../../../../Common/Table';
import { getBudgeColor } from '../../../../Common/Badge';
import { isRoleEnough, UserRoles, UserStatus } from '../../../../../enums/user';
import { useAuthState } from '../../../../../store/auth';
import { useSimpleModal } from '../../../../Common/Modal/Simple';
import { useSearchParams } from '../../../../../hooks/useSearchParams';
import Action, { RequiredActionProps, Statuses, ActionProps } from '../../Common/Table/Action';

import styles from './index.module.scss';

interface UserRow extends User {
  url: string;
}

const columns: ProColumns<UserRow>[] = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (text, record) => (
      <NavLink to={record.url}>
        {record.firstName}
        {' '}
        {record.lastName}
      </NavLink>
    ),
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
    render: (text, entity) => <NavLink to={`tel:${entity?.phone}`}>{entity.phone}</NavLink>,
  },
  {
    title: 'Email login',
    key: 'email',
    dataIndex: 'email',
    sorter: true,
    render: (text, entity) => <NavLink to={`mailto:${entity?.email}`}>{entity.email}</NavLink>,
  },
  {
    title: 'Role',
    dataIndex: 'role',
    key: 'role',
    render: (role) => (
      <span style={{ textTransform: 'capitalize' }}>{role === UserRoles.CLIENT_ADMIN ? 'Client Admin' : role}</span>
    ),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (text) => <Badge className={styles.status} color={getBudgeColor(text)} text={text} />,
  },
];

const statuses: Statuses = {
  active: UserStatus.ACTIVE,
  inactive: UserStatus.DEACTIVATED,
};

interface ClientUserListProps {
  setReload?: (time: number) => void;
}
function ClientUserList({ setReload }: ClientUserListProps): React.ReactNode {
  const { open, contextHolder } = useSimpleModal();
  const userUpdate = useUserUpdate();
  const { user } = useAuthState();
  const { clientId } = useParams();
  const users = useUsersGet<PagingDataResponse<UserRow>>();
  const [searchParams] = useSearchParams();
  const urlParamsString = searchParams.toString();
  const urlParams = urlParamsString.length ? `?${urlParamsString}` : '';
  const isEditable = isRoleEnough(user?.role, UserRoles.LOKEM_ADMIN);

  const getUsers = () => {
    const props: UsersGetParams = {};

    usersGetParamList.forEach((key) => {
      const value = searchParams.getAll(key);

      if (value.length || !(value.length === 1 && value[0] === '')) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        props[key] = value.length === 1 ? value[0] : value;
      }
    });

    props.client = clientId;
    props.roles = [UserRoles.CLIENT_ADMIN, UserRoles.MANAGER];
    props.partialName = searchParams.get('search') || undefined;

    users.fetch(props);
  };

  const usersGetData = useMemo<PagingDataResponse<UserRow>>(() => {
    if (!users.loading && !users.error && users?.data?.data.length) {
      return {
        ...users.data,
        data: users.data.data.map((items) => ({
          ...items,
          url: items.id + urlParams,
        })),
      } as PagingDataResponse<UserRow>;
    }

    return defaultTableData;
  }, [users, urlParams]);

  useEffect(() => {
    if (userUpdate.data && !userUpdate.error && users) {
      getUsers();

      if (setReload) {
        setReload(Date.now());
      }
    }
  }, [userUpdate.response, setReload]);

  useEffect(() => {
    getUsers();
  }, [searchParams]);

  useEffect(() => {
    if (userUpdate.data && !userUpdate.error && users) {
      getUsers();
    }
  }, [userUpdate.response]);

  const toolBarRender = () => [
    <NavLink key="create" to={`/clients/${clientId}/users/create${urlParams}`}>
      <Button type="primary">Create user</Button>
    </NavLink>,
  ];

  return (
    <>
      <Table<UserRow, RequiredActionProps<UserRow, UserUpdateParams>, ActionProps<UserRow, UserUpdateParams>>
        rowKey="id"
        search={false}
        headerTitle="List of users"
        columnsState={{ persistenceKey: 'pro-table-client-users', persistenceType: 'localStorage' }}
        toolBarRender={toolBarRender}
        options={{
          reload: false,
          density: false,
        }}
        actions={Action}
        actionsProps={{
          isEditable,
          open,
          hookAction: userUpdate,
          statusName: 'status',
          statuses,
          title: (actionName) => `${actionName} user`,
          // eslint-disable-next-line max-len
          content: (actionName, entity) => `Are you sure you want to ${actionName?.toLowerCase()} this user (${entity.fullName})?`,
        }}
        columns={columns}
        loading={users.loading}
        pagingData={usersGetData}
      />

      {contextHolder}
    </>
  );
}

ClientUserList.defaultProps = {
  setReload: undefined,
};

export default ClientUserList;
