import React from 'react';

import styles from './index.module.scss';

const hours = Array.from({ length: 23 }, (_, i) => i + 1);

function Day(): React.ReactNode {
  return (
    <div className={styles.col}>
      {hours.map((hour) => (
        <div key={hour} className={styles.hour}>
          {`${hour}:00`}
        </div>
      ))}
    </div>
  );
}

export default React.memo(Day);
