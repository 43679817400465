import { Space, List } from 'antd';
import { FilePdfFilled } from '@ant-design/icons';
import Wrapper from '../Wrapper';
import { COLOR_PRIMARY } from '../../../../../AntdConfigProvider';
import ViewAction from '../../../../Common/Applicant/ViewAction';
import { useSettingsContext } from '../../Context';

import styles from './index.module.scss';

export default function Documents() {
  const { applicant } = useSettingsContext();

  return (
    <Space className={styles.wrapper} direction="vertical">
      <Wrapper>
        <List
          className={styles.documents}
          itemLayout="horizontal"
          dataSource={applicant?.documents}
          renderItem={(item, index) => (
            <List.Item actions={[<ViewAction key={index} fileId={item.file.id} name={item.file?.originalName} />]}>
              <List.Item.Meta
                avatar={<FilePdfFilled style={{ fontSize: '28px', color: COLOR_PRIMARY }} />}
                title={item?.documentType?.title}
                description={item.file?.originalName}
              />
            </List.Item>
          )}
        />
      </Wrapper>
    </Space>
  );
}
