import {
  Space, Typography, Badge, Button,
} from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useLocationContext } from '../../Context';

import styles from './index.module.scss';

export default function TopBar() {
  const navigate = useNavigate();
  const {
    isChanging, onSave, location, loading, isValid,
  } = useLocationContext();

  return (
    <Space className={styles.main}>
      <Space>
        <Button className={styles.btnBack} type="text" disabled={isChanging} onClick={() => navigate('/locations')}>
          <ArrowLeftOutlined style={{ color: '#8C8C8C' }} />
        </Button>
        <Typography.Title level={4} style={{ margin: 0 }}>
          {`${location?.name} - ${location?.domains[0]?.title}`}
        </Typography.Title>
        <Badge
          className={styles.badge}
          color={location!.isActive ? '#52C41A' : '#BFBFBF'}
          text={location!.isActive ? 'Active' : 'Inactive'}
        />
      </Space>

      <div>
        <Button
          type="primary"
          onClick={() => (onSave ? onSave() : null)}
          disabled={!isChanging || !isValid}
          loading={loading}
        >
          Save
        </Button>
      </div>
    </Space>
  );
}
