import { LeftOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import {
  Alert, Button, Form as AntdForm, Input, Typography, Row, Col,
} from 'antd';
import { validationSignInEmailAndPassword, createRulesForAntd } from '../../../../utils/validations';
import SendSuccess from '../SendSuccess/index';
import { Logo } from '../../../Common/Icon';
import { usePasswordForgot } from '../../../../hooks/password';
import { getMessageInError } from '../../../../hooks/fetch';

import styles from './index.module.scss';

const { Text, Paragraph } = Typography;
const validationRules = createRulesForAntd(validationSignInEmailAndPassword);

export default function Form() {
  const passwordCreate = usePasswordForgot();
  const [sendSuccess, setSendSuccess] = useState(false);

  const onFinish = ({ email }: { email: string }) => {
    passwordCreate.fetch({ email });
  };

  useEffect(() => {
    if (passwordCreate.data) {
      setSendSuccess(true);
    }
  }, [passwordCreate.data]);

  return (
    <Row justify="center">
      <Col>
        <Logo className={styles.logo} />
        <AntdForm className={styles.form} layout="vertical" onFinish={onFinish}>
          {!sendSuccess && (
            <>
              <Text className={styles.text} strong>
                Forgot your password?
              </Text>
              <Paragraph className={styles.secondary} type="secondary">
                No problem! Enter your login email, and we’ll send you instructions on how to reset it.
              </Paragraph>

              {passwordCreate.error && (
                <AntdForm.Item>
                  <Alert
                    type="error"
                    onClose={() => passwordCreate.clearError()}
                    message={getMessageInError(passwordCreate.error)}
                    closable
                    showIcon
                  />
                </AntdForm.Item>
              )}

              <AntdForm.Item name="email" label="Email *" className={styles.email} rules={[validationRules]}>
                <Input size="large" placeholder="Email" />
              </AntdForm.Item>
              <AntdForm.Item shouldUpdate>
                {({ getFieldsValue, getFieldsError }) => {
                  const { email: loginValue } = getFieldsValue();
                  const fieldsError = getFieldsError();

                  return (
                    <Button
                      size="large"
                      type="primary"
                      block
                      loading={passwordCreate.loading}
                      htmlType="submit"
                      disabled={!loginValue || fieldsError.some((item) => item.errors.length)}
                    >
                      Send password reset email
                    </Button>
                  );
                }}
              </AntdForm.Item>
              <AntdForm.Item>
                <Link to="/sign-in">
                  <Button type="text" size="large" style={{ color: '#8C8C8C' }} block icon={<LeftOutlined />}>
                    Back to log in
                  </Button>
                </Link>
              </AntdForm.Item>
            </>
          )}
          {sendSuccess && <SendSuccess />}
        </AntdForm>
      </Col>
    </Row>
  );
}
