import {
  FilePdfFilled, CloseOutlined, CheckOutlined, InfoCircleTwoTone,
} from '@ant-design/icons';
import {
  Col, List, Typography, Space, Tooltip,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useShiftContext } from '../../../Pages/Shift/Context';
import { Applicant } from '../../../../types/applicant';
import ViewAction from '../../Applicant/ViewAction';
import { COLOR_PRIMARY } from '../../../../AntdConfigProvider';
import UploadAction from '../../Applicant/UploadAction';

import styles from './index.module.scss';

interface DocumentsProps {
  applicant: Applicant | null;
  fetchApplicant: () => void;
}

export default function Documents({ applicant, fetchApplicant }: DocumentsProps) {
  const { shift, isApplicant } = useShiftContext();
  const { t } = useTranslation();
  const documentsIds = applicant?.documents?.map((doc) => doc?.documentType?.id);

  const getActions = (typeId: string) => {
    if (applicant) {
      const document = applicant.documents?.find((doc) => doc.documentType?.id === typeId);

      return (
        <span>
          {document && <ViewAction fileId={document.file.id} name={document.file.originalName} />}
          {!document && isApplicant && (
            <UploadAction applicantId={applicant.id} typeId={typeId} fetch={fetchApplicant} />
          )}
        </span>
      );
    }

    return null;
  };

  return (
    <Col span={12}>
      <Typography.Text strong>
        Minimum Required Documents
        {' '}
        {isApplicant && (
          <Tooltip title={t('requiredDocuments')}>
            <InfoCircleTwoTone style={{ cursor: 'help' }} twoToneColor={COLOR_PRIMARY} />
          </Tooltip>
        )}
      </Typography.Text>
      <List
        className={styles.documents}
        itemLayout="horizontal"
        dataSource={shift?.documents}
        renderItem={(item) => (
          <List.Item actions={[getActions(item.id)]}>
            <List.Item.Meta
              avatar={<FilePdfFilled style={{ fontSize: '28px', color: COLOR_PRIMARY }} />}
              title={item?.title}
              description={
                documentsIds?.includes(item?.id) ? (
                  <Space>
                    <CheckOutlined style={{ color: COLOR_PRIMARY }} />
                    Uploaded
                  </Space>
                ) : (
                  <Space>
                    <CloseOutlined style={{ color: '#FF4D4F' }} />
                    Not uploaded
                  </Space>
                )
              }
            />
          </List.Item>
        )}
      />
    </Col>
  );
}
