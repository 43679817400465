import { useEffect } from 'react';
import { Form, Alert, App } from 'antd';
import { tabList } from '../index';
import { useClientId, useClientUpdate } from '../../../../../hooks/client';
import { useSettingsContext } from '../Context';
import Loading from '../../../../Common/Loading';
import { getMessageInError } from '../../../../../hooks/fetch';
import Fields from '../Fields';

import styles from './index.module.scss';

type GeneralType = {
  name: string;
  address: string;
  phone: string;
  email: string;
};
export default function General() {
  const { message } = App.useApp();
  const client = useClientId();
  const updateClient = useClientUpdate();
  const {
    isEdit, setEdit, setForm, setActiveTab, setOnSave, setChanging, clientId,
  } = useSettingsContext();
  const [form] = Form.useForm();

  useEffect(() => {
    fetch();
    setEdit(() => false);
    setChanging(() => false);
    setForm(() => form);
    setActiveTab(() => tabList[1].key);
    setOnSave(() => onSave);
  }, []);

  const onSave = () => {
    if (clientId) {
      form
        .validateFields()
        .then(() => {
          form.submit();
          updateClient.fetch(form.getFieldsValue(), clientId);
        })
        .catch(() => {
          setChanging(() => false);
        });
    }
  };

  const fetch = () => {
    if (clientId) {
      client.fetch(undefined, clientId);
    }
  };

  useEffect(() => {
    if (updateClient.data) {
      setEdit(() => false);
      setChanging(() => false);
      fetch();
      message.info('You have successfully updated your data');
    }
  }, [updateClient.data]);

  useEffect(() => {
    if (client.data) {
      form.setFieldsValue(client.data);
    }
  }, [client.data, isEdit]);

  // eslint-disable-next-line
  const onFieldsChange = (_: any, allFields: any[]) => {
    const isFieldsValid = allFields.every((field) => field.errors.length === 0);

    if (client.data) {
      setChanging(() => allFields.some((field) => field.value !== client.data![field.name[0] as keyof GeneralType]));
    }

    if (!isFieldsValid) {
      setChanging(() => false);
    }
  };

  return (
    <div className={styles.wrapper}>
      {updateClient.error ? (
        <Alert
          type="error"
          message={getMessageInError(updateClient.error)}
          closable
          onClose={updateClient.clearError}
        />
      ) : null}
      {client.error ? (
        <Alert type="error" message={getMessageInError(client.error)} closable onClose={client.clearError} />
      ) : null}
      {client.loading ? (
        <div className={styles.loading}>
          <Loading />
        </div>
      ) : (
        <Form<GeneralType>
          form={form}
          requiredMark={false}
          disabled={!isEdit}
          onFieldsChange={onFieldsChange}
          layout="vertical"
          className={styles.form}
        >
          <Fields />
        </Form>
      )}
    </div>
  );
}
