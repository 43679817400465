import {
  Button, Empty, Space, Typography, Row, App,
} from 'antd';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { Notification, useNotificationsSawAll } from '../../../../hooks/notification';
import { groupArrayBy } from '../../../../utils/prepareData';
import NotificationRow from './NotificationRow';
import { stringDateFormat } from '../../../../contstant';
import { useMessageError } from '../../../../hooks/common';
import { notificationAction } from '../../../../store/notifications';
import styles from '../index.module.scss';

interface NotificationsContentProps {
  notifications?: Notification[];
  fullWidth?: boolean;
  loading: boolean;
}

function NotificationsContent({ notifications, fullWidth, loading }: NotificationsContentProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sawAll = useNotificationsSawAll();
  // eslint-disable-next-line max-len
  const prepareDateField = (data: any) => (dayjs(data).isSame(dayjs(), 'day') ? 'Today' : dayjs(data).format(stringDateFormat));
  const { message } = App.useApp();
  const groupedNotifications = groupArrayBy(notifications || [], 'createdAt', prepareDateField);

  useEffect(() => {
    if (sawAll.data) {
      message.success('All notifications have been marked as read');
      dispatch(notificationAction());
    }
  }, [sawAll.data]);

  useMessageError([sawAll]);

  return (
    <div className={clsx(styles.notificationsWrapper, fullWidth ? styles.fullWidth : null)}>
      <Row justify="space-between" style={{ flexWrap: 'nowrap' }}>
        <Typography.Title level={4} className={styles.notificationsGroupTitle}>
          Notifications
        </Typography.Title>

        <Space>
          <Button loading={sawAll.loading || loading} onClick={() => sawAll.fetch()} type="link">
            Mark all as read
          </Button>
          <Button type="primary" loading={loading} onClick={() => navigate('/notifications?category=shifts')}>
            View All
          </Button>
        </Space>
      </Row>

      <Space direction="vertical" className={styles.notificationsContent}>
        {notifications && notifications.length ? (
          Object.entries(groupedNotifications).map(([date, items]) => (
            <Space direction="vertical" key={date} style={{ width: '100%' }}>
              <Typography className={styles.notificationsGroupTitle}>{date}</Typography>
              {items.map((item: Notification) => (
                <NotificationRow key={item.id} item={item} />
              ))}
            </Space>
          ))
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No notifications to display" />
        )}
      </Space>
    </div>
  );
}

NotificationsContent.defaultProps = {
  notifications: undefined,
  fullWidth: undefined,
};

export default NotificationsContent;
