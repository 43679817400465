import { useState, useEffect } from 'react';
import {
  Button, Input, Form, Modal,
} from 'antd';
import { FileTextOutlined } from '@ant-design/icons';
import { FieldData } from 'rc-field-form/lib/interface';
import { useUpdateApplicant } from '../../../../hooks/applicant';
import { useSettingsContext } from '../../../Pages/Profile/Applicant/Context';
import styles from './index.module.scss';
import { COLOR_PRIMARY } from '../../../../AntdConfigProvider';
import { useMessageError } from '../../../../hooks/common';

interface NotesProps {
  name: string;
}

function Notes({ name }: NotesProps) {
  const { applicant, isAdmin } = useSettingsContext();
  const updateApplicant = useUpdateApplicant();
  const [isChanging, setChanging] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [form] = Form.useForm();

  const onFieldsChange = (_: FieldData[], allFields: FieldData[]) => {
    setChanging(allFields.every((field) => field?.errors?.length === 0));
  };

  useEffect(() => {
    form.setFieldValue(name, applicant?.[name]);
  }, [applicant?.[name]]);

  useEffect(() => {
    if (updateApplicant.data) {
      setModalOpen(false);
    }
  }, [updateApplicant.data]);

  useMessageError([updateApplicant]);

  return (
    isAdmin && (
      <>
        <Button
          type="link"
          className={styles.notes}
          onClick={() => {
            setModalOpen(true);
          }}
        >
          Notes
          <FileTextOutlined
            className={styles.icon}
            style={form.getFieldValue(name) ? { color: COLOR_PRIMARY } : { color: '#8C8C8C' }}
          />
        </Button>
        <Modal
          title="Personal details notes"
          open={isModalOpen}
          onOk={() => updateApplicant.fetch({ [name]: form.getFieldValue(name) }, applicant?.id)}
          onCancel={() => setModalOpen(false)}
          okButtonProps={{ disabled: !isChanging, loading: updateApplicant.loading }}
          okText="Save"
          className={styles.modal}
          closable={false}
        >
          <Form form={form} requiredMark={false} layout="vertical" onFieldsChange={onFieldsChange}>
            <Form.Item name={name}>
              <Input.TextArea rows={4} style={{ width: '100%' }} />
            </Form.Item>
          </Form>
        </Modal>
      </>
    )
  );
}

export default Notes;
