import Checkboxes from '../../../Common/Filter/Checkboxes';
import FilterMenu from '../../../Common/Filter/Menu/index';
import Search from '../../../Common/Filter/Search';
import FilterWrapper from '../../../Common/Filter/Wrapper';

interface FiltersProps {
  disabled?: boolean;
}

function Filter({ disabled }: FiltersProps) {
  return (
    <FilterWrapper clearFilters disabled={disabled}>
      <FilterMenu>
        <Checkboxes
          name="isActive"
          title="Team status"
          items={[
            {
              label: 'Active',
              key: 'true',
            },
            {
              label: 'Inactive',
              key: 'false',
            },
          ]}
          badge
          checkAll
        />

        <Search name="partialName" />
      </FilterMenu>
    </FilterWrapper>
  );
}

Filter.defaultProps = {
  disabled: false,
};

export default Filter;
