import { InfoCircleTwoTone, SearchOutlined } from '@ant-design/icons';
import {
  Input, Form, Select, Modal, Typography,
} from 'antd';
import { debounce } from 'lodash';
import { useEffect, useState, useMemo } from 'react';
import { FieldData } from 'rc-field-form/lib/interface';
import { useShiftActionContext } from '../../../Pages/Shift/Create/Context';
import { ApplicantsGetParams, useApplicantsGet } from '../../../../hooks/applicant';
import { getDateTimeISO } from '../../../../utils';
import { useMessageError } from '../../../../hooks/common';
import { COLOR_PRIMARY } from '../../../../AntdConfigProvider';

interface PrefferedApplicantsProps {
  isModalOpen: boolean;
  setModalOpen: (modalOpen: boolean) => void;
}

export const timeFrameOptions = [
  {
    label: '24 hours',
    value: '24h',
  },
  {
    label: '48 hours',
    value: '48h',
  },
  {
    label: '72 hours',
    value: '72h',
  },
];

export default function PrefferedApplicants({ isModalOpen, setModalOpen }: PrefferedApplicantsProps) {
  const applicants = useApplicantsGet();
  const { form, setFields } = useShiftActionContext();
  const [applicantsForm] = Form.useForm();
  const [isValid, setValid] = useState(true);

  useMessageError([applicants]);

  useEffect(() => {
    if (isModalOpen) {
      getApplicants();
    }
  }, [isModalOpen]);

  const getApplicants = (search?: string) => {
    const clients = form?.getFieldValue('client');
    const date = form?.getFieldValue('date');
    const time = form?.getFieldValue('time');

    const params: ApplicantsGetParams = {
      clients,
      statuses: 'preferred',
      userStatuses: ['active'],
      experiences: form?.getFieldValue('experiences'),
      from: getDateTimeISO(date[0] || '', time[0] || ''),
      to: getDateTimeISO(date[1], time[1]),
    };

    if (search) {
      params.partialFullName = search;
    }

    applicants.fetch(params);
  };

  const handleSearch = useMemo(
    () => debounce((value: string) => {
      getApplicants(value);
    }, 300),
    [],
  );

  useEffect(() => {
    if (applicants.data) {
      // @ts-ignore Value of type '(state: any) => any' has no properties
      setFields((state) => ({
        ...state,
        preferredApplicants: applicants?.data?.data?.map((item) => ({
          title: `${item.user.firstName} ${item.user.lastName}`,
          value: item.id,
        })),
      }));
    }
  }, [applicants.data]);

  const handleOk = () => {
    const fieldsValue = applicantsForm.getFieldsValue();

    form?.setFieldValue(
      'preferredApplicants',
      fieldsValue.preferredApplicants.length ? fieldsValue.preferredApplicants : null,
    );
    form?.setFieldValue('timeFrame', fieldsValue.time ? fieldsValue.time : null);
    setModalOpen(false);
  };

  const onFieldsChange = (_: FieldData[], allFields: FieldData[]) => {
    setValid(
      allFields.every((field) => field.value) || allFields.every((field) => !field.value || !field.value?.length),
    );
  };

  return (
    <Modal
      title="Send to Preferred Applicant(s) first"
      open={isModalOpen}
      onOk={handleOk}
      okButtonProps={{ disabled: !isValid }}
      onCancel={() => setModalOpen(false)}
      centered
      maskClosable={false}
    >
      <Form layout="vertical" form={applicantsForm} onFieldsChange={onFieldsChange}>
        <Typography.Text style={{ display: 'block', marginBottom: '8px' }}>
          Only applicants marked as ‘preferred’ will appear in this list.
        </Typography.Text>
        <Form.Item name="preferredApplicants" label="Select applicant (multiselect)">
          <Select
            mode="multiple"
            placeholder="Please select"
            showSearch={false}
            // eslint-disable-next-line react/no-unstable-nested-components
            dropdownRender={(menu) => (
              <>
                <Input
                  placeholder="Search"
                  prefix={<SearchOutlined />}
                  onChange={(e) => handleSearch(e.target.value)}
                />
                {menu}
              </>
            )}
            loading={applicants.loading}
            options={applicants?.data?.data?.map((item) => ({
              label: `${item.user.firstName} ${item.user.lastName}`,
              value: item.id,
            }))}
          />
        </Form.Item>
        <Form.Item
          name="time"
          label="Set time frame"
          tooltip={{
            title: 'Once the time frame expires, any qualified applicants can apply for the shift.',
            icon: <InfoCircleTwoTone style={{ cursor: 'help' }} twoToneColor={COLOR_PRIMARY} />,
          }}
        >
          <Select options={timeFrameOptions} allowClear />
        </Form.Item>
      </Form>
    </Modal>
  );
}
