import React from 'react';
import Layout from '../../components/Layout';
import ContentApplicant from '../../components/Pages/Applicant';

export default function Applicant(): React.ReactNode {
  document.title = `${process.env.REACT_APP_TITLE_NAME}: Applicant`;

  return (
    <Layout disableLeftMenu>
      <ContentApplicant />
    </Layout>
  );
}
