import React from 'react';
import Form from '../../../components/Pages/ForgotPassword/Form';
import Simple from '../../../components/Layout/Simple';

export default function ForgotPassword(): React.ReactNode {
  document.title = `${process.env.REACT_APP_TITLE_NAME}: Forgot password`;

  return (
    <Simple>
      <Form />
    </Simple>
  );
}
