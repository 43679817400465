import { useEffect, useState } from 'react';
import { InfoCircleTwoTone } from '@ant-design/icons';
import { useSpecialitiesGet } from '../../../../../hooks/specialities';
import { useMessageError } from '../../../../../hooks/common';
import CustomDropdown from '../../../../Common/CustomDropdown';
import { COLOR_PRIMARY } from '../../../../../AntdConfigProvider';

export interface SelectProps {
  label: string;
  value: string;
}
export default function Specialities() {
  const specialitiesGet = useSpecialitiesGet();
  const [specialities, setSpecialities] = useState<SelectProps[] | null>(null);

  useEffect(() => {
    specialitiesGet.fetch();
  }, []);

  useEffect(() => {
    if (specialitiesGet.data) {
      setSpecialities(specialitiesGet?.data?.map(({ title }) => ({ label: title, value: title })));
    }
  }, [specialitiesGet.data]);

  useMessageError([specialitiesGet]);

  return (
    <CustomDropdown
      items={specialities}
      setItems={setSpecialities}
      itemName="speciality"
      label="Specialty"
      tooltip={{
        title: 'Select from the available Specialties or create your own.',
        icon: <InfoCircleTwoTone style={{ cursor: 'help' }} twoToneColor={COLOR_PRIMARY} />,
      }}
    />
  );
}
