import React, { createContext, useState } from 'react';

export interface NoteContextProps {
  state: string
  setState: (value: string) => void;
}

const defaultValue: NoteContextProps = {
  state: '',
  setState: () => {
    // default
  },
};

export const NoteContext = createContext<NoteContextProps>(defaultValue);

export interface NoteProviderProps {
  notes: string
  children: React.ReactNode
}

function NoteProvider({
  notes,
  children,
}: NoteProviderProps) {
  const [state, setState] = useState<string>(notes);

  return (
    <NoteContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        state,
        setState,
      }}
    >
      {children}
    </NoteContext.Provider>
  );
}

NoteProvider.defaultProps = {
  defaultTab: undefined,
  resource: undefined,
  action: undefined,
  mode: undefined,
};

export default NoteProvider;

export const useNoteContext = (): NoteContextProps => React.useContext(NoteContext);
