/* eslint-disable @typescript-eslint/no-unused-vars, no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  delay, takeLatest, put, call,
} from 'redux-saga/effects';
import { EnhancedStore } from '@reduxjs/toolkit/src/configureStore';
import { useSelector } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import {
  Action,
  createUrl,
  defaultProcessingSaga,
  defaultResponseProcessing,
  FetchResponse,
  fetchSaga, getState,
} from '@triare/auth-redux';
import { RootState } from '../index';
import { Notification } from '../../hooks/notification';
import { UserRoles } from '../../enums/user';
import { State as AuthState } from '../auth/index';

export const moduleName = 'notifications';

export interface State {
  loading: boolean;
  error: null | AxiosError;
  response: null | AxiosResponse<Notification[]>;
  unreadResponse: null | AxiosResponse<Notification[]>;
}

export function useNotificationsCount(): number {
  return useSelector((state: RootState) => state[moduleName]?.unreadResponse?.meta?.itemCount);
}

export function useLastNotifications(): Notification[] {
  return useSelector((state: RootState) => state[moduleName]?.response?.data);
}

export const auth = createSlice({
  name: moduleName,
  initialState: {
    loading: false, error: null, response: null, unreadResponse: null,
  },
  reducers: {
    notifications: () => {
      // triger
    },
    notificationsStart: (state) => {
      state.loading = true;
    },
    notificationsSuccess: (state, { payload }) => {
      state.loading = false;
      state.response = payload;
    },
    unreadNotificationsSuccess: (state, { payload }) => {
      state.loading = false;
      state.unreadResponse = payload;
    },
    notificationsError: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export const notificationAction = () => ({ type: actions.notifications.type });

export const { reducer, actions } = auth;

export function* notificationRequestSaga() {
  const authData = (yield getState()) as AuthState;

  if (!authData || !authData.user || authData.user.role === UserRoles.GUEST) {
    return;
  }

  yield put({
    type: actions.notificationsStart.type,
  });

  const responseUnread = (yield call(fetchSaga, createUrl('notifications?saw=false'), {
    method: 'GET',
  })) as FetchResponse;

  yield defaultResponseProcessing(
    responseUnread,
    actions.unreadNotificationsSuccess.type,
    actions.notificationsError.type,
    false,
  );

  const response = (yield call(fetchSaga, createUrl('notifications?orderBy=DESC&pageSize=5'), {
    method: 'GET',
  })) as FetchResponse;

  yield defaultResponseProcessing(response, actions.notificationsSuccess.type, actions.notificationsError.type, false);
}

function* reloadNotifications() {
  yield put(notificationAction());

  while (true) {
    yield delay(5 * 60 * 1000);

    yield put(notificationAction());
  }
}

export function* saga(store: EnhancedStore) {
  // yield takeLatest(
  //   actions.notifications.type,
  //   defaultProcessingSaga(notificationRequestSaga, {
  //     error: actions.notificationsError.type,
  //   }),
  // );
  yield reloadNotifications();
}
