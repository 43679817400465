import React, {
  createContext, useState, PropsWithChildren,
} from 'react';

interface CollapseContextProps {
  list: string[];
  setList: (list: string[]) => void;
}

const defaultValue: CollapseContextProps = {
  list: [],
  setList: () => {
    // default
  },
};

export const CollapseContext = createContext<CollapseContextProps>(defaultValue);

function CollapseProvider({ children }: PropsWithChildren) {
  const [list, setList] = useState<string[]>([]);

  return (
    <CollapseContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        list,
        setList,
      }}
    >
      {children}
    </CollapseContext.Provider>
  );
}

export default CollapseProvider;

export const useCollapseContext = (): CollapseContextProps => React.useContext(CollapseContext);
