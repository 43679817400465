import React from 'react';
import Layout from '../../components/Layout';
import ContentUser from '../../components/Pages/User';

export default function User(): React.ReactNode {
  document.title = `${process.env.REACT_APP_TITLE_NAME}: User`;

  return (
    <Layout disableLeftMenu>
      <ContentUser />
    </Layout>
  );
}
