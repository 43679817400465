import React from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { Logo } from '../../../Common/Icon';
import { useContextSignUp } from '../Context';
import Personal from '../Steps/Personal';
import Verification from '../Steps/Verification';
import AHPRA from '../Steps/AHPRA';
import Experience from '../Steps/Experience';
import Indemnity from '../Steps/Indemnity';
import Documents from '../Steps/Documents';
import Confirmation from '../Steps/Confirmation';
import { SignUpPageDefaultProp } from '../types';

import styles from './index.module.scss';
import stylesPage from '../../SignIn/OTPCode/index.module.scss';

interface List {
  name: string
  Content: React.FC<SignUpPageDefaultProp>
}

export const list: List[] = [
  {
    name: 'Personal details',
    Content: Personal,
  },
  {
    name: 'Verification',
    Content: Verification,
  },
  {
    name: 'AHPRA №',
    Content: AHPRA,
  },
  {
    name: 'Experience',
    Content: Experience,
  },
  {
    name: 'Indemnity',
    Content: Indemnity,
  },
  {
    name: 'Documents',
    Content: Documents,
  },
  {
    name: 'Confirmation',
    Content: Confirmation,
  },
];

export default function Wrapper(): React.ReactNode | null {
  const {
    pageNumber,
  } = useContextSignUp();

  return (
    <>
      <div className={styles.tabs}>
        {list.map(({ name }, id) => (
          <div
            key={name}
            className={clsx(styles.item, { [styles.active]: id === pageNumber })}
            role="none"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <div className={styles.icon}>
              {id + 1}
            </div>
            <div className={styles.name}>
              {name}
            </div>
          </div>
        ))}
      </div>
      <div className={styles.content}>
        <Logo style={{ marginTop: 16, justifySelf: 'center', maxWidth: '240px' }} />
        <div className={clsx(styles.page, stylesPage.wrapper)}>
          {list.map(({ Content, name }, id) => (
            <Content key={name} name={name} id={id} className={clsx({ [styles.disabled]: id !== pageNumber })} />
          ))}
        </div>

        {pageNumber === 0 ? (
          <div className={styles.account}>
            Already have account?
            <NavLink to="/sign-in">Log In</NavLink>
          </div>
        ) : null}
      </div>
    </>
  );
}
