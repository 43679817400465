import {
  Row, Col, List, Typography, Button, App, InputNumber, Modal,
} from 'antd';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useShiftContext } from '../../../Pages/Shift/Context';
import { useApplicantId } from '../../../../hooks/applicant';
import { Applicant } from '../../../../types/applicant';
import { useShiftUpdate } from '../../../../hooks/shift';
import { getMessageInError } from '../../../../hooks/fetch';
import { useSimpleModal } from '../../Modal/Simple';
import ApplicantInfo from '../ApplicantInfo';
import ShiftStatus from '../../../../enums/shift';
import { generateDefaultUrl } from '../../../../routes';
import { useAuthState } from '../../../../store/auth';

import styles from './index.module.scss';

export default function ApplicantView() {
  const updateShift = useShiftUpdate();
  const { shift, isAdmin } = useShiftContext();
  const [activeId, setActiveId] = useState<string | null>(null);
  const [applicant, setApplicant] = useState<Applicant | null>(null);
  const { message } = App.useApp();
  const navigate = useNavigate();
  const { open, contextHolder } = useSimpleModal();
  const getApplicant = useApplicantId();
  const isApplicantChosen = !!shift?.applicant;
  const [finalPrice, setFinalPrice] = useState<number | null>(null);
  const [priceModalOpen, setPriceModalOpen] = useState(false);
  const { user } = useAuthState();

  useEffect(() => {
    if (shift?.rateRangeFrom && shift?.rateType === 'range') {
      setFinalPrice(shift?.rateRangeFrom);
    }
  }, [shift?.rateRangeFrom]);

  const fetchApplicant = () => {
    if (activeId) {
      getApplicant.fetch(undefined, activeId);
    }
  };

  useEffect(() => {
    if (activeId) {
      fetchApplicant();
    }
  }, [activeId]);

  useEffect(() => {
    if (getApplicant.data) {
      setApplicant(getApplicant.data);
    }
  }, [getApplicant]);

  const getChoose = () => {
    if (activeId) {
      updateShift.fetch(
        finalPrice ? { applicant: activeId, rate: finalPrice as number } : { applicant: activeId },
        shift?.id,
      );
    }
  };

  const onChoose = () => {
    const documentsIds = applicant?.documents?.map((item) => item.documentType?.id);
    const inductionsIds = applicant?.inductions?.map((item) => item.id);
    const isDocumentsMatch = shift?.documents?.every((item) => documentsIds?.includes(item.id));
    const isInductionsMatch = shift?.inductions?.every((item) => inductionsIds?.includes(item.id));

    if (!isDocumentsMatch || !isInductionsMatch) {
      open({
        title: 'Requirements missing',
        content: (
          <div className={styles.requirements}>
            This applicant is missing the following requirements:
            <ul>
              {!isInductionsMatch && <li>Inductions</li>}
              {!isDocumentsMatch && <li>Documents</li>}
            </ul>
            Are you sure you want to select this applicant?
          </div>
        ),
        okButtonProps: { loading: updateShift.loading },
        cancelText: 'Cancel',
        centered: true,
        okText: 'Choose',
        onOk: () => {
          if (shift?.rateType === 'range') {
            setPriceModalOpen(true);
          } else {
            getChoose();
          }
        },
      });
    } else if (shift?.rateType === 'range') {
      setPriceModalOpen(true);
    } else {
      getChoose();
    }
  };

  useEffect(() => {
    if (updateShift.data) {
      if (!updateShift.data.applicant) {
        message.success('Applicant successfully removed.');
      } else {
        message.success('You have successfully selected an applicant!');
      }
      navigate(`/shifts/${generateDefaultUrl(user)}`);
    }
  }, [updateShift.data]);

  useEffect(() => {
    if (updateShift.error) {
      message.error(getMessageInError(updateShift.error));
      updateShift.clearError();
    }
  }, [updateShift.error]);

  return (
    <div className={styles.wrapper}>
      {!isApplicantChosen && activeId && shift?.status !== ShiftStatus.EXPIRED ? (
        <Button
          className={styles.btn}
          type="primary"
          loading={getApplicant.loading || updateShift.loading}
          onClick={onChoose}
        >
          Choose applicant
        </Button>
      ) : null}

      <Row gutter={[16, 0]}>
        <Col span={8}>
          <List
            loading={!shift}
            className={styles.applicants}
            itemLayout="horizontal"
            dataSource={isApplicantChosen ? [shift?.applicant] : shift?.applicants}
            renderItem={(item) => (
              <List.Item
                className={clsx(styles.item, activeId === item?.id ? styles.active : '')}
                onClick={() => item && setActiveId(item.id)}
              >
                <List.Item.Meta title={`${item?.user.firstName} ${item?.user.lastName}`} />
              </List.Item>
            )}
            footer={
              isAdmin && isApplicantChosen ? (
                <Button
                  className={styles.remove}
                  onClick={() => {
                    open({
                      title: 'Remove the applicant',
                      content: 'Are you sure you want to remove the applicant?',
                      okButtonProps: { loading: updateShift.loading },
                      cancelText: 'Cancel',
                      centered: true,
                      okText: 'Remove',
                      onOk: () => {
                        updateShift.fetch({ applicant: null }, shift?.id);
                      },
                    });
                  }}
                  type="text"
                  danger
                >
                  Remove applicant
                </Button>
              ) : null
            }
          />
        </Col>

        {activeId && (
          <Col span={16}>
            <ApplicantInfo
              loading={getApplicant.loading || !getApplicant.data}
              applicant={applicant}
              fetchApplicant={fetchApplicant}
            />
          </Col>
        )}
      </Row>

      <Modal
        title="Please set final price for the applicant"
        centered
        open={priceModalOpen}
        onOk={getChoose}
        okButtonProps={{
          loading: updateShift.loading,
          disabled:
            !finalPrice || finalPrice < (shift?.rateRangeFrom || 1) || finalPrice > (shift?.rateRangeTo || 1000000),
        }}
        onCancel={() => setPriceModalOpen(false)}
        closable={false}
        maskClosable={false}
      >
        <div>
          <Typography.Text>
            This shift has a price range of
            {` $${shift?.rateRangeFrom} - $${shift?.rateRangeTo}. `}
            Please enter a final price and click save.
          </Typography.Text>
          <InputNumber
            controls={false}
            addonBefore="$"
            placeholder="From"
            onInput={(e) => {
              const value = e.replace(/([^0-9])/gim, '');

              if (!value) {
                setFinalPrice(null);
              } else {
                setFinalPrice(parseInt(value.toString(), 10));
              }
            }}
            value={finalPrice}
            style={{ width: '100%' }}
            min={shift?.rateRangeFrom || 1}
            max={shift?.rateRangeTo || 1000000}
            maxLength={(shift?.rateRangeTo || 1000000).toString().length}
          />
        </div>
      </Modal>
      {contextHolder}
    </div>
  );
}
