import { CheckSquareFilled } from '@ant-design/icons';
import {
  Row, Col, Typography, Space,
} from 'antd';
import { NavLink } from 'react-router-dom';
import { useShiftContext } from '../../../Pages/Shift/Context';
import ViewItem from '../ViewItem/index';
import { Applicant } from '../../../../types/applicant';
import Documents from '../Documents';
import Inductions from '../Inductions';
import Loading from '../../Loading';

import styles from './index.module.scss';

interface ApplicantInfoProps {
  applicant: Applicant | null;
  fetchApplicant: () => void;
  loading: boolean;
}

export default function ApplicantInfo({ applicant, fetchApplicant }: ApplicantInfoProps) {
  const { shift } = useShiftContext();

  return (
    <div className={styles.panel}>
      {!applicant && (
        <div className={styles.loadingArea}>
          <Loading size={44} />
        </div>
      )}
      <Typography.Title className={styles.title} level={5}>
        General details
      </Typography.Title>
      <Row gutter={[16, 0]}>
        <Col span={12}>
          <Space direction="vertical" size="small" className={styles.space}>
            <Typography.Text strong>Experience</Typography.Text>

            {shift?.experiences.map((item) => (applicant?.experiences?.map((exp) => exp.id)?.includes(item.id) ? (
              <Space className={styles.experience} key={item?.id}>
                <CheckSquareFilled />
                {item.title}
              </Space>
            ) : (
              item.title
            )))}
          </Space>
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <ViewItem text="Title" span={12} value={applicant?.title} />
        <ViewItem text="Address" span={12} value={applicant?.user?.address} />
      </Row>
      <Row gutter={[16, 0]}>
        <ViewItem text="First name" span={12} value={applicant?.user?.firstName} />
        <ViewItem text="Last name" span={12} value={applicant?.user?.lastName} />
      </Row>
      <Row gutter={[16, 0]}>
        <ViewItem
          text="Phone"
          span={12}
          node={<NavLink to={`tel:${applicant?.user?.phone}`}>{applicant?.user?.phone}</NavLink>}
        />
        <ViewItem
          text="Email"
          span={12}
          node={<NavLink to={`mailto:${applicant?.user?.email}`}>{applicant?.user?.email}</NavLink>}
        />
      </Row>
      <Row gutter={[16, 0]}>
        <ViewItem text="AHPRA number" span={12} value={applicant?.ahpra?.toString()} />
      </Row>
      <Typography.Title className={styles.title} level={5}>
        Indemnity insurance
      </Typography.Title>
      <Row gutter={[16, 0]}>
        <ViewItem text="Provider" span={12} value={applicant?.indemnityProvider?.title} />
        <ViewItem text="Policy number" span={12} value={applicant?.indemnityNumber?.toString()} />
      </Row>

      <Row gutter={[16, 0]}>
        <Inductions applicant={applicant} title="Required inductions" fetchApplicant={fetchApplicant} />
        <Documents applicant={applicant} fetchApplicant={fetchApplicant} />
      </Row>
    </div>
  );
}
