import {
  DefaultFetchError,
  FetchCreate,
  FetchGet,
  FetchGetId,
  FetchUpdate,
  useFetchCreate,
  useFetchGet,
  useFetchGetId,
  useFetchDelete,
  useFetchUpdate, PagingParams, PagingDataResponse, FetchSuccess,
} from './fetch';
import { pagingParamList } from '../contstant';
import { IdOnly } from '../types';
import { UserRole, UserSettings, UserStatus } from './user';

export interface User extends IdOnly {
  role: UserRole
  email: string
  status: UserStatus
  phone: string
  firstName: string
  lastName: string
  fullName: string
  address: string
  settings: UserSettings
  manager: IdOnly
  locationAdmin: IdOnly
  applicant: IdOnly
  locationCount: number
  locationName: string
}

export interface Domain extends IdOnly {
  title: string
}

export interface Location extends IdOnly {
  name: string
  address: string
  isActive: boolean
  phone: string
  email: string
  link: string
  domains: Domain[]
}

export type Applicant = IdOnly

export type ApplicantStatusStatus = 'preferred' | 'excluded'

export interface ApplicantStatus extends IdOnly {
  applicant: Applicant
  status: ApplicantStatusStatus
}

export interface Manager {
  user: User
  locations: Location[]
}

export type LocationAdminUser = User

export interface LocationAdmin {
  user: LocationAdminUser
}
interface Logo {
  createdAt: string;
  id: string;
  location: string;
  mimeType: string;
  originalName: string;
}

export interface Client extends IdOnly {
  autoApproved: boolean
  twoFAtoggle: boolean
  address: string
  isActive: boolean
  locations: Location[]
  domains: Domain[]
  name: string
  phone: string
  email: string
  logo: Logo
  logoMini: Logo
  applicantStatuses: ApplicantStatus[]
  createdAt: string
  managers: Manager[]
  locationAdmins: LocationAdmin[]

  // TODO: need add in API
  status: string
}

export interface ClientsGetParams extends PagingParams {
  orderByColumn?: 'id' | 'name' | 'createdAt' | 'isActive' | 'phone' | 'email'
  isActive?: boolean | boolean[]
  partialName?: string
  clients?: string[]
}

export function useClientsGet<D = PagingDataResponse<Client>,
  DD = D>(decorateData?: (data: D) => DD): FetchGet<D, ClientsGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, ClientsGetParams, DD>(
    'clients',
    {
      decorateData,
      autoStart: false,
      startStateLoading: false,
    },
  );
}

export function useClientsSelectGet<D = Client[],
  DD = D>(decorateData?: (data: D) => DD): FetchGet<D, ClientsGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, ClientsGetParams, DD>(
    'clients/select',
    {
      name: 'Clients',
      decorateData,
      autoStart: false,
      startStateLoading: false,
    },
  );
}

interface SelectClient extends Client {
  locationsCount: number
}

export function useClientsSelectLocationsGet<D = SelectClient[],
  DD = D>(decorateData?: (data: D) => DD): FetchGet<D, ClientsGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, ClientsGetParams, DD>(
    'clients/select/locations',
    {
      decorateData,
      autoStart: false,
      startStateLoading: false,
    },
  );
}

export function useClientsSelectAllGet<D = Client[],
  DD = D>(decorateData?: (data: D) => DD): FetchGet<D, ClientsGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, ClientsGetParams, DD>(
    'clients/plain',
    {
      name: 'ClientsShifts',
      decorateData,
      autoStart: false,
      startStateLoading: false,
    },
  );
}

export function useClientsSelectShiftGet<D = Client[],
  DD = D>(decorateData?: (data: D) => DD): FetchGet<D, ClientsGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, ClientsGetParams, DD>(
    'clients/select/shifts',
    {
      name: 'ClientsShifts',
      decorateData,
      autoStart: false,
      startStateLoading: false,

      beforeError(err) {
        if (err && err.response?.status === 422) {
          return true;
        }

        return false;
      },
    },
  );
}

export const useClientId = (id?: string): FetchGetId<Client> => useFetchGetId('clients', id, { autoStart: false });

export function useClientsFilterStatusAllGet<D = string[],
  DD = D>(decorateData?: (data: D) => DD): FetchGet<D, ClientsGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, ClientsGetParams, DD>(
    'clients/statuses/all',
    {
      decorateData,
      autoStart: false,
      startStateLoading: false,
    },
  );
}

export function useClientsFilterStatusGet<D = string[],
  DD = D>(decorateData?: (data: D) => DD): FetchGet<D, ClientsGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, ClientsGetParams, DD>(
    'clients/statuses',
    {
      decorateData,
      autoStart: false,
      startStateLoading: false,
    },
  );
}

export const clientsGetParamList = [
  'isActive',
  'partialName',
  'orderBy',
  'orderByColumn',
  ...pagingParamList,
];

export interface ClientCreateParams {
  autoApproved: boolean;
  twoFAtoggle: boolean;
  address: string;
  locations?: (null | string)[];
  domains?: (null | string)[];
  isActive: boolean;
  name: string;
  phone: string;
  email: string;
}

export const useClientCreate = ():
  FetchCreate<FetchSuccess, DefaultFetchError, ClientCreateParams> => useFetchCreate('clients');

export interface ClientUpdateParams {
  autoApproved?: boolean;
  twoFAtoggle?: boolean;
  address?: string;
  locations?: string[];
  domains?: string[];
  shifts?: string[];
  isActive?: boolean;
  name?: string;
  phone?: string;
  email?: string;
  logo?: string | null;
  logoMini?: string | null;
}

export const useClientUpdate = (id?: string): FetchUpdate<FetchSuccess, DefaultFetchError, ClientUpdateParams> => (
  useFetchUpdate('clients', id)
);

export const useClientDelete = () => useFetchDelete<FetchSuccess, DefaultFetchError, string>('clients');

export const useClientLogoDelete = () => useFetchDelete<FetchSuccess, DefaultFetchError, string>('clients/logo');
/* eslint-disable max-len */
export const useClientLogoMiniDelete = () => useFetchDelete<FetchSuccess, DefaultFetchError, string>('clients/logo/mini');

export const useClientValidate = (): FetchGetId<{success: boolean}> => (
  useFetchGetId('clients/validate/removal', '', { autoStart: false })
);
