import Checkboxes from '../../../Common/Filter/Checkboxes/index';
import FilterMenu from '../../../Common/Filter/Menu';
import FilterWrapper from '../../../Common/Filter/Wrapper';
import Search from '../../../Common/Filter/Search';
import {
  useUserAdminStatusesSelectGet,
  useUserStatusesAllSelectGet,
} from '../../../../hooks/user';
import { useMessageError, useSelectData } from '../../../../hooks/common';
import { getBudgeColor } from '../../../Common/Badge';

interface FilterProps {
  disabled?: boolean;
}

function Filter({ disabled }: FilterProps) {
  const defaultUserStatusesAllSelectGet = useUserStatusesAllSelectGet();
  const userAdminStatusesSelectGet = useUserAdminStatusesSelectGet();
  const items = useSelectData(userAdminStatusesSelectGet, (data, id, item) => ({
    ...item,
    color: getBudgeColor(item.value),
  }));

  useMessageError([
    defaultUserStatusesAllSelectGet, userAdminStatusesSelectGet,
  ]);

  return (
    <FilterWrapper clearFilters disabled={disabled}>
      <FilterMenu>
        <Checkboxes
          title="Admin status"
          name="statuses"
          defaultHook={defaultUserStatusesAllSelectGet}
          items={items}
          fetch={userAdminStatusesSelectGet.fetch}
          loading={userAdminStatusesSelectGet.loading || defaultUserStatusesAllSelectGet.loading}
          badge
          checkAll
        />
        <Search name="partialName" placeholder="Search admin" />
      </FilterMenu>
    </FilterWrapper>
  );
}

Filter.defaultProps = {
  disabled: false,
};

export default Filter;
