import React from 'react';
import Layout from '../../../../../components/Layout';
// import Filter from '../../../../../components/Pages/Shift/Filter';
import ConfirmationContent from '../../../../../components/Pages/Shift/Confirmation';

export default function ShiftConfirmationList(): React.ReactNode {
  document.title = `${process.env.REACT_APP_TITLE_NAME}: Shift confirmation list`;

  return (
    <Layout disableLeftMenu>
      <ConfirmationContent />
    </Layout>
  );
}
