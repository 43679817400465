import { FilePdfFilled, InfoCircleTwoTone } from '@ant-design/icons';
import {
  Col, List, Typography, Tooltip,
} from 'antd';
import { useTranslation } from 'react-i18next';
import ViewAction from '../../Applicant/ViewAction';
import { COLOR_PRIMARY } from '../../../../AntdConfigProvider';
import { File } from '../../../../hooks/orientation';
import styles from './index.module.scss';

interface OrientationDocumentsProps {
  documents: File[];
}

export default function OrientationDocuments({ documents }: OrientationDocumentsProps) {
  const { t } = useTranslation();

  return (
    <Col span={12}>
      <Typography.Text strong>
        Orientation documents
        {' '}
        <Tooltip title={t('orientationDocuments')}>
          <InfoCircleTwoTone style={{ cursor: 'help' }} twoToneColor={COLOR_PRIMARY} />
        </Tooltip>
      </Typography.Text>
      <List
        className={styles.documents}
        itemLayout="horizontal"
        dataSource={documents}
        renderItem={(item) => (
          <List.Item
            actions={[
              <span key={item.id}>
                <ViewAction fileId={item.id} name={item.originalName} />
              </span>,
            ]}
          >
            <List.Item.Meta
              avatar={<FilePdfFilled style={{ fontSize: '28px', color: COLOR_PRIMARY }} />}
              title={item?.originalName}
            />
          </List.Item>
        )}
      />
    </Col>
  );
}
