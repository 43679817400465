import {
  Alert, App, Empty, Space,
} from 'antd';
import React, { useEffect } from 'react';
import type { CollapseProps } from 'antd';
import { useLocationsShiftsGet } from '../../../../../../hooks/location';
import { useSearchParams } from '../../../../../../hooks/useSearchParams';
import { getMessageInError } from '../../../../../../hooks/fetch';
import Loading from '../../../../../Common/Loading';
import Collapse from '../../../../../Common/Collapse';
import ShiftList from '../../index';
import Counter from '../../../../../Common/Counter';
import { UserRoles } from '../../../../../../enums/user';
import { useAuthState } from '../../../../../../store/auth';

import styles from './index.module.scss';

interface NestedLocationsProps {
  domainId: string;
  openShift?: boolean;
}

function NestedLocations({ domainId, openShift }: NestedLocationsProps) {
  const locationsShiftsGet = useLocationsShiftsGet();
  const { message } = App.useApp();
  const [,, paramsWithoutTableProps] = useSearchParams();
  const { user } = useAuthState();
  const isClient = user?.role === UserRoles.MANAGER || user?.role === UserRoles.CLIENT_ADMIN;

  useEffect(() => {
    locationsShiftsGet.fetch({
      ...paramsWithoutTableProps,
      pageSize: 1000,
      domain: domainId,
    });
  }, [paramsWithoutTableProps]);

  useEffect(() => {
    if (locationsShiftsGet.error) {
      message.error(getMessageInError(locationsShiftsGet.error));
      locationsShiftsGet.clearError();
    }
  }, [locationsShiftsGet.error]);

  const items: CollapseProps['items'] = (locationsShiftsGet.data?.data || [])
    .filter(({ client }) => client?.isActive)
    ?.map(({
      id, name, client, shiftCount,
    }) => ({
      key: id,
      label: (
        <Space>
          {(!isClient && client?.name ? `${client?.name} - ` : '') + name}
          <Counter count={shiftCount} />
        </Space>
      ),
      children: <ShiftList disableHeader domainId={domainId} locationId={id} openShift={openShift} />,
    }));

  if (locationsShiftsGet.loading) {
    return (
      <div className={styles.loading}>
        <Loading />
      </div>
    );
  }

  if (locationsShiftsGet.error) {
    return (
      <Alert
        type="error"
        message={getMessageInError(locationsShiftsGet.error)}
        closable
        onClose={locationsShiftsGet.clearError}
      />
    );
  }

  return (
    <div className={styles.nested}>
      <Collapse accordion destroyInactivePanel items={items} />

      {(!locationsShiftsGet.data?.data || !locationsShiftsGet.data?.data.length) && <Empty />}
    </div>
  );
}

NestedLocations.defaultProps = {
  openShift: false,
};

export default NestedLocations;
