import {
  Col, Form, Row, Radio,
} from 'antd';
import Wrapper from '../Wrapper';

type InterviewProps = {
  disabled?: boolean;
  isScheduled: boolean;
};

function Interview({ disabled, isScheduled }: InterviewProps) {
  return (
    <Wrapper>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item name="interviewScheduled" label="Was the interview scheduled?" style={{ marginBottom: 0 }}>
            <Radio.Group disabled={disabled}>
              <Radio value>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="interviewSuccessful" label="Was the interview successful?" style={{ marginBottom: 0 }}>
            <Radio.Group disabled={!isScheduled || disabled}>
              <Radio value>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
    </Wrapper>
  );
}

Interview.defaultProps = {
  disabled: false,
};

export default Interview;
