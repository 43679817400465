import React, {
  createContext, useState, useEffect, PropsWithChildren,
} from 'react';
import { FormInstance, App } from 'antd';
import { useParams } from 'react-router-dom';
import { Locations } from '../../../../types/location';
import { useLocationsId } from '../../../../hooks/location';
import { getMessageInError } from '../../../../hooks/fetch';

type LocationContextProps = {
  form: FormInstance | undefined;
  setForm: (callback: () => FormInstance) => void;
  isEdit: boolean;
  setEdit: (callback: () => boolean) => void;
  isValid: boolean;
  setValid: (callback: () => boolean) => void;
  isChanging: boolean;
  setChanging: (callback: () => boolean) => void;
  activeTab: string;
  setActiveTab: (callback: () => string) => void;
  onSave: (() => void) | undefined;
  setOnSave: (onSave: () => void) => void;
  fetch: () => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;

  location: Locations | null;
  setLocation: (location: Locations | null) => void;
};

const defaultValue: LocationContextProps = {
  form: undefined,
  setForm: () => {
    // default
  },

  isEdit: true,
  setEdit: () => {
    // default
  },

  isValid: true,
  setValid: () => {
    // default
  },

  isChanging: false,
  setChanging: () => {
    // default
  },

  activeTab: '',
  setActiveTab: () => {
    // default
  },

  onSave: () => {
    // default
  },
  setOnSave: () => {
    // default
  },
  fetch: () => {
    // default
  },

  loading: false,
  setLoading: () => {
    // default
  },

  setLocation: () => {
    // default
  },
  location: null,
};

export const LocationContext = createContext<LocationContextProps>(defaultValue);

function LocationProvider({ children }: PropsWithChildren) {
  const { locationId } = useParams();
  const [location, setLocation] = useState<Locations | null>(null);
  const { message } = App.useApp();
  const fetchLocations = useLocationsId();
  const [isChanging, setChanging] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEdit, setEdit] = useState(true);
  const [isValid, setValid] = useState(true);
  const [form, setForm] = useState<FormInstance | undefined>();
  const [onSave, setOnSave] = useState<(() => void) | undefined>();
  const [activeTab, setActiveTab] = useState('');

  const fetch = () => {
    fetchLocations.fetch(undefined, locationId);
    setLoading(true);
  };

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    if (fetchLocations.data) {
      setLocation(fetchLocations.data);
      setLoading(false);
    }
  }, [fetchLocations.data]);

  useEffect(() => {
    if (fetchLocations.error) {
      message.error(getMessageInError(fetchLocations.error));
      fetchLocations.clearError();
      setLoading(false);
    }
  }, [fetchLocations.error]);

  return (
    location && (
      <LocationContext.Provider
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        value={{
          form,
          setForm,

          isEdit,
          setEdit,

          isValid,
          setValid,

          isChanging,
          setChanging,

          activeTab,
          setActiveTab,

          onSave,
          setOnSave,

          fetch,

          loading,
          setLoading,

          location,
          setLocation,
        }}
      >
        {children}
      </LocationContext.Provider>
    )
  );
}

export default LocationProvider;

export const useLocationContext = (): LocationContextProps => React.useContext(LocationContext);
