import ShiftActionProvider from '../Create/Context';
import ShiftForm from '../../../Common/Shift/ShiftForm';

export default function CopyShift() {
  return (
    <ShiftActionProvider mode="copy">
      <ShiftForm />
    </ShiftActionProvider>
  );
}
