import { Form, Input, Col } from 'antd';
import { createRulesForAntd, validationApplicant } from '../../../../utils/validations';
import InputAddress from '../../Input/Address';

type GeneralProps = {
  disabled?: boolean;
};

const validationRules = createRulesForAntd(validationApplicant);

function General({ disabled }: GeneralProps) {
  return (
    <>
      <Col span={12}>
        <Form.Item name="title" label="Title">
          <Input disabled={disabled} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <InputAddress name="address" label="Address" disabled={disabled} />
      </Col>
      <Col span={12}>
        <Form.Item name="firstName" label="First name" rules={[validationRules]}>
          <Input disabled={disabled} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="lastName" label="Last name" rules={[validationRules]}>
          <Input disabled={disabled} />
        </Form.Item>
      </Col>
    </>
  );
}

General.defaultProps = {
  disabled: false,
};

export default General;
