import { Form, Input } from 'antd';
import { createRulesForAntd, validationUserAdd } from '../../../../../utils/validations';

const validationRules = createRulesForAntd(validationUserAdd);

export default function Fields() {
  return (
    <>
      <Form.Item label="First Name" name="firstName" rules={[validationRules]}>
        <Input placeholder="First name" />
      </Form.Item>
      <Form.Item label="Last Name" name="lastName" rules={[validationRules]}>
        <Input placeholder="Last name" />
      </Form.Item>

      <Form.Item label="Phone" name="phone" rules={[validationRules]}>
        <Input placeholder="Phone" />
      </Form.Item>

      <Form.Item label="Contact Email" name="email" rules={[validationRules]}>
        <Input placeholder="Contact Email" />
      </Form.Item>
    </>
  );
}
