import React, { useEffect, useRef } from 'react';
import {
  Alert, Button, Col, DatePicker, Form, Input, Row, Select,
} from 'antd';
import { useTranslation } from 'react-i18next';
import type { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import { signOut } from '@triare/auth-redux/dist/saga/auth/signOut';
import { useNavigate } from 'react-router-dom';
import { SignUpPageDefaultProp } from '../../types';
import { useContextSignUp } from '../../Context';
import { useSimpleModal } from '../../Modal/Simple';
import { getMessageInError } from '../../../../../hooks/fetch';
import { dateFormat } from '../../../../../contstant';
import { useCheckAHPRANumber } from '../../../../../hooks/auth';
import { useMessageError } from '../../../../../hooks/common';
import store from '../../../../../store';

import styles from './index.module.scss';

interface FormValues {
  name: string;
}

type AHPRAProps = SignUpPageDefaultProp;

export default function AHPRA({ id, name, className }: AHPRAProps): React.ReactNode | null {
  const timeout = useRef<NodeJS.Timeout | undefined>();
  const { t } = useTranslation();
  const { open, contextHolder } = useSimpleModal();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const formValues = Form.useWatch([], form);
  const {
    pageNumber, setForm, onFinish, triggerBack, signUpFinish, isPossibleTriggerBack,
  } = useContextSignUp();
  const provisionalOrGeneral = Form.useWatch('provisionalOrGeneral', form);
  const isProvisional = provisionalOrGeneral === 'Provisional';
  const AHPRANumber = Form.useWatch('AHPRANumber', form);
  const checkAHPRANumber = useCheckAHPRANumber();

  useEffect(() => {
    if (AHPRANumber && AHPRANumber.toString().length >= 4) {
      checkAHPRANumber.fetch({
        ahpra: AHPRANumber,
      });

      if (timeout.current) {
        clearTimeout(timeout.current);
      }

      timeout.current = setTimeout(() => {
        timeout.current = undefined;
        form.setFields([
          {
            name: 'AHPRANumber',
            errors: ['Number check. Loading...'],
          },
        ]);
      }, 5);
    }

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [form, AHPRANumber]);

  useEffect(() => {
    if (checkAHPRANumber.error) {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }

      timeout.current = setTimeout(() => {
        timeout.current = undefined;
        form.setFields([
          {
            name: 'AHPRANumber',
            errors: [getMessageInError(checkAHPRANumber.error)],
          },
        ]);
      }, 5);
    }

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [form, checkAHPRANumber.error]);

  useEffect(() => {
    if (checkAHPRANumber.data) {
      if (checkAHPRANumber.data?.unique === true) {
        if (timeout.current) {
          clearTimeout(timeout.current);
        }

        timeout.current = setTimeout(() => {
          timeout.current = undefined;
          form.setFields([
            {
              name: 'AHPRANumber',
              errors: [],
            },
          ]);
          form
            .validateFields()
            .then(() => null)
            .catch(() => null);
        }, 5);
      }

      if (checkAHPRANumber.data?.unique === false) {
        if (timeout.current) {
          clearTimeout(timeout.current);
        }

        timeout.current = setTimeout(() => {
          timeout.current = undefined;
          form.setFields([
            {
              name: 'AHPRANumber',
              errors: ['This AHPRA number is already in use'],
            },
          ]);
        }, 5);
      }
    }

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [form, checkAHPRANumber.data]);

  useMessageError([checkAHPRANumber]);

  useEffect(() => {
    if (pageNumber === id && signUpFinish.response) {
      store.dispatch(signOut());
      navigate('/sign-in');
    }
  }, [signUpFinish.response, id, pageNumber]);

  useEffect(() => {
    setForm(name, form);
  }, [name, form]);

  const onFinishHandler = (values: FormValues) => {
    if (isProvisional) {
      open({
        contentH2: 'Thanks for your interest in Lokem!',
        contentSpan: `We’ll reach out on ${form.getFieldValue('date').format(dateFormat)}`,
        okText: 'Ok',
        onOk: () => onFinish(name, values),
      });
    } else {
      onFinish(name, values);
    }
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current) => current && current < dayjs().endOf('day');

  return (
    <Form<FormValues>
      form={form}
      className={className}
      name={name}
      autoComplete="off"
      layout="vertical"
      onFinish={onFinishHandler}
    >
      <h2>Registration Details</h2>
      <Row>
        <Col span={24}>
          <span>{`${t('AHPRA Registration Number')} *`}</span>
          <Form.Item
            name="AHPRANumber"
            rules={[
              { required: true, message: `${t('Please enter AHPRA Number')}` },
              { min: 4, message: 'Must be longer than or equal to 4 numbers' },
            ]}
          >
            <Input placeholder="12345678" size="large" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <span>{`${t('Provisional or General')}? *`}</span>
          <Form.Item name="provisionalOrGeneral">
            <Select
              options={[
                { value: 'Provisional', label: `${t('Provisional')}` },
                { value: 'General', label: `${t('General')}` },
              ]}
              placeholder="Please select"
              size="large"
            />
          </Form.Item>
        </Col>
        {isProvisional ? (
          <>
            <span className={styles.textDate}>
              Thanks for your interest in Lokem! Unfortunately, a General AHPRA license is required to book shifts with
              Lokem. Please select the date your provisional license ends if you’d like us to save your information and
              reach out to you nearer that time!
            </span>
            <Col span={24}>
              <Form.Item name="date">
                <DatePicker disabledDate={disabledDate} size="large" format={dateFormat} />
              </Form.Item>
            </Col>
          </>
        ) : null}
      </Row>

      {signUpFinish.error ? (
        <Alert
          type="error"
          message={getMessageInError(signUpFinish.error)}
          closable
          onClose={signUpFinish.clearError}
          style={{ marginBottom: 16, marginTop: -16 }}
        />
      ) : null}

      <div>
        {isPossibleTriggerBack() ? (
          <Button onClick={triggerBack} size="large">
            Go Back
          </Button>
        ) : null}
        <Form.Item shouldUpdate className="submit">
          {() => (
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              disabled={
                form.getFieldsError().filter(({ errors }) => errors.length).length > 0
                || !(form.getFieldValue('AHPRANumber') || '').trim()
                || !(form.getFieldValue('provisionalOrGeneral') || '').trim()
                || (form.getFieldValue('provisionalOrGeneral') === 'Provisional' && !(form.getFieldValue('date') || ''))
              }
            >
              {formValues?.provisionalOrGeneral === 'Provisional' ? 'Save and End' : 'Save and Continue'}
            </Button>
          )}
        </Form.Item>
      </div>

      {contextHolder}
    </Form>
  );
}
