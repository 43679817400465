import React, { useMemo } from 'react';
import {
  Menu, Checkbox, Badge, Input,
} from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { getItem, MenuItem } from '../Menu';
import Loading from '../../Loading';
import { getBudgeColor } from '../../Badge';
import { Item, sortByDisable } from '../../../../hooks/common';
import Logic, { LogicExportProps } from '../Logic';

import styles from './index.module.scss';

export interface CheckboxesViewProps extends LogicExportProps {
  itemsValid: Item[],
  itemsAll: Item[],
  itemList: Item[],
  uncheckAll: boolean;
  checkedItems: string[]
  indeterminate: boolean;
  handleSelectAll: (e: CheckboxChangeEvent) => void
  handleChange: (value: string) => (e: CheckboxChangeEvent) => void
}

function CheckboxesView({
  title,
  badge,
  checkAll,
  loading,
  search,
  defaultCheck,
  keyAsArray,
  itemsAll,
  itemsValid,
  itemList,
  uncheckAll,
  checkedItems,
  indeterminate,
  handleSelectAll,
  onSearch,
  handleChange,
}: CheckboxesViewProps) {
  const menuItems: MenuItem[] = useMemo(() => ([
    getItem(
      title,
      'subMenu',
      checkAll ? (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          className={styles.checkboxWrap}
        >
          <Checkbox
            checked={
              (!uncheckAll && checkedItems.length === 0 && defaultCheck)
              || (keyAsArray
                ? itemsValid.reduce((acc, currentValue) => acc + (currentValue.key as Array<string>).length, 0)
                  === checkedItems.length
                : checkedItems.length === itemsValid.length)
            }
            indeterminate={indeterminate}
            onChange={handleSelectAll}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        </div>
      ) : null,
      loading && (itemsAll && !itemsAll.length)
        ? [
          getItem(
            <div className={styles.loading}>
              <Loading />
            </div>,
            'loading',
          ),
        ]
        : [
          search
            ? getItem(<Input.Search
                onChange={(e) => {
                  if (onSearch) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    onSearch(e);
                  }
                }}
                className={styles.search}
            />, 'search')
            : null,

          ...sortByDisable(itemList).map((item) => getItem(
            <Checkbox
              value={item.key}
              onChange={handleChange((item.key as string) || '')}
              disabled={item.disabled}
              checked={item.disabled ? false
                : ((!uncheckAll && checkedItems.length === 0 && defaultCheck)
                  || (keyAsArray
                    ? (item.key as Array<string>).every((key) => checkedItems?.includes(key))
                    : checkedItems.includes((item.key as string) || '')))}
            >
              {badge ? <Badge color={getBudgeColor(item.label)} text={item.label} /> : item.label}
            </Checkbox>,
            (item.key as string) || '',
          )),
        ],
    ),
  ]), [loading, checkedItems, uncheckAll, defaultCheck, keyAsArray, itemList]);

  // if (!defaultItems?.length) {
  //   return null;
  // }

  menuItems.forEach((item: any) => {
    if (item) {
      if (item.children && Array.isArray(item.children)) {
        item.children.sort((a: any, b: any) => {
          if (!a || !a.key) return 1; // Move null or undefined values to the end
          if (!b || !b.key) return -1;

          // eslint-disable-next-line no-nested-ternary
          return a.key === 'Active' ? -1 : a.key === 'Inactive' ? 1 : 0;
        });
      }
    }
  });

  return (
    <div className={styles.wrapper}>
      <Menu className={styles.menu} defaultOpenKeys={['subMenu']} mode="inline" items={menuItems} />

      {loading && itemsAll && itemsAll.length ? (
        <div className={styles.loading}>
          <Loading />
        </div>
      ) : null}
    </div>
  );
}

CheckboxesView.defaultProps = {
  addFilter: undefined,
  search: false,
  defaultCheck: true,
  searchBottom: false,
  badge: false,
  checkAll: false,
  uncheck: false,
  loading: false,
  isMine: undefined,
  keyAsArray: false,
  params: {},
  fetch: undefined,
  decorateData: undefined,
  defaultHook: undefined,
};

export default function Checkboxes(props: LogicExportProps) {
  return <Logic {...props} Component={CheckboxesView} />;
}
