import { pagingParamList } from '../contstant';
import {
  DefaultFetchError,
  FetchGet,
  useFetchGet,
  PagingDataResponse,
  PagingParams,
  useFetchGetId,
  FetchGetId,
} from './fetch';
import { Shift } from './shift';

export interface InvoiceGetParams extends PagingParams {
  take?: number;
  experiences?: string[];
  roles?: string[];
  status?: string;
  client?: string;
  from?: string
  to?: string
}

export const invoicesGetParamList = [
  'domains',
  'clients',
  'applicants',
  'locations',
  'locationRoles',
  'roles',
  'from',
  'to',
  'orderByColumn',
  'orderBy',
  ...pagingParamList,
];

export interface Invoice extends Shift {
  unitPrice: string
  quantity: string
  amount: string
}

export function useInvoicesGet<D = PagingDataResponse<Invoice>, DD = D>(
  decorateData?: (data: D) => DD,
): FetchGet<D, InvoiceGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, InvoiceGetParams, DD>('invoices', {
    decorateData,
    autoStart: false,
    startStateLoading: false,
  });
}

export interface InvoiceGenerateParams {
  from?: string | null;
  to?: string | null;
  shifts?: string[] | [];
  clients?: string[] | [];
  domains?: string[] | [];
  applicants?: string[] | [];
  locations?: string[] | [];
  locationRoles?: string[] | [];
  roles?: string[] | [];
  all?: boolean
}

export interface InvoiceGenerateSimpleParams extends InvoiceGenerateParams {
  orderByColumn?: string
  skipColumns?: string[]
}
export const invoicesGenerateList = [
  'domains',
  'clients',
  'applicants',
  'locations',
  'locationRoles',
  'roles',
  'shifts',
  'type',
  'from',
  'to',
];

export const invoicesGenerateSimpleList = [
  ...invoicesGenerateList,
  'orderByColumn',
  'skipColumns',
];

export const useInvoiceClient = (
  abortController: AbortController,
): FetchGetId<ArrayBuffer> => (
  useFetchGetId(
    'invoices/generate-invoice/client',
    '',
    {
      autoStart: false,
      startStateLoading: false,
      config: {
        headers: { 'content-type': 'multipart/form-data' },
        signal: abortController.signal,
      },
    },
    'arraybuffer',
  )
);

export const useInvoiceApplicant = (
  abortController: AbortController,
): FetchGetId<ArrayBuffer> => (
  useFetchGetId(
    'invoices/generate-invoice/applicant',
    '',
    {
      autoStart: false,
      startStateLoading: false,
      config: {
        headers: { 'content-type': 'multipart/form-data' },
        signal: abortController.signal,
      },
    },
    'arraybuffer',
  )
);

export const useInvoiceGeneral = (
  abortController: AbortController,
): FetchGetId<ArrayBuffer> => (
  useFetchGetId(
    'invoices/generate-invoice',
    '',
    {
      autoStart: false,
      startStateLoading: false,
      config: {
        headers: { 'content-type': 'multipart/form-data' },
        signal: abortController.signal,
      },
    },
    'arraybuffer',
  )
);

export interface InvoiceCompleteParams extends InvoiceGenerateParams {
  type?: string
}

interface InvoiceCompleteResponse {
  partial: boolean
}

export function useInvoiceComplete<D = InvoiceCompleteResponse, DD = D>(
  decorateData?: (data: D) => DD,
): FetchGet<D, InvoiceCompleteParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, InvoiceCompleteParams, DD>('invoices/generate-invoice/complete', {
    decorateData,
    autoStart: false,
    startStateLoading: false,
  });
}
