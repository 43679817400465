import { useState, useEffect } from 'react';
import {
  Typography, Modal, Select, App,
} from 'antd';
import { useLocationContext } from '../../Context';
import { useLocationsRolesGet, useLocationsRolesUpdate } from '../../../../../hooks/locationsRoles';
import { useMessageError } from '../../../../../hooks/common';

import styles from './index.module.scss';

type LinkedModalProps = {
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  activeId: string;
};

export default function LinkedModal({ isModalOpen, setIsModalOpen, activeId }: LinkedModalProps) {
  const getRoles = useLocationsRolesGet();
  const updateRole = useLocationsRolesUpdate();
  const [role, setRole] = useState<string | null>(null);
  const { location, fetch } = useLocationContext();
  const { message } = App.useApp();

  const afterOpenChange = (open: boolean) => {
    if (open) {
      getRoles.fetch({ location: location?.id });
    }
  };

  useEffect(() => {
    if (updateRole.data) {
      setRole(null);
      setIsModalOpen(false);
      message.info('You have successfully linked a role to an orientation');
      fetch();
    }
  }, [updateRole.data]);

  useMessageError([getRoles, updateRole]);

  return (
    <Modal
      title="Link Orientation Package to role"
      open={isModalOpen}
      onOk={() => {
        if (role) {
          updateRole.fetch({ orientation: activeId }, role);
        }
      }}
      okButtonProps={{ loading: updateRole.loading, disabled: !role }}
      onCancel={() => {
        setRole(null);
        setIsModalOpen(false);
      }}
      okText="Link package"
      className={styles.modal}
      closable={false}
      centered
      afterOpenChange={afterOpenChange}
    >
      <Typography.Text strong>Role</Typography.Text>
      <Select
        className={styles.select}
        value={role}
        placeholder="Select role"
        options={
          getRoles?.data?.data
            ?.filter((item) => !item.orientation)
            .map((item) => ({ label: item.name, value: item.id })) || []
        }
        onChange={(value) => setRole(value)}
      />
    </Modal>
  );
}
