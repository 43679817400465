import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

// import styles from './index.module.scss';

interface LoadingProps {
  size?: number
  className?: string
}

function Loading({ size: fontSize, className }: LoadingProps) {
  const antIcon = <LoadingOutlined style={{ fontSize }} spin />;

  return (
    <Spin indicator={antIcon} className={className} />
  );
}

Loading.defaultProps = {
  size: 24,
  className: undefined,
};

export default Loading;
