import { BellOutlined } from '@ant-design/icons';
import { Badge, Popover } from 'antd';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import NotificationsContent from './NotificationModalContent';
import { useNotificationsSaw } from '../../../hooks/notification';
import {
  useSendAllFetch, FetchUpdate, FetchSuccess, DefaultFetchError,
} from '../../../hooks/fetch';
import { notificationAction, useNotificationsCount, useLastNotifications } from '../../../store/notifications';
import styles from './index.module.scss';

interface NotificationsModalProps {
  iconClassName?: string;
}

function NotificationsModal({ iconClassName }: NotificationsModalProps) {
  const notificationsCount = useNotificationsCount();
  const notificationsLast = useLastNotifications();
  const dispatch = useDispatch();
  const location = useLocation();
  const sawNotifications = useNotificationsSaw();
  /* eslint-disable max-len */
  const sendAllFetch = useSendAllFetch<FetchUpdate<FetchSuccess, DefaultFetchError, null>>(sawNotifications);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!isNotificationsOpen) return;
    setIsNotificationsOpen(false);
  }, [location]);

  useEffect(() => {
    if (!sendAllFetch.loading && sawNotifications.response) {
      dispatch(notificationAction());
    }
  }, [sendAllFetch.loading, sawNotifications.response]);

  useEffect(() => {
    if (isNotificationsOpen) {
      dispatch(notificationAction());
      const unreadNotifications = notificationsLast?.filter((notification) => !notification.status.saw);

      if (!unreadNotifications || !unreadNotifications?.length) return;

      sendAllFetch.fetch(unreadNotifications.map((notification) => [null, `${notification.id}/saw`]));
    }
  }, [isNotificationsOpen]);

  return (
    <Popover
      open={isNotificationsOpen}
      trigger={['click']}
      placement="bottom"
      onOpenChange={(visible) => (location.pathname !== '/notifications' ? setIsNotificationsOpen(visible) : null)}
      overlayClassName="clean-tooltip-overlay"
      showArrow={false}
      content={<NotificationsContent notifications={notificationsLast} loading={sendAllFetch.loading} />}
    >
      <div className={styles.tapArea} />
      <Badge count={notificationsCount}>
        <BellOutlined className={clsx(styles.notificationsButton, iconClassName)} style={{ fontSize: 18 }} />
      </Badge>
    </Popover>
  );
}

NotificationsModal.defaultProps = {
  iconClassName: undefined,
};

export default NotificationsModal;
