import { DefaultFetchError, FetchGet, useFetchGet } from './fetch';

export interface Level {
  id: string;
  title: string;
}

export interface LevelsGetParams {
  title?: string;
  orderByColumn?: 'id' | 'title';
}

export function useLevelsGet<D = Level[], DD = D>(
  decorateData?: (data: D) => DD,
): FetchGet<D, LevelsGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, LevelsGetParams, DD>('levels', {
    decorateData,
    autoStart: false,
    startStateLoading: false,
    cacheLifetime: 10 * 60 * 1000,
    multiple: 'location-levels',
  });
}
