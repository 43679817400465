import React from 'react';
import dayjs from 'dayjs';
import { useRosterContext } from '../../../Context';

import styles from './index.module.scss';

function List(): React.ReactNode {
  const { days } = useRosterContext();

  return days.map((col) => (
    <div className={styles.col} key={col.toISOString()}>
      {col.format('dddd DD/MM')}
      {col.get('date') === dayjs().get('date') ? <div className={styles.active} /> : null}
    </div>
  ));
}

export default React.memo(List);
