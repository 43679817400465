import React, { useEffect } from 'react';
import { Select } from 'antd';
import { useRosterContext } from '../../../Context';
import { useMessageError } from '../../../../../../hooks/common';
import { useTeamsGet } from '../../../../../../hooks/team';
import styles from './index.module.scss';

export const defaultTeam = {
  label: 'All',
  value: 'all',
};

export default function Team(): React.ReactNode {
  const { team, setTeam } = useRosterContext();

  const teams = useTeamsGet();

  useEffect(() => {
    teams.fetch({ isActive: true });
  }, []);

  useMessageError([teams]);

  return (
    <div>
      <label>Team: </label>
      <Select
        className={styles.select}
        value={team}
        placeholder="Select team"
        onChange={setTeam}
        loading={teams.loading}
        options={teams.data ? [defaultTeam, ...teams.data.map(({ id, name }) => ({ value: id, label: name }))] : []}
      />
    </div>
  );
}
