import { useEffect, useState } from 'react';
import {
  Form, Alert, Typography, App, Button,
} from 'antd';
import Fields from './Fields';
import Loading from '../../../Common/Loading';
import { getMessageInError } from '../../../../hooks/fetch';
import { useUserId, useUserUpdate } from '../../../../hooks/user';
import { useAuthState } from '../../../../store/auth';
import TopPanel from '../../../Common/TopPanel';
import ReceiveEmails from '../../../Common/ReceiveEmails';
import { UserRoles, isRoleEnough } from '../../../../enums/user';

import styles from './index.module.scss';

type UserType = {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
};

export default function Profile() {
  const { user } = useAuthState();
  const isAdmin = isRoleEnough(user?.role, UserRoles.LOKEM_ADMIN);
  const [form] = Form.useForm();
  const [isEdit, setIsEdit] = useState(false);
  const [isChanging, setIsChanging] = useState(false);
  const getUser = useUserId();
  const updateUser = useUserUpdate();
  const { message } = App.useApp();

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    if (getUser.data) {
      form.setFieldsValue(getUser.data);
      setIsChanging(false);
    }
  }, [getUser.data, isEdit]);

  const onSave = () => {
    updateUser.fetch(form.getFieldsValue(), user?.id);
  };

  const fetch = () => {
    getUser.fetch(undefined, user?.id);
  };

  useEffect(() => {
    if (updateUser.data) {
      fetch();
      message.info('You have successfully updated your profile');
      setIsEdit(false);
    }
  }, [updateUser.data]);

  // eslint-disable-next-line
  const onFieldsChange = (_: any, allFields: any[]) => {
    if (getUser.data) {
      setIsChanging(allFields.some((field) => field.value !== getUser.data![field.name[0] as keyof UserType]));
    }
  };

  return (
    <div className={styles.wrapper}>
      <TopPanel
        padding
        title="Profile"
        rightContent={(
          <>
            {!isEdit && <Button onClick={() => setIsEdit(true)}>Edit</Button>}
            {isEdit && (
              <Button
                type="primary"
                onClick={() => (onSave ? onSave() : null)}
                disabled={!isChanging}
                loading={updateUser.loading}
              >
                Save
              </Button>
            )}
          </>
        )}
      />
      <div className={styles.inner}>
        <Form<UserType>
          form={form}
          requiredMark={false}
          disabled={!isEdit}
          onFieldsChange={onFieldsChange}
          layout="vertical"
          className={styles.form}
        >
          {updateUser.error ? (
            <Alert
              style={{ margin: '0 0 10px 0' }}
              type="error"
              message={getMessageInError(updateUser.error)}
              closable
              onClose={updateUser.clearError}
            />
          ) : null}
          <Typography.Title level={5} style={{ margin: '0 0 16px 0' }}>
            Personal info
          </Typography.Title>
          {getUser.loading ? (
            <div className={styles.loading}>
              <Loading />
            </div>
          ) : (
            <>
              <Fields />
              <ReceiveEmails />
              {isAdmin
              && (
              <ReceiveEmails
                tooltipe="Receive client notifications?"
                text="Receive client notifications?"
                property="receiveUserEmails"
              />
              ) }

            </>
          )}
        </Form>
      </div>
    </div>
  );
}
