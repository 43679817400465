import { ExclamationCircleFilled } from '@ant-design/icons';
import { Typography, Tag } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Link } from 'react-router-dom';
import { Notification } from '../../../../../hooks/notification';
import { COLOR_PRIMARY } from '../../../../../AntdConfigProvider';
import { timeFormat12 } from '../../../../../contstant';
import styles from '../../index.module.scss';

dayjs.extend(customParseFormat);

interface NotificationRowProps {
  item: Notification;
}

function NotificationRow({ item }: NotificationRowProps) {
  const getNotificationIcon = () => {
    switch (item.entity) {
      case 'practices':
        return <ExclamationCircleFilled />;
      case 'services':
        return <ExclamationCircleFilled />;
      default:
        return <ExclamationCircleFilled />;
    }
  };

  return (
    <Link className={styles.notificationRow} to={`/notifications?category=${item.category}`}>
      <div className={styles.notificationIconWrapper}>
        <div className={styles.notificationIcon}>{getNotificationIcon()}</div>
      </div>
      <div className={styles.notificationContent}>
        <Typography.Text>{item.title}</Typography.Text>
        {!item.status.saw && (
          <Tag color={COLOR_PRIMARY} className={styles.badge}>
            New
          </Tag>
        )}
      </div>
      <div className={styles.notificationTime}>{dayjs.utc(item.createdAt).local().format(timeFormat12)}</div>
    </Link>
  );
}

export default NotificationRow;
