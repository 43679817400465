import React from 'react';
import Layout from '../../components/Layout';
import Content from '../../components/Pages/Admin';

export default function Admin(): React.ReactNode {
  document.title = `${process.env.REACT_APP_TITLE_NAME}: Admin`;

  return (
    <Layout disableLeftMenu>
      <Content />
    </Layout>
  );
}
