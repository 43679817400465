import {
  DefaultFetchError,
  FetchCreate,
  FetchGet,
  FetchSuccess,
  PagingParams,
  useFetchCreate,
  useFetchGet,
} from './fetch';

export interface Indemnity {
  id: string
  title: string
}

export interface IndemnityCreateParams {
  title: string
}

export const useIndemnityCreate = (): FetchCreate<FetchSuccess, DefaultFetchError, IndemnityCreateParams> => (
  useFetchCreate('experiences')
);

export interface IndemnitiesGetParams extends PagingParams {
  title?: string
  orderByColumn?: 'id' | 'title'
}

export function useIndemnitiesGet<D = Indemnity[],
  DD = D>(decorateData?: (data: D) => DD): FetchGet<D, IndemnitiesGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, IndemnitiesGetParams, DD>(
    'indemnities',
    {
      decorateData,
      autoStart: false,
      startStateLoading: false,
    },
  );
}
