import { useState, useEffect } from 'react';
import { App, Form, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import UserFields from '../../../Common/UserFields';
import { UserCreateParams, useUserCreate } from '../../../../hooks/user';
import { getMessageInError } from '../../../../hooks/fetch';
import { UserRoles } from '../../../../enums/user';
import { useAuthState } from '../../../../store/auth';
import TopPanel from '../../../Common/TopPanel';

import styles from './index.module.scss';

export default function AddUser() {
  const { message } = App.useApp();
  const { user } = useAuthState();
  const clientId = user?.locationAdmin?.client.id || user?.manager?.client.id;
  const navigate = useNavigate();
  const createUser = useUserCreate();
  const [wasChanging, setWasChanging] = useState(false);
  const [showLocations, setShowLocation] = useState(false);
  const [form] = Form.useForm();

  const onEdit = () => {
    form
      .validateFields()
      .then(() => {
        const params: UserCreateParams = {
          ...form.getFieldsValue(),
          clientId,
        };

        createUser.fetch(params);
      })
      .catch(() => {
        setWasChanging(false);
      });
  };

  useEffect(() => {
    if (createUser.data) {
      message.info("User has been successfully created. An email has been sent to the user's email address");
      navigate('/users', { replace: true });
    }
  }, [createUser.data]);

  useEffect(() => {
    if (createUser.error) {
      message.error(getMessageInError(createUser.error));
      createUser.clearError();
    }
  }, [createUser.error]);

  // eslint-disable-next-line
  const onFieldsChange = (_: any, allFields: any[]) => {
    setWasChanging(allFields.every((field) => field.errors.length === 0 && field.value && field.value !== ' '));
    setShowLocation(form?.getFieldValue('role')?.toLowerCase() === UserRoles.MANAGER.toLowerCase());
  };

  useEffect(() => {
    setWasChanging(
      form.getFieldsError().every((field) => field.errors.length === 0)
        && Object.values(form.getFieldsValue()).every((item) => item),
    );
  }, [showLocations]);

  return (
    <div className={styles.wrapper}>
      <TopPanel
        padding
        title="Add user"
        arrowBack="/users"
        rightContent={(
          <Button disabled={!wasChanging} onClick={() => onEdit()} type="primary" loading={createUser.loading}>
            Add user
          </Button>
        )}
      />
      <div className={styles.inner}>
        <Form
          form={form}
          requiredMark={false}
          onFieldsChange={onFieldsChange}
          layout="vertical"
          className={styles.form}
        >
          <UserFields showLocation={showLocations} />
        </Form>
      </div>
    </div>
  );
}
