import {
  NavigateOptions,
  SetURLSearchParams,
  URLSearchParamsInit,
  useSearchParams as useRouterSearchParams,
} from 'react-router-dom';
import { AnyObject } from '@triare/auth-redux';
import { useEffect, useState } from 'react';
import { getValidSearchParamsWithout, ValidSearchParams } from '../utils';

export function getSearchParamsWithoutTableProps(searchParams: URLSearchParams): ValidSearchParams {
  return getValidSearchParamsWithout(['page', 'pageSize', 'orderBy', 'orderByColumn'], searchParams);
}

export function useWatchSearchParam(name: string): string[] {
  const [searchParams] = useRouterSearchParams();
  const [state, setState] = useState<string[]>(searchParams.getAll(name));

  useEffect(() => {
    const newState = searchParams.getAll(name);

    if (newState.toString() !== state.toString()) {
      setState(newState);
    }
  }, [searchParams]);

  return state;
}

const paramsWithoutTableProps = (() => {
  let beforeResult = {};
  let beforeResultStringify = 'false';

  return (searchParams: URLSearchParams) => {
    const newParams = getSearchParamsWithoutTableProps(searchParams);
    const newParamsStringify = JSON.stringify(newParams);

    if (beforeResultStringify !== newParamsStringify) {
      beforeResultStringify = newParamsStringify;
      beforeResult = newParams;
    }

    return beforeResult;
  };
})();

export function useSearchParams(
  defaultInit?: URLSearchParamsInit,
): [URLSearchParams, SetURLSearchParams, ValidSearchParams] {
  const [searchParams, setSearchParams] = useRouterSearchParams(defaultInit);

  return [searchParams, (
    nextInit?: URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit),
    navigateOpts?: NavigateOptions,
  ) => {
    if (nextInit) {
      if (typeof nextInit === 'object') {
        const keys = Object.keys(nextInit);

        if (keys.length && keys.find((key) => {
          const next = (nextInit as AnyObject)[key];
          const param = searchParams.getAll(key);

          if (Array.isArray(next)) {
            if (next.length !== (Array.isArray(param) ? param.length : param)) {
              return true;
            }

            return !!next.find((value) => !(Array.isArray(param) && param.includes(value)));
          } if (typeof next === 'object') {
            const nextKeys = Object.keys(next);

            return !!nextKeys.find((nextKey) => next[nextKey] !== (param as AnyObject)[nextKey]);
          }

          return next !== param;
        })) {
          setSearchParams(nextInit, navigateOpts);
        }
      } else {
        setSearchParams(nextInit, navigateOpts);
      }
    }
  }, paramsWithoutTableProps(searchParams)];
}

export default {
  useSearchParams,
};
