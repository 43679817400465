import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Detail from '../Common/Detail';
import { useClientId, useClientUpdate } from '../../../../hooks/client';
import { TabPaneItem } from '../../../Common/Tabs';
import ClientForm from '../Form';
import ClientProvider, { useClientContext } from '../Context';
import ClientUserList from '../User/List';

export default function ClientEdit(): React.ReactNode {
  const { clientId } = useParams();
  const client = useClientId();
  const clientUpdate = useClientUpdate(clientId);

  useEffect(() => {
    client.fetch(undefined, clientId);
  }, [clientId]);

  const tabList: TabPaneItem[] = [
    {
      label: 'General info',
      key: 'general',
      children: <ClientForm />,
    },
    {
      label: 'List of users',
      key: 'users',
      children: <ClientUserList />,
    },
  ];

  return (
    <ClientProvider
      defaultTab={tabList[0].key}
      mode="edit"
      resource={client}
      action={clientUpdate}
    >
      <Detail
        tabList={tabList}
        name={client.data?.name}
        mode="edit"
        useContext={useClientContext}
      />
    </ClientProvider>
  );
}
