import { useState, useEffect } from 'react';
import {
  App, Form, Space, Button, Typography,
} from 'antd';
import { useParams } from 'react-router-dom';
import { CheckCircleOutlined, StopOutlined } from '@ant-design/icons';
import UserFields from '../../Common/UserFields';
import { User as UserType, useUserId, useUserUpdate } from '../../../hooks/user';
import Loading from '../../Common/Loading';
import { UserRoles, isRoleEnough } from '../../../enums/user';
import TopPanel from '../../Common/TopPanel';
import { useAuthState } from '../../../store/auth';
import { usePasswordForgot } from '../../../hooks/password';
import { useSimpleModal } from '../../Common/Modal/Simple';
import { useManagerUpdate } from '../../../hooks/managers';
import { useMessageError } from '../../../hooks/common';
import { COLOR_PRIMARY } from '../../../AntdConfigProvider';

import styles from './index.module.scss';

export default function User() {
  const { user } = useAuthState();
  const passwordCreate = usePasswordForgot();
  const { message } = App.useApp();
  const userById = useUserId();
  const userUpdate = useUserUpdate();
  const managerUpdate = useManagerUpdate();
  const { userId } = useParams();
  const [isChanging, setChanging] = useState(false);
  const [showLocations, setShowLocation] = useState(false);
  const [fields, setFields] = useState<UserType | null>(null);
  const [form] = Form.useForm();
  const isAdmin = isRoleEnough(user?.role, UserRoles.CLIENT_ADMIN);
  const { open, contextHolder } = useSimpleModal();
  const isActive = fields?.status === 'active';
  const actionName = isActive ? 'Deactivate' : 'Activate';
  const values = Form.useWatch([], form);
  const clientId = user?.locationAdmin?.client.id || user?.manager?.client.id;

  const getUser = () => {
    userById.fetch(undefined, userId);
  };

  useEffect(() => {
    getUser();
  }, []);

  const afterUpdate = () => {
    getUser();
    setChanging(false);
    message.info('You have successfully edited this user!');
  };

  useEffect(() => {
    if (userUpdate.data) {
      if (userById?.data?.manager) {
        managerUpdate.fetch({ locations: form.getFieldValue('location') }, userById?.data?.manager?.id);
      } else {
        afterUpdate();
      }
    }
  }, [userUpdate.data]);

  useEffect(() => {
    if (managerUpdate.data) {
      afterUpdate();
    }
  }, [managerUpdate.data]);

  useEffect(() => {
    if (userById.data) {
      setFields(userById.data);
    }
  }, [userById.data]);

  useMessageError([userById, userUpdate, passwordCreate]);

  useEffect(() => {
    if (passwordCreate.data) {
      message.success('Password reset link was sent successfully');
    }
  }, [passwordCreate.data]);

  useEffect(() => {
    form.setFieldsValue({ ...fields, location: fields?.manager?.locations.map((item) => item.id) });
  }, [fields]);

  // eslint-disable-next-line
  const onFieldsChange = (_: any, allFields: any[]) => {
    if (userById.data) {
      setChanging(allFields.some((field) => field.value !== userById?.data![field.name[0] as keyof UserType]));
    }
    if (!allFields.every((field) => field.errors.length === 0)) {
      setChanging(false);
    }
    setShowLocation(form?.getFieldValue('role')?.toLowerCase() === UserRoles.MANAGER.toLowerCase());
  };

  useEffect(() => {
    form.validateFields();
  }, [values?.role]);

  return userById.loading ? (
    <div className={styles.loading}>
      <Loading />
    </div>
  ) : (
    <div className={styles.wrapper}>
      <TopPanel
        padding
        title={`${fields?.firstName} ${fields?.lastName}`}
        arrowBack="/users"
        rightContent={(
          <Button
            disabled={!isChanging}
            onClick={() => form
              .validateFields()
              .then(() => {
                userUpdate.fetch({ ...form.getFieldsValue(), clientId }, userId);
              })
              .catch(() => {
                setChanging(false);
              })}
            type="primary"
            loading={userUpdate.loading}
          >
            Save
          </Button>
        )}
      />
      <div className={styles.inner}>
        <Form
          form={form}
          requiredMark={false}
          onFieldsChange={onFieldsChange}
          layout="vertical"
          className={styles.form}
          disabled={!isAdmin}
        >
          <UserFields showLocation={showLocations} />
          {isAdmin && (
            <Space direction="vertical">
              <Space direction="vertical">
                <Typography.Text strong>Send password reset link</Typography.Text>
                <Button
                  loading={passwordCreate.loading}
                  type="primary"
                  onClick={() => passwordCreate.fetch({ email: fields!.email })}
                  ghost
                >
                  Send link
                </Button>
              </Space>
              <Space direction="vertical" className={styles.statusBtn}>
                <Typography.Text strong>Status</Typography.Text>
                <Button
                  danger={isActive}
                  type={!isActive ? 'primary' : undefined}
                  onClick={(e) => {
                    e.preventDefault();
                    open({
                      icon: isActive ? (
                        <StopOutlined style={{ color: '#FF4D4F' }} />
                      ) : (
                        <CheckCircleOutlined style={{ color: COLOR_PRIMARY }} />
                      ),
                      title: `${actionName} client`,
                      content: `Are you sure you want to ${actionName.toLowerCase()} this user?`,
                      cancelText: 'Cancel',
                      okText: actionName,
                      centered: true,
                      onOk: () => userUpdate.fetch(
                        {
                          status: isActive ? 'deactivated' : 'active',
                        },
                        userId,
                      ),
                    });
                  }}
                >
                  {actionName}
                </Button>
              </Space>
            </Space>
          )}
        </Form>
      </div>
      {contextHolder}
    </div>
  );
}
