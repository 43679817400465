import React from 'react';
import { Pagination as AntdPagination } from 'antd';
import type { PaginationProps as AntdPaginationProps } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { useRosterContext } from '../../Context';
import { pageDefault } from '../../../../../contstant';
import { getValidSearchParams } from '../../../../../utils';

const pageSizePossible = [5, 10, 20, 30, 50];

function Pagination(): React.ReactNode {
  const { grid } = useRosterContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const handlePageChange: AntdPaginationProps['onShowSizeChange'] = (page, pageSize) => {
    setSearchParams({
      ...getValidSearchParams('*', searchParams),
      page: page?.toString() || '',
      pageSize: pageSize?.toString() || '',
    });
  };

  return (
    <AntdPagination
      current={grid?.meta.page || pageDefault}
      onChange={handlePageChange}
      pageSize={Number(searchParams.get('pageSize')) || 20}
      total={grid?.meta.itemCount}
      showSizeChanger
      showQuickJumper
      pageSizeOptions={pageSizePossible}
    />
  );
}

export default Pagination;
