import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { isEqual } from 'lodash';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { AnyObject } from '@triare/auth-redux';
import { NavigateOptions } from 'react-router-dom';
import { SelectProps as AntdSelectProps } from 'antd/es/select';
import { getValidSearchParams } from '../../../../utils';
import { useSearchParams } from '../../../../hooks/useSearchParams';
import {
  Item, selectData, useSelectData,
} from '../../../../hooks/common';
import { FetchGet } from '../../../../hooks/fetch';

function getOnlyValid(
  searchParams: URLSearchParams,
  name: string,
  list: Item[],
  keyAsArray: boolean,
  key: keyof Item = 'key',
): string[] {
  if (keyAsArray) {
    return searchParams.getAll(name).filter((value) => list.filter((item) => item[key].includes(value)).length);
  }

  return searchParams.getAll(name).filter((value) => list.filter((item) => item[key] === value).length);
}

function createItems(defaultItems: Item[], items: Item[]) {
  return defaultItems.map((option) => ({
    ...option,
    disabled: !items.some((item) => {
      if (option.key && item.key) {
        const optionKeys = Array.isArray(option.key) ? option.key : [option.key];
        const itemKeys = Array.isArray(item.key) ? item.key : [item.key];

        return optionKeys.some((
          optionKey,
        ) => itemKeys.some((
          itemKey,
        ) => itemKey.toString().toLowerCase() === optionKey.toString().toLowerCase()));
      }

      if (option.value && item.value) {
        const optionValues = Array.isArray(option.value) ? option.value : [option.value];
        const itemValues = Array.isArray(item.value) ? item.value : [item.value];

        return optionValues.some((
          optionValue,
        ) => itemValues.some((
          itemValue,
        ) => itemValue.toString().toLowerCase() === optionValue.toString().toLowerCase()));
      }

      return false;
    }),
  }));
}

export interface LogicExportProps extends AntdSelectProps {
  items: Item[];
  search?: boolean;
  searchBottom?: boolean;
  title: string;
  name: string;
  badge?: boolean;
  checkAll?: boolean;
  uncheck?: boolean;
  loading?: boolean;
  fetch?: (props: AnyObject) => Promise<AnyObject | null>;
  params?: AnyObject;
  defaultCheck?: boolean;
  isMine?: boolean;
  keyAsArray?: boolean;
  decorateData?: (data: AnyObject, id: number, item: Item) => Item,
  defaultHook?: FetchGet
  addFilter?: AnyObject
}

interface LogicProps extends LogicExportProps {
  // eslint-disable-next-line
  Component: React.FC<any>
}

function Logic(props: LogicProps) {
  const {
    items,
    name,
    fetch,
    uncheck,
    params,
    isMine,
    keyAsArray,
    decorateData,
    defaultHook,
    addFilter,
    Component,
  } = props;
  const initValidation = useRef(false);
  const [itemList, setItemsList] = useState<Item[]>([]);
  const [triggerSearchParams, setTriggerSearchParams] = useState<string[] | undefined>();
  const [searchParams, setSearchParams, paramsWithoutTableProps] = useSearchParams();
  const [uncheckAll, setUncheckAll] = useState(uncheck);

  const createProps = useCallback((values: AnyObject) => {
    if (addFilter) {
      const add = Object.keys(addFilter);

      if (add.length) {
        add.forEach((key) => {
          if (!values[key]) {
            // eslint-disable-next-line no-param-reassign
            values[key] = addFilter[key];
          } else {
            const currentValue = Array.isArray(values[key]) ? values[key] : [values[key]];
            const addValue = Array.isArray(addFilter[key]) ? addFilter[key] : [addFilter[key]];

            // eslint-disable-next-line no-param-reassign
            values[key] = [...new Set([...currentValue, ...addValue])];
          }
        });
      }
    }

    return values;
  }, [addFilter]);

  useEffect(() => {
    if (defaultHook) {
      defaultHook.fetch(createProps({
        isMine: typeof isMine === 'undefined' ? undefined : isMine,
        ...params,
      }));
    }
  }, []);

  const defaultItems = useSelectData(defaultHook, decorateData);

  const itemsAll = useMemo<Item[]>(
    () => createItems(defaultItems, items),
    [defaultItems, items],
  );

  useEffect(() => {
    setItemsList(itemsAll);
  }, [itemsAll]);

  const itemsValid = useMemo<Item[]>(() => itemsAll.filter(({ disabled }) => !disabled), [itemsAll]);

  useEffect(() => {
    if (!triggerSearchParams
      && items
      && items.length
      && defaultHook?.data
      && !defaultHook?.loading
      && !defaultHook.error
    ) {
      const paramList = paramsWithoutTableProps[name];
      const currentValidItems = createItems(selectData(defaultHook, decorateData), items)
        .filter(({ disabled }) => !disabled);

      setTriggerSearchParams((Array.isArray(paramList) ? paramList : [paramList])
        .filter((value) => currentValidItems.some((
          item,
        ) => (item.value || '').toString().toLowerCase() === (value || '').toString().toLowerCase())));
    }
  }, [items, defaultHook, paramsWithoutTableProps, triggerSearchParams]);

  useEffect(() => {
    if (triggerSearchParams && searchParams && !initValidation.current) {
      initValidation.current = true;

      const newSearchParams = getValidSearchParams('*', searchParams);

      newSearchParams[name] = triggerSearchParams;

      setSearchParams(newSearchParams);
    }
  }, [name, triggerSearchParams, searchParams, initValidation]);

  const checkedItems = useMemo(
    () => getOnlyValid(searchParams, name, defaultItems, keyAsArray || false),
    [searchParams, name, defaultItems],
  );

  const indeterminate = useMemo(
    () => !!checkedItems.length && checkedItems.length < defaultItems.length && checkedItems.length > 0,
    [checkedItems, defaultItems],
  );

  const getSortedList = useCallback((options: Item[]) => {
    const sortedList = [...options];

    return sortedList.sort((a, b) => {
      if (checkedItems.includes((a.key as string) || '') && !checkedItems.includes((b.key as string) || '')) {
        return -1;
      }
      if (!checkedItems.includes((a.key as string) || '') && checkedItems.includes((b.key as string) || '')) {
        return 1;
      }

      return 0;
    });
  }, [checkedItems]);

  useEffect(() => {
    if (fetch && defaultHook?.data) {
      fetch(createProps({
        ...paramsWithoutTableProps,
        isMine: typeof isMine === 'undefined' ? undefined : isMine,
        ...params,
      }));
    }
  }, [fetch, paramsWithoutTableProps, defaultHook?.data]);

  const setSearch = useCallback((list: string[], navigateOpts?: NavigateOptions) => {
    if (itemsValid && itemsValid.length) {
      const data = getValidSearchParams('*', searchParams);

      if (keyAsArray) {
        data[name] = list.flat();
        setSearchParams(data, navigateOpts);
      } else {
        data[name] = list;
        setSearchParams(data, navigateOpts);
      }
    }
  }, [searchParams, itemsValid]);

  const handleSelectAll = useCallback((e: CheckboxChangeEvent) => {
    if (!e.target.checked) {
      setUncheckAll(true);
      setSearch([]);
    } else {
      setUncheckAll(false);

      if (keyAsArray) {
        setSearch(itemsValid.reduce((acc: string[], current) => acc.concat(current.key as Array<string>), []));
      } else {
        setSearch(itemsValid.map((item) => (item.key as string) || ''));
      }
    }
  }, [itemsValid, keyAsArray]);

  const handleChange = (value: string) => (e: CheckboxChangeEvent) => {
    if (!e.target.checked && checkedItems.length === 0) {
      setUncheckAll(true);

      if (keyAsArray) {
        setSearch(
          itemsValid.filter(({ key }) => !isEqual(value, key as string)).map(({ key }) => (key as string) || ''),
        );

        return;
      }

      setSearch(
        itemsValid.filter(({ key }) => (key as string) !== value).map(({ key }) => (key as string) || ''),
      );
    } else if (e.target.checked) {
      if (keyAsArray) {
        setSearch([...checkedItems, ...value]);

        return;
      }
      setSearch([...checkedItems, value]);
    } else {
      if (keyAsArray) {
        setSearch(checkedItems.filter((item) => !value.includes(item)));
      } else {
        setSearch(checkedItems.filter((item) => item !== value));
      }

      if (!checkedItems.filter((item) => item !== value).length) {
        setUncheckAll(true);
      }
    }
  };

  const onSearch = useCallback(({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setItemsList(
      getSortedList(itemsAll)
        .filter((item) => {
          if (checkedItems.includes((item.key as string) || '')) return true;
          if (item.label) return item.label.toString().toLowerCase().includes(value.toLowerCase());

          return false;
        })
        .slice(0, 15 + checkedItems.length),
    );
  }, [getSortedList, itemsAll, checkedItems]);

  return (
    <Component
      {...props}
      uncheckAll={uncheckAll}
      itemsValid={itemsValid}
      checkedItems={checkedItems}
      indeterminate={indeterminate}
      handleSelectAll={handleSelectAll}
      itemsAll={itemsAll}
      itemList={itemList}
      onSearch={onSearch}
      handleChange={handleChange}
    />
  );
}

Logic.defaultProps = {
  addFilter: undefined,
  search: false,
  defaultCheck: true,
  searchBottom: false,
  badge: false,
  checkAll: false,
  uncheck: false,
  loading: false,
  isMine: undefined,
  keyAsArray: false,
  params: {},
  fetch: undefined,
  decorateData: undefined,
  defaultHook: undefined,
};

export default Logic;
