import React, { useEffect, useMemo, useRef } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import clsx from 'clsx';
import { App, Typography } from 'antd';
import { GridShift, useRosterContext } from '../../Context';
import { ResizeIcon } from '../../../../Common/Icon';
import Options from '../Options';
import { getRate } from '../../../Shift/List';
import Icons from '../../Common/Shift/Icons';
import { getHoursBetween } from '../../../../../utils';
import { useShiftUpdate } from '../../../../../hooks/shift';
import { useMessageError } from '../../../../../hooks/common';
import Loading from '../../../../Common/Loading';
import { Action } from '../../../../../enums/roster';
import ShiftStatus from '../../../../../enums/shift';
import styles from './index.module.scss';

interface EmptyShiftProps {
  size?: number;
}

export function EmptyShift({ size }: EmptyShiftProps) {
  // @ts-ignore use css variable
  return <div className={styles.shift} style={size ? { '--empty-shift-size': size } : undefined} />;
}

EmptyShift.defaultProps = {
  size: undefined,
};

export const DAY_SIZE = 86400000;

interface RefShift extends HTMLDivElement {
  timeoutId: NodeJS.Timeout;
}

const setTime = (date: Dayjs, hour: number, min: number) => {
  const roundedMinutes = Math.round(min / 5) * 5;

  return date.clone().set('hour', hour).set('minute', roundedMinutes);
};

interface TimeRange {
  start: string;
  end: string;
}

function getCurrentRange(data: TimeRange[], shift: GridShift) {
  if (!data) {
    return null;
  }
  const currentDateTime = dayjs(shift.datetimeStart);

  const foundRange = data.find((range) => {
    const startDateTime = dayjs(range.start).subtract(2, 'minute');
    const endDateTime = dayjs(range.end);

    return currentDateTime.isAfter(startDateTime) && currentDateTime.isBefore(endDateTime);
  });

  return foundRange || null;
}

interface ShiftProps {
  step?: number;
  trId: number;
  tdId: number;
  day: Dayjs;
  data: GridShift;
  unassigned: boolean;
  tableWidth: number;
}

function Shift({
  step, tdId, day, data, unassigned, tableWidth,
}: ShiftProps): React.ReactNode {
  const {
    view,
    isApplicantPage,
    isDayView,
    size,
    selectedShift,
    setSelectedShift,
    setSelectedRef,
    getShifts,
    loading,
    gridVariants,
    grid,
  } = useRosterContext();
  const updateShift = useShiftUpdate();
  const { message } = App.useApp();
  const refShift = useRef<RefShift | null>(null);
  const refResizeLeft = useRef<HTMLDivElement | null>(null);
  const refResizeRight = useRef<HTMLDivElement | null>(null);
  const refShiftContent = useRef<HTMLDivElement | null>(null);
  const isLongShift = data.start.get('date') !== day.get('date') || data.end.get('date') !== day.get('date');

  useEffect(() => {
    moveResizeIcon();
  }, [gridVariants, grid]);

  const contentClasses = {
    [styles.draft]: data.status === ShiftStatus.DRAFT,
    [styles.unassigned]: !data.applicant,
  };

  useEffect(() => {
    if (updateShift.data) {
      message.success('The shift time has been changed');
      getShifts();
    }
  }, [updateShift.data]);

  const shiftWidth = useMemo(() => {
    const startDayValueOf = day.clone().startOf('date').valueOf();
    const marginLeft = Math.max(0, ((data.start.valueOf() - startDayValueOf) / DAY_SIZE) * 100);

    if (data.start.diff(day, 'day') === 0 || data.end.diff(day, 'day') === 0) {
      return ((data.end.valueOf() - startDayValueOf) / DAY_SIZE) * 100 - marginLeft;
    }

    return ((data.end.valueOf() - data.start.valueOf()) / DAY_SIZE) * 100;
  }, [view, day, data]);
  const isActive = data.id === selectedShift?.id;

  const resizeShift = () => {
    if (refShift.current) {
      if (refShiftContent.current) {
        let td = refShiftContent.current.closest('td');

        if (td) {
          const tdSize = data.end.diff(day, 'day');
          let { width } = td.getBoundingClientRect();

          if (!isDayView) {
            refShiftContent.current.style.borderRight = '';
            refShiftContent.current.style.borderTopRightRadius = '';
            refShiftContent.current.style.borderBottomRightRadius = '';
          }

          for (let i = 0; i < tdSize; i++) {
            if (td && td.nextSibling) {
              td = td.nextSibling as HTMLTableCellElement;
              width += td.getBoundingClientRect().width;
            } else if (!isDayView && data.end.get('date') !== day.get('date')) {
              width += 8;
              refShiftContent.current.style.borderRight = 'none';
              refShiftContent.current.style.borderTopRightRadius = '0';
              refShiftContent.current.style.borderBottomRightRadius = '0';
              break;
            }
          }

          if (data.beforeStartDay) {
            refShift.current.style.marginLeft = '-16px';
            refShiftContent.current.style.paddingLeft = '24px';
            refShift.current.style.width = `calc(${shiftWidth}% + 16px)`;

            if (!isDayView && refShiftContent.current.style.borderRight !== 'none') {
              width += 8;
            }
          } else {
            refShiftContent.current.style.paddingLeft = '';
            refShift.current.style.marginLeft = '';
            refShift.current.style.marginRight = '';
          }

          if (isDayView) {
            refShiftContent.current.style.width = `${width * (shiftWidth / 100)}px`;
          } else {
            refShiftContent.current.style.width = `${
              width
              // eslint-disable-next-line no-nested-ternary
              - (isDayView ? 0 : data.beforeStartDay ? (tdSize >= size ? 0 : 8) : 16)
            }px`;
          }
        }
      }

      if (isDayView && !data.beforeStartDay) {
        const diff = data.start.valueOf() - data.start.clone().startOf('date').valueOf();

        if (diff > 0) {
          const left = (diff / DAY_SIZE) * 100;

          refShift.current.style.marginLeft = `${left}%`;
        }
      }
    }
  };

  useEffect(() => {
    if (refShift.current) {
      window.addEventListener('resize', resizeShift);
      resizeShift();
      setTimeout(resizeShift, 100);

      return () => {
        window.removeEventListener('resize', resizeShift);
      };
    }

    return () => {
      // default
    };
  }, [view, size, data]);

  const content = useMemo(
    () => (
      <>
        {updateShift.loading && (
          <div className={styles.loading}>
            <Loading />
          </div>
        )}
        <Icons shift={data} />
        <Typography.Paragraph className={styles.name} ellipsis>
          {isApplicantPage && `${data.client.name} - ${data.location.name}`}
          {!isApplicantPage && (data.applicant ? data.applicant.user.fullName : 'Unassigned')}
        </Typography.Paragraph>

        {isApplicantPage ? <div className={styles.type}>{data.locationRole?.name}</div> : null}

        <Typography.Paragraph className={styles.time} ellipsis>
          <span>{getHoursBetween(data?.datetimeStart, data?.datetimeEnd)}</span>
          {' | '}
          {getRate(data)}
        </Typography.Paragraph>
      </>
    ),
    [data, updateShift.loading],
  );

  const moveShift = (e: React.MouseEvent, isLeft: boolean) => {
    if (updateShift.loading || loading || !gridVariants?.length) {
      return;
    }
    const startDayValueOf = day.clone().startOf('date').valueOf();
    const sideWidth = 450;
    const shift = data;

    const dates = isApplicantPage
      ? gridVariants.find((varian) => varian.id === selectedShift?.applicant?.id)?.freeDateRanges
      : gridVariants[0].freeDateRanges;

    if (!dates || !selectedShift) {
      return;
    }
    const maxRange = getCurrentRange(dates, selectedShift);

    const handleDrag = (event: MouseEvent) => {
      const hoursFromSide = (event.clientX - sideWidth) / (tableWidth / 24);
      const hour = Math.floor(hoursFromSide);
      const min = Math.round((hoursFromSide - hour) * 60);

      const thisTime = setTime(data.start, hour, min);

      if (isLeft) {
        const duration = (shift.end.valueOf() - thisTime.valueOf()) / (1000 * 60 * 60);

        if (thisTime.valueOf() <= dayjs(maxRange?.start).valueOf()) {
          return;
        }

        if (duration <= 0.25) {
          return;
        }

        shift.start = thisTime;
        shift.datetimeStart = thisTime.toISOString();
      }

      if (!isLeft) {
        const duration = (thisTime.valueOf() - shift.start.valueOf()) / (1000 * 60 * 60);

        if (thisTime.valueOf() >= dayjs(maxRange?.end).valueOf()) {
          return;
        }

        if (duration <= 0.25) {
          return;
        }

        shift.end = thisTime;
        shift.datetimeEnd = thisTime.toISOString();
      }

      const marginLeft = Math.max(0, ((shift.start.valueOf() - startDayValueOf) / DAY_SIZE) * 100);

      if (isLeft && refShift.current?.style) {
        refShift.current.style.marginLeft = `${marginLeft}%`;
      }
      if (refShift.current?.style) {
        refShift!.current!.style.width = `${((shift.end.valueOf() - startDayValueOf) / DAY_SIZE) * 100 - marginLeft}%`;
      }
      moveResizeIcon();
    };

    const handleDragEnd = () => {
      updateShift.fetch({ datetimeStart: shift.datetimeStart, datetimeEnd: shift.datetimeEnd }, data.id);
      document.removeEventListener('mousemove', handleDrag);
      document.removeEventListener('mouseup', handleDragEnd);
    };

    document.addEventListener('mousemove', handleDrag);
    document.addEventListener('mouseup', handleDragEnd);
  };

  const moveResizeIcon = () => {
    const right = refResizeRight.current;
    const left = refResizeLeft.current;

    if (!right || !left || !refShift.current) {
      return;
    }

    const { height, top } = refShift.current.getBoundingClientRect();
    const resizeTop = top - (refShift.current.closest('tr')?.getBoundingClientRect()?.top || 0);
    const marginLeft = refShift.current.style.marginLeft || '0';

    left.style.left = `calc(${marginLeft} - 12px)`;
    left.style.top = `${resizeTop}px`;
    left.style.height = `${height.toString()}px`;
    right.style.height = `${height.toString()}px`;
    right.style.left = `calc(${parseFloat(marginLeft) + parseFloat(refShift.current.style.width)}% - 13px)`;

    right.style.top = `${resizeTop}px`;
  };

  useMessageError([updateShift]);

  return (
    <>
      {isDayView && !data.beforeStartDay && !data.beforeEndDay ? (
        <>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div
            ref={refResizeLeft}
            className={clsx(styles.resizeIcon, styles.left, {
              [styles.visible]: isActive,
              [styles.disable]: updateShift.loading || loading,
            })}
            onMouseDown={(e: React.MouseEvent) => {
              moveShift(e, true);
            }}
          >
            <ResizeIcon />
          </div>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div
            ref={refResizeRight}
            className={clsx(styles.resizeIcon, styles.right, {
              [styles.visible]: isActive,
              [styles.disable]: updateShift.loading || loading,
            })}
            onMouseDown={(e: React.MouseEvent) => {
              moveShift(e, false);
            }}
          >
            <ResizeIcon />
          </div>
        </>
      ) : null}
      <div
        className={clsx(styles.shift, 'shift_item', {
          [styles.day]: isDayView,
          [styles.absolute]: isDayView && !data.beforeStartDay && !data.beforeEndDay,
          [styles.draft]: data.status === ShiftStatus.DRAFT,
          [styles.unassigned]: !data.applicant,
        })}
        ref={refShift}
        style={{
          width: isDayView && shiftWidth ? `${shiftWidth}%` : undefined,
          bottom: `${isDayView && !data.beforeStartDay && !data.beforeEndDay && step ? 62 * step - 4 - 28 : 0}px`,
        }}
        onClick={(e) => {
          e.preventDefault();

          if (isActive) {
            setSelectedShift(null);
          } else {
            setSelectedShift({ ...data, action: Action.MOVE });
          }

          if (isDayView && !data.beforeStartDay && !data.beforeEndDay) {
            moveResizeIcon();
          }
          setSelectedRef(undefined);
        }}
        role="none"
      >
        {isLongShift ? (
          <>
            <div className={clsx(styles.content, styles.fake, 'shift_fake')}>{content}</div>
            <div
              className={clsx(styles.content, styles.long, contentClasses, 'shift_long', { [styles.active]: isActive })}
              ref={refShiftContent}
              style={{ maxWidth: `${tableWidth - 2}px` }}
            >
              {content}
              {!isDayView ? <Options data={data} /> : null}
            </div>
          </>
        ) : (
          <div className={clsx(styles.content, contentClasses, { [styles.active]: isActive })}>
            {content}
            {!isDayView ? <Options data={data} /> : null}
          </div>
        )}
        {isDayView && (data.beforeStartDay || data.beforeEndDay || !isActive) ? <Options data={data} /> : null}
      </div>
    </>
  );
}

Shift.defaultProps = {
  step: undefined,
};

export default Shift;
