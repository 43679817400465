import {
  CSSProperties, useEffect, useRef, useState,
} from 'react';
import clsx from 'clsx';
import {
  CloseOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  HighlightOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Badge, Button, Typography, Space,
} from 'antd';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useContextCalendar } from '../../Context';
import {
  Shift,
  ShiftMonthGetParams,
  ShiftMonthResponse,
  useShiftDayGet,
  useShiftDelete,
} from '../../../../../hooks/shift';
import { useAuthState } from '../../../../../store/auth';
import { isRoleEnough, UserRoles } from '../../../../../enums/user';
import { useSearchParams } from '../../../../../hooks/useSearchParams';
import Loading from '../../../Loading';
import { DefaultFetchError, FetchGet } from '../../../../../hooks/fetch';
import { dateFormat, timeFormat } from '../../../../../contstant';
import { getBudgeColor } from '../../../Badge';
import { useMessageError } from '../../../../../hooks/common';
import ShiftStatus, {
  editStatuses,
  deleteStatuses,
  adminNotEditStatuses,
  getShiftStatus,
} from '../../../../../enums/shift';
import { MultiDayIcon } from '../../../Icon';
import { getDaysBetween, getHoursBetween } from '../../../../../utils';

import styles from './index.module.scss';

const dayBoxWidth = 246;
const shiftBoxWidth = 367;

interface IconEditProps {
  id: string;
}

function IconEdit({ id }: IconEditProps) {
  const navigate = useNavigate();

  if (!id) {
    return null;
  }

  return <EditOutlined onClick={() => navigate(`/shifts/${id}/edit`)} />;
}

function IconCopy({ id }: IconEditProps) {
  const navigate = useNavigate();

  if (!id) {
    return null;
  }

  return <CopyOutlined onClick={() => navigate(`/shifts/${id}/copy`)} />;
}

interface IconDeleteProps extends IconEditProps {
  shiftDayFetch: (reloadMonth?: boolean) => void;
}

function IconDelete({ id, shiftDayFetch }: IconDeleteProps) {
  const shiftDelete = useShiftDelete();

  useMessageError([shiftDelete]);

  useEffect(() => {
    if (shiftDelete.response && !shiftDelete.error) {
      shiftDayFetch(true);
    }
  }, [shiftDelete.response]);

  if (!id) {
    return null;
  }

  if (shiftDelete.loading) {
    return <LoadingOutlined spin />;
  }

  return <DeleteOutlined onClick={() => shiftDelete.fetch(id)} />;
}

interface DetailProps {
  shift: Shift;
}

function Detail({ shift }: DetailProps) {
  return (
    <div className={styles.content}>
      <div className={styles.row}>
        <span>Domain</span>
        <span>{shift.domain?.title}</span>
      </div>
      <div className={styles.row}>
        <span>Location</span>
        <span>{shift.location?.name}</span>
      </div>
      <div className={styles.row}>
        <span>Shift Time</span>
        <span>
          <NavLink to={`/shifts/${shift.id}`}>
            <Space direction="vertical" className={styles.time}>
              {shift.multiShift
                ? getDaysBetween(shift?.multiShift.datetimeStart, shift?.multiShift.datetimeEnd)
                : getDaysBetween(shift?.datetimeStart, shift?.datetimeEnd)}
              {getHoursBetween(shift?.datetimeStart, shift?.datetimeEnd)}
            </Space>
          </NavLink>
        </span>
      </div>
      <div className={styles.row}>
        <span>Status</span>
        <span>
          <Badge color={getBudgeColor(shift.status)} text={getShiftStatus(shift?.status)} />
        </span>
      </div>
      <div className={styles.row}>
        <span>Role</span>
        <span>{shift.locationRole?.name}</span>
      </div>
      <div className={styles.row}>
        <span>Rate</span>
        <span>{shift.rate ? `$${shift.rate}` : '-'}</span>
      </div>
      <div className={styles.row}>
        <span>Applicant</span>
        <span>
          <NavLink to={`/applicants/${shift.applicant?.id}`}>
            {`${shift.applicant?.title || ''} `
              + `${shift.applicant?.user?.firstName || ''} `
              + `${shift.applicant?.user?.lastName || ''}`}
          </NavLink>
        </span>
      </div>
    </div>
  );
}

interface ModalProps {
  action?: boolean;
  contentId: string;
  paddingLeft?: number;
  paddingTop?: number;
  shiftMonthGet: FetchGet<ShiftMonthResponse, ShiftMonthGetParams, DefaultFetchError, ShiftMonthResponse>;
}

function Modal({ action, shiftMonthGet, contentId }: ModalProps) {
  const navigate = useNavigate();
  const { user } = useAuthState();
  const {
    selected, setSelected, handleMouseEnter, handleMouseLeave, hovered,
  } = useContextCalendar();
  const [selectedShift, setSelectedShift] = useState<string | undefined>();
  const refDayModal = useRef<HTMLDivElement | null>(null);
  const refShiftModal = useRef<HTMLDivElement | null>(null);
  const [, , paramsWithoutTableProps] = useSearchParams();
  const shiftDayGet = useShiftDayGet();
  const showAddButton = isRoleEnough(user?.role, UserRoles.MANAGER);
  const { pathname } = useLocation();
  const isOpenShiftPage = pathname.includes('/open-shifts');
  const isAdmin = isRoleEnough(user?.role, UserRoles.LOKEM_ADMIN);
  // eslint-disable-next-line max-len
  const selectedShiftData = shiftDayGet.data && selectedShift && shiftDayGet.data.find(({ id }) => id === selectedShift);
  const shiftDayFetch = (reloadMonth = false) => {
    const props = {
      ...paramsWithoutTableProps,
      isMine: !isOpenShiftPage && user?.role === UserRoles.APPLICANT,
      limit: 5,
      year: selected?.date?.get('year') || 1,
      month: (selected?.date?.get('month') || 0) + 1,
    };

    if (reloadMonth) {
      shiftMonthGet.fetch(props);
    }

    shiftDayGet.fetch({
      ...props,
      day: selected?.date.get('date') || 1,
    });
  };

  useEffect(() => {
    shiftDayGet.clearResponse();
    if (selected === undefined) {
      onCloseDayModal();
    }

    if (selected?.date) {
      setSelectedShift(undefined);

      if (shiftMonthGet.data && shiftMonthGet.data[selected?.date.get('date')]?.length) {
        shiftDayFetch();
      }
    }
  }, [selected?.date]);

  useEffect(() => {
    if (selected?.date && shiftMonthGet.data && !shiftMonthGet.data[selected?.date.get('date')]?.length) {
      onCloseShiftModal();
    }
  }, [selected?.date, shiftMonthGet.data]);

  useEffect(() => {
    if (selected?.ref?.current && refDayModal.current) {
      const dayContent = selected?.ref.current.closest('td');

      if (dayContent) {
        const layoutContent = refDayModal.current.closest(contentId);
        const dayModal = refDayModal.current;

        if (dayModal) {
          Object.assign(dayModal.style, {
            width: `${dayBoxWidth}px`,
          });
        }

        const shiftModal = refShiftModal.current;

        if (selectedShift && shiftModal) {
          Object.assign(shiftModal.style, {
            width: `${shiftBoxWidth}px`,
          });
        }

        const onResize = () => {
          if (dayModal && layoutContent) {
            const sizeDayModal = dayModal.getBoundingClientRect();
            const sizeDayContent = dayContent.getBoundingClientRect();
            const sizeLayoutContent = layoutContent.getBoundingClientRect();

            const dayOffsetLeft = dayContent.offsetLeft;
            const dayRight = dayOffsetLeft + sizeDayContent.width;
            const dayOffsetTop = dayContent.offsetTop;
            const dayBottom = dayContent.offsetTop + sizeDayContent.height;

            let left = dayOffsetLeft;
            let top = dayOffsetTop;

            if (dayOffsetLeft > dayBoxWidth) {
              left -= dayBoxWidth;
            } else {
              left = dayRight;
            }

            if (sizeLayoutContent.height <= dayBottom + sizeDayModal.height) {
              top = dayBottom - sizeDayModal.height;
            }

            Object.assign(dayModal.style, {
              transform: `translate(${left}px, ${top}px)`,
            });

            if (selectedShift && shiftModal) {
              let shiftLeft = left - shiftBoxWidth;
              const shiftTop = top;

              if (left < shiftBoxWidth) {
                if (dayOffsetLeft <= dayBoxWidth) {
                  shiftLeft = left + dayBoxWidth;
                } else {
                  shiftLeft = dayRight;
                }
              }

              Object.assign(shiftModal.style, {
                transform: `translate(${shiftLeft}px, ${shiftTop}px)`,
              });
            }
          }
        };

        onResize();

        window.addEventListener('resize', onResize, false);

        return () => window.removeEventListener('resize', onResize);
      }
    }

    return () => {
      // default
    };
  }, [selectedShift, selected?.ref, refDayModal.current]);

  const onCloseShiftModal = () => {
    if (refShiftModal.current) {
      Object.assign(refShiftModal.current?.style, {
        transform: 'translate(-999999px, -999999px)',
      });
    }
    setSelectedShift(undefined);
  };

  const onCloseDayModal = () => {
    if (refDayModal.current) {
      Object.assign(refDayModal.current?.style, {
        transform: 'translate(-999999px, -999999px)',
      });
    }
    setSelected(undefined);
    onCloseShiftModal();
  };

  useMessageError([shiftDayGet]);

  return (
    <>
      <div className={clsx(styles.day, { [styles.disable]: selected === undefined })} ref={refDayModal}>
        <div className={styles.header}>
          <h4>{selected ? `${selected.date.format('dddd')} ${selected.date.get('D')}` : null}</h4>
          <CloseOutlined onClick={onCloseDayModal} />
        </div>
        <div className={styles.content}>
          <ul className={clsx({ [styles.max]: !showAddButton })}>
            {shiftDayGet.loading ? (
              <div className={styles.loading}>
                <Loading />
              </div>
            ) : (
              shiftDayGet.data?.map(({
                id, datetimeEnd, datetimeStart, location, status, multiShift, completedEdit,
              }) => (
                <li
                  key={id}
                  onClick={() => setSelectedShift(id)}
                  className={clsx(styles.item, {
                    [styles.active]: selectedShift === id,
                    [styles.active]: hovered && multiShift?.id === hovered,
                  })}
                  style={{ '--point-color': getBudgeColor(status) } as CSSProperties}
                  onMouseEnter={() => handleMouseEnter(multiShift?.id)}
                  onMouseLeave={handleMouseLeave}
                >
                  <Typography.Text ellipsis>
                    {dayjs(datetimeStart).format(timeFormat)}
                    -
                    {dayjs(datetimeEnd).format(timeFormat)}
                    {' '}
                    {location?.name}
                  </Typography.Text>
                  {multiShift && <MultiDayIcon />}
                  {completedEdit && <HighlightOutlined style={{ color: '#BFBFBF' }} />}
                </li>
              ))
            )}
          </ul>
          {showAddButton && dayjs(selected?.date).startOf('d').diff(dayjs().startOf('d')) > 0 ? (
            <Button
              className={styles.btn}
              type="text"
              icon={<PlusOutlined />}
              onClick={() => navigate(`/shifts/create?date=${selected?.date.format(dateFormat)}`)}
            >
              Add shift
            </Button>
          ) : null}
        </div>
      </div>

      {selectedShiftData ? (
        <div className={clsx(styles.shift, { [styles.disable]: selectedShift === undefined })} ref={refShiftModal}>
          <div className={styles.header}>
            <h4>{selected ? `${selected.date.format('dddd')} ${selected.date.get('D')}` : null}</h4>
            <div>
              {action && selectedShift ? (
                <>
                  {/* eslint-disable-next-line max-len */}
                  {((isAdmin && !adminNotEditStatuses.includes(selectedShiftData?.status as ShiftStatus))
                    // eslint-disable-next-line max-len
                    || editStatuses.includes(selectedShiftData?.status as ShiftStatus)) && <IconEdit id={selectedShift} />}
                  <IconCopy id={selectedShift} />
                  {(isAdmin || deleteStatuses.includes(selectedShiftData?.status as ShiftStatus)) && (
                    <IconDelete id={selectedShift} shiftDayFetch={shiftDayFetch} />
                  )}
                </>
              ) : null}
              <CloseOutlined onClick={onCloseShiftModal} />
            </div>
          </div>
          <Detail shift={selectedShiftData} />
        </div>
      ) : null}
    </>
  );
}

Modal.defaultProps = {
  action: true,
  paddingLeft: 0,
  paddingTop: 0,
};

export default Modal;
