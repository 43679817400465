import { MenuProps } from 'antd';
import React from 'react';

import styles from './index.module.scss';

export type MenuItem = Required<MenuProps>['items'][number];

export function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

interface FilterMenuProps {
  children: React.ReactNode
}

export default function FilterMenu({ children }: FilterMenuProps): React.ReactNode {
  return (
    <div className={styles.filterMenu}>
      {children}
    </div>
  );
}
