import { NavLink, useParams, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import {
  Alert, Button, Form as AntdForm, Input, Typography, Row, Col, App, Form,
} from 'antd';
import { validationNewPassword, createRulesForAntd } from '../../../utils/validations';
import { Logo } from '../../Common/Icon';
import { usePasswordReset } from '../../../hooks/password';
import { getMessageInError } from '../../../hooks/fetch';

import styles from './index.module.scss';

const validationRules = createRulesForAntd(validationNewPassword);

export default function ResetPassword() {
  const passwordReset = usePasswordReset();
  const { secretKey } = useParams();
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const onFinish = ({ password }: { password: string }) => {
    if (secretKey) {
      passwordReset.fetch({ password, secretKey });
    }
  };

  useEffect(() => {
    if (passwordReset.data) {
      message.info('Your password has been successfully changed');
      navigate('/sign-in');
    }
  }, [passwordReset.data]);

  return (
    <Row justify="center">
      <Col>
        <Logo className={styles.logo} />
        <AntdForm className={styles.form} form={form} layout="vertical" onFinish={onFinish}>
          <Typography.Title level={5} className={styles.title}>
            Set and confirm new password
          </Typography.Title>

          {passwordReset.error && (
            <AntdForm.Item>
              <Alert
                type="error"
                onClose={() => passwordReset.clearError()}
                message={getMessageInError(passwordReset.error)}
                closable
                showIcon
              />
            </AntdForm.Item>
          )}

          <AntdForm.Item name="password" label="New password *" className={styles.input} rules={[validationRules]}>
            <Input.Password
              placeholder="New password"
              size="large"
              onChange={() => {
                if (form.getFieldValue('confirmPassword')) {
                  form.validateFields(['confirmPassword']);
                }
              }}
            />
          </AntdForm.Item>

          <AntdForm.Item
            name="confirmPassword"
            label="Confirm new password *"
            className={styles.input}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(new Error('Passwords that you entered do not match!'));
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirm new password" size="large" />
          </AntdForm.Item>

          <AntdForm.Item shouldUpdate>
            {({ getFieldsValue, getFieldsError }) => {
              const { confirmPassword: confirmPasswordValue, password: passwordValue } = getFieldsValue();
              const fieldsError = getFieldsError();

              return (
                <Button
                  disabled={!confirmPasswordValue || !passwordValue || fieldsError.some((item) => item.errors.length)}
                  size="large"
                  type="primary"
                  htmlType="submit"
                  block
                  className={styles.btn}
                  loading={passwordReset.loading}
                >
                  Log in
                </Button>
              );
            }}
          </AntdForm.Item>
        </AntdForm>

        <Typography.Text strong style={{ textAlign: 'center', display: 'block', width: '100%' }}>
          Don’t have an account? &thinsp;
          <NavLink to="/sign-up">Sign Up</NavLink>
        </Typography.Text>
      </Col>
    </Row>
  );
}
