import { useEffect, useState } from 'react';
import {
  Col, Form, Row, Input, Button, App, Checkbox,
} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { FieldData } from 'rc-field-form/lib/interface';
import { useLocationsRolesId, useLocationsRolesUpdate } from '../../../../../hooks/locationsRoles';
import { getMessageInError } from '../../../../../hooks/fetch';
import Specialities from '../Specialities';
import Levels from '../Levels';
import Documents from '../../Documents';
import Experiences from '../../../../Common/Experiences';
import Inductions from '../Inductions';
import Loading from '../../../../Common/Loading';
import { useLocationContext } from '../../Context';
import RateType from '../RateType';
import Rate from '../Rate';
import { useDocumentRequired } from '../../../../../hooks/document';

import styles from './index.module.scss';
import Breaks, { intervalBreaks } from '../Breaks';
import { useMessageError } from '../../../../../hooks/common';

type RoleProps = {
  roleId: string;
};

export default function Role({ roleId }: RoleProps) {
  const getRole = useLocationsRolesId();
  const updateRole = useLocationsRolesUpdate();
  const requiredDocuments = useDocumentRequired();
  const [form] = Form.useForm();
  const {
    setEdit, isEdit, setOnSave, setChanging, fetch, setLoading,
  } = useLocationContext();
  const [editable, setEditable] = useState<boolean>(false);
  const [inductionRequired, setInductionRequired] = useState(false);
  const { message } = App.useApp();

  const handleEdit = () => {
    setEditable(true);
  };

  const onSave = () => {
    setLoading(true);
    form
      .validateFields()
      .then(() => {
        const { customLunchBreak, ...values } = form.getFieldsValue();

        updateRole.fetch(
          {
            ...values,
            documents: [...(values.documents || []), ...(requiredDocuments?.data?.map(({ id }) => id) || [])],
            inductions: values?.inductions?.length ? values.inductions : [],
            // eslint-disable-next-line no-nested-ternary
            lunchBreak: Number.isInteger(values.lunchBreak)
              ? values.lunchBreak : values.lunchBreak === 'custom' ? customLunchBreak : null,
          },
          roleId,
        );
      })
      .catch(() => {
        setLoading(false);
        setChanging(() => false);
      });
  };

  useEffect(() => {
    requiredDocuments.fetch();
  }, []);

  useEffect(() => {
    if (updateRole.data) {
      setChanging(() => false);
      setEditable(false);
      fetch();
      setLoading(false);
      message.success('Changes saved successfully!');
    }
  }, [updateRole.data]);

  useEffect(() => {
    getRole.fetch(undefined, roleId);
    setEdit(() => true);
    setChanging(() => false);
    setOnSave(() => onSave);
  }, [roleId]);

  useEffect(() => {
    if (getRole.data) {
      const requiredDocumentsIds = requiredDocuments?.data?.map(({ id }) => id);
      const role = getRole.data;

      form.setFieldsValue({
        ...role,
        experiences: role?.experiences?.map(({ id }) => id),
        documents: role?.documents?.map(({ id }) => id).filter((id) => !requiredDocumentsIds?.includes(id)),
        inductions: role?.inductions?.map(({ id }) => id),
        // eslint-disable-next-line no-nested-ternary
        lunchBreak: Number.isInteger(role.lunchBreak)
          ? intervalBreaks.includes(role.lunchBreak) ? role.lunchBreak : 'custom' : undefined,
        customLunchBreak: role.lunchBreak || null,
      });
      setEditable(false);

      setInductionRequired(!!role?.inductions?.length);
    }
  }, [getRole.data, isEdit, requiredDocuments.data]);

  useEffect(() => {
    setLoading(false);
  }, [getRole.error, updateRole.error]);

  useMessageError([updateRole, getRole]);

  const onFieldsChange = (_: FieldData[], allFields: FieldData[]) => {
    if (allFields.some(({ errors }) => errors?.length)) {
      setChanging(() => false);
    } else {
      setChanging(() => true);
    }
  };

  return getRole.loading || !getRole.data ? (
    <div className={styles.loading}>
      <Loading />
    </div>
  ) : (
    <div className={styles.wrapper}>
      {!editable && (
        <Button className={styles.editable} type="text" size="small" onClick={handleEdit}>
          <EditOutlined />
        </Button>
      )}
      <Form
        form={form}
        className={styles.form}
        requiredMark={false}
        layout="vertical"
        disabled={!editable}
        onFieldsChange={onFieldsChange}
      >
        <Row gutter={[16, 6]}>
          <Col span={12}>
            <Levels />
          </Col>
          <Col span={12}>
            <Specialities />
          </Col>
          <Col span={12}>
            <RateType />
          </Col>
          <Col span={12}>
            <Rate form={form} />
          </Col>
        </Row>

        <Experiences disabled={!editable} required />
        <Documents disabled={!editable} />

        <Form.Item>
          <Checkbox
            checked={inductionRequired}
            onChange={(e) => {
              setChanging(() => true);
              setInductionRequired(e.target.checked);
              form.validateFields();
            }}
          >
            Induction required?
          </Checkbox>
        </Form.Item>

        {inductionRequired && <Inductions inductionRequired={inductionRequired} />}

        <Row gutter={[16, 6]}>
          <Breaks form={form} />
        </Row>

        <Form.Item name="notes" label="Additional notes for applicants">
          <Input.TextArea rows={3} />
        </Form.Item>
      </Form>
    </div>
  );
}
