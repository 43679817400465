import ShiftStatus, { getShiftStatus } from '../../../enums/shift';

// eslint-disable-next-line
export const getBudgeColor = (status?: any): string => {
  switch (typeof status === 'string' ? status?.toLowerCase() : status) {
    case getShiftStatus(ShiftStatus.APPROVAL).toLowerCase():
    case ShiftStatus.APPROVAL:
      return '#FFAC1C';

    case ShiftStatus.DRAFT:
      return '#D9D9D9';

    case ShiftStatus.OPEN:
      return '#FADB14';

    case getShiftStatus(ShiftStatus.PENDING).toLowerCase():
    case ShiftStatus.PENDING:
      return '#D1A44B';

    case ShiftStatus.REJECTED:
      return '#F5222D';

    case getShiftStatus(ShiftStatus.REQUEST).toLowerCase():
    case ShiftStatus.REQUEST:
      return '#9FDDB8';

    case ShiftStatus.EXPIRED:
      return '#777777';

    case getShiftStatus(ShiftStatus.DELETE).toLowerCase():
    case ShiftStatus.DELETE:
      return '#1F1F1F';

    case ShiftStatus.COMPLETED:
      return '#2F9CEB';

    case getShiftStatus(ShiftStatus.INDUCTION).toLowerCase():
    case ShiftStatus.INDUCTION:
      return '#262626';

    case ShiftStatus.CANCELLED:
      return '#D46641';

    case 'excluded':
    case 'rejected':
    case 'high':
      return '#FF4D4F';

    case 'medium':
      return '#FFA545';
    case 'low':
      return '#61A23A';

    case true:
    case 'success':
    case 'active':
    case ShiftStatus.FILLED:
      return '#52C41A';

    case 'preferred':
    case 'pending':
      return '#FFE34F';

    case 'deactivated':
    case 'inactive':
    default:
      return '#BFBFBF';
  }
};

export default {
  getBudgeColor,
};
