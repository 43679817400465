import {
  App, Badge, Button, Form, Input, Radio, Space, Tooltip,
} from 'antd';
import { useEffect, useState } from 'react';
import { NavLink, useParams, useSearchParams } from 'react-router-dom';
import { ProColumns } from '@ant-design/pro-components';
import { DeleteOutlined } from '@ant-design/icons';
import { FieldData } from 'rc-field-form/lib/interface';
import SettingsProvider, { useSettingsContext } from './Context';
import Loading from '../../Common/Loading';
import TopPanel from '../../Common/TopPanel';
import { Team as TeamType, useTeamUpdate } from '../../../hooks/team';
import { ApplicantsGetParams, applicantsGetParamList, useApplicantsGet } from '../../../hooks/applicant';
import Table from '../../Common/Table';
import { Applicant } from '../../../types/applicant';
import {
  completedShiftsColumn,
  employmentTypeColumn,
  experienceColumn,
  hourlyRateColumn,
  lastShiftColumn,
} from '../Applicant/List';
import { getBudgeColor } from '../../Common/Badge';
import { ActionProps } from './List';
import { useSimpleModal } from '../../Common/Modal/Simple';
import { useMessageError } from '../../../hooks/common';
import Modal from './Modal';
import styles from './index.module.scss';

const columns: ProColumns<Applicant>[] = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (_, record) => (
      <NavLink target="_blank" to={`/applicants/${record.id}`}>
        {record.user.firstName}
        {' '}
        {record.user.lastName}
      </NavLink>
    ),
  },
  experienceColumn,
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (_, record) => (
      <Badge
        className={styles.status}
        status="success"
        color={getBudgeColor(record.user.status)}
        text={record.user.status}
      />
    ),
  },
  employmentTypeColumn,
  hourlyRateColumn,
  lastShiftColumn,
  completedShiftsColumn,
];

function Content() {
  const applicantsGet = useApplicantsGet();
  const teamUpdate = useTeamUpdate();
  const { open, contextHolder } = useSimpleModal();
  const {
    loading, team, setModalOpen, members, fetch,
  } = useSettingsContext();
  const [searchParams] = useSearchParams();
  const { teamId } = useParams();
  const [form] = Form.useForm();
  const [changing, setChanging] = useState(false);
  const { message } = App.useApp();
  const fromValues = Form.useWatch([], form);

  const onFieldsChange = (_: FieldData[], allFields: FieldData[]) => {
    if (team) {
      setChanging(() => allFields.some((field) => field.value !== team![field.name[0] as keyof TeamType]));

      if (!allFields.every((field) => field?.errors?.length === 0)) {
        setChanging(false);
      }
    }
  };

  const getApplicants = () => {
    const props: ApplicantsGetParams = {};

    applicantsGetParamList.forEach((key) => {
      const value = searchParams.getAll(key);

      if (value.length || !(value.length === 1 && value[0] === '')) {
        // @ts-ignore @typescript-eslint/ban-ts-comment
        props[key] = value.length === 1 ? value[0] : value;
      }
    });

    props.teams = [teamId];

    applicantsGet.fetch({ ...props, isGeneral: true });
  };

  useEffect(() => getApplicants(), [searchParams, team]);

  useEffect(() => {
    if (team) {
      form.setFieldsValue({ ...team });
    }
  }, [team]);

  const actions = ({ entity }: ActionProps<Applicant>) => (
    <Tooltip title="Delete">
      <DeleteOutlined
        onClick={(e) => {
          e.preventDefault();
          open({
            icon: <DeleteOutlined style={{ color: '#FF4D4F' }} />,
            title: 'Remove team member',
            content: `
            Are you sure you want to remove ${entity.title} ${entity.user.firstName} ${entity.user.lastName}
            from the ${team?.name}? Existing shifts assigned to this applicant
            will not be removed or reassigned.`,
            cancelText: 'Cancel',
            okText: 'Delete',
            onOk: () => {
              teamUpdate
                .fetch(
                  { members: team?.members?.filter((member) => member.id !== entity.id).map((member) => member.id) },
                  teamId,
                )
                .then(() => {
                  message.success(`
                  ${entity.title} ${entity.user.firstName} ${entity.user.lastName}
                  has been successfully removed from the ${team?.name}
                  `);
                  fetch();
                });
            },
          });
        }}
      />
    </Tooltip>
  );

  const addMembers = () => {
    if (team?.members && members) {
      teamUpdate.fetch({ members: [...team.members.map((member) => member.id), ...members] }, teamId).then(() => {
        message.success(`Applicants have been successfully added to the night ${team?.name}`);
        fetch();
      });
    }
  };

  useMessageError([applicantsGet, teamUpdate]);

  return loading ? (
    <div className={styles.loading}>
      <Loading />
    </div>
  ) : (
    <>
      <div className={styles.container}>
        <TopPanel
          padding
          title={`${team?.name}`}
          arrowBack="/teams"
          rightContent={(
            <Space>
              <Button
                disabled={!changing}
                loading={teamUpdate.loading}
                onClick={() => {
                  form
                    .validateFields()
                    .then(() => {
                      teamUpdate.fetch(form.getFieldsValue(), teamId).then(() => {
                        message.success('Team info has been successfully updated');
                        setChanging(() => false);
                        setModalOpen(false);
                        fetch();
                      });
                    })
                    .catch(() => {
                      setChanging(false);
                    });
                }}
              >
                Save changes
              </Button>
              <Button
                disabled={!fromValues?.isActive}
                loading={teamUpdate.loading}
                onClick={() => setModalOpen(true)}
                type="primary"
              >
                Add team member
              </Button>
            </Space>
          )}
        />
      </div>
      <div className={styles.wrapper}>
        <Form<TeamType>
          form={form}
          requiredMark={false}
          layout="vertical"
          onFieldsChange={onFieldsChange}
          className={styles.form}
        >
          <Form.Item
            name="name"
            label="Team name"
            rules={[{ required: true, message: 'Required field!' }]}
            className={styles.input}
          >
            <Input placeholder="Input" />
          </Form.Item>
          <Form.Item name="isActive" label="Team status">
            <Radio.Group>
              <Radio value>Active</Radio>
              <Radio value={false}>Inactive</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </div>

      <div className={styles.table}>
        <Table
          rowKey="id"
          search={false}
          headerTitle="Team members"
          columnsState={{ persistenceKey: 'pro-table-applicants-teams', persistenceType: 'localStorage' }}
          options={{
            reload: false,
            density: false,
          }}
          actions={actions}
          columns={columns}
          loading={applicantsGet.loading}
          pagingData={applicantsGet.data}
        />
      </div>
      <Modal addMembers={addMembers} />
      {contextHolder}
    </>
  );
}

export default function Team() {
  return (
    <SettingsProvider>
      <Content />
    </SettingsProvider>
  );
}
