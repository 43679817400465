import { InfoCircleTwoTone, SearchOutlined } from '@ant-design/icons';
import {
  Input, Space, Form, Select, Radio, InputNumber, Button, Typography, Row, Col, Tooltip, App,
} from 'antd';
import { debounce } from 'lodash';
import { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { COLOR_PRIMARY } from '../../../../AntdConfigProvider';
import { useShiftActionContext } from '../../../Pages/Shift/Create/Context';
import { useInductionsLocationGet } from '../../../../hooks/inductions';
import { ApplicantsGetParams, useApplicantsGet } from '../../../../hooks/applicant';
import PrefferedApplicants, { timeFrameOptions } from '../PrefferedApplicants';
import { getDateTimeISO } from '../../../../utils';
import { Applicant } from '../../../../types/applicant';
import { useMessageError } from '../../../../hooks/common';

import styles from './index.module.scss';
import ShiftStatus from '../../../../enums/shift';

export type Select = {
  label: string;
  value: string;
};

export default function Applicants() {
  const { t } = useTranslation();
  const inductions = useInductionsLocationGet();
  const applicants = useApplicantsGet();
  const [searchParams] = useSearchParams();
  const {
    form, fields, isAdmin, setFields, shift, mode,
  } = useShiftActionContext();
  const { message } = App.useApp();
  const [isModalOpen, setModalOpen] = useState(false);
  const values = Form.useWatch([], form);
  const isEditMode = mode === 'edit';
  const isCompletedEdit = (isEditMode && shift && shift.status === ShiftStatus.COMPLETED) || false;

  useEffect(() => {
    if (applicants.data) {
      // @ts-ignore Value of type '(state: any) => any' has no properties
      setFields((state) => ({
        ...state,
        applicants: applicants?.data?.data.map(({ user, id }) => ({
          label: `${user.firstName} ${user.lastName}`,
          value: id,
        })),
      }));

      if (
        searchParams.get('applicant')
        && values?.applicant
        && !applicants.data.data.find((item) => item.id === values.applicant)
      ) {
        form?.setFieldValue('applicant', undefined);
        message.info('The applicant was removed from the shift due to a time overlap');
      }
    }
  }, [applicants.data]);

  useEffect(() => {
    if (inductions.data) {
      // @ts-ignore Value of type '(state: any) => any' has no properties
      setFields((state) => ({
        ...state,
        inductions: inductions?.data?.map(({ title, id }) => ({ title, value: id })),
      }));
    }
  }, [inductions.data]);

  useEffect(() => {
    getApplicants();
  }, []);

  useEffect(() => {
    if (values?.time && values?.date) {
      getApplicants();
    }
  }, [values?.time, values?.date]);

  useEffect(() => {
    if (values?.location) {
      inductions.fetch({ locations: [values.location] });
      if (mode !== 'edit') {
        form?.setFieldValue('inductions', []);
      }
    }
  }, [values?.location]);

  useEffect(() => {
    if (values?.rateType === 'range') {
      form?.setFieldValue('shiftType', 'client review');
    }
  }, [values?.rateType]);

  const handleSearch = useMemo(
    () => debounce((value: string) => {
      getApplicants(value);
    }, 300),
    [],
  );

  const getApplicants = (search?: string) => {
    const formData = form?.getFieldsValue();

    const params: ApplicantsGetParams = {
      pageSize: 100,
      userStatuses: ['active'],
    };

    if (formData?.date && formData?.time) {
      params.from = getDateTimeISO(formData.date[0], formData.time[0]);
      params.to = getDateTimeISO(formData.date[1], formData.time[1]);
    }

    if (search) {
      params.partialFullName = search;
    }

    applicants.fetch(params);
  };

  useMessageError([applicants, inductions]);

  return (
    <div style={{ width: '100%' }}>
      <Row gutter={[16, 0]}>
        <Col span={24} lg={8}>
          <Form.Item
            name="shiftType"
            label="Shift Type*"
            tooltip={{
              title: t('shiftType'),
              icon: <InfoCircleTwoTone style={{ cursor: 'help' }} twoToneColor={COLOR_PRIMARY} />,
            }}
          >
            <Radio.Group
              disabled={isCompletedEdit}
              onChange={() => {
                if (form?.getFieldValue('locationRole')) {
                  form?.validateFields();
                }
              }}
            >
              <Radio disabled={values?.rateType === 'range'} value="instant">
                Instant
              </Radio>
              <Radio value="client review">Client review</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>

        {values?.shiftType === 'instant' && isAdmin && (
          <Col span={8}>
            <Form.Item name="applicant" label="Assign applicant">
              <Select
                disabled={!values?.date || !values?.time || isCompletedEdit}
                placeholder="Please select"
                showSearch={false}
                onDropdownVisibleChange={(open) => {
                  if (open) {
                    getApplicants();
                  }
                }}
                // eslint-disable-next-line react/no-unstable-nested-components
                dropdownRender={(menu) => (
                  <>
                    <Input
                      placeholder="Search"
                      prefix={<SearchOutlined />}
                      onChange={(e) => handleSearch(e.target.value)}
                    />
                    {menu}
                  </>
                )}
                loading={applicants.loading}
                options={
                  // eslint-disable-next-line no-nested-ternary
                  applicants?.data?.data?.length
                    ? shift?.applicant && !applicants?.data?.data.map(({ id }) => id).includes(shift?.applicant.id)
                      ? [...applicants.data.data, shift.applicant as Applicant]?.map(({ id, user }) => ({
                        label: `${user?.firstName} ${user?.lastName}`,
                        value: id,
                      }))
                      : applicants.data?.data.map(({ id, user }) => ({
                        label: `${user.firstName} ${user.lastName}`,
                        value: id,
                      }))
                    : []
                }
              />
            </Form.Item>
          </Col>
        )}

        {values?.shiftType === 'client review' && (
          <>
            <Col span={12} lg={8}>
              <Space direction="vertical">
                <Form.Item
                  name="maxApplicantAmount"
                  label="Maximum Number of Applicants*"
                  rules={[{ required: true, message: 'Required field!' }]}
                >
                  <InputNumber
                    disabled={isCompletedEdit}
                    style={{ width: '100%' }}
                    min={1}
                    max={100}
                    placeholder="Up to 100"
                  />
                </Form.Item>
              </Space>
            </Col>
            <Col span={12} lg={8}>
              <Space direction="vertical">
                <Space>
                  <Typography.Text strong>
                    Send to Preferred Applicant(s) first
                    {' '}
                    <Tooltip title={t('shiftPreferred')}>
                      <InfoCircleTwoTone style={{ cursor: 'help' }} twoToneColor={COLOR_PRIMARY} />
                    </Tooltip>
                  </Typography.Text>
                  <Button
                    type="link"
                    onClick={() => setModalOpen(true)}
                    disabled={
                      !values?.client || !values?.date
                       || !values?.time || !values?.experiences?.length || isCompletedEdit
}
                  >
                    {values.preferredApplicants?.length ? 'Edit selection' : 'Select applicants'}
                  </Button>
                </Space>

                <div>
                  <Form.Item name="preferredApplicants" className={styles.disabledSelect}>
                    {values.preferredApplicants?.length ? (
                      <Select
                        disabled
                        mode="multiple"
                        options={fields?.preferredApplicants?.map((item) => ({
                          label: item.title,
                          value: item.value,
                        }))}
                      />
                    ) : null}
                  </Form.Item>

                  <Form.Item name="timeFrame" className={styles.disabledSelect}>
                    {values.preferredApplicants?.length ? <Select disabled options={timeFrameOptions} /> : null}
                  </Form.Item>
                </div>
              </Space>
            </Col>
          </>
        )}
      </Row>

      <Row gutter={[16, 0]}>
        <Col span={24} lg={8}>
          <Form.Item
            name="inductions"
            label="Inductions (multi-select)"
            tooltip={{
              title: t('shiftInductions'),
              icon: <InfoCircleTwoTone style={{ cursor: 'help' }} twoToneColor={COLOR_PRIMARY} />,
            }}
          >
            <Select
              disabled={!values?.location || isCompletedEdit}
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              loading={inductions.loading}
              options={inductions?.data?.map((level) => ({ label: level.title, value: level.id }))}
              mode="multiple"
              placeholder="Select Induction"
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        name="notes"
        label="Private Note to Lokem admins"
        tooltip={{
          title: t('shiftNotes'),
          icon: <InfoCircleTwoTone style={{ cursor: 'help' }} twoToneColor={COLOR_PRIMARY} />,
        }}
      >
        <Input.TextArea rows={3} placeholder="Type here" disabled={isCompletedEdit} />
      </Form.Item>

      <PrefferedApplicants isModalOpen={isModalOpen} setModalOpen={setModalOpen} />
    </div>
  );
}
