import {
  FetchSuccess,
  PagingDataResponse,
  PagingParams,
  DefaultFetchError,
  FetchCreate,
  FetchGet,
  FetchGetId,
  useFetchCreate,
  useFetchGet,
  useFetchGetId,
  useFetchDelete,
  useFetchUpdate,
  FetchUpdate,
} from './fetch';
import { Domain, Locations } from '../types/location';
import { pagingParamList } from '../contstant';

export interface Location {
  id: string;
  name: string;
  address: string;
  isActive: boolean;
  phone: string;
  email: string;
  link: string;
  domains: Domain[];
}

export interface LocationsCreateParams {
  name: string;
  domains: string[];
  client: string;
}

export interface LocationsUpdateParams {
  phone?: string;
  email?: string;
  address?: string;
  isActive?: boolean
  inductions?: string[]
}

export interface LocationsGetParams extends PagingParams {
  id?: string;
  domain?: string
  status?: string
  ids?: string[];
  client?: string;
  isMine?: boolean;
}

export const locationGetParamList = [
  'id',
  'status',
  'ids',
  ...pagingParamList,
];

export const useLocationCreate = (): FetchCreate<FetchSuccess, DefaultFetchError, LocationsCreateParams> => (
  useFetchCreate('locations')
);

export const useLocationUpdate = (): FetchUpdate<FetchSuccess, DefaultFetchError, LocationsUpdateParams> => (
  useFetchUpdate('locations')
);

export const useLocationDelete = () => useFetchDelete<FetchSuccess, DefaultFetchError, string>('locations');

export function useLocationsGet<D = PagingDataResponse<Locations>, DD = D>(
  decorateData?: (data: D) => DD,
): FetchGet<D, LocationsGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, LocationsGetParams, DD>('locations', {
    decorateData,
    autoStart: false,
    startStateLoading: false,
  });
}

export function useLocationsActiveGet<D = Locations[], DD = D>(
  decorateData?: (data: D) => DD,
): FetchGet<D, LocationsGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, LocationsGetParams, DD>('locations/active', {
    decorateData,
    autoStart: false,
    startStateLoading: false,
  });
}

export function useLocationsShiftsGet<D = PagingDataResponse<Locations>, DD = D>(
  decorateData?: (data: D) => DD,
): FetchGet<D, LocationsGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, LocationsGetParams, DD>('locations/shifts', {
    decorateData,
    autoStart: false,
    startStateLoading: false,
  });
}

export function useLocationsSelectGet<D = Locations[], DD = D>(
  decorateData?: (data: D) => DD,
): FetchGet<D, LocationsGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, LocationsGetParams, DD>('locations/select', {
    name: 'Locations',
    decorateData,
    autoStart: false,
    startStateLoading: false,
  });
}

export function useLocationsShiftsSelectGet<D = Locations[], DD = D>(
  decorateData?: (data: D) => DD,
): FetchGet<D, LocationsGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, LocationsGetParams, DD>('locations/select/shifts', {
    name: 'Locations',
    decorateData,
    autoStart: false,
    startStateLoading: false,

    beforeError(err) {
      if (err && err.response?.status === 422) {
        return true;
      }

      return false;
    },
  });
}

export const useLocationsAllGet = (clientId?: string): FetchGet<string[]> => useFetchGet(
  `locations/all${clientId ? `?clients=${clientId}` : ''}`,
);

export const useLocationsId = (): FetchGetId<Locations> => useFetchGetId('locations', '', { autoStart: false });

export const useLocationValidate = (): FetchGetId<{success: boolean}> => (
  useFetchGetId('locations/validate/removal', '', { autoStart: false })
);

export const useLocationStatusesAllSelectGet = (): FetchGet<string[]> => useFetchGet('locations/statuses/all', {
  startStateLoading: false,
  autoStart: false,
});

export const useLocationStatusesSelectGet = (): FetchGet<string[]> => useFetchGet('locations/statuses', {
  startStateLoading: false,
  autoStart: false,
});
