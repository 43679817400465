import React from 'react';

import styles from './index.module.scss';

interface TableWrapperProps {
  children: React.ReactNode
}

export default function TableWrapper({ children }: TableWrapperProps): React.ReactNode {
  return (
    <div className={styles.wrapper}>
      {children}
    </div>
  );
}
