import React from 'react';
import { useRosterContext } from '../../Context';
import Day from './Day';
import List from './List';

function Timeline(): React.ReactNode {
  const { days } = useRosterContext();

  return days.length === 1 ? <Day /> : <List />;
}

export default React.memo(Timeline);
