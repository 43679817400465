import { Form, Input } from 'antd';
import Wrapper from '../Wrapper';
import { createRulesForAntd, validationApplicant } from '../../../../utils/validations';

type AHPRAnumberProps = {
  disabled?: boolean;
};
const validationRules = createRulesForAntd(validationApplicant);

function AHPRAnumber({ disabled }: AHPRAnumberProps) {
  return (
    <div style={{ maxWidth: '770px' }}>
      <Wrapper width="calc(50% + 16px)">
        <Form.Item name="ahpra" label="AHPRA number" style={{ marginBottom: 0 }} rules={[validationRules]}>
          <Input disabled={disabled} />
        </Form.Item>
      </Wrapper>
    </div>
  );
}

AHPRAnumber.defaultProps = {
  disabled: false,
};

export default AHPRAnumber;
