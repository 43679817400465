import React from 'react';
import Layout from '../../../components/Layout';
import ContentClientUser from '../../../components/Pages/Client/User';

export default function ClientUser(): React.ReactNode {
  document.title = `${process.env.REACT_APP_TITLE_NAME}: Client user: detail page`;

  return (
    <Layout disableLeftMenu transparent>
      <ContentClientUser />
    </Layout>
  );
}
