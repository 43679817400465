import { useEffect } from 'react';
import {
  Col, Form, Row, Select,
} from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';
import { useExperiencesGet } from '../../../hooks/experience';
import Loading from '../Loading';
import { useMessageError } from '../../../hooks/common';

import styles from './index.module.scss';
import { COLOR_PRIMARY } from '../../../AntdConfigProvider';

type ExperiencesProps = {
  disabled?: boolean;
  required?: boolean;
  span?: number;
};

function Experiences({ disabled, span, required }: ExperiencesProps) {
  const experiences = useExperiencesGet();

  useEffect(() => {
    experiences.fetch();
  }, []);

  useMessageError([experiences]);

  return (
    <div>
      {experiences.loading ? (
        <div className={styles.loading}>
          <Loading />
        </div>
      ) : (
        experiences.data && (
          <Row gutter={[16, 5]}>
            <Col span={span}>
              <Form.Item
                name="experiences"
                label="Experience"
                tooltip={{
                  title: 'Select the relevant experience types that will '
                    + 'be considered a suitable match for this Role.'
                    + ' Any Applicant with one or more of these experience '
                    + 'types will be able to apply for shifts with this Role.',
                  icon: <InfoCircleTwoTone style={{ cursor: 'help' }} twoToneColor={COLOR_PRIMARY} />,
                }}
                rules={[{ required, message: 'Required field!' }]}
              >
                <Select
                  disabled={disabled}
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Please select"
                  filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  options={experiences.data?.map(({ title, id }) => ({ label: title, value: id }))}
                />
              </Form.Item>
            </Col>
          </Row>
        )
      )}
    </div>
  );
}

Experiences.defaultProps = {
  disabled: false,
  required: false,
  span: 24,
};

export default Experiences;
