import { useEffect, useMemo } from 'react';
import { App, Form } from 'antd';
import { isEqual } from 'lodash';
import { FieldData } from 'rc-field-form/lib/interface';
import { useSettingsContext } from '../Context';
import {
  useDocumentCreate,
  useDocumentDelete,
  useDocumentsAllUpdate,
  useDocumentsPaginGet,
} from '../../../../hooks/document';
import { tabList } from '../index';
import FormList from '../Common/List';
import { useSearchParams } from '../../../../hooks/useSearchParams';
import { pagingParamSearchList } from '../../../../contstant';
import { useMessageError } from '../../../../hooks/common';
import CreateModal from '../Common/CreateModal';
import { getMessageInError } from '../../../../hooks/fetch';

export default function Documents() {
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const {
    setForm, setLoading, setValid, setChanging, isChanging, activeTab,
  } = useSettingsContext();
  const [searchParams] = useSearchParams();
  const documents = useDocumentsPaginGet();
  const documentsAllUpdate = useDocumentsAllUpdate();
  const documentDelete = useDocumentDelete();
  const documentCreate = useDocumentCreate();
  const documentData = useMemo(() => {
    if (documents.data) {
      return {
        documents: documents.data.data,
      };
    }

    return {
      documents: [],
    };
  }, [documents.data]);

  useEffect(() => fetch(), [searchParams]);

  const fetch = () => {
    const props = {};

    pagingParamSearchList.forEach((key) => {
      const value = searchParams.getAll(key);

      if (value.length || !(value.length === 1 && value[0] === '')) {
        // @ts-ignore @typescript-eslint/ban-ts-comment
        props[key] = value.length === 1 ? value[0] : value;
      }
    });

    documents.fetch(props);
  };

  useEffect(() => {
    if (!isChanging) {
      form.setFieldsValue(documentData);
    }
  }, [documentData, isChanging]);

  useEffect(() => {
    setForm((state) => ({
      ...state,
      [tabList[2].key]: {
        form,
        update: documentsAllUpdate,
      },
    }));
  }, []);

  useEffect(() => {
    if (documentsAllUpdate.data) {
      message.success('Changes saved successfully!');
      setLoading(false);
      setChanging(false);
      fetch();
    }
  }, [documentsAllUpdate.data]);

  useEffect(() => {
    if (documentsAllUpdate.error) {
      message.success(getMessageInError(documentsAllUpdate.error));
      setLoading(false);
    }
  }, [documentsAllUpdate.error]);

  const onFieldsChange = (_: FieldData[], allFields: FieldData[]) => {
    if (allFields.every((field) => field?.errors?.length === 0)) {
      setValid(true);
    } else {
      setValid(false);
    }

    const values = allFields.find((item) => item.name.length === 1 && item.name[0] === 'documents')?.value;

    if (!isEqual(values, documentData?.documents)) {
      setChanging(true);
    } else {
      setChanging(false);
    }
  };

  useMessageError([documentsAllUpdate, documents]);

  return (
    <Form
      form={form}
      name="dynamic_form_document"
      autoComplete="off"
      initialValues={documentData}
      onFieldsChange={onFieldsChange}
    >
      <FormList
        deleteAction={documentDelete}
        removeTitle="Disable document"
        removeButtonText="Disable anyway"
        nameButton="Add document"
        name="documents"
        valueName="title"
        placeholder="Some document"
        fetch={fetch}
        items={documents?.data}
        requiredCheckbox
        loading={documents.loading}
      />
      {activeTab === 'documents' && (
        <CreateModal
          createAction={documentCreate}
          fetch={fetch}
          nameButton="Add document"
          valueName="title"
          nameLabel="Document name"
          requiredCheckbox
        />
      )}
    </Form>
  );
}
