import React, { useEffect } from 'react';
// import { useTranslation } from 'react-i18next';
import { AnyObject } from '@triare/auth-redux';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { SignUpPageDefaultProp } from '../../types';
import { useContextSignUp } from '../../Context';
import { RootState } from '../../../../../store';
import { moduleName } from '../../../../../store/auth';
import OTPVerification from '../../../../Common/Auth/OTPVerification';
import { list as listSteps } from '../../Wrapper';

interface VerificationProps extends SignUpPageDefaultProp {
  response: null | AnyObject;
}

function Verification({ response, name, className }: VerificationProps): React.ReactNode | null {
  // const { t } = useTranslation();
  const {
    list, setForm, onFinish, triggerBack, isPossibleTriggerBack, pageNumber,
  } = useContextSignUp();

  useEffect(() => {
    if (response) {
      onFinish(name, response);
    }
  }, [response]);

  return (
    pageNumber === 1 && (
      <OTPVerification
        email={list[listSteps[0].name] ? list[listSteps[0].name].getFieldValue('email') : 'email'}
        className={className}
        nameBtnFinish="Save and Continue"
        beforeBtnFinish={
          isPossibleTriggerBack() ? (
            <Button onClick={triggerBack} size="large">
              Go Back
            </Button>
          ) : undefined
        }
        onInit={(form) => {
          setForm(name, form);
        }}
      />
    )
  );
}

export default connect((state: RootState) => ({
  response: state[moduleName].OTP.response,
}))(Verification);
