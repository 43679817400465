import { AnyObject } from '@triare/auth-redux';
import { pagingParamList } from '../contstant';
import {
  DefaultFetchError,
  FetchGet,
  FetchGetId,
  useFetchGet,
  useFetchGetId,
  PagingDataResponse,
  PagingParams,
} from './fetch';
import { User } from './user';

export interface Logger {
  id: string;
  event: string;
  entity: string;
  action: string;
  entityId: string;
  createdAt: string;
  updatedAt: string;
  description: string;
  data: AnyObject;
  user: User;
}
export interface loggerGetParams extends PagingParams {
  entity?: string
  entityId?: string
  userId?: string
}

export const loggerGetParamList = [
  'entity',
  'entityId',
  'userId',
  ...pagingParamList,
];

export function useLoggerGet<D = PagingDataResponse<Logger>, DD = D>(
  decorateData?: (data: D) => DD,
): FetchGet<D, loggerGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, loggerGetParams, DD>('loggers', {
    decorateData,
    autoStart: false,
    startStateLoading: false,
  });
}

export interface LoggerItem {
  id: string;
  event: string;
  entity: string;
  action: string;
  entityId: string;
  createdAt: string;
  updatedAt: string;
  description: string;
  data: AnyObject;
  user: null;
  before: LoggerShiftData;
  after: LoggerShiftData;
}

export interface LoggerShiftData {
  id: string;
  rate: number;
  notes: string;
  status: string;
  rateType: string;
  shiftType: string;
  timeFrame: null;
  datetimeEnd: string;
  rateRangeTo: null;
  datetimeStart: string;
  rateRangeFrom: null;
  rejectionReason: string;
  onCallDatetimeEnd: null;
  maxApplicantAmount: number;
  onCallDatetimeStart: null;
  additionalRequirements: string;
  lunchBreak: number
  allowances: {
    type: string
    id: string
    amount: number
  }[]
}

export const useLoggerId = (): FetchGetId<LoggerItem> => useFetchGetId('loggers', '', {
  autoStart: false,
  startStateLoading: false,
});
