import {
  DefaultFetchError,
  FetchCreate,
  FetchGet,
  useFetchCreate,
  useFetchGet,
  useFetchDelete,
  FetchSuccess,
  FetchUpdate,
  useFetchUpdate,
  PagingDataResponse,
} from './fetch';

export interface Induction {
  id: string;
  title: string;
}

export interface InductionsGetParams {
  title?: string;
  partialTitle?: string
  orderByColumn?: 'id' | 'title';
}
export interface InductionsParams {
  title: string;
}

export function useInductionsGet<D = Induction[], DD = D>(
  decorateData?: (data: D) => DD,
): FetchGet<D, InductionsGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, InductionsGetParams, DD>('inductions', {
    decorateData,
    autoStart: false,
    startStateLoading: false,
  });
}
export interface InductionsLocationGetParams {
  locations?: string[]
}

export function useInductionsLocationGet<D = Induction[], DD = D>(
  decorateData?: (data: D) => DD,
): FetchGet<D, InductionsLocationGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, InductionsLocationGetParams, DD>('inductions/locations', {
    decorateData,
    autoStart: false,
    startStateLoading: false,
  });
}

export function useInductionsPaginGet<D = PagingDataResponse<Induction>,
  DD = D>(decorateData?: (data: D) => DD): FetchGet<D, InductionsGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, InductionsGetParams, DD>(
    'inductions/pagginate',
    {
      decorateData,
      autoStart: false,
      startStateLoading: false,
    },
  );
}

export type InductionsCreateParams = InductionsParams

export const useInductionCreate = (): FetchCreate<FetchSuccess, DefaultFetchError, InductionsCreateParams> => (
  useFetchCreate('inductions')
);

export const useInductionDelete = () => (
  useFetchDelete<FetchSuccess, DefaultFetchError, string>('inductions')
);

export type InductionUpdateParams = Partial<InductionsParams>

export const useInductionUpdate = (): FetchUpdate<FetchSuccess, DefaultFetchError, InductionUpdateParams> => (
  useFetchUpdate('inductions')
);

export interface InductionsAllUpdateParams extends InductionUpdateParams {
  id: string
}

export const useInductionsAllUpdate = (): FetchUpdate<
  FetchSuccess,
  DefaultFetchError,
  InductionsAllUpdateParams[]
> => (
  useFetchUpdate('inductions/all')
);
