import Checkboxes from '../../../Common/Filter/Checkboxes/index';
import FilterMenu from '../../../Common/Filter/Menu/index';
import FilterWrapper from '../../../Common/Filter/Wrapper';
import { useLocationsShiftsSelectGet } from '../../../../hooks/location';
import Select from '../../../Common/Filter/Select';
import { useClientsSelectAllGet, useClientsSelectShiftGet } from '../../../../hooks/client';
import { useMessageError, useSelectData } from '../../../../hooks/common';
import { useLocationsRolesSelectGet } from '../../../../hooks/locationsRoles';
import { useApplicantsSelectShiftsGet } from '../../../../hooks/applicant';
import { Applicant } from '../../../../types/applicant';
import { useDomainsAllGet, useDomainShiftsGet } from '../../../../hooks/domain';

interface FiltersProps {
  disabled?: boolean;
}

const addFilter = {
  statuses: ['completed'],
};

export default function Filter({ disabled }: FiltersProps) {
  const defaultDomainsAllGet = useDomainsAllGet();
  const domainsAllGet = useDomainShiftsGet();
  const domains = useSelectData(domainsAllGet);

  const defaultClientsSelectAllGet = useClientsSelectAllGet();
  const clientsSelectAllGet = useClientsSelectShiftGet();
  const clients = useSelectData(clientsSelectAllGet);

  const defaultLocationsGet = useLocationsShiftsSelectGet();
  const locationsGet = useLocationsShiftsSelectGet();
  const locations = useSelectData(locationsGet);

  const defaultLocationsRolesGet = useLocationsRolesSelectGet();
  const locationsRolesGet = useLocationsRolesSelectGet();
  const locationsRoles = (locationsRolesGet?.data
      && locationsRolesGet?.data?.map((item) => ({
        label: item.name,
        key: item.ids,
      })))
    || [];

  const defaultApplicantsSelectGet = useApplicantsSelectShiftsGet();
  const applicantsSelectGet = useApplicantsSelectShiftsGet();
  const applicantsSelect = useSelectData<Applicant[]>(applicantsSelectGet, (data, id, item) => ({
    ...item,
    label: data[id].user?.fullName,
  }));

  useMessageError([
    defaultClientsSelectAllGet, clientsSelectAllGet,
    defaultLocationsGet, locationsGet,
    defaultDomainsAllGet, domainsAllGet,
    defaultLocationsRolesGet, locationsRolesGet,
    defaultApplicantsSelectGet, applicantsSelectGet,
  ]);

  return (
    <FilterWrapper clearFilters disabled={disabled}>
      <FilterMenu>
        <Select
          title="Domain"
          name="domains"
          addFilter={addFilter}
          defaultHook={defaultDomainsAllGet}
          items={domains}
          loading={domainsAllGet.loading || defaultDomainsAllGet.loading}
          fetch={domainsAllGet.fetch}
        />

        <Checkboxes
          title="Client"
          name="clients"
          search
          checkAll
          // addFilter={addFilter}
          defaultHook={defaultClientsSelectAllGet}
          items={clients}
          loading={clientsSelectAllGet.loading || defaultClientsSelectAllGet.loading}
          fetch={clientsSelectAllGet.fetch}
          params={{ invoices: true }}
        />

        <Select
          title="Locations"
          name="locations"
          addFilter={addFilter}
          defaultHook={defaultLocationsGet}
          items={locations}
          loading={locationsGet.loading || defaultLocationsGet.loading}
          fetch={locationsGet.fetch}
          params={{ pageSize: 1000 }}
        />

        <Checkboxes
          title="Role"
          name="roles"
          checkAll
          search
          addFilter={addFilter}
          defaultHook={defaultLocationsRolesGet}
          items={locationsRoles}
          loading={locationsRolesGet.loading || defaultLocationsRolesGet.loading}
          fetch={locationsRolesGet.fetch}
          keyAsArray
          params={{ invoices: true }}
        />

        <Checkboxes
          title="Applicants"
          name="applicants"
          checkAll
          search
          addFilter={addFilter}
          defaultHook={defaultApplicantsSelectGet}
          decorateData={(data, id, item) => ({
            ...item,
            label: data[id].user?.fullName,
          })}
          items={applicantsSelect}
          loading={applicantsSelectGet.loading || defaultApplicantsSelectGet.loading}
          fetch={applicantsSelectGet.fetch}
        />
      </FilterMenu>
    </FilterWrapper>
  );
}

Filter.defaultProps = {
  disabled: false,
};
