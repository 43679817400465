import { Empty } from 'antd';
import { useLocation, useSearchParams } from 'react-router-dom';
import { AnyObject } from '@triare/auth-redux';
import { pagingParamList } from '../../../contstant';
import { getValidSearchParams } from '../../../utils';
import { getRouteParams } from '../../../routes';
import { useAuthState } from '../../../store/auth';
import styles from './index.module.scss';

interface NoDataProps {
  name?: string;
}

function NoData({ name }: NoDataProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();
  const { user } = useAuthState();
  const path = pathname.trim().split('/');

  return (
    <Empty
      description={(
        <span className={styles.text}>
          Sorry, no
          {' '}
          {name}
          {' '}
          match your criteria. Try
          <button
            className={styles.btn}
            type="button"
            onClick={(e) => {
              e.preventDefault();
              const params: AnyObject = {};

              if (pathname === '/') {
                Object.assign(params, {
                  ...getRouteParams('/', user),
                });
              }

              if (path.includes('shifts')) {
                Object.assign(params, {
                  ...getRouteParams('shifts', user),
                });
              }

              if (path.includes('applicants')) {
                Object.assign(params, {
                  ...getRouteParams('applicants', user),
                });
              }

              setSearchParams({
                ...getValidSearchParams(pagingParamList, searchParams),
                ...params,
              });
            }}
          >
            resetting your filters
          </button>
        </span>
      )}
    />
  );
}

NoData.defaultProps = {
  name: 'shifts',
};

export default NoData;
