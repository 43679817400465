import React from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { LeftOutlined } from '@ant-design/icons';
import OTPVerification from '../../../Common/Auth/OTPVerification';

import styles from './index.module.scss';

interface OTPCodeProps {
  email: string
  onBack: () => void
}

function OTPCode({ email, onBack }: OTPCodeProps): React.ReactNode | null {
  const { t } = useTranslation();

  return (
    <div
      className={styles.wrapper}
    >
      <OTPVerification
        email={email}
        nameBtnFinish="Log in"
        afterBtnFinish={(
          <Button
            type="text"
            onClick={onBack}
            size="large"
            style={{ color: '#8C8C8C' }}
            icon={<LeftOutlined />}
          >
            {t('Back')}
          </Button>
        )}
      />
    </div>
  );
}

export default OTPCode;
