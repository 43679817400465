import { useEffect, useMemo, useState } from 'react';
import { Menu, Radio as AntdRadio } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { AnyObject } from '@triare/auth-redux';
import { getItem, MenuItem } from '../Menu';
import { getValidSearchParams } from '../../../../utils';
import { useSearchParams } from '../../../../hooks/useSearchParams';
import { Item } from '../../../../hooks/common';

import styles from './index.module.scss';

function getOnlyValid(searchParams: URLSearchParams, name: string, list: Item[], key: keyof Item = 'key'): string[] {
  return searchParams.getAll(name).filter((value) => list.filter((item) => item[key] === value).length);
}

type RadioProps = {
  items: Item[];
  title: string;
  name: string;
  fetch?: (props: AnyObject) => void;
};

function Radio({
  items: list, title, name, fetch,
}: RadioProps) {
  const [searchParams, setSearchParams, paramsWithoutTableProps] = useSearchParams();
  const [itemList, setItemsList] = useState<Item[]>([]);
  const checkedItems = useMemo(() => getOnlyValid(searchParams, name, itemList), [searchParams, name, itemList]);

  useEffect(() => {
    if (fetch) {
      fetch(paramsWithoutTableProps);
    }
  }, [fetch, paramsWithoutTableProps]);

  useEffect(() => {
    setItemsList(list.map((item) => ({ ...item, key: item.key })));
  }, [list]);

  const setSearch = (items: string[]) => {
    if (itemList && itemList.length) {
      setSearchParams({
        ...getValidSearchParams('*', searchParams),
        [name]: items,
      });
    }
  };

  const handleChange = (value: string) => (e: CheckboxChangeEvent) => {
    if (value === '_all') {
      setSearch([]);

      return;
    }

    if (e.target.checked) {
      setSearch([value]);
    }
  };

  const items: MenuItem[] = [
    getItem(title, 'subMenu', null, [
      ...itemList.map((item, i) => getItem(
        <AntdRadio
          name={name}
          value={item.key}
          checked={checkedItems.includes(
            (Array.isArray(item.key) ? item.key[0] : item.key) || '',
          ) || (i === 0 && !searchParams.get(name))}
          onChange={handleChange((Array.isArray(item.key) ? item.key[0] : item.key) || '')}
        >
          {item.label}
        </AntdRadio>,
        (Array.isArray(item.key) ? item.key[0] : item.key) || '',
      )),
    ]),
  ];

  return <Menu className={styles.menu} defaultOpenKeys={['subMenu']} mode="inline" items={items} />;
}

Radio.defaultProps = {
  fetch: undefined,
};

export default Radio;
