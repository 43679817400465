import React from 'react';
import Layout from '../../../components/Layout';
import ContentRoster from '../../../components/Pages/Roster/Applicant';
import Filter from '../../../components/Pages/Roster/Filter';
import RosterProvider from '../../../components/Pages/Roster/Context';
import ModalDetails from '../../../components/Pages/Roster/Common/ModalDetails';

export default function RosterByApplicant(): React.ReactNode {
  document.title = `${process.env.REACT_APP_TITLE_NAME}: Roster by Applicant`;

  return (
    <RosterProvider>
      <Layout minWidth leftMenu={<Filter />}>
        <ContentRoster />
      </Layout>
      <ModalDetails />
    </RosterProvider>
  );
}
