import { Form, Input } from 'antd';
import { validationUserAdd, createRulesForAntd } from '../../../utils/validations';
import PhoneNumberInput from '../PhoneNumberInput';

const validationRules = createRulesForAntd(validationUserAdd);

interface AdminFieldsProps {
  showDate?: boolean;
  disabled?: boolean;
}

function AdminFields({ showDate, disabled }: AdminFieldsProps) {
  return (
    <>
      <Form.Item
        label="First Name"
        name="firstName"
        rules={[{ required: true, message: 'First name should be at least one character long!' }]}
      >
        <Input placeholder="First Name" disabled={disabled} />
      </Form.Item>

      <Form.Item
        label="Last Name"
        name="lastName"
        rules={[{ required: true, message: ' Last name should be at least one character long!' }]}
      >
        <Input placeholder="lastName" disabled={disabled} />
      </Form.Item>

      {showDate && (
        <Form.Item label="Date created" name="createdAt">
          <Input placeholder="Date created" disabled />
        </Form.Item>
      )}

      <Form.Item label="Phone" name="phone" rules={[validationRules]}>
        <PhoneNumberInput placeholder="Phone" />
      </Form.Item>

      <Form.Item label="Login Email" name="email" rules={[validationRules]}>
        <Input placeholder="Email" disabled={disabled} />
      </Form.Item>
    </>
  );
}

AdminFields.defaultProps = {
  showDate: false,
  disabled: false,
};

export default AdminFields;
