import React from 'react';
import Layout from '../../../components/Layout';
import CreateShiftForm from '../../../components/Pages/Shift/Create';

export default function CreateShift(): React.ReactNode {
  document.title = `${process.env.REACT_APP_TITLE_NAME}: Create Shift`;

  return (
    <Layout disableLeftMenu>
      <CreateShiftForm />
    </Layout>
  );
}
