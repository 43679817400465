import { useExperiencesAllGet, useExperiencesGet } from '../../../../hooks/experience';
import Checkboxes from '../../../Common/Filter/Checkboxes/index';
import FilterMenu from '../../../Common/Filter/Menu/index';
import FilterWrapper from '../../../Common/Filter/Wrapper';
import { useAuthState } from '../../../../store/auth';
import { UserRoles, isRoleEnough } from '../../../../enums/user';
import { useLocationsSelectGet } from '../../../../hooks/location';
import { useClientsSelectGet } from '../../../../hooks/client';
import { useMessageError, useSelectData } from '../../../../hooks/common';
import Select from '../../../Common/Filter/Select';
import Search from '../../../Common/Filter/Search';
import {
  useApplicantClientStatusesAllSelectGet, useApplicantClientStatusesSelectGet,
  useApplicantStatusesAllSelectGet,
  useApplicantStatusesSelectGet,
} from '../../../../hooks/applicant';

function CheckboxesClient() {
  const { user } = useAuthState();
  const defaultApplicantClientStatusesSelectGet = useApplicantClientStatusesAllSelectGet();
  const applicantClientStatusesSelectGet = useApplicantClientStatusesSelectGet();
  const statuses = useSelectData(applicantClientStatusesSelectGet);

  useMessageError([
    defaultApplicantClientStatusesSelectGet, applicantClientStatusesSelectGet,
  ]);

  return (
    <Checkboxes
      title="Applicant status"
      name="statuses"
      addFilter={{ clients: [user?.id] }}
      defaultHook={defaultApplicantClientStatusesSelectGet}
      items={statuses}
      fetch={applicantClientStatusesSelectGet.fetch}
      loading={applicantClientStatusesSelectGet.loading || defaultApplicantClientStatusesSelectGet.loading}
      uncheck
      badge
      checkAll
    />
  );
}

function CheckboxesAdmin() {
  const defaultApplicantStatusesSelectGet = useApplicantStatusesAllSelectGet();
  const applicantStatusesSelectGet = useApplicantStatusesSelectGet();
  const statuses = useSelectData(applicantStatusesSelectGet);

  useMessageError([
    defaultApplicantStatusesSelectGet, applicantStatusesSelectGet,
  ]);

  return (
    <Checkboxes
      title="Applicant status"
      name="userStatuses"
      defaultHook={defaultApplicantStatusesSelectGet}
      items={statuses}
      fetch={applicantStatusesSelectGet.fetch}
      loading={applicantStatusesSelectGet.loading}
      uncheck
      badge
      checkAll
    />
  );
}

interface FiltersProps {
  disabled?: boolean;
}

export default function Filter({ disabled }: FiltersProps) {
  const { user } = useAuthState();
  const isAdmin = isRoleEnough(user?.role, UserRoles.LOKEM_ADMIN);

  const defaultClientsSelectGet = useClientsSelectGet();
  const clientsSelectGet = useClientsSelectGet();
  const clients = useSelectData(clientsSelectGet);

  const defaultLocationsGet = useLocationsSelectGet();
  const locationsGet = useLocationsSelectGet();
  const locations = useSelectData(locationsGet);

  const defaultExperiencesGet = useExperiencesAllGet();
  const experiencesGet = useExperiencesGet();
  const experiences = useSelectData(experiencesGet);

  useMessageError([
    defaultLocationsGet, locationsGet,
    defaultExperiencesGet, experiencesGet,
    defaultClientsSelectGet, clientsSelectGet,
  ]);

  return (
    <FilterWrapper clearFilters disabled={disabled}>
      <FilterMenu>
        {isAdmin ? (
          <CheckboxesAdmin />
        ) : (
          <CheckboxesClient />
        )}

        <Select
          title="Experience"
          name="experiences"
          mode="multiple"
          addFilter={user?.role === UserRoles.MANAGER || user?.role === UserRoles.CLIENT_ADMIN ? {
            clients: [user?.id],
          } : undefined}
          defaultHook={defaultExperiencesGet}
          items={experiences}
          loading={experiencesGet.loading || defaultExperiencesGet.loading}
          fetch={experiencesGet.fetch}
        />

        {isAdmin && (
          <Checkboxes
            title="Clients"
            name="clients"
            items={clients}
            search
            defaultCheck={false}
            defaultHook={defaultClientsSelectGet}
            loading={clientsSelectGet.loading || defaultClientsSelectGet.loading}
            fetch={clientsSelectGet.fetch}
          />
        )}

        {isAdmin && (
          <Checkboxes
            title="Locations"
            name="locations"
            search
            checkAll
            defaultCheck={false}
            defaultHook={defaultLocationsGet}
            items={locations}
            loading={locationsGet.loading || defaultLocationsGet.loading}
            fetch={locationsGet.fetch}
          />
        )}
        <Search name="partialFullName" placeholder="Search applicant" />
      </FilterMenu>
    </FilterWrapper>
  );
}

Filter.defaultProps = {
  disabled: false,
};
