import { Modal } from 'antd';
import React from 'react';
import { ModalFuncProps } from 'antd/es/modal/interface';
import { ModalStaticFunctions } from 'antd/es/modal/confirm';
import { SuccessIcon } from '../../../../Common/Icon';

import styles from './index.module.scss';

interface UseSimpleModalProps extends ModalFuncProps {
  contentH2?: string
  contentSpan?: string
}

interface UseSimpleModal {
  open: (props?: UseSimpleModalProps) => void
  instance: Omit<ModalStaticFunctions, 'warn'>
  contextHolder: React.ReactElement
}

export function useSimpleModal(initProps?: UseSimpleModalProps): UseSimpleModal {
  const [instance, contextHolder] = Modal.useModal();

  return {
    open: (props?: UseSimpleModalProps) => instance.success({
      className: styles.modal,
      content: (
        <div className={styles.content}>
          <SuccessIcon />
          {initProps?.contentH2 || props?.contentH2 ? (
            <h2>{initProps?.contentH2 || props?.contentH2}</h2>
          ) : null}
          {initProps?.contentSpan || props?.contentSpan ? (
            <span>{initProps?.contentSpan || props?.contentSpan}</span>
          ) : null}
        </div>
      ),
      okButtonProps: {
        size: 'large',
      },
      okText: 'Ok',
      ...initProps,
      ...props,
    }),
    instance,
    contextHolder,
  };
}

interface SimpleModalProps extends UseSimpleModalProps {
  open: boolean;
}

export default function SimpleModal(initProps: SimpleModalProps) {
  const { contextHolder } = useSimpleModal(initProps);

  return contextHolder;
}
