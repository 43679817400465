import { useDomainsAllGet, useDomainsSelectLocationsGet } from '../../../../hooks/domain';
import { useAuthState } from '../../../../store/auth';
import Checkboxes from '../../../Common/Filter/Checkboxes/index';
import Select from '../../../Common/Filter/Select';
import FilterMenu from '../../../Common/Filter/Menu';
import FilterWrapper from '../../../Common/Filter/Wrapper';
import SearchMenu from '../../../Common/Filter/Search/Menu';
import { UserRoles, isRoleEnough } from '../../../../enums/user';
import { useMessageError, useSelectData } from '../../../../hooks/common';
import { useLocationStatusesAllSelectGet, useLocationStatusesSelectGet } from '../../../../hooks/location';
import { getBudgeColor } from '../../../Common/Badge';

interface FiltersProps {
  disabled?: boolean;
}

function Filter({ disabled }: FiltersProps) {
  const { user } = useAuthState();
  const isAdmin = isRoleEnough(user?.role, UserRoles.LOKEM_ADMIN);
  const clientId = user?.locationAdmin?.client.id || user?.manager?.client.id;

  const defaultLocationStatusesSelectGet = useLocationStatusesAllSelectGet();
  const locationStatusesSelectGet = useLocationStatusesSelectGet();
  const locationStatusesSelect = useSelectData(locationStatusesSelectGet, (data, id, item) => ({
    ...item,
    color: getBudgeColor(item.value),
  }));

  const defaultDomainsSelectLocationsGet = useDomainsAllGet();
  const domainsSelectLocationsGet = useDomainsSelectLocationsGet();
  const domainsSelectLocations = useSelectData(domainsSelectLocationsGet, (data, id, item) => ({
    ...item,
    disabled: data[id].locationsCount === 0,
  }));

  useMessageError([
    defaultDomainsSelectLocationsGet, domainsSelectLocationsGet,
    defaultLocationStatusesSelectGet, locationStatusesSelectGet,
  ]);

  return (
    <FilterWrapper clearFilters disabled={disabled}>
      <FilterMenu>
        <Select
          title="Domain"
          name="domains"
          defaultHook={defaultDomainsSelectLocationsGet}
          items={domainsSelectLocations.filter((item) => !item.disabled)}
          loading={domainsSelectLocationsGet.loading || defaultDomainsSelectLocationsGet.loading}
          fetch={domainsSelectLocationsGet.fetch}
          params={isAdmin ? undefined : { clients: clientId }}
        />

        {!isAdmin && (
          <Checkboxes
            title="Location status"
            name="isActive"
            defaultHook={defaultLocationStatusesSelectGet}
            items={locationStatusesSelect}
            fetch={locationStatusesSelectGet.fetch}
            loading={locationStatusesSelectGet.loading || defaultLocationStatusesSelectGet.loading}
            badge
            checkAll
          />
        )}

        {isAdmin && (
          <>
            <SearchMenu
              name="partialName"
              title="Client"
            />

            <Checkboxes
              title="Location status"
              name="isActive"
              defaultHook={defaultLocationStatusesSelectGet}
              items={locationStatusesSelect}
              fetch={locationStatusesSelectGet.fetch}
              loading={locationStatusesSelectGet.loading || defaultLocationStatusesSelectGet.loading}
              badge
              checkAll
            />
          </>
        )}
      </FilterMenu>
    </FilterWrapper>
  );
}

Filter.defaultProps = {
  disabled: false,
};

export default Filter;
