import { UserRoles } from '../enums/user';
import {
  DefaultFetchError,
  FetchCreate,
  FetchGet,
  FetchGetId,
  FetchSuccess,
  FetchUpdate,
  PagingDataResponse,
  PagingParams,
  useFetchCreate,
  useFetchDelete,
  useFetchGet,
  useFetchGetId,
  useFetchUpdate,
} from './fetch';
import { pagingParamList } from '../contstant';
import { IdOnly } from '../types';

export interface UserSettings {
  isEmailVerified: boolean
  isPhoneVerified: true
}

export type UserStatus = 'active' | 'deactivated' | 'pending' | 'archived'

export type UserRole = 'guest' | 'applicant' | 'manager' | 'clientAdmin' | 'lokemAdmin' | 'root'

export interface UserSimple extends IdOnly {
  id: string;
  lastName: string;
  firstName: string;
}

export interface User extends UserSimple {
  role: UserRoles
  email: string
  status: UserStatus
  phone: string
  fullName: string
  address: string
  settings: UserSettings
  locationAdmin: IdOnly
  applicant: {
    id: string
    ahpraLicenseDate: string
  }
  locationCount: number
  locationName: string
  locationNames: string[]
  createdAt: string
  receiveEmails: boolean
  receiveUserEmails: boolean
  [key: string]: any;
  manager?: {
    id: string
    locations: IdOnly[]
  }
}

export interface CreateUserPayload {
  id?: string;
  lastName: string;
  firstName: string;
  role?: UserRoles;
  email: string;
  password?: string;
  status?: UserStatus;
  phone: string;
  settings?: UserSettings;
}

export interface UpdateUserPayload extends CreateUserPayload{
  id?: string;
}

// EXAMPLE. Unused at the moment, because we using redux-saga.
export interface UserCreateParams {
  phone?: string;
  status?: string;
  role?: UserRoles;
  login?: string;
  email?: string;
  password?: string;
  lastName?: string;
  firstName?: string;
}

export const useUserCreate = (): FetchCreate<FetchSuccess, DefaultFetchError, UserCreateParams> => (
  useFetchCreate('users')
);

export const usersGetParamList = [
  'role',
  'roles',
  'locations',
  'take',
  'email',
  'status',
  'statuses',
  'orderBy',
  'fullName',
  'orderByColumn',
  'partialName',
  'ids',
  'isActive',
  'location',
  'type',
  ...pagingParamList,
];

export interface UsersGetParams extends PagingParams {
  role?: string;
  roles?: string[] | string;
  take?: number;
  email?: string;
  statuses?: string[];
  partialName?: string;
  orderByColumn?: 'id' | 'fullName' | 'email' | 'roles' | 'status' | 'phone'; // | 'created' | 'updated'
  client?: string
  location?: string
  type?: string[]
}

export function useUsersGet<D = PagingDataResponse<User>,
  DD = D>(decorateData?: (data: D) => DD): FetchGet<D, UsersGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, UsersGetParams, DD>(
    'users',
    {
      decorateData,
      autoStart: false,
      startStateLoading: false,
    },
  );
}

interface Incomplete extends User {
  endOfLicense: string
}

export function useIncompleteGet<D = PagingDataResponse<Incomplete>,
  DD = D>(decorateData?: (data: D) => DD): FetchGet<D, UsersGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, UsersGetParams, DD>(
    'users/incomplete',
    {
      decorateData,
      autoStart: false,
      startStateLoading: false,
    },
  );
}

interface AddressGetParams {
  address: string
}

export const useAddressGet = (): FetchGet<
  string[],
  AddressGetParams,
  DefaultFetchError,
  string[]
> => useFetchGet('users/address', {
  autoStart: false,
  startStateLoading: false,
  authorization: false,
});

export interface UserUpdateParams {
  role?: UserRoles;
  login?: string;
  email?: string;
  status?: string;
  password?: string;
  lastName?: string;
  firstName?: string;
  receiveUserEmails?: boolean
  receiveEmails?: boolean
}

export const useUserUpdate = (id?: string): FetchUpdate<FetchSuccess, DefaultFetchError, UserUpdateParams> => (
  useFetchUpdate('users', id)
);

export const useUserId = (id?: string): FetchGetId<User> => useFetchGetId('users', id, { autoStart: false });

export const useUserStatusesAllSelectGet = (
  id?: string | number,
): FetchGet<string[]> => useFetchGet('users/statuses/all', {
  startStateLoading: false,
  autoStart: false,
  params: { client: id },
});

export const useUserStatusesSelectGet = (
  id?: string | number,
): FetchGet<string[]> => useFetchGet('users/statuses', {
  startStateLoading: false,
  autoStart: false,
  params: { client: id },
});

export const useUserAdminStatusesSelectGet = (): FetchGet<string[]> => useFetchGet('users/admin/statuses', {
  startStateLoading: false,
  autoStart: false,
});

export const useUserClientRolesSelectAllGet = (): FetchGet<string[]> => useFetchGet('users/client/roles/all', {
  startStateLoading: false,
  autoStart: false,
});

export const useUserClientRolesSelectGet = (): FetchGet<string[]> => useFetchGet('users/client/roles', {
  startStateLoading: false,
  autoStart: false,
});

export const useUserDelete = () => useFetchDelete<FetchSuccess, DefaultFetchError, string>('users');
export const useUserDeleteGeneral = () => useFetchDelete<FetchSuccess, DefaultFetchError, string>('users/delete');

export function useUsersFilterStatusGet<D = string[], DD = D>(
  decorateData?: (data: D) => DD,
): FetchGet<D, UsersGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, UsersGetParams, DD>(
    'users/statuses',
    {
      decorateData,
      autoStart: false,
      startStateLoading: false,
    },
  );
}

interface UserChangePhone {
  phone: string
}

interface UserChangePhoneSuccess {
  secretKey: string
}

export const useUserChangePhone = (): FetchCreate<UserChangePhoneSuccess, DefaultFetchError, UserChangePhone> => (
  useFetchCreate('users/change-phone')
);

interface UserPhoneConfirm {
  phone: string
  secretKey: string
  secretCode: string
}

export const useUserPhoneConfirm = (): FetchCreate<FetchSuccess, DefaultFetchError, UserPhoneConfirm> => (
  useFetchCreate('users/change-phone/confirm')
);
