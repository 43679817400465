import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { Alert } from 'antd';
import { Error } from '@triare/auth-redux/dist/saga/common';
import { AlertProps } from 'antd/es/alert';
import { RootState } from '../../../../../../store';
import { actions, moduleName } from '../../../../../../store/auth';

import styles from './index.module.scss';

export interface AlertErrorProps extends AlertProps {
  disable?: boolean;
  error?: Error | null;
}

function AlertError({ error, disable, ...props }: AlertErrorProps): React.ReactNode | null {
  const dispatch = useDispatch();

  if (disable === false && error) {
    return (
      <Alert
        type="error"
        message={error?.data?.error || error.message}
        closable
        showIcon
        className={styles.alert}
        onClose={() => dispatch({
          type: actions.signInErrorClear.type,
        })}
        {...props}
      />
    );
  }

  return null;
}

AlertError.defaultProps = {
  error: null,
  disable: false,
};

export default connect((state: RootState) => ({
  error: state[moduleName].signIn.error,
}))(AlertError);
