import { Row } from 'antd';
import dayjs from 'dayjs';
import ViewItem from '../ViewItem';
import { capitalize } from '../../../../utils';
import { useShiftContext } from '../../../Pages/Shift/Context';
import { dateFormatShort } from '../../../../contstant';
import { Allowances } from '../../../../hooks/shift';
import styles from './index.module.scss';

export default function AllowancesDetails() {
  const { shift } = useShiftContext();

  const allowancesAmount = shift?.allowances?.reduce(
    (acc: number, allowance: Allowances) => (allowance?.amount ? acc + Number(allowance?.amount) : acc + 0),
    0,
  ) || 0;

  const getDate = () => {
    if (!shift?.datetimeStart || !shift?.datetimeEnd) {
      return '';
    }

    const start = shift?.multiShift ? shift.multiShift.datetimeStart : shift?.datetimeStart;
    const end = shift?.multiShift ? shift.multiShift.datetimeEnd : shift?.datetimeEnd;

    return ` (${dayjs(start)?.format(dateFormatShort)}-${dayjs(end)?.format(dateFormatShort)})`;
  };

  return (
    <div className={styles.wrapper}>
      {shift?.allowances?.length ? (
        <>
          <Row gutter={[16, 0]}>
            <ViewItem
              text="Allowance Type"
              value={shift?.allowances?.map((item) => item?.type && capitalize(item.type))}
            />
            <ViewItem
              text="Total Amount"
              value={shift?.allowances?.map((item: Allowances) => (item?.amount ? `$${item.amount}` : ''))}
            />
          </Row>
          <Row gutter={[16, 0]}>
            <ViewItem text={`Allowance Total${getDate()}: $${allowancesAmount}`} />
          </Row>
        </>
      ) : (
        <ViewItem text="This shift does not include any allowances." />
      )}

      <Row gutter={[16, 0]}>
        <ViewItem
          text="Breaks"
          value={`${shift?.lunchBreak || 0} min`}
        />
      </Row>
    </div>
  );
}
