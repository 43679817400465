import { TabPaneProps, Tabs as AtdTabs } from 'antd';
import clsx from 'clsx';
import React from 'react';

import styles from './index.module.scss';

export interface TabPaneItem extends TabPaneProps {
  label: string
  key: string
  children: React.ReactNode | null
}

type TabsProps = {
  className?: string
  items: TabPaneItem[]
  activeKey?: string
  onChange?: (key: string) => void
  destroyInactiveTabPane?: boolean
};

function Tabs({
  className, items, activeKey, onChange, destroyInactiveTabPane,
}: TabsProps): React.ReactNode | null {
  return (
    <AtdTabs
      destroyInactiveTabPane={destroyInactiveTabPane}
      onChange={onChange}
      activeKey={activeKey}
      type="card"
      items={items}
      className={clsx(styles.tabs, className)}
    />
  );
}

Tabs.defaultProps = {
  destroyInactiveTabPane: true,
  className: undefined,
  activeKey: undefined,
  onChange: undefined,
  destroyInactive: true,
};

export default Tabs;
