import { useEffect } from 'react';
import dayjs from 'dayjs';
import type { TableColumnsType } from 'antd';
import { Checkbox, Table } from 'antd';
import { NavLink } from 'react-router-dom';
import { useMessageError } from '../../../../hooks/common';
import { Logger, useLoggerGet } from '../../../../hooks/logger';
import { useShiftContext } from '../../../Pages/Shift/Context';
import NestedLog from '../NestedLog';
import { capitalize } from '../../../../utils';
import styles from './index.module.scss';

const columns: TableColumnsType<Logger> = [
  {
    title: 'Modifier',
    dataIndex: 'modifier',
    key: 'modifier',
    render: (_, record) => (
      record?.user?.email
        ? (
          <NavLink to={`mailto:${record?.user?.email}`}>
            {record?.user?.email }
          </NavLink>
        )
        : '<system change>'
    ),

  },
  {
    title: 'Action',
    dataIndex: 'event',
    key: 'event',
    render: (_, record) => capitalize(record.event),
  },
  {
    title: 'Timestamp',
    dataIndex: 'timestamp',
    key: 'timestamp',
    render: (_, record) => dayjs(record.updatedAt).format('MMM DD, YYYY hh:mm A'),
  },
];

export default function Logs() {
  const { shift } = useShiftContext();
  const logger = useLoggerGet();

  useEffect(() => {
    if (shift) {
      logger.fetch({ entityId: shift.id, pageSize: 999 });
    }
  }, [shift]);

  const expandedRowRender = (item: any) => <NestedLog item={item} />;

  useMessageError([logger]);

  return (
    <div>
      <Table
        className={styles.table}
        rowKey="id"
        columns={columns}
        expandable={{
          expandedRowRender,
          // eslint-disable-next-line react/no-unstable-nested-components
          expandIcon: ({ expanded, onExpand, record }) => (expanded ? (
            <Checkbox indeterminate onClick={(e) => onExpand(record, e)} />
          ) : (
            <Checkbox onClick={(e) => onExpand(record, e)} />
          )),
        }}
        pagination={false}
        dataSource={logger.data?.data.filter(({ event }) => event !== 'create') || undefined}
      />
    </div>
  );
}
