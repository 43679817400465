import React, { createContext, useState, PropsWithChildren } from 'react';
import { FormInstance } from 'antd';
import {
  DefaultFetchError, FetchCreate, FetchGetId, FetchSuccess, FetchUpdate,
} from '../../../../../hooks/fetch';
import { UserCreateParams, UserUpdateParams, User } from '../../../../../hooks/user';
import { ContextMode } from '../../../../../types';

export interface ClientUserContextProps extends ContextMode {
  resource?: FetchGetId<User>
  action?: FetchUpdate<FetchSuccess, DefaultFetchError, UserUpdateParams>
    | FetchCreate<FetchSuccess, DefaultFetchError, UserCreateParams>
  form: FormInstance | undefined,
  setForm: (callback: () => FormInstance) => void;
  isEdit: boolean;
  setEdit: (callback: () => boolean) => void;
  isChanging: boolean;
  setChanging: (callback: () => boolean) => void;
  activeTab?: string;
  setActiveTab: (callback: () => string) => void;
  onSave: (() => void) | undefined;
  setOnSave: (onSave: () => void) => void;
}

const defaultValue: ClientUserContextProps = {
  mode: undefined,
  form: undefined,
  setForm: () => {
    // default
  },

  isEdit: false,
  setEdit: () => {
    // default
  },

  isChanging: false,
  setChanging: () => {
    // default
  },

  activeTab: undefined,
  setActiveTab: () => {
    // default
  },

  onSave: () => {
    // default
  },
  setOnSave: () => {
    // default
  },
};

export const ClientUserContext = createContext<ClientUserContextProps>(defaultValue);

interface ClientUserProviderProps extends PropsWithChildren, ContextMode {
  resource?: FetchGetId<User>
  action?: FetchUpdate<FetchSuccess, DefaultFetchError, UserUpdateParams>
    | FetchCreate<FetchSuccess, DefaultFetchError, UserCreateParams>
  defaultTab?: string
}

function ClientUserProvider({
  defaultTab, mode, children, action, resource,
}: ClientUserProviderProps) {
  const [isChanging, setChanging] = useState(false);
  const [isEdit, setEdit] = useState(!!mode);
  const [form, setForm] = useState<FormInstance | undefined>();
  const [onSave, setOnSave] = useState<(() => void) | undefined>();
  const [activeTab, setActiveTab] = useState<string | undefined>(defaultTab);

  return (
    <ClientUserContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        mode,
        action,
        resource,
        form,
        setForm,

        isEdit,
        setEdit,

        isChanging,
        setChanging,

        activeTab,
        setActiveTab,

        onSave,
        setOnSave,
      }}
    >
      {children}
    </ClientUserContext.Provider>
  );
}

ClientUserProvider.defaultProps = {
  defaultTab: undefined,
  resource: undefined,
  action: undefined,
  mode: undefined,
};

export default ClientUserProvider;

export const useClientUserContext = (): ClientUserContextProps => React.useContext(ClientUserContext);
