import {
  Form, Select, Row, Col, InputNumber, Typography, Divider, Space, Button, Input,
} from 'antd';
import { useState } from 'react';
import {
  PlusCircleTwoTone, PlusOutlined, DeleteOutlined, InfoCircleTwoTone,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useShiftActionContext } from '../../../Pages/Shift/Create/Context';
import { COLOR_PRIMARY } from '../../../../AntdConfigProvider';
import replaseToDollar from '../../../../utils/regular';
import { getDateTimeISO } from '../../../../utils';
import { dateFormatShort } from '../../../../contstant';

import styles from './index.module.scss';
import { intervalBreaks } from '../../../Pages/Location/Components/Breaks';

export default function Allowances() {
  const { mode } = useShiftActionContext();
  const { form } = useShiftActionContext();
  const { t } = useTranslation();
  const values = Form.useWatch([], form);
  const [allowance, setAllowance] = useState('');
  const [items, setItems] = useState([
    { value: 'flights', label: 'Flights' },
    { value: 'meals', label: 'Meals' },
    { value: 'parking', label: 'Parking' },
  ]);
  const isEdit = mode === 'edit';

  const getDate = () => {
    if (!values?.date || !values?.time) {
      return '';
    }

    return ` (${dayjs(getDateTimeISO(values.date[0], values.time[0]))?.format(dateFormatShort)}-${dayjs(
      getDateTimeISO(values.date[1], values.time[1]),
    )?.format(dateFormatShort)})`;
  };

  return (
    <div className={styles.collapse}>
      <Row gutter={[16, 0]}>
        <Col span={24}>
          <Form.List name="allowances">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }, i) => (
                  <div key={key} className={styles.wrapper}>
                    <DeleteOutlined
                      className={clsx(styles.remove, { [styles.firstItem]: i === 0 })}
                      onClick={() => {
                        remove(name);
                        form?.validateFields();
                      }}
                    />

                    <Col span={8} lg={8}>
                      <Form.Item
                        {...restField}
                        name={[name, 'type']}
                        label={i === 0 ? 'Allowance Type' : undefined}
                        rules={[{ required: true, message: 'Missing Allowance Type' }]}
                        tooltip={
                            i === 0
                              ? {
                                title: t('allowanceType'),
                                icon: <InfoCircleTwoTone style={{ cursor: 'help' }} twoToneColor={COLOR_PRIMARY} />,
                              }
                              : undefined
                          }
                      >
                        <Select
                          style={{ width: '100%' }}
                          placeholder="Select Type"
                          options={
                              items.map((item) => ({
                                ...item,
                                disabled: values?.allowances?.some(
                                  (allowanceItem: any) => allowanceItem?.type === item?.value,
                                ),
                              })) || []
                            }
                          showSearch
                            // eslint-disable-next-line max-len
                          filterOption={(input, option) => (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())}
                            // eslint-disable-next-line react/no-unstable-nested-components
                          dropdownRender={(menu) => (
                            <>
                              {menu}
                              <Divider style={{ margin: '8px 0' }} />
                              <Space style={{ padding: '0 8px 4px' }}>
                                <Input
                                  placeholder="Please enter allowance"
                                  value={allowance}
                                  onChange={(e) => setAllowance(e.target.value)}
                                />
                                <Button
                                  type="text"
                                  icon={<PlusOutlined />}
                                  disabled={!allowance}
                                  onClick={() => {
                                    setItems([
                                      ...(items || []),
                                      {
                                        label: allowance,
                                        value: allowance,
                                      },
                                    ]);
                                    setAllowance('');
                                  }}
                                >
                                  Add allowance
                                </Button>
                              </Space>
                            </>
                          )}
                        />
                      </Form.Item>
                    </Col>
                    <Form.Item
                      {...restField}
                      name={[name, 'amount']}
                      rules={[{ required: true, message: 'Missing Total Amount' }]}
                      label={i === 0 ? 'Total Amount' : undefined}
                      tooltip={
                          i === 0
                            ? {
                              title: t('allowanceAmount'),
                              icon: <InfoCircleTwoTone style={{ cursor: 'help' }} twoToneColor={COLOR_PRIMARY} />,
                            }
                            : undefined
                        }
                    >
                      <InputNumber
                        max={100000}
                        min={1}
                        placeholder="Amount"
                        formatter={(value) => `$ ${value}`.replace(replaseToDollar, ',')}
                        controls={false}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>

                    {isEdit ? (
                      <Form.Item
                        {...restField}
                        name={[name, 'id']}
                        initialValue={undefined}
                        style={{ display: 'none' }}
                      >
                        <input type="hidden" />
                      </Form.Item>
                    ) : null}
                  </div>
                ))}
                <Form.Item>
                  <PlusCircleTwoTone className={styles.add} onClick={() => add()} twoToneColor={COLOR_PRIMARY} />
                </Form.Item>
              </>
            )}
          </Form.List>
        </Col>
        <Col span={24}>
          <Typography.Text strong>
            Allowance Total
            {getDate()}
            :
            {`$${
              values?.allowances?.reduce(
                (acc: number, item: any) => (item?.amount ? acc + Number(item?.amount) : acc + 0),
                0,
              ) || 0
            }`}
          </Typography.Text>
        </Col>
      </Row>
      <Typography.Text>
        Please note that allowance amounts are for the entire shift. They are not per day.
      </Typography.Text>
      <br />
      <br />
      <Row gutter={[16, 6]}>
        <Col span={8}>
          <Form.Item
            name="lunchBreak"
            label="Breaks"
            tooltip={{
              title: 'Break time is deducted from the total for hourly shifts',
              icon: <InfoCircleTwoTone style={{ cursor: 'help' }} twoToneColor={COLOR_PRIMARY} />,
            }}
          >
            <Select
              allowClear
              placeholder="Select"
              style={{ width: '100%' }}
              options={[
                { label: '0 min', value: 0 },
                { label: '10 min', value: 10 },
                { label: '15 min', value: 15 },
                { label: '30 min', value: 30 },
                { label: '60 min', value: 60 },
                { label: 'Custom', value: 'custom' },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          {(values && values?.lunchBreak && !intervalBreaks.includes(values.lunchBreak))
            ? (
              <Form.Item
                name="customLunchBreak"
                label=" "
                rules={[{ required: true, message: 'Required field!' }]}
              >
                <InputNumber style={{ width: '100%' }} max={60} addonAfter="min" min={1} />
              </Form.Item>
            ) : null}
        </Col>
      </Row>
    </div>
  );
}
