import React from 'react';
import {
  Button, Checkbox, Form as AntdForm, Typography,
} from 'antd';
import { Action } from '@triare/auth-redux';
import { Callbacks, Rule, ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { FormProps as AntdFormProps } from 'rc-field-form/lib/Form';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { SignInByPhoneAction, SignInByUsernameAction } from '@triare/auth-redux/dist/saga/auth/signIn';
import { useAuthState } from '../../../../store/auth';
import AlertSuccess, { AlertSuccessProps } from './Alert/Success';
import AlertError, { AlertErrorProps } from './Alert/Error';

import styles from './index.module.scss';

interface FormProps<P> extends AntdFormProps {
  validationRules: Rule;
  styles?: { [key: string]: string };

  children: React.ReactNode;
  alertSuccess?: AlertSuccessProps;
  alertError?: AlertErrorProps;

  signIn: (payload: P) => Action | void;
  onFinishFailed?: Callbacks<P>['onFinishFailed'];
}

function Form<P>({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  validationRules,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  form,
  styles: additionalStyles,
  children,
  alertSuccess,
  alertError,
  signIn,
  onFinishFailed,
  ...props
}: FormProps<P>): React.ReactNode | null {
  const { signIn: { loading }, userMe } = useAuthState();

  return (
    <AntdForm
      className={clsx(styles.form, additionalStyles?.form)}
      name="signIn"
      autoComplete="off"
      layout="vertical"
      onFinish={signIn}
      onFinishFailed={onFinishFailed}
      {...props}
    >
      <Typography.Text className={styles.text} strong>
        Log in using your credentials
      </Typography.Text>
      <AlertSuccess {...alertSuccess} />
      <AlertError {...alertError} />

      {children}

      <AntdForm.Item>
        <AntdForm.Item
          className={clsx(styles?.remember, additionalStyles?.remember)}
          name="remember"
          valuePropName="checked"
          noStyle
        >
          <Checkbox>Remember me</Checkbox>
        </AntdForm.Item>

        <NavLink to="/forgot-password" style={{ float: 'right' }}>
          Forgot password?
        </NavLink>
      </AntdForm.Item>

      <AntdForm.Item shouldUpdate className={clsx(styles.submit, additionalStyles?.submit)}>
        {({ getFieldsValue, getFieldsError }) => {
          const { email: emailValue, password: passwordValue } = getFieldsValue();
          const fieldsError = getFieldsError();

          return (
            <Button
              disabled={!emailValue || !passwordValue || fieldsError.some((item) => item.errors.length)}
              size="large"
              type="primary"
              htmlType="submit"
              block
              className={styles.btn}
              loading={loading || userMe.loading}
            >
              Log in
            </Button>
          );
        }}
      </AntdForm.Item>
    </AntdForm>
  );
}

Form.defaultProps = {
  alertSuccess: undefined,
  alertError: undefined,
  styles: {},
  initialValues: { remember: true },
  onFinishFailed: (errorInfo: ValidateErrorEntity<SignInByPhoneAction | SignInByUsernameAction>) => {
    console.log('Failed:', errorInfo);
  },
};

export default Form;
