import React from 'react';
import { DatePicker, Button } from 'antd';
import dayjs from 'dayjs';
import { NavLink } from 'react-router-dom';
import Title, { list } from '../../../Common/Title/Title';
import { useSearchParams } from '../../../../../../hooks/useSearchParams';
import { getValidSearchParams } from '../../../../../../utils';
import { dateFormat, dateFormatISO } from '../../../../../../contstant';
import { useAuthState } from '../../../../../../store/auth';
import { UserRoles } from '../../../../../../enums/user';

import styles from './index.module.scss';

const { RangePicker } = DatePicker;

interface HeaderProps {
  defaultActive?: string
}

function Header({ defaultActive }: HeaderProps): React.ReactNode {
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useAuthState();
  const from = searchParams.get('from');
  const to = searchParams.get('to');
  const dayJsFrom = dayjs(from, dateFormatISO).utc();
  const dayJsTo = dayjs(to, dateFormatISO).utc();

  return (
    <div className={styles.header}>
      <Title defaultActive={defaultActive as string} />

      <div className={styles.filter}>
        {user?.role === UserRoles.APPLICANT ? null : (
          <NavLink to="/shifts/create">
            <Button className={styles.btn} type="primary">Create shift</Button>
          </NavLink>
        )}

        <RangePicker
          defaultValue={[
            from ? dayjs(from, dateFormatISO) : null,
            to ? dayjs(to, dateFormatISO) : null,
          ]}
          format={dateFormat}
          value={[dayJsFrom.isValid() ? dayJsFrom : null, dayJsTo.isValid() ? dayJsTo : null]}
          onChange={(date) => {
            if (!date) {
              setSearchParams({
                ...getValidSearchParams('*', searchParams),
                from: [],
                to: [],
              });
            } else {
              setSearchParams({
                ...getValidSearchParams('*', searchParams),
                from: (date as dayjs.Dayjs[])[0].utc().startOf('day').toISOString(),
                to: (date as dayjs.Dayjs[])[1].utc().endOf('day').toISOString(),
              });
            }
          }}
        />
      </div>
    </div>
  );
}

Header.defaultProps = {
  defaultActive: list[0].key,
};

export default Header;
