import { useNavigate } from 'react-router-dom';
import { Button, Typography } from 'antd';

import styles from './index.module.scss';

export default function SendSuccess() {
  const navigate = useNavigate();

  return (
    <>
      <Typography.Title level={5} className={styles.title}>
        Please check your email
      </Typography.Title>
      <Typography.Paragraph className={styles.secondary} type="secondary">
        A reset password link has been sent to your email address.
      </Typography.Paragraph>
      <Button size="large" type="primary" block onClick={() => navigate('/sign-in')}>
        Back to log in
      </Button>
    </>
  );
}
