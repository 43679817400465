import { useEffect } from 'react';
import {
  Checkbox, Col, Form, Row, App, Select, Typography,
} from 'antd';
import { getMessageInError } from '../../../../hooks/fetch';
import Loading from '../../../Common/Loading';
import { useDocumentsGet } from '../../../../hooks/document';

const styles = {
  with: '100%',
  height: '70px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

interface DocumentsProps {
  disabled?: boolean;
}

function Documents({ disabled }: DocumentsProps) {
  const documents = useDocumentsGet({ signUp: true });
  const { message } = App.useApp();

  useEffect(() => {
    if (documents.error) {
      message.error(getMessageInError(documents.error));
      documents.clearError();
    }
  }, [documents.error]);

  useEffect(() => {
    documents.fetch();
  }, []);

  return (
    <div>
      {documents.loading ? (
        <div style={styles}>
          <Loading />
        </div>
      ) : (
        documents.data && (
          <Row gutter={[16, 5]}>
            <Col span={24}>
              <Typography.Text strong>Minimum Required Documents</Typography.Text>
            </Col>
            <Col span={24}>
              <Checkbox checked disabled>
                Resume (always required)
              </Checkbox>
            </Col>
            <Col span={24}>
              <Form.Item name="documents">
                <Select
                  disabled={disabled}
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Please select"
                  filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  options={documents.data
                    ?.map(({ title, id }) => ({ label: title, value: id }))
                    .filter(({ label }) => label.toLowerCase() !== 'resume')}
                />
              </Form.Item>
            </Col>
          </Row>
        )
      )}
    </div>
  );
}

Documents.defaultProps = {
  disabled: false,
};
export default Documents;
