import React from 'react';
import Layout from '../../../components/Layout';
import ContentShiftCalendar from '../../../components/Pages/Shift/Calendar';
import Filter from '../../../components/Pages/Shift/Filter';

export default function ShiftCalendar(): React.ReactNode {
  document.title = `${process.env.REACT_APP_TITLE_NAME}: Shift: calendar`;

  return (
    <Layout minWidth leftMenu={<Filter />}>
      <ContentShiftCalendar />
    </Layout>
  );
}
