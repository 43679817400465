import React, { useEffect } from 'react';
import {
  Alert, Button, Checkbox, Col, Form, Row,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SignUpPageDefaultProp } from '../../types';
import { useContextSignUp } from '../../Context';
import { actions, useAuthState } from '../../../../../store/auth';
import { useSimpleModal } from '../../Modal/Simple';
import { getMessageInError } from '../../../../../hooks/fetch';
import { generateDefaultUrl } from '../../../../../routes';

interface FormValues {
  confirm: boolean;
}

type ConfirmationProps = SignUpPageDefaultProp;

export default function Confirmation({ id, name, className }: ConfirmationProps): React.ReactNode | null {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { open, contextHolder } = useSimpleModal();
  const dispatch = useDispatch();
  const { user } = useAuthState();
  const [form] = Form.useForm();
  const {
    pageNumber, setForm, onFinish, triggerBack, signUpFinish, isPossibleTriggerBack,
  } = useContextSignUp();

  useEffect(() => {
    setForm(name, form);
  }, [name, form]);

  useEffect(() => {
    if (pageNumber === id && signUpFinish.response) {
      dispatch({
        type: actions.signUpFinish.type,
        payload: {
          access: signUpFinish.data?.access,
          refresh: signUpFinish.data?.refresh,
          remember: true,
        },
      });

      navigate(`/${generateDefaultUrl(user)}`);
    }
  }, [signUpFinish.response, id, pageNumber]);

  const onFinishHandler = (values: FormValues) => {
    open({
      contentH2: 'Application submitted!',
      contentSpan:
        "Thank you for submitting your application. You can log in to Lokem but you won't be able "
        + 'to apply for shifts until your profile has been reviewed and approved by Lokem staff.',
      okText: t('Log in'),
      onOk: () => onFinish(name, values),
    });
  };

  return (
    <Form<FormValues>
      form={form}
      className={className}
      name={name}
      autoComplete="off"
      layout="vertical"
      onFinish={onFinishHandler}
    >
      <h2>Confirmation</h2>
      <span>
        Once you click the text box below and submit this registration, it will be reviewed by a Lokem staff member. We
        will check everything over and may give you a quick call before approving your profile. You’ll be applying for
        shifts in no time!
      </span>
      <Row gutter={24}>
        <Col span={24}>
          <span />
          <Form.Item name="confirm" valuePropName="checked">
            <div>
              <Checkbox onChange={(e) => form.setFieldValue('confirm', e.target.checked)}>
                I confirm that I agree to Lokem’s
              </Checkbox>
              <a target="_blank" href="/privacy" style={{ marginLeft: -2 }}>
                terms and conditions
              </a>
              .
            </div>
          </Form.Item>
        </Col>
      </Row>

      {signUpFinish.error ? (
        <Alert
          type="error"
          message={getMessageInError(signUpFinish.error)}
          closable
          onClose={signUpFinish.clearError}
        />
      ) : null}

      <div>
        {isPossibleTriggerBack() ? (
          <Button onClick={triggerBack} size="large">
            Go Back
          </Button>
        ) : null}
        <Form.Item shouldUpdate className="submit">
          {() => (
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              loading={signUpFinish.loading}
              disabled={!form.getFieldValue('confirm')}
            >
              Submit
            </Button>
          )}
        </Form.Item>
      </div>
      {contextHolder}
    </Form>
  );
}
