import { useState, useEffect } from 'react';
import {
  List as AntdList, Tag, Button, Space, App,
} from 'antd';
import {
  ExclamationCircleFilled,
  CalendarOutlined,
  ClockCircleOutlined,
  DeleteOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import type { PaginationProps } from 'antd';
import { NavLink } from 'react-router-dom';
import dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useDispatch } from 'react-redux';
import {
  NotificationsGetParams,
  notificationsGetParamList,
  useNotificationsDelete,
  useNotificationsGet,
  useNotificationsSaw,
} from '../../../../hooks/notification';
import Loading from '../../../Common/Loading';
import { getValidSearchParams } from '../../../../utils';
import { useSearchParams } from '../../../../hooks/useSearchParams';
import { useMessageError } from '../../../../hooks/common';
import { COLOR_PRIMARY } from '../../../../AntdConfigProvider';
import { dateFormatShort, timeFormat12 } from '../../../../contstant';
import {
  useSendAllFetch, FetchUpdate, FetchSuccess, DefaultFetchError,
} from '../../../../hooks/fetch';
import { notificationAction } from '../../../../store/notifications';
import styles from './index.module.scss';

dayjs.extend(utcPlugin);
dayjs.extend(customParseFormat);

interface ListProps {
  category: string;
}

export default function List({ category }: ListProps) {
  const notifications = useNotificationsGet();
  const sawNotifications = useNotificationsSaw();
  /* eslint-disable max-len */
  const sendAllFetch = useSendAllFetch<FetchUpdate<FetchSuccess, DefaultFetchError, null>>(sawNotifications);
  const deleteNotifications = useNotificationsDelete();
  const dispatch = useDispatch();
  const { message } = App.useApp();
  const [searchParams, setSearchParams] = useSearchParams();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    pageSizeOptions: [5, 10, 20, 30],
    total: 5,
  });

  const getNotifications = (page?: number, pageSize?: number) => {
    const props: NotificationsGetParams = { pageSize: 5, orderBy: 'DESC' };

    notificationsGetParamList.forEach((key) => {
      const value = searchParams.getAll(key);

      if (value.length || !(value.length === 1 && value[0] === '')) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        props[key] = value.length === 1 ? value[0] : value;
      }
    });

    if (page) {
      props.page = page;
    }
    if (pageSize) {
      props.pageSize = pageSize;
    }
    props.category = category;
    props.removed = false;
    notifications.fetch(props);
  };

  useEffect(() => {
    getNotifications();
  }, []);

  useEffect(() => {
    if (deleteNotifications.data) {
      if (notifications?.data?.data.length === 1) {
        getNotifications((notifications?.data?.meta.page || 2) - 1 || 1);
        setSearchParams({
          ...getValidSearchParams(notificationsGetParamList, searchParams),
          page: ((notifications?.data?.meta.page || 2) - 1)?.toString() || '',
        });
      } else {
        getNotifications();
      }

      message.info('Notification was successfully deleted');
    }
  }, [deleteNotifications.data]);

  useEffect(() => {
    if (notifications?.data?.data) {
      markPageAsRead();
      setPagination({
        ...pagination,
        total: notifications?.data?.meta.itemCount,
        current: notifications?.data?.meta.page,
      });
    }
  }, [notifications.data]);

  const handlePageChange: PaginationProps['onShowSizeChange'] = (page, pageSize) => {
    setPagination({
      ...pagination,
      current: page,
      pageSize,
    });

    setSearchParams({
      ...getValidSearchParams(notificationsGetParamList, searchParams),
      page: page?.toString() || '',
      pageSize: pageSize?.toString() || '',
    });
    getNotifications(page, pageSize);
  };

  const markPageAsRead = () => {
    const unreadNotifications = notifications?.data?.data?.filter((notification) => !notification.status.saw);

    if (!unreadNotifications) return;

    sendAllFetch.fetch(unreadNotifications.map((notification) => [null, `${notification.id}/saw`]));
  };

  useEffect(() => {
    if (!sendAllFetch.loading && sawNotifications.response) {
      dispatch(notificationAction());
    }
  }, [sendAllFetch.loading, sawNotifications.response]);

  useMessageError([notifications, deleteNotifications, sawNotifications]);

  return notifications.loading ? (
    <div className={styles.loading}>
      <Loading />
    </div>
  ) : (
    notifications?.data?.data && (
      <AntdList
        itemLayout="vertical"
        size="large"
        locale={{ emptyText: 'No notifications to display' }}
        pagination={{
          onChange: handlePageChange,
          showSizeChanger: true,
          showQuickJumper: true,
          ...pagination,
        }}
        dataSource={notifications.data.data}
        loading={deleteNotifications.loading}
        renderItem={(item) => (
          <AntdList.Item
            className={styles.item}
            key={item.id}
            actions={[
              <Space key="date" className={styles.date}>
                <CalendarOutlined />
                {dayjs.utc(item.createdAt).format(dateFormatShort)}
              </Space>,
            ]}
            extra={(
              <div className={styles.extra}>
                <Space>
                  {item.link && (
                    <NavLink to={`/${item.link}`} target="_blank">
                      <Button type="primary" ghost>
                        {item.type === 'new' && item.entity === 'applicants' && (
                          <Space>
                            <EyeOutlined />
                            View applicant
                          </Space>
                        )}
                        {item.type === 'news' && item.entity === 'shifts' && (
                          <Space>
                            <EyeOutlined />
                            View shifts
                          </Space>
                        )}
                        {item.type === 'new' && item.entity === 'shifts' && (
                          <Space>
                            <EyeOutlined />
                            View shift
                          </Space>
                        )}
                      </Button>
                    </NavLink>
                  )}
                  <Button type="text" className={styles.removeBtn} onClick={() => deleteNotifications.fetch(item.id)}>
                    <DeleteOutlined />
                  </Button>
                </Space>
                <Space>
                  <ClockCircleOutlined className={styles.time} />
                  {dayjs.utc(item.createdAt).local().format(timeFormat12)}
                </Space>
              </div>
            )}
          >
            <AntdList.Item.Meta
              avatar={<ExclamationCircleFilled className={styles.avatar} />}
              title={(
                <Space>
                  {item.title}
                  {!item.status.saw && (
                    <Tag color={COLOR_PRIMARY} className={styles.badge}>
                      New
                    </Tag>
                  )}
                </Space>
              )}
              description={item.message}
            />
          </AntdList.Item>
        )}
      />
    )
  );
}
